
import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {DatePicker, DefaultButton, IColumn} from '@fluentui/react';

export class DateTimeColumn extends EnhancedColumn {
  public static type = ColumnType.DateTime;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return <>{typeof value === 'string' ? DateTimeColumn.mapValue(value) : ''}</>;
  }

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? DateTimeColumn.mapValue(itemValue).includes(lowerCaseSearch) : false;
  }

  private static mapValue(value: string): string {
    return value.replace('T', ' ').split('.')[0];
  }

  public static getColumnValueForExcel(itemValue: string): string {
    return typeof itemValue === 'string' ? DateTimeColumn.mapValue(itemValue) : '';
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined) => void,
    allOptions: string[]
  ): JSX.Element {
    let startDate: Date | undefined = defaultValue ? new Date(defaultValue[0]) : undefined;
    let endDate: Date | undefined = defaultValue && defaultValue.length === 2 ? new Date(defaultValue[1]) : undefined;
    let dates: string[];
    return (
      <>
        <DatePicker
          isRequired
          label="Start Date"
          value={defaultValue ? new Date(defaultValue[0]) : undefined}
          onSelectDate={value => {
            if (value) startDate = value;
            if (startDate && endDate) {
              dates = [startDate.toDateString(), endDate.toDateString()];
              onChange(dates);
            }
          }}
        />
        <DatePicker
          isRequired
          label="End Date"
          value={defaultValue && defaultValue.length === 2 ? new Date(defaultValue[1]) : undefined}
          onSelectDate={value => {
            if (value) endDate = value;
            if (startDate && endDate) {
              dates = [startDate.toDateString(), endDate.toDateString()];
              onChange(dates);
            }
          }}
        />
        <DefaultButton
          aria-label="Clear the date input"
          text="Clear"
          onClick={() => {
            dates = [];
            onChange();
          }}
        />
      </>
    );
  }

  public static filterFunction(itemValue: string | null, filterValue: string[]): boolean {
    if (itemValue && filterValue.length === 2) {
      const itemDate = new Date(itemValue);
      const startDate = new Date(new Date(filterValue[0]));
      const endDate = new Date(filterValue[1]);

      return itemDate >= startDate && itemDate <= endDate;
    } else if (itemValue === null) {
      return false;
    } else {
      return true;
    }
  }
}
