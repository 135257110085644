import {useEffect, useMemo, useState} from 'react';

import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {MasterProcessManage} from '../../../models';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {UnitMinimalDto} from '../../../services/generated';

export const useUnitAssignmentPage = () => {
  const {getMasterProcessManage} = useMasterProcess();
  const {getAdminUnitAssignment} = useAdmin();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [masterProcessData, setMasterProcessData] = useState<MasterProcessManage[]>([]);
  const [adminUnitAssignData, setAdminUnitAssignData] = useState<UnitMinimalDto[]>([]);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const responseMasterProcesses = await getMasterProcessManage();
      if (responseMasterProcesses.result && !responseMasterProcesses.apiCode) {
        const masterProcessListFiltered = responseMasterProcesses.result.filter(
          item => item.deleted === false
        );
        setMasterProcessData(masterProcessListFiltered);
      }

      const adminUnitAssignData = await getAdminUnitAssignment();
      if (adminUnitAssignData.result && !adminUnitAssignData.apiCode) {
        setAdminUnitAssignData(adminUnitAssignData.result);
      }
      setIsDataLoading(false);
    })();
  }, [getMasterProcessManage, getAdminUnitAssignment]);

  return useMemo(
    () => ({isDataLoading, masterProcessData, adminUnitAssignData}),
    [isDataLoading, masterProcessData, adminUnitAssignData]
  );
};
