import {FC, useContext, useMemo, useState} from 'react';
import {sortByTitle} from '../../../../helpers/arrayMehods';
import {DocumentSlim} from '../../../../models/DocumentSlim';
import {Accordion} from '../../../common/Accordion';
import {ColumnKey} from '../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {DocumentAndFile} from '../../../common/ProcessSections';
import {getControlled} from '../helpers';
import styles from '../Process.module.scss';
import {DictionariesContext} from '../../../../providers';

interface IProcessDocumentsProps {
  documents: DocumentSlim[] | null;
}

export const ProcessDocuments: FC<IProcessDocumentsProps> = ({documents}) => {
  const {globalFiltersFunction} = useContext(DictionariesContext);

  const hiddenColumns = useMemo(
    () => [
      ColumnKey.Active,
      ColumnKey.Order,
      ColumnKey.DocumentOwner,
      ColumnKey.FunctionString,
      ColumnKey.Specialty,
      ColumnKey.ProjectSizeCategory,
      ColumnKey.ApprovedDate,
      ColumnKey.SecurityLevel,
      ColumnKey.Language
    ],
    []
  );

  const [itemsFiltered, setItemsFiltered] = useState<boolean>(false);

  const filteredDocuments = useMemo(() => {
    const result = getControlled(documents)?.filter(globalFiltersFunction).sort(sortByTitle) || [];
    if (documents && documents.length > 0 && result.length !== documents.length) {
      setItemsFiltered(true);
    } else {
      setItemsFiltered(false);
    }

    return result;
  }, [documents, globalFiltersFunction]);

  return (
    <div className={styles.documentsWrapper}>
      <Accordion isOpen={!!filteredDocuments.length} header={'Documents'}>
        <DocumentAndFile
          itemsFiltered={itemsFiltered}
          data={filteredDocuments}
          hiddenColumns={hiddenColumns}
        />
      </Accordion>
    </div>
  );
};
