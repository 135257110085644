import {Links, ParentProcessDto} from '../services/generated';

export class ParentProcess implements ParentProcessDto {
  public id: number;
  public link: Links[] | null;
  public unitId: number;
  public unitTitle: string | null;
  public canNotBeImplemented: boolean;

  public get title() {
    return this.unitTitle;
  }

  constructor(parentProcess: ParentProcessDto) {
    this.id = parentProcess.id;
    this.link = parentProcess.link;
    this.unitId = parentProcess.unitId;
    this.unitTitle = parentProcess.unitTitle;
    this.canNotBeImplemented = parentProcess.canNotBeImplemented;
  }
}
