import {Icon, Spinner} from '@fluentui/react';
import {useContext} from 'react';
import {Navigate, NavLink, Route, Routes, useNavigate} from 'react-router-dom';

import {UserContext} from '../../../providers';
import {MiddleTopBarWrapper} from '../../common/MiddleTopBarWrapper';
import {AllExistingProcesses} from '../AllProcesses';
import {LinksCenter} from '../LinksCenter';
import {MasterProcessListPage} from '../MasterProcessList';
import {UnitsAssignmentCenter} from '../UnitAssignment';
import {AdminPage} from './AdminPage';
import {ViewsCenter} from '../ViewsCenter';
import {GlobalSettings} from '../GlobalSettings/GlobalSettings';
import {ReleasePage} from '../ReleasePage';
import {DocumentsCenter} from '../DocumentsCenter';
import {UnitsCenter} from '../UnitsCenter';
import {FeedbackCenter} from '../FeedbackCenter';
import {ActivitiesCenter} from '../ActivitiesCenter';
import {PreviousUnitPageContext} from '../../../providers/PreviousUnitPageContext';
import {BrokenLinksCenter} from '../BrokenLinksCenter';
import {Helmet} from 'react-helmet';
import styles from './Admin.module.scss';

export const Admin = () => {
  const {isUserAdmin, isUserLoading} = useContext(UserContext);
  const {unitId} = useContext(PreviousUnitPageContext);
  const navigate = useNavigate();

  return (
    <main className={`${isUserLoading ? 'isLoading' : ''} noPaddingTop`}>
      {isUserLoading ? (
        <Spinner />
      ) : isUserAdmin ? (
        <>
          <Helmet>
            <title>IMS Admin Panel</title>
          </Helmet>
          <MiddleTopBarWrapper
            li={
              <>
                <li>
                  <Icon
                    className={styles.homeIcon}
                    iconName="Home"
                    onClick={() => navigate(unitId ? `/unit/${unitId}` : '/')}
                  />
                </li>
                <li>
                  <NavLink to={'masterProcessList'}>Master Process List</NavLink>
                </li>
                <li>
                  <NavLink to={'unitsAssignmentCenter'}>Unit Process Assignment</NavLink>
                </li>
                <li>
                  <NavLink to={'unitsCenter'}>Units Center</NavLink>
                </li>
                <li>
                  <NavLink to={'independentProcesses'}>Independent Processes</NavLink>
                </li>
                <li>
                  <NavLink to={'documentsCenter'}>Documents Center</NavLink>
                </li>
                <li>
                  <NavLink to={'activitiesCenter'}>Activities Center</NavLink>
                </li>
                <li>
                  <NavLink to={'linksCenter'}>Links Center</NavLink>
                </li>
                <li>
                  <NavLink to={'brokenLinksCenter'}>Broken links Center</NavLink>
                </li>
                <li>
                  <NavLink to={'viewsCenter'}>Views Center</NavLink>
                </li>
                <li>
                  <NavLink to={'feedbackCenter'}>Feedback Center</NavLink>
                </li>
                <li>
                  <NavLink to={'globalSettings'}>Global Settings</NavLink>
                </li>
                <li>
                  <NavLink to={'releaseNotes'}>Release notes</NavLink>
                </li>
              </>
            }
            hideFilters
          />
          <Routes>
            <Route path="/" element={<AdminPage />} />
            <Route path="masterProcessList" element={<MasterProcessListPage />} />
            <Route path="unitsAssignmentCenter" element={<UnitsAssignmentCenter />} />
            <Route path="unitsCenter" element={<UnitsCenter />} />
            <Route path="independentProcesses" element={<AllExistingProcesses />} />
            <Route
              path="documentsCenter"
              element={
                <DocumentsCenter
                  showEditAuditable={false}
                  showEditDetails={true}
                  showHeader
                  frozen
                />
              }
            />
            <Route path="activitiesCenter" element={<ActivitiesCenter />} />
            <Route path="linksCenter" element={<LinksCenter />} />
            <Route path="brokenLinksCenter" element={<BrokenLinksCenter />} />
            <Route path="viewsCenter" element={<ViewsCenter />} />
            <Route path="feedbackCenter" element={<FeedbackCenter />} />
            <Route path="globalSettings" element={<GlobalSettings />} />
            <Route path="releaseNotes" element={<ReleasePage />} />
          </Routes>
        </>
      ) : (
        <Navigate replace to="/accessDenied" />
      )}
    </main>
  );
};
