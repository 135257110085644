import styles from './MasterProcessInput.module.scss';
import {FC, useCallback} from 'react';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {SpecialityDto} from '../../../../services/generated';
import { DictionaryMultiselectDropdown } from '../../../common/DictionaryDropdown/DictionaryMultiselectDropdown';

interface IMasterProcessInputSpecialtyProps {
  model: MasterProcessItemModel;
  options: SpecialityDto[] | null;
  disabled?: boolean;
}

export const MasterProcessInputSpeciality: FC<IMasterProcessInputSpecialtyProps> = ({
  model,
  options,
  disabled
}) => {
  const onChange = useCallback(
    (value: SpecialityDto[] | undefined) => (model.specialities = value || null),
    [model]
  );
  return (
    <DictionaryMultiselectDropdown
      className={styles.input}
      options={options}
      onChange={onChange}
      defaultValues={model.specialities}
      disabled={disabled || !model.isChecked}
    />
  );
};
