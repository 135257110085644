import {FC, useCallback, useEffect, useState} from 'react';
import {Accordion} from '../../../../common/Accordion';
import {Spinner} from '@fluentui/react';
import {useComments} from '../../../../../hooks/services/useComments';
import {Process, ProcessComment} from '../../../../../models';
import {AddFeedback} from './AddFeedback';
import {FeedBackCommentWithReplies} from './FeedBackCommentWithReplies';

interface IFeedbackProps {
  process: Process;
  unitId: number
}

export const Feedback: FC<IFeedbackProps> = ({process, unitId}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [feedback, setFeedback] = useState<ProcessComment[]>();
  const [allReplyTo, setAllReplyTo] = useState<ProcessComment[]>();
  const {getComments} = useComments();

  const onToggle = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
  }, []);

  const refresh = useCallback(
    (skipLoading?: boolean) => {
      (async () => {
        if (!skipLoading) setIsLoaded(false);
        const response = await getComments(process.id);
        const newFeedback = response.result || [];
        newFeedback.sort((a, b) => (a.created < b.created ? 1 : -1));
        const mainComments = newFeedback.filter(x => x.replyTo === null);
        const allReplies = newFeedback.filter(x => x.replyTo);
        setFeedback(mainComments);
        setAllReplyTo(allReplies);
        if (!skipLoading) setIsLoaded(true);
      })();
    },
    [getComments, process]
  );

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion isOpen={isOpen} header={`Feedback`} onToggle={onToggle}>
      <AddFeedback processId={process.id} onCommentAdded={refresh} />
      {isLoaded ? (
        <div>
          {feedback
            ?.filter(x => x.replyTo == null)
            .map(comment => (
              <FeedBackCommentWithReplies
                key={comment.id}
                allReplyTo={allReplyTo}
                unitId={unitId}
                comment={comment}
                process={process}
                refresh={refresh}
              />
            ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Accordion>
  );
};
