import {Links} from '../services/generated';

export class ProcessBase {
  public id: number;
  public readonly link: Array<Links> | null;
  public title: string | null;

  constructor(base: ProcessBase) {
    this.id = base.id;
    this.link = base.link;
    this.title = base.title;
  }
}
