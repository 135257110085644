import styles from './EnhancedFilterButton.module.scss';
import {BaseItem, ColumnKey} from './IEnhancedDetailsList';
import {EnhancedColumn} from './ColumnTypes/EnhancedColumn';
import {FC, useCallback} from 'react';
import {IComboBoxOption, IContextualMenuListProps, IRenderFunction} from '@fluentui/react';
import {TheButton} from '../TheButton';

interface IEnhancedFilterButtonProps {
  columnKey: ColumnKey;
  type: typeof EnhancedColumn;
  filter: {
    [key: string]: string | string[] | undefined;
  };
  onFilterChange: (
    key: ColumnKey,
    value?: string | string[] | undefined,
    options?: IComboBoxOption[]
  ) => void;
  items: BaseItem[];
}

export const EnhancedFilterButton: FC<IEnhancedFilterButtonProps> = ({
  columnKey,
  type,
  filter,
  onFilterChange,
  items
}) => {
  const hasFilter = useCallback((): boolean => {
    const value = (filter as any)[columnKey];
    return type.hasActiveFilter(value);
  }, [type, filter, columnKey]);
  return (
    <TheButton
      isIcon
      iconProps={{
        iconName: hasFilter() ? 'FilterSolid' : 'Filter'
      }}
      className={styles.filterIcon}
      menuProps={{
        calloutProps: {
          preventDismissOnEvent: ev => {
            return false;
          }
        },
        items: [
          {
            key: ''
          }
        ],
        onRenderMenuList: (
          menuListProps?: IContextualMenuListProps,
          defaultRender?: IRenderFunction<IContextualMenuListProps>
        ) => {
          const defaultValue = (filter as any)[columnKey];
          const onChange = (newValue: string | string[] | undefined) =>
            onFilterChange(columnKey, newValue);
          const allOptions = items?.map(item => (item[columnKey] as string) || '') || [];
          return (
            <div className={styles.filterWrapper}>
              {type.renderFilter(defaultValue, onChange, allOptions)}
            </div>
          );
        }
      }}
    />
  );
};
