/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = MainNode
 *
 * 2 = SupportingNode
 *
 * 3 = MatrixStage
 *
 * 4 = MatrixFunction
 */
export enum DiagramProcessGroup {
  MainNode = 1,
  SupportingNode = 2,
  MatrixStage = 3,
  MatrixFunction = 4,
}
