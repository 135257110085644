import {Icon, MessageBar, MessageBarType} from '@fluentui/react';
import {FC, useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Navigation} from '../Navigation';
import logo from '../../../logo.svg';
import styles from './Header.module.scss';
import {HiddenTopBar} from '../PreferencesForm';
import {LogsBar} from '../LogsBar';
import {SearchBox} from '@fluentui/react/lib/SearchBox';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import {InfoTooltip} from '../InfoTooltip';


export const Header: FC<{hasHiddenTopBar?: boolean}> = ({hasHiddenTopBar}) => {
  const {auth} = useContext(AuthenticationContext);
  const {search} = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const {unitId} = useParams();
  const {globalSettings} = useContext(GlobalSettingsContext);
  useEffect(() => {
    const value = new URLSearchParams(search).get('search') || '';
    setSearchValue(value);
  }, [search]);
  const paramUnit = new URLSearchParams(search).get('unitId');

  const onSearchRun = (searchValue: string) => {
    const unit = unitId || paramUnit || '';
    navigate(
      `/searchResults?search=${searchValue}${unit ? `&unitId=${unit}` : ''}` /*, {
      replace: true
    }*/
    );
  };

  

  return (
    <>
      {globalSettings && globalSettings.settings.turnOffNotifications === 'true' && (
        <MessageBar messageBarType={MessageBarType.warning}>
          Maintenance Mode is on, E-mail notifications will not be sent.
        </MessageBar>
      )}
       {globalSettings && globalSettings.settings.turnOffNotifications === 'false' && globalSettings.unitTurnOffNotification === true && (
        <MessageBar messageBarType={MessageBarType.warning}>
          Unit's Maintenance Mode is on, E-mail notifications will not be sent.
        </MessageBar>
      )}
      <header className={styles.box}>
        <div className={styles.topbar}>
          <div className={styles.rightIcons}>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="ABB logo" />
            </Link>
            <div className={styles.appName}>IMS</div>
            <div className={styles.appName}>{process.env.REACT_APP_DIVISION_NAME}</div>
          </div>
          <div className={styles.searchWrapper}>
            <SearchBox
              className={styles.search}
              value={searchValue}
              placeholder="Search"
              underlined={true}
              showIcon
              styles={{
                root: {
                  borderColor: '#ebebeb'
                }
              }}
              onChange={(e, newValue) => setSearchValue(newValue || '')}
              onSearch={newValue => onSearchRun(newValue)}
            />
            <InfoTooltip
              content={unitId || paramUnit ? 'Search in selected unit' : 'Search entire IMS'}
            />
          </div>
          <div className={styles.rightIcons}>
            <Navigation />
            <div className={styles.person}>
              {auth?.account ? (
                <>
                  <Icon iconName="Contact" />
                  <span>{auth.account.name}</span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {hasHiddenTopBar ? <HiddenTopBar /> : null}
      </header>
      <LogsBar />
    </>
  );
};
