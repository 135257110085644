import styles from './MasterProcessInput.module.scss';
import {DictionaryDropdown} from '../../../common/DictionaryDropdown';
import {FC, useCallback} from 'react';
import {FunctionDto} from '../../../../services/generated';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';

interface IMasterProcessInputFunctionProps {
  model: MasterProcessItemModel;
  options: FunctionDto[] | null;
  disabled?: boolean;
  disableErrorMessage?: boolean;
}

export const MasterProcessInputFunction: FC<IMasterProcessInputFunctionProps> = ({
  model,
  options,
  disabled,
  disableErrorMessage
}) => {
  const onChange = useCallback(
    (value: FunctionDto | undefined) => (model.function = value || ({} as FunctionDto)), // TODO temp solution
    [model]
  );
  return (
    <DictionaryDropdown
      className={styles.input}
      options={options}
      onChange={onChange}
      defaultValue={model.function}
      disabled={disabled || !model.isChecked}
      disableErrorMessage={disableErrorMessage}
      required
    />
  );
};
