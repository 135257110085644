import {Links} from '../services/generated';

export class MasterProcessBase {
  public id: number;
  public readonly link: Array<Links> | null;
  public title: string | null;
  public order: number;

  constructor(masterProcess: MasterProcessBase) {
    this.id = masterProcess.id;
    this.link = masterProcess.link;
    this.title = masterProcess.title;
    this.order = masterProcess.order;
  }
}
