import {FC} from 'react';
import {Outlet} from 'react-router';
import {ApiErrorStatus} from '../../Pages/ErrorPages/ApiErrorStatus';
import styles from './Main.module.scss';

export const Main: FC = () => {
  return (
    <div className={styles.wrapper}>
      <ApiErrorStatus>
        <Outlet />
      </ApiErrorStatus>
    </div>
  );
};
