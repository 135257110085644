import {Modal, ProgressIndicator} from '@fluentui/react';
import {FC} from 'react';

import {TheButton} from '../TheButton';
import {IModalWithHeaderProps} from './IModalWithDividedRequestsProps';
import styles from './ModalWithDividedRequests.module.scss';

export const ModalWithDividedRequests: FC<IModalWithHeaderProps> = ({
  body,
  isVisible,
  couldBeClosed,
  dismiss,
  header,
  progressStatus
}) => {
  const isReady = progressStatus?.readyItems;
  const allItems = progressStatus?.allItems;
  const percentComplete =
    allItems !== undefined && isReady !== undefined
      ? Math.round((isReady / allItems) * 100)
      : undefined;
  return (
    <Modal
      isOpen={isVisible}
      onDismiss={dismiss}
      isBlocking={!couldBeClosed}
      containerClassName={styles.container}>
      <div className={styles.header}>
        <h5 className={styles.heading}>{header || 'Add items'}</h5>
        {couldBeClosed && (
          <TheButton
            isIcon
            className={styles.close}
            iconProps={{iconName: 'Cancel'}}
            ariaLabel="Close popup modal"
            onClick={dismiss}
          />
        )}
      </div>
      <div className={styles.content}>
        {body}
        {percentComplete !== undefined &&
          (percentComplete === 0 ? (
            <ProgressIndicator label="Progress..." description={`Ready ${percentComplete}%`} />
          ) : (
            <ProgressIndicator
              label="Progress..."
              description={`Ready ${percentComplete}%`}
              percentComplete={percentComplete / 100}
            />
          ))}
        {couldBeClosed && (
          <div className={styles.footer}>
            <TheButton onClick={dismiss}>Close</TheButton>
          </div>
        )}
      </div>
    </Modal>
  );
};
