import {Icon} from '@fluentui/react';
import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';

import {ProcessType} from '../../../enums';
import {ProcessTree} from '../../../models';
import styles from './DropdownMenu.module.scss';
import {HoverableLi} from './HoverableLi';
import {MenuStructure} from './MenuStructure';
import {DictionariesContext} from '../../../providers';

interface ISingleMenuItemProps {
  unitId: number;
  process: ProcessTree;
}

function recursiveAnyChildVisible(
  processes: ProcessTree[] | null,
  validationFunction: (item: ProcessTree) => boolean
): boolean {
  if (!processes) return false;
  return processes.some(process => {
    if (process.deleted) return false;
    if (validationFunction(process)) {
      return true;
    } else {
      return recursiveAnyChildVisible(process.children, validationFunction);
    }
  });
}

export const SingleMenuItem: FC<ISingleMenuItemProps> = ({unitId, process}) => {
  const {globalFiltersFunction, units} = useContext(DictionariesContext);
  const {children, title, id, processType} = process;
  const childExists = recursiveAnyChildVisible(children, globalFiltersFunction);

  const liRef = useRef<HTMLLIElement>(null);
  const [childrenFits, setChildrenFits] = useState(true);
  const recalcOnResize = useCallback(() => {
    if (!liRef.current || !childExists) return;
    const rect = liRef.current.getBoundingClientRect();
    const childrenFits = rect.x + rect.width + 250 < document.body.clientWidth;
    setChildrenFits(childrenFits);
  }, [childExists]);
  useEffect(() => {
    recalcOnResize();
    window.addEventListener('resize', recalcOnResize);
    return () => {
      window.removeEventListener('resize', recalcOnResize);
    };
  }, [recalcOnResize]);
  useEffect(() => {
    recalcOnResize();
  }, [recalcOnResize, units]);
  const childrenExistsAndFit = childExists && childrenFits;
  const childrenExistsAndNotFit = childExists && !childrenFits;
  const [isOpened, setIsOpened] = useState(false);
  const showChildren = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsOpened(!isOpened);
    },
    [isOpened]
  );
  if (id === 0) {
    return <MenuStructure menu={children} unitId={unitId} />;
  }
  if (!childExists && !globalFiltersFunction(process)) {
    return null;
  }

  return (
    <HoverableLi ref={liRef} className={`${styles.hoverableListItem}`}>
      <div className={styles.listItemWrapper}>
        {processType === ProcessType.Process ? (
          <a href={`/unit/${unitId}/process/${id}`} className={styles.link}>
            <span className={styles.title}>{title}</span>
            {childrenExistsAndFit && <Icon iconName={'ChevronRight'} />}
          </a>
        ) : (
          <span className={`${styles.link} ${styles.nonProcess}`}>
            <span className={styles.title}>{title}</span>
            {childrenExistsAndFit && <Icon iconName={'ChevronRight'} />}
          </span>
        )}
        {childrenExistsAndNotFit && (
          <Icon
            iconName={isOpened ? 'ChevronUp' : 'ChevronDown'}
            onClick={showChildren}
            className={styles.downButton}
          />
        )}
      </div>
      {childrenExistsAndFit && (
        <ul key={id} className={styles.childrenWrapper}>
          <MenuStructure menu={children} unitId={unitId} />
        </ul>
      )}
      {childrenExistsAndNotFit && isOpened ? (
        <ul key={id} className={styles.downdropdown}>
          <MenuStructure menu={children} unitId={unitId} />
        </ul>
      ) : null}
    </HoverableLi>
  );
};
