import {
  Label,
  MessageBar,
  MessageBarType,
  SpinButton,
  Spinner,
  TextField,
  Toggle
} from '@fluentui/react';
import {FC} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {InputValidationError} from '../../common/Input/ValidationError';
import {MiddleTopBarUnitManage} from '../../common/MiddleTopBarUnitManage';
import {TheButton} from '../../common/TheButton';
import styles from './UnitSettings.module.scss';
import stylesPortalOwner from '../PortalOwners/EditPortalOwner.module.scss';
import {useUnitSettings} from './useUnitSettings';
import {InfoTooltip} from '../../common/InfoTooltip';
import {PeoplePicker} from '../../common/PeoplePicker';
import {Helmet} from "react-helmet";

interface IUnitSettings {
  unitId: number;
}

export const UnitSettings: FC<IUnitSettings> = ({unitId}) => {
  const {
    isFormInvalid,
    unitTitle,
    isSaving,
    isLoading,
    unitDetails,
    monthsValue,
    turnOffNotifications,
    setMonthsValue,
    pdcLink,
    setPdcLink,
    pdcOperatingUnit,
    setPdcOperatingUnit,
    cancelForm,
    saveForm,
    isMonthsInvalid,
    portalOwnersSelect,
    onSelectPortalOwners,
    processOwnersSelect,
    onSelectProcessOwner,
    isPortalOwnersInvalid,
    isProcessOwnersInvalid,
    changeTurnOffNotifications
  } = useUnitSettings(unitId);

  return (
    <main className={'noPaddingTop'}>
      <MiddleTopBarUnitManage unitId={unitId} active={'unitSettings'} />
      {unitTitle && <Helmet>
        <title>IMS {unitTitle} Settings</title>
      </Helmet>}
      <HeadingWithDecoration text="Unit Settings" />
      {isFormInvalid && (
        <MessageBar messageBarType={MessageBarType.error}>
          Sorry, the form is invalid.
          {isMonthsInvalid ? ' Check if the value is bigger than 0.' : ''}
          {isPortalOwnersInvalid ? ' Select portal owner.' : ''}
          {isProcessOwnersInvalid ? ' Select default process owner.' : ''}
        </MessageBar>
      )}
      {isSaving || isLoading ? (
        <Spinner />
      ) : (
        unitDetails && (
          <>
            <Toggle
              label={'Turn off notifications'}
              inlineLabel
              checked={turnOffNotifications}
              onChange={() => {
                changeTurnOffNotifications();
              }}
            />
            {portalOwnersSelect && (
              <>
                <div className={stylesPortalOwner.labelWrapper}>
                  <Label htmlFor={'people'} required>
                    Portal Owners
                  </Label>
                  <InfoTooltip content="Owners have full access to selected unit" />
                </div>
                <PeoplePicker
                  className={styles.narrowInput}
                  key={'normal'}
                  itemLimit={20}
                  defaultSelectedPerson={portalOwnersSelect}
                  onSelectPerson={onSelectPortalOwners}
                  showRemoved
                />
              </>
            )}
            {processOwnersSelect && (
              <>
                <Label htmlFor={'people'} required>
                  Default Process Owner
                </Label>
                <PeoplePicker
                  className={styles.narrowInput}
                  key={'normal'}
                  itemLimit={1}
                  defaultSelectedPerson={processOwnersSelect}
                  onSelectPerson={onSelectProcessOwner}
                  showRemoved
                />
              </>
            )}
            <Label required>Specify unit default due date for next process update in months</Label>
            <SpinButton
              className={styles.narrowInput}
              min={1}
              step={1}
              precision={0}
              defaultValue={typeof monthsValue === 'undefined' ? '12' : `${monthsValue}`}
              onValidate={value => {
                if (value.indexOf('.') !== -1) return `${Math.floor(Number(value))}`;
                return value;
              }}
              onChange={(e, value) => setMonthsValue(value)}
            />

            {!monthsValue || Number(monthsValue) < 1 ? (
              <InputValidationError message={'This value could not be empty and smaller than 1.'} />
            ) : null}
            <TextField
              className={styles.narrowInput}
              label="Link to PDC documents library"
              value={pdcLink || undefined}
              onChange={(e, value) => setPdcLink(value ?? null)}
            />
            <TextField
              className={styles.narrowInput}
              label="PDC Operating Unit"
              value={pdcOperatingUnit || undefined}
              onChange={(e, value) => setPdcOperatingUnit(value ?? null)}
            />

            <div className={styles.footer}>
              <TheButton onClick={cancelForm}>Cancel</TheButton>
              <TheButton primary onClick={saveForm}>
                Save
              </TheButton>
            </div>
          </>
        )
      )}
    </main>
  );
};
