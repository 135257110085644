import {DatePicker, DayOfWeek} from '@fluentui/react';
import styles from '../../UnitManagementProcess.module.scss';
import {FC, useState} from 'react';
import {dateTommorowMidnight} from '../../../../../../helpers/date';
import {InputValidationError} from '../../../../../common/Input/ValidationError';

interface INextUpdateRow {
  model: {
    nextUpdate: string;
    isChecked?: boolean;
  };
  required?: boolean;
  disabled?: boolean;
  maxDate: Date;
}

export const NextUpdateRow: FC<INextUpdateRow> = ({model, maxDate, required = true, disabled}) => {
  const dateTommorow = dateTommorowMidnight;
  const [dateSelected, setDateSelected] = useState<boolean>(false);
  const isError = required && model.isChecked && !dateSelected;

  return (
    <div className={styles.inputWrapper}>
      <DatePicker
        disabled={disabled}
        firstDayOfWeek={DayOfWeek.Monday}
        placeholder="Select a date..."
        ariaLabel="Select a date"
        minDate={dateTommorow}
        maxDate={maxDate}
        value={undefined}
        onSelectDate={date => {
          model.nextUpdate = date
            ? new Date(date.setHours(23, 59, 59, 999)).toISOString()
            : new Date(maxDate.setHours(23, 59, 59, 999)).toISOString();
            setDateSelected(true);
        }}
        className={`${isError ? ` ${styles.error}` : ''}`}
      />
      {isError ? (
        <InputValidationError message={'This value could not be empty. Choose next update.'} />
      ) : null}
    </div>
  );
};
