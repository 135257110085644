import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn} from '@fluentui/react';

export class DateColumn extends EnhancedColumn {
  public static type = ColumnType.Date;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return <>{typeof value === 'string' && value !== '0001-01-01T00:00:00' ? DateColumn.mapValue(value) : ''}</>;
  }

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? DateColumn.mapValue(itemValue).includes(lowerCaseSearch) : false;
  }

  private static mapValue(value: string): string {
    return value.split('T')[0];
  }

  public static getColumnValueForExcel(itemValue: string): string {
    return typeof itemValue === 'string' ? DateColumn.mapValue(itemValue) : '';
  }
}
