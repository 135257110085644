import {Label} from '@fluentui/react';
import {Person, PersonCardInteraction, PersonViewType} from '@microsoft/mgt-react';
import {FC} from 'react';

import styles from './LastEditor.module.scss';

interface ILastEditorProps {
  personData: string | undefined;
  whenEdited?: string | null;
  whenPublished?: string | null;
}

export const LastEditor: FC<ILastEditorProps> = ({personData, whenEdited, whenPublished}) => {
  const modified = whenEdited ? whenEdited.split('T')[0] : undefined;
  const published = whenPublished && whenPublished !== '0001-01-01T00:00:00' ? whenPublished.split('T')[0] : undefined;
  return (
    <div className={styles.lastEditorWrapper}>
      <div className={styles.lastEditorInner}>
        {published && (
          <div className={styles.update}>
            <Label>Published:</Label> {published}
          </div>
        )}
        {personData && (
          <div className={styles.update}>
            <Label>Modified by:</Label>
            <Person
              userId={personData}
              view={PersonViewType.oneline}
              avatarSize={'small'}
              personCardInteraction={PersonCardInteraction.hover}
            />
          </div>
        )}
        {modified && (
          <div className={styles.update}>
            <Label>Last modified:</Label> {modified}
          </div>
        )}
      </div>
    </div>
  );
};
