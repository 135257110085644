import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IComboBoxOption} from '@fluentui/react';
import {SelectPicker} from '../../SelectPicker';

export class SelectColumn extends EnhancedColumn {
  public static type = ColumnType.Select;

  public static hasActiveFilter(value: any): boolean {
    return value && value.length > 0;
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: string[]
  ): JSX.Element {
    const options = [...new Set(allOptions)];
    return (
      <SelectPicker
        type="combobox"
        multiSelect
        label="Filter"
        defaultValue={defaultValue}
        options={options}
        onChange={(keys, items) => onChange(keys, items)}
      />
    );
  }

  public static filterFunction(itemValue: string[], filterValue: string[]): boolean {
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if (!filterVal) return !itemValue;
      return (itemValue || '').indexOf(filterVal) !== -1;
    });
  }

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? `${itemValue}`.toLowerCase().includes(lowerCaseSearch) : false;
  }
}
