import styles from './EditProcessNavigator.module.scss';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {IProcessControl} from '../../../interfaces';
import {ITag, Label, MessageBar, MessageBarType, Spinner, TextField} from '@fluentui/react';
import {MiddleTopBarUnitManage} from '../../common/MiddleTopBarUnitManage';
import {PositionEnum, ProcessEnum} from '../../../enums/ProcessEnum';
import {ProcessNavigatorInput} from './ProcessNavigatorInput';
import {TheButton} from '../../common/TheButton';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useProcessNavigator} from './useProcessNavigator';
import {useService} from '../../../hooks';
import {Helmet} from 'react-helmet';

enum GroupId {
  Management = 1,
  Execution = 2,
  Support = 3,
  Result = 4
}

export const EditNavigatorControl = () => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const navigate = useNavigate();
  const {updateUnitProcessNavigators} = useService();
  const {
    isLoading,
    unit,
    unitTitle,
    publishedProcesses,
    getITagsFromPublishedProcess,
    getDataFiltred,
    getResultUrl,
    createIProcess,
    createResultControl
  } = useProcessNavigator(unitId);

  const unitApiCode = unit?.apiCode;
  const unitDetails = unit?.result;
  const uniDataExists = unitApiCode === null && unitDetails;

  const processesWithoutDeleted =
    publishedProcesses && publishedProcesses.filter(x => x.deleted === false);
  const unitsProcesses =
    processesWithoutDeleted && getITagsFromPublishedProcess(processesWithoutDeleted);

  const dataManagement = getDataFiltred(ProcessEnum.Management);
  const dataSupport = getDataFiltred(ProcessEnum.Support);
  const dataExecutionTop = getDataFiltred(ProcessEnum.Execution, PositionEnum.Top);
  const dataExecutionMiddle = getDataFiltred(ProcessEnum.Execution, PositionEnum.Middle);
  const dataExecutionBottom = getDataFiltred(ProcessEnum.Execution, PositionEnum.Bottom);
  const resultButton = getResultUrl();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);

  const [dataManagementSelected, setDataManagementSelected] = useState<ITag[] | undefined>(
    dataManagement
  );
  const [dataSupportSelected, setDataSupportSelected] = useState<ITag[] | undefined>(dataSupport);
  const [dataExecutionTopSelected, setDataExecutionTopSelected] = useState<ITag[] | undefined>(
    dataExecutionTop
  );
  const [dataExecutionMiddleSelected, setDataExecutionMiddleSelected] = useState<
    ITag[] | undefined
  >(dataExecutionMiddle);
  const [dataExecutionBottomSelected, setDataExecutionBottomSelected] = useState<
    ITag[] | undefined
  >(dataExecutionBottom);
  const [resultValue, setResultValue] = useState<string | undefined>(resultButton || undefined);
  const [dragStartInputLabel, setDragStartInputLabel] = useState<string | null | undefined>();

  useEffect(() => {
    if (dataManagementSelected === undefined) {
      setDataManagementSelected(dataManagement);
    }
    if (dataExecutionTopSelected === undefined) {
      setDataExecutionTopSelected(dataExecutionTop);
    }
    if (dataExecutionMiddleSelected === undefined) {
      setDataExecutionMiddleSelected(dataExecutionMiddle);
    }
    if (dataExecutionBottomSelected === undefined) {
      setDataExecutionBottomSelected(dataExecutionBottom);
    }
    if (dataSupportSelected === undefined) {
      setDataSupportSelected(dataSupport);
    }
    if (resultValue === undefined) {
      setResultValue(resultButton || undefined);
    }
  }, [
    dataManagementSelected,
    dataManagement,
    dataExecutionTopSelected,
    dataExecutionTop,
    dataExecutionMiddleSelected,
    dataExecutionMiddle,
    dataExecutionBottomSelected,
    resultButton,
    dataExecutionBottom,
    dataSupport,
    dataSupportSelected,
    resultValue
  ]);

  const dataExists =
    dataManagementSelected ||
    dataExecutionTopSelected ||
    dataExecutionMiddleSelected ||
    dataExecutionBottomSelected ||
    dataSupportSelected ||
    resultValue;

  const CreateNavigatorControlsList = (): IProcessControl[] => {
    let navigatorControls: IProcessControl[] = [];
    createIProcess(dataManagementSelected, navigatorControls, GroupId.Management);
    createIProcess(
      dataExecutionTopSelected,
      navigatorControls,
      GroupId.Execution,
      PositionEnum.Top
    );
    createIProcess(
      dataExecutionMiddleSelected,
      navigatorControls,
      GroupId.Execution,
      PositionEnum.Middle
    );
    createIProcess(
      dataExecutionBottomSelected,
      navigatorControls,
      GroupId.Execution,
      PositionEnum.Bottom
    );
    createIProcess(dataSupportSelected, navigatorControls, GroupId.Support);
    createResultControl(resultValue, navigatorControls, GroupId.Result);

    return navigatorControls;
  };

  const cancelForm = () => {
    const fullUrl = `/unit/${unitId}/manage`;
    navigate(fullUrl, {replace: true});
  };

  const saveForm = async () => {
    const result = CreateNavigatorControlsList();
    setIsSaving(true);
    await updateUnitProcessNavigators(unitId, result);
    setIsFormInvalid(false);
    setIsSaving(false);
  };

  return (
    <>
      <main className={`${isLoading || isSaving ? 'isLoading' : ''} noPaddingTop`}>
        {isFormInvalid && (
          <MessageBar messageBarType={MessageBarType.error} className={styles.messageBar}>
            Sorry, the form is invalid. At least one field should be filled in.
          </MessageBar>
        )}
        {isSaving || isLoading ? (
          <Spinner /> /* : unitApiCode && !isLoading ? (
        <ApiErrorStatus message={unit?.message} />
      )*/
        ) : (
          <>
            <MiddleTopBarUnitManage notUseHelmet unitId={unitId} active={'navigator'} />
            {unitTitle && (
              <Helmet>
                <title>IMS {unitTitle} Navigator Control</title>
              </Helmet>
            )}
            {uniDataExists && (
              <HeadingWithDecoration text={unitDetails?.title + ' Navigator Control:'} />
            )}
            {/* //stad */}
            {dataExists && (
              <form>
                <ProcessNavigatorInput
                  label={ProcessEnum.Management}
                  defaultItems={dataManagementSelected}
                  setValue={setDataManagementSelected}
                  processes={unitsProcesses}
                  dragStartInputLabel={dragStartInputLabel}
                  setDragStartInputLabel={setDragStartInputLabel}
                  showRemoved
                />
                <Label>{ProcessEnum.Execution}</Label>
                <ProcessNavigatorInput
                  label={PositionEnum.Top}
                  defaultItems={dataExecutionTopSelected}
                  setValue={setDataExecutionTopSelected}
                  processes={unitsProcesses}
                  dragStartInputLabel={dragStartInputLabel}
                  setDragStartInputLabel={setDragStartInputLabel}
                  showRemoved
                />
                <ProcessNavigatorInput
                  label={PositionEnum.Middle}
                  defaultItems={dataExecutionMiddleSelected}
                  setValue={setDataExecutionMiddleSelected}
                  processes={unitsProcesses}
                  dragStartInputLabel={dragStartInputLabel}
                  setDragStartInputLabel={setDragStartInputLabel}
                  showRemoved
                />
                <ProcessNavigatorInput
                  label={PositionEnum.Bottom}
                  defaultItems={dataExecutionBottomSelected}
                  setValue={setDataExecutionBottomSelected}
                  processes={unitsProcesses}
                  dragStartInputLabel={dragStartInputLabel}
                  setDragStartInputLabel={setDragStartInputLabel}
                  showRemoved
                />
                <ProcessNavigatorInput
                  label={ProcessEnum.Support}
                  defaultItems={dataSupportSelected}
                  setValue={setDataSupportSelected}
                  processes={unitsProcesses}
                  dragStartInputLabel={dragStartInputLabel}
                  setDragStartInputLabel={setDragStartInputLabel}
                  showRemoved
                />
                <TextField
                  label="Result"
                  value={resultValue}
                  onChange={(e, value) => setResultValue(value)}
                />
                <div className={styles.footer}>
                  <TheButton onClick={() => cancelForm()}>Cancel</TheButton>
                  <TheButton primary disabled={false} onClick={() => saveForm()}>
                    Save
                  </TheButton>
                </div>
              </form>
            )}
          </>
        )}
      </main>
    </>
  );
};
