import {ProcessType} from '../../../../enums';
import {Activity, Document, ExternalLink} from '../../../../models';
import {ManageProcessDetails} from '../../../../models/ManageProcessDetails';
import {ProcessForSaveBulkDto, UserDto} from '../../../../services/generated';

export class UnitManagementProcessDetailsTreeModel {
  public get id() {
    return this.processDraft.masterProcessId;
  }

  public get processId() {
    return this.processDraft.id;
  }

  public get parentId() {
    return this.processDraft.parentMasterProcessId;
  }

  public get title() {
    return this.processDraft.title || '';
  }

  public isChecked: boolean = !this.processDraft.deleted;

  public isEdit = false;

  public initialDescription: string;
  public description: string;
  public nextUpdate: string;

  private initialProcessOwnerAzureId: string | undefined;
  public processOwnerAzureId: string | undefined;

  private initialSecondaryProcessOwnerAzureId: string | undefined;
  public secondaryProcessOwnerAzureId: string | undefined;
  public initialActivityInputs: Activity[];
  public initialActivityOutputs: Activity[];
  public initialDocuments: Document[];
  public initialExternalLinks: ExternalLink[];
  public initialNextUpdate: string | undefined;
  public draftExists: boolean = false;
  public type: ProcessType;
  public isMirrorCopy: boolean = false;
  public get orderPrefix() {
    return this.processDraft.fakePrefix;
  }

  public get isModified() {
    return (
      this.changedDocuments.length ||
      this.changedExternalLinks.length ||
      this.changedActivityInputs.length ||
      this.changedActivityOutputs.length ||
      this.removedDocuments.length ||
      this.removedExternalLinks.length ||
      this.removedActivityInputs.length ||
      this.removedActivityOutputs.length ||
      this.initialDescription !== this.description ||
      this.initialProcessOwnerAzureId !== this.processOwnerAzureId ||
      this.initialSecondaryProcessOwnerAzureId !== this.secondaryProcessOwnerAzureId ||
      this.initialNextUpdate !== this.nextUpdate
    );
  }

  public get isValid() {
    return (
      this.processOwnerAzureId && this.processOwnerAzureId !== this.secondaryProcessOwnerAzureId
    );
  }

  public get currentProcessDescription() {
    return this.processDraft.descriptions?.find(
      desc => desc.ownerProcessId === this.processDraft.id
    );
  }

  public get rowValue(): ProcessForSaveBulkDto {
    const descriptions = this.processDraft.descriptions;
    const procesDescription =
      this.initialDescription !== this.description ? this.currentProcessDescription : undefined;
    if (procesDescription) {
      procesDescription.content = this.description;
    }
    return {
      id: this.processDraft.id,
      link: this.processDraft.link,
      title: this.processDraft.title,
      forcePublish: this.processDraft.draftExists,
      nextUpdate: this.nextUpdate,
      descriptions: procesDescription ? descriptions : null,
      documents: this.changedDocuments.length ? this.changedDocuments : null,
      externalLinks: this.changedExternalLinks.length ? this.changedExternalLinks : null,
      processOwner: this.processOwnerAzureId
        ? ({azureId: this.processOwnerAzureId} as UserDto)
        : this.processDraft.processOwner,
      secondaryProcessOwner: this.secondaryProcessOwnerAzureId
        ? ({azureId: this.secondaryProcessOwnerAzureId} as UserDto)
        : null,
      type: this.processDraft.processType,
      unitId: this.processDraft.unitId,
      activityInputs: this.changedActivityInputs.length ? this.changedActivityInputs : null,
      activityOutputs: this.changedActivityOutputs.length ? this.changedActivityOutputs : null,
      removedDocuments: this.removedDocuments.length ? this.removedDocuments : null,
      removedExternalLinks: this.removedExternalLinks.length ? this.removedExternalLinks : null,
      removedActivityInputs: this.removedActivityInputs.length ? this.removedActivityInputs : null,
      removedActivityOutputs: this.removedActivityOutputs.length ? this.removedActivityOutputs : null
    };
  }

  public changedDocuments: Document[] = [];
  public changedExternalLinks: ExternalLink[] = [];
  public changedActivityInputs: Activity[] = [];
  public changedActivityOutputs: Activity[] = [];
  public removedDocuments: Document[] = [];
  public removedExternalLinks: ExternalLink[] = [];
  public removedActivityInputs: Activity[] = [];
  public removedActivityOutputs: Activity[] = [];

  constructor(
    public processDraft: ManageProcessDetails = new ManageProcessDetails({
      id: 0,
      link: null,
      deleted: true,
      descriptions: [],
      masterProcessId: 0,
      processType: ProcessType.Process,
      title: null,
      parentMasterProcessId: 0,
      unitId: 0,
      unitTitle: null,
      processOwner: {
        azureId: '',
        id: 0,
        link: null,
        userPrincipalName: null
      },
      secondaryProcessOwner: {
        azureId: '',
        id: 0,
        link: null,
        userPrincipalName: null
      },
      parentProcessId: 0,
      parentUnitId: 0,
      parentUnitTitle: null,
      order: 0,
      draftExists: false,
      documents: [],
      activityInputs: [],
      activityOutputs: [],
      externalLinks: [],
      nextUpdate: '',
      isMirrorCopy: false
    })
  ) {
    const processOwnerAzureId = processDraft.processOwner?.azureId;
    this.processOwnerAzureId = processOwnerAzureId;
    this.initialProcessOwnerAzureId = processOwnerAzureId;

    const nextUpdate = processDraft.nextUpdate;
    this.initialNextUpdate = nextUpdate;
    this.nextUpdate = nextUpdate;

    const secondaryProcessOwnerAzureId = processDraft.secondaryProcessOwner?.azureId;
    this.secondaryProcessOwnerAzureId = secondaryProcessOwnerAzureId;
    this.initialSecondaryProcessOwnerAzureId = secondaryProcessOwnerAzureId;

    const currentDescription = processDraft.descriptions?.find(
      desc => desc.ownerProcessId === processDraft.id
    );
    const description = currentDescription?.content || '';
    this.initialDescription = description;
    this.description = description;

    this.initialActivityInputs = processDraft.activityInputs
      ? processDraft.activityInputs.map(dto => new Activity(dto))
      : [];
    this.initialActivityOutputs = processDraft.activityOutputs
      ? processDraft.activityOutputs.map(dto => new Activity(dto))
      : [];
    this.initialDocuments = processDraft.documents
      ? processDraft.documents.map(dto => new Document(dto))
      : [];
    this.initialExternalLinks = processDraft.externalLinks
      ? processDraft.externalLinks.map(dto => new ExternalLink(dto))
      : [];

    this.draftExists = processDraft.draftExists;
    this.isMirrorCopy = processDraft.isMirrorCopy;
    this.type = processDraft.processType;
  }
}
