import {
  FunctionDto,
  ProcessTreeStructureDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UserDto,
  BusinessLineDto
} from '../services/generated';
import {ProcessStructure} from './ProcessStructure';

export class ProcessTree extends ProcessStructure implements ProcessTreeStructureDto {
  public children: Array<ProcessTree> | null;
  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;
  public parentProcessId: number;
  public parentUnitId: number;
  public parentUnitTitle: string | null;
  public function: FunctionDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public industries: IndustryDto[] | null;
  public businessLines: BusinessLineDto[] | null;
  constructor(process: ProcessTreeStructureDto) {
    super(process);
    this.processOwner = process.processOwner;
    this.secondaryProcessOwner = process.secondaryProcessOwner;
    this.children = process.children;
    this.parentProcessId = process.parentProcessId;
    this.parentUnitId = process.parentUnitId;
    this.parentUnitTitle = process.parentUnitTitle;
    this.function = process.function;
    this.projectSizeCategories = process.projectSizeCategories;
    this.specialities = process.specialities;
    this.industries = process.industries;
    this.businessLines = process.businessLines;
  }
}
