import {MessageBarType} from '@fluentui/react';
import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {ILog, LoggerContext} from './LoggerContext';
import { log } from 'console';

export const LoggerProvider: FC<PropsWithChildren> = ({children}) => {
  const [pageException, setPageException] = useState<string | null>(null);
  const [logs, setLogs] = useState<ILog[]>([]);
  const location = useLocation();

  const addLog = (logType: MessageBarType, logMessage?: string | null, consoleError?: unknown) => {
    let message = "";
    switch (logMessage) {
      case null:
      case undefined:
      message = "An error occurred while processing your request.";
      break;
      case 'NetworkError when attempting to fetch resource.':
      case 'Generic Error':
      message = 'Too many requests. Please try again later.';
      break;
      default:
      message = logMessage;
    }
    const newLog: ILog = {
      key: new Date().toISOString(),
      message: message,
      messageType: logType,
      date: new Date()
    };
    setLogs([...logs, newLog]);
    if (consoleError) console.error(consoleError);
  };

  const deleteLog = (logKey: string) => {
    const oldLogs = logs.slice();
    const index = oldLogs.findIndex(x => x.key === logKey);
    oldLogs.splice(index, 1);
    setLogs([...oldLogs]);
  };

  useEffect(() => {
    setLogs(logs.filter(log => Date.now() - log.date.getTime() < 1000));
    setPageException(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <LoggerContext.Provider
      value={{
        pageException,
        setPageException,
        logs,
        addLog,
        deleteLog
      }}>
      {children}
    </LoggerContext.Provider>
  );
};
