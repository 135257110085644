import React, {useCallback, useState} from 'react';
import {DocumentInUnit} from '../../../models/DocumentInUnit';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {MessageBar, MessageBarType, TextField} from '@fluentui/react';
import styles from './Documents.module.scss';
import {TheButton} from '../../common/TheButton';
import {useDocument} from '../../../hooks/services/useDocument';
import {DocumentUpdateDto} from '../../../services/generated/models/DocumentUpdateDto';

interface IDocumentsCenterModal {
  isVisible: boolean;
  editItem: DocumentInUnit;
  onClose: () => void;
  onUpdated: (item: DocumentUpdateDto) => void;
}

export const DocumentsCenterModal: React.FC<IDocumentsCenterModal> = ({
  isVisible,
  editItem,
  onClose,
  onUpdated
}) => {
  const {updateDocument} = useDocument();
  const {id, documentId, languageCode} = editItem;
  const [docId, setDocId] = useState<string | null | undefined>(documentId);
  const [langCode, setLangCode] = useState<string | null | undefined>(languageCode);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMgs, setErrorMsg] = useState<{
    [key: string]: JSX.Element;
  }>({});

  const onCloseModal = () => {
    setErrorMsg({errorNoData: <></>});
    onClose();
  };

  const validation = useCallback(() => {
    setErrorMsg({});
    let arrError = [];
    if (
      docId &&
      docId?.toLocaleLowerCase() === documentId?.toLocaleLowerCase() &&
      langCode &&
      langCode?.toLocaleLowerCase() === languageCode?.toLocaleLowerCase()
    ) {
      const textNoChanges =
        'Neither document Id nor language code was changed. Could not send the form';
      arrError.push(textNoChanges);
    }
    if (!docId) {
      const textNoDoc = "You can't remove: document Id";
      arrError.push(textNoDoc);
    }
    if (!langCode) {
      const textNoLangCode = "You can't remove: language code";
      arrError.push(textNoLangCode);
    }
    if (arrError.length) {
      setErrorMsg({
        errorMsg: (
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            {arrError.map(item => item + '\r\n')}
          </MessageBar>
        )
      });
      return;
    }
  }, [docId, documentId, langCode, languageCode]);

  const onSubmit = useCallback(async () => {
    validation();
    setIsLoading(true);
    const formData = {
      id: id,
      documentId: docId || undefined,
      languageCode: langCode || undefined
    };
    if (formData) {
      await updateDocument(id, formData);
      onUpdated(formData);
    }

    setIsLoading(false);
  }, [docId, id, langCode, onUpdated, updateDocument, validation]);

  return (
    <ModalWithHeader
      header="Update Document Id or Language code"
      isVisible={isVisible}
      dismiss={onCloseModal}>
      {errorMgs.errorMsg}
      <TextField
        label="Document Id"
        defaultValue={editItem?.documentId || ''}
        onChange={(e, value) => setDocId(value || '')}
      />
      <TextField
        label="Language Code"
        defaultValue={editItem?.languageCode || ''}
        onChange={(e, value) => setLangCode(value || '')}
      />
      <footer className={styles.btn}>
        <TheButton onClick={onCloseModal}>Close</TheButton>
        <TheButton primary disabled={isLoading} onClick={onSubmit}>
          Update
        </TheButton>
      </footer>
    </ModalWithHeader>
  );
};
