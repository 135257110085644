import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {sortByOrderPrefix} from '../../../../helpers/arrayMehods';
import {useService} from '../../../../hooks';
import {useProcess} from '../../../../hooks/services/useProcess';
import {useUnit} from '../../../../hooks/services/useUnit';
import {AuthenticationContext} from '../../../../providers/AuthenticationContext';
import {ITreeColumn} from '../../../common/TreeBuilder/TreeColumn';
import {
  NextUpdateRow,
  ProcessRowDocuments,
  ProcessRowExternalLinks,
  ProcessRowOwner
} from '../UnitManagementProcesses/inputs';
import {ProcessRowDescriptions} from '../UnitManagementProcesses/inputs/Descriptions/ProcessRowDescriptions';
import {UnitManagementProcessDetailsTreeModel} from './UnitManagementProcessDetailsTreeModel';
import styles from './UnitManagementProcessDetails.module.scss';
import {MainStoreContext} from '../../../../providers/MainStoreContext';
import {ProcessRowActivities} from '../UnitManagementProcesses/inputs/Activities';
import {LoggerContext} from '../../../../providers';
import {MessageBarType} from '@fluentui/react';
import {useChunkedRequest} from '../../../../hooks/services/useChunkedRequest';
import {ProcessType} from '../../../../services/generated';
import {Activity, Document, ExternalLink, Unit} from '../../../../models';
import {PublishScenario} from '../../../../enums';
import { ProcessRowRemoveDocuments } from '../UnitManagementProcesses/inputs/Documents/ProcessRowRemoveDocuments';
import { ProcessRowRemoveExternalLinks } from '../UnitManagementProcesses/inputs/ExternalLinks/ProcessRowRemoveExternalLinks';
import { ProcessRowRemoveActivities } from '../UnitManagementProcesses/inputs/Activities/ProcessRowRemoveActivities';

interface publishOptions {
  notification: boolean;
  publish: boolean;
  publishScenario: PublishScenario;
  publicationComment: string | null;
}

export const useUnitManagementProcessesDetails = () => {
  const navigate = useNavigate();
  const {addLog} = useContext(LoggerContext);
  const {getProcessesDraftsToManage, getUnit} = useUnit();
  const {updateProcessBulk} = useProcess();
  const {users} = useContext(MainStoreContext);
  const {getUserById} = useService();
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [unitTitle, setUnitTitle] = useState<string>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [itemsFlat, setItemsFlat] = useState<UnitManagementProcessDetailsTreeModel[]>([]);
  const [revealedMasterProcessIds, setRevealedMasterProcessIds] = useState<Array<number>>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const maxChunkSize = 1;

  const {sendRequests, isRequestInProgress, moreThanOneChunk, progressStatus} = useChunkedRequest<
    UnitManagementProcessDetailsTreeModel,
    void,
    publishOptions
  >(async (data, options) => {
    await updateProcessBulk(options ? options.notification : false, {
      processes: data.map(item => item.rowValue),
      publish: options ? options.publish : false,
      publishScenario: options ? options.publishScenario : PublishScenario.Save,
      publicationComment: options?.publicationComment
    });
  }, maxChunkSize);

  const isAnythingLoading = isLoading || isAzureLoading || isRequestInProgress;
  const isNonProcess = (item: UnitManagementProcessDetailsTreeModel): boolean => {
    return item.type === ProcessType.NonProcess;
  };

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  const getCorrectItems = (
    publish: boolean,
    itemsFlat: UnitManagementProcessDetailsTreeModel[]
  ) => {
    return publish
      ? itemsFlat.filter(item => item.isModified || (item.isEdit && item.draftExists))
      : itemsFlat.filter(item => item.isModified);
  };

  const columns = useMemo(() => {
    const cols: ITreeColumn<UnitManagementProcessDetailsTreeModel>[] = [
      {
        key: 1,
        title: 'Title',
        type: 'title',
        getTitle: (model: UnitManagementProcessDetailsTreeModel) => model.title,
        minCSSWidth: '500px',
        property: 'title',
        exportToExcel: true,
        getExportToExcelValue: item => {
          return {
            text: item.title,
            link: `${window.location.origin}/unit/${unitId}/process/${item.processId}`
          };
        }
      },
      {
        key: 10,
        title: 'Next Update',
        type: 'bulk',
        onRenderColumn: model => (model.nextUpdate.split('T')[0]),
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <NextUpdateRow model={item} disabled={disabled} required={true} maxDate={maxDate || new Date()} />;
        },
        getExportToExcelValue: model => (model.nextUpdate.split('T')[0]),
        minCSSWidth: '100px',
        required: true,
        property: 'nextUpdate',
        exportToExcel: true
      },
      {
        key: 8,
        title: 'Draft exists',
        type: 'simple',
        onRenderColumn: model => (model.draftExists ? 'True' : 'False'),
        getExportToExcelValue: model => (model.draftExists ? 'True' : 'False'),
        minCSSWidth: '50px',
        property: 'draftExists',
        exportToExcel: true
      },
      {
        key: 3,
        title: 'Documents',
        type: 'bulk',
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (!model.initialDocuments || !model.initialDocuments.length) return '';
          const text = model.initialDocuments.map(item => item.title).join('<br />');
          return model.initialDocuments.length > 1
            ? {
                html: text
              }
            : {
                text,
                link: model.initialDocuments[0].url || ''
              };
        },
        onRenderColumn: (item, column, disabled) => {
          let result = item.initialDocuments.concat(item.changedDocuments)
          result = result.filter(input => !item.removedDocuments.some(removed => removed.id === input.id))
          return (
            <div className={styles.columnBreakText}>
               {result.map(item => item.title).join(', ')}
            </div>
          );
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowDocuments model={item} disabled={disabled} />;
        },
        property: 'changedDocuments'
      },
      {
        key: 11,
        hidden: true,
        title: 'Documents to remove',
        type: 'bulk',
        onRenderColumn: (item, column, disabled) => {
          return <></>;
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowRemoveDocuments model={item} disabled={disabled} />;
        },
        property: 'removedDocuments',
        onCustomBulkChange: (item, model) => {
          let documentsIds = (model as Document[]).map(x => `${x.documentId}${x.languageCode}`);
          item.initialDocuments = item.initialDocuments.filter(
            doc => !documentsIds.includes(`${doc.documentId}${doc.languageCode}`)
          );
          item.removedDocuments = model as Document[];
          item.removedDocuments.forEach(x => (x.removed = true));
        }
      },
      {
        key: 4,
        title: 'Links',
        type: 'bulk',
        exportToExcel: true,
        minCSSWidth: '150px',
        getExportToExcelValue: async model => {
          if (!model.initialExternalLinks || !model.initialExternalLinks.length) return '';
          const text = model.initialExternalLinks.map(item => item.title).join('<br />');
          return model.initialExternalLinks.length > 1
            ? {
                html: text
              }
            : {
                text,
                link: model.initialExternalLinks[0].url || ''
              };
        },
        onRenderColumn: (item, column, disabled) => {
          let result = item.initialExternalLinks.concat(item.changedExternalLinks)
          result = result.filter(input => !item.removedExternalLinks.some(removed => removed.id === input.id))
          return (
            <div className={styles.columnBreakText}>
               {result.map(item => item.title).join(', ')}
            </div>
          );
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowExternalLinks model={item} disabled={disabled} />;
        },
        property: 'changedExternalLinks'
      },
      {
        key: 12,
        hidden: true,
        title: 'Links to remove',
        type: 'bulk',
        onRenderColumn: (item, column, disabled) => {
          return <></>;
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowRemoveExternalLinks model={item} disabled={disabled} />;
        },
        property: 'removedExternalLinks',
        onCustomBulkChange: (item, model) => {
          let externalLinksTitles = (model as ExternalLink[]).map(x => x.title);
          item.initialExternalLinks = item.initialExternalLinks.filter(
            el => !externalLinksTitles.includes(el.title)
          );
          item.removedExternalLinks = model as ExternalLink[];
          item.removedExternalLinks.forEach(x => (x.removed = true));
        }
      },
      {
        key: 6,
        title: 'Activity inputs',
        type: 'bulk',
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (!model.initialActivityInputs || !model.initialActivityInputs.length) return '';
          const text = model.initialActivityInputs.map(item => item.title).join('<br />');
          return model.initialActivityInputs.length > 1
            ? {
                html: text
              }
            : {
                text,
                link: model.initialActivityInputs[0].url || ''
              };
        },
        minCSSWidth: '150px',
        onRenderColumn: (item, column, disabled) => {
          let result = item.initialActivityInputs.concat(item.changedActivityInputs)
          result = result.filter(input => !item.removedActivityInputs.some(removed => removed.id === input.id))
          return (
            <div className={styles.columnBreakText}>
              {result.map(item => item.title).join(', ')}
            </div>
          );
        },

        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowActivities isInput model={item} disabled={disabled} unitId={unitId} />;
        },
        property: 'changedActivityInputs'
      },
      {
        key: 13,
        hidden: true,
        title: 'Activity inputs to remove',
        type: 'bulk',
        onRenderColumn: (item, column, disabled) => {
          return <></>;
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowRemoveActivities isInput={true} model={item} disabled={disabled} unitId={unitId} />;
        },
        property: 'removedActivityInputs',
        onCustomBulkChange: (item, model) => {
          let activityInputsTitles = (model as Activity[]).map(x => x.title);
          item.initialActivityInputs = item.initialActivityInputs.filter(
            el => !activityInputsTitles.includes(el.title)
          );
          item.removedActivityInputs = model as Activity[];
          item.removedActivityInputs.forEach(x => (x.removed = true));
        }
      },
      {
        key: 7,
        title: 'Activity outputs',
        type: 'bulk',
        exportToExcel: true,
        minCSSWidth: '150px',
        getExportToExcelValue: async model => {
          if (!model.initialActivityOutputs || !model.initialActivityOutputs.length) return '';
          const text = model.initialActivityOutputs.map(item => item.title).join('<br />');
          return model.initialActivityOutputs.length > 1
            ? {
                html: text
              }
            : {
                text,
                link: model.initialActivityOutputs[0].url || ''
              };
        },
        onRenderColumn: (item, column, disabled) => {
          let result = item.initialActivityOutputs.concat(item.changedActivityOutputs)
          result = result.filter(input => !item.removedActivityOutputs.some(removed => removed.id === input.id))
          return (
            <div className={styles.columnBreakText}>
               {result.map(item => item.title).join(', ')}
            </div>
          );
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return (
            <ProcessRowActivities
              isInput={false}
              model={item}
              disabled={disabled}
              unitId={unitId}
            />
          );
        },
        property: 'changedActivityOutputs'
      },
      {
        key: 14,
        hidden: true,
        title: 'Activity outputs to remove',
        type: 'bulk',
        onRenderColumn: (item, column, disabled) => {
          return <></>;
        },
        onRenderBulk: (item, column, idsForEdit, disabled) => {
          return <ProcessRowRemoveActivities isInput={false} model={item} disabled={disabled} unitId={unitId} />;
        },
        property: 'removedActivityOutputs',
        onCustomBulkChange: (item, model) => {
          var activityOutputsTitles = (model as Activity[]).map(x => x.title);
          item.initialActivityOutputs = item.initialActivityOutputs.filter(
            el => !activityOutputsTitles.includes(el.title)
          );
          item.removedActivityOutputs = model as Activity[];
          item.removedActivityOutputs.forEach(x => (x.removed = true));
        }
      },
      {
        key: 2,
        title: 'Process Owner',
        minCSSWidth: '200px',
        type: 'bulk',
        onRenderColumn: (
          model: UnitManagementProcessDetailsTreeModel,
          column: ITreeColumn<UnitManagementProcessDetailsTreeModel>,
          disabled?: boolean
        ) => <ProcessRowOwner model={model} disabled={disabled} readonly={isNonProcess(model) || model.isMirrorCopy} />,
        property: 'processOwnerAzureId',
        required: true,
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (model.processOwnerAzureId) {
            let user = await users.cacheById[model.processOwnerAzureId];
            if (!user) {
              user = await getUserById(model.processOwnerAzureId);
            }
            return user?.displayName;
          }
        }
      },
      {
        key: 9,
        title: 'Secondary Process Owner',
        minCSSWidth: '200px',
        type: 'bulk',
        onRenderColumn: (
          model: UnitManagementProcessDetailsTreeModel,
          column: ITreeColumn<UnitManagementProcessDetailsTreeModel>,
          disabled?: boolean
        ) => (
          <ProcessRowOwner
            model={model}
            readonly={isNonProcess(model) || model.isMirrorCopy}
            disabled={disabled}
            required={false}
            ownerType="secondaryProcessOwnerAzureId"
          />
        ),
        property: 'secondaryProcessOwnerAzureId',
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (model.secondaryProcessOwnerAzureId) {
            let user = await users.cacheById[model.secondaryProcessOwnerAzureId];
            if (!user) {
              user = await getUserById(model.secondaryProcessOwnerAzureId);
            }
            return user?.displayName;
          }
        }
      },
      {
        key: 5,
        title: 'Description',
        type: 'simple',
        minCSSWidth: '1000px',
        onRenderColumn: (item, column, disabled) => {
          return <ProcessRowDescriptions model={item} disabled={isNonProcess(item) || item.isMirrorCopy} />;
        },
        getExportToExcelValue: item => {
          return item.currentProcessDescription?.content
            ? {
                html:
                  item.currentProcessDescription.content.length > 32766
                    ? item.currentProcessDescription.content.slice(0, 32766)
                    : item.currentProcessDescription?.content
              }
            : '';
        },
        property: 'description',
        exportToExcel: true
      }
    ];
    return cols;
  }, [unitId, users.cacheById, getUserById, maxDate]);

  useEffect(() => {
    setIsModalVisible(moreThanOneChunk);
  }, [moreThanOneChunk]);

  const onDismiss = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const refreshData = useCallback(async () => {
    const unitResponse = await getUnit(unitId);
    if (unitResponse && unitResponse.result) {
      const processValidityInMonth = unitResponse.result.processValidityInMonth;
      var newDate = new Date(new Date().setMonth(new Date().getMonth()+processValidityInMonth));
      setMaxDate(newDate)
      setUnitTitle(prepareUnitTitle(unitResponse.result));
    }
    const processesResult = await getProcessesDraftsToManage(unitId);
    if (processesResult.result) {
      const processesDrafts = processesResult.result
        .filter(item => !item.deleted)
        .map(item => new UnitManagementProcessDetailsTreeModel(item));
      processesDrafts.forEach(item => {
        const parent = processesDrafts.find(parent => parent.id === item.parentId);
        if (parent) {
          item.processDraft.setParent(parent.processDraft);
        }
      });
      setItemsFlat(processesDrafts.sort(sortByOrderPrefix));
      const preselectedMasterProcessStr = new URLSearchParams(window.location.search).get('reveal');
      if (preselectedMasterProcessStr) {
        const revealedMasterProcessIds: Array<number> = [];
        let nextParentMasterProcessId = Number(preselectedMasterProcessStr);
        while (nextParentMasterProcessId) {
          const next = nextParentMasterProcessId;
          const nextProcess = processesDrafts.find(process => process.id === next);
          if (nextProcess?.parentId) {
            revealedMasterProcessIds.push(nextProcess.parentId);
            nextParentMasterProcessId = nextProcess.parentId;
          } else {
            break;
          }
        }
        setRevealedMasterProcessIds(revealedMasterProcessIds);
      }
    }
  }, [unitId, getProcessesDraftsToManage, getUnit]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refreshData();
      setIsLoading(false);
    })();
  }, [refreshData]);

  const onCancel = useCallback(() => {
    navigate(`/unit/${unitId}/manage`);
  }, [navigate, unitId]);

  const saveItems = useCallback(
    async (
      publish: boolean,
      publishScenario: PublishScenario,
      publicationComment: string | null = null,
      notification: boolean = false
    ) => {
      setIsLoading(true);
      const modifiedItems = getCorrectItems(publish, itemsFlat);
      const isValid = modifiedItems.every(item => item.isValid);
      setIsInvalid(!isValid);
      if (isValid) {
        if (modifiedItems.length) {
          await sendRequests(modifiedItems, {
            publish,
            notification,
            publishScenario,
            publicationComment
          });
          await refreshData();
        } else {
          addLog(
            MessageBarType.warning,
            `There aren't any pending changes to be ${publish ? 'published' : 'saved'}`
          );
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
      setIsLoading(false);
    },
    [itemsFlat, sendRequests, refreshData, addLog]
  );

  const onSave = useCallback(async () => saveItems(false, PublishScenario.Save), [saveItems]);
  const onSubmit = useCallback(async () => saveItems(true, PublishScenario.Publish), [saveItems]);
  const onSubmitWithNotification = useCallback(
    async (publicationComment: string) =>
      saveItems(true, PublishScenario.PublishAndConfirmProcessValidity, publicationComment, true),
    [saveItems]
  );
  const getBulkModel = useCallback(() => new UnitManagementProcessDetailsTreeModel(), []);

  return useMemo(
    () => ({
      unitId,
      unitTitle,
      columns,
      itemsFlat,
      revealedMasterProcessIds,
      isAnythingLoading,
      isInvalid,
      getBulkModel,
      onCancel,
      onSave,
      onSubmit,
      onSubmitWithNotification,
      progressStatus,
      onDismiss,
      isModalVisible
    }),
    [
      unitId,
      unitTitle,
      columns,
      itemsFlat,
      revealedMasterProcessIds,
      isAnythingLoading,
      isInvalid,
      getBulkModel,
      onCancel,
      onSave,
      onSubmit,
      onSubmitWithNotification,
      progressStatus,
      onDismiss,
      isModalVisible
    ]
  );
};
