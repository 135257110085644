import {createContext} from 'react';
import {GlobalSettings} from './GlobalSettingsProvider';
import {SettingDto} from '../services/generated';
import { LibraryDto } from '../services/generated/models/LibraryDto';

export const GlobalSettingsContext = createContext<{
  globalSettings: GlobalSettings;
  isLoading: boolean;
  setGlobalSettings: (settingsFlat: SettingDto[],unitTurnOffNotification: boolean, libraries: LibraryDto[] ) => void;
}>({
  globalSettings: {settingsFlat: [], settings: {}, unitTurnOffNotification: false, libraries: []},
  isLoading: false,
  setGlobalSettings: (settingsFlat: SettingDto[]) => {}
});
