import {Links, ViewProcessDiagramDto} from '../services/generated';
import {ProcessDiagramBase} from './ProcessDiagramBase';
import {ProcessDiagramItem} from './ProcessDiagramItem';

export class ProcessDiagram extends ProcessDiagramBase implements ViewProcessDiagramDto {
  public processTitle: string | null;
  public elements: Array<ProcessDiagramItem>;
  readonly link: Array<Links> | null;
  public isInherited: boolean;

  constructor(diagram: ViewProcessDiagramDto) {
    super(diagram);
    this.processTitle = diagram.processTitle;
    this.elements = diagram.elements?.map(item => new ProcessDiagramItem(item)) || [];
    this.link = diagram.link;
    this.isInherited = diagram.isInherited;
  }
}
