import {Links, UserDto} from '../services/generated';
import {ProcessSubscriptionDto} from '../services/generated/models/ProcessSubscriptionDto';

class ProcessSubscriptionBase {
  link: Links[] | null;
  processId: number;
  processTitle: string | null;
  processOwnerAzureId: string;
  isDeleted: boolean;
  constructor(processSubscriptionDto: ProcessSubscriptionDto) {
    this.link = processSubscriptionDto.link;
    this.processId = processSubscriptionDto.processId;
    this.processTitle = processSubscriptionDto.processTitle;
    this.processOwnerAzureId = processSubscriptionDto.processOwnerAzureId;
    this.isDeleted = processSubscriptionDto.isDeleted;
  }
}
export class ProcessSubscription extends ProcessSubscriptionBase implements ProcessSubscriptionDto {
  id: number;
  userAzureId: string;

  constructor(processSubscriptionDto: ProcessSubscriptionDto) {
    super(processSubscriptionDto);
    this.id = processSubscriptionDto.id;
    this.userAzureId = processSubscriptionDto.userAzureId;
  }
}

export interface subscriptionUser {
  subscriptionId: number;
  userAzureId: string;
}

export class GroupedProcessSubscription extends ProcessSubscriptionBase {
  subscriptionUsers: subscriptionUser[] = [];
  id: number;
  processOwner: UserDto | null;
  constructor(
    processSubscriptionDto: ProcessSubscriptionDto,
    subscribers: subscriptionUser[] = [],
    processOwner: UserDto | null
  ) {
    super(processSubscriptionDto);
    this.subscriptionUsers = subscribers;
    this.id = this.processId;
    this.processOwner = processOwner;
  }
}
