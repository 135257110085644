import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import styles from './acknowledgement.module.scss';
import {useParams} from 'react-router-dom';
import {Checkbox, MessageBar, MessageBarType, Spinner} from '@fluentui/react';
import {TheButton} from '../../common/TheButton';
import {useAcknowledgementBatch} from '../../../hooks/services/useAcknowledgementBatch';
import {BatchAcknowledgement} from '../../../models/Acknowledgement';
import {EnhancedDetailsList, OtherColumn, StringColumn} from '../../common/EnhancedDetailsList';
import {IEnhancedColumn} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {BatchAcknowledgementElementDto} from '../../../services/generated/models/BatchAcknowledgementElementDto';

export const Acknowledgement: FC = () => {
  const {packageId} = useParams();
  const packageItemId = Number(packageId);

  const {
    getAcknowledgementBatch,
    putAcknowledgementOpened,
    putAcknowledgementConfirmed,
    putAcknowledgementFinished
  } = useAcknowledgementBatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [acknowledgementData, setAcknowledgementData] = useState<BatchAcknowledgement>();

  const [messageBar, setMessageBar] = useState<{errorMsg: JSX.Element} | undefined>();
  const [renderKey, setRenderKey] = useState<number>(0);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [acknowledgementItems, setAcknowledgementItems] = useState<
    BatchAcknowledgementElementDto[]
  >([]);
  const [btnFinishDisable, setBtnFinishDisable] = useState<boolean>(true);

  const setterBtnFinishStatus = (items: BatchAcknowledgementElementDto[]) => {
    if (items.length) {
      const allItemsChecked = items.every(item => item.opened && item.confirmed);
      setBtnFinishDisable(!allItemsChecked);
    }
  };

  const getAcknowledgementData = useCallback(async () => {
    if (!packageItemId) {
      return;
    }
    const response = await getAcknowledgementBatch(packageItemId);
    if (response.result) {
      const data = response.result.elements;
      setAcknowledgementData(response.result);
      setAcknowledgementItems(response.result.elements);
      setIsReadOnly(response.result.readOnly);
      setterBtnFinishStatus(data);
      setRenderKey(prev => prev + 1);
    }
    setIsLoading(false);
  }, [getAcknowledgementBatch, packageItemId]);

  const onOpenDocumentHandler = useCallback(
    async (item: BatchAcknowledgementElementDto) => {
      if (item.opened || isReadOnly) {
        return;
      }
      setIsLoading(true);
      setMessageBar(undefined);
      const result = await putAcknowledgementOpened(item.id);
      if (result.apiCode) {
        setMessageBar({
          errorMsg: (
            <MessageBar messageBarType={MessageBarType.error}>
              Something went wrong. Error during saving opened status, code: {result.apiCode}
            </MessageBar>
          )
        });
      }
      getAcknowledgementData();
      setIsLoading(false);
    },
    [getAcknowledgementData, putAcknowledgementOpened, isReadOnly]
  );

  const buildDocumentLink = (url: string) => {
    const extensionRegex = url.match(/\.(\w{2,5})(?:$|\?)/);
    const extension = extensionRegex ? extensionRegex[1] : null;
    const openLink =
      extension === 'pdf' || !extension ? url : `${url}${url.indexOf('?') === -1 ? '?' : '&'}web=1`;
    return openLink;
  };

  const onConfirmItemSave = useCallback(
    async (id: number) => {
      setIsLoading(true);
      const result = await putAcknowledgementConfirmed(id);
      if (!result.apiCode) {
        getAcknowledgementData();
      }
      setIsLoading(false);
    },
    [getAcknowledgementData, putAcknowledgementConfirmed]
  );

  const onConfirmHandler = useCallback(
    (item: BatchAcknowledgementElementDto, isChecked: boolean) => {
      setMessageBar(undefined);
      if (isChecked && !item.opened) {
        setMessageBar({
          errorMsg: (
            <MessageBar messageBarType={MessageBarType.error}>
              Sorry but you can't mark a documents as acknowledged before you have read it. Check if
              all documents were opened.
            </MessageBar>
          )
        });
        return;
      }
      onConfirmItemSave(item.id);
    },
    [onConfirmItemSave]
  );

  const onFinishConfirmationSave = async () => {
    setIsLoading(true);
    const result = await putAcknowledgementFinished(packageItemId);
    if (!result.apiCode) {
      getAcknowledgementData();
    }
    setIsLoading(false);
  };

  const onFinishConfirmationHandler = () => {
    setMessageBar(undefined);
    const allItemsChecked = acknowledgementItems.every(item => item.opened && item.confirmed);
    if (!allItemsChecked) {
      setMessageBar({
        errorMsg: (
          <MessageBar messageBarType={MessageBarType.error}>
            Sorry but you didn't check all documents as opened and acknowledged. The form was not
            sent
          </MessageBar>
        )
      });
      return;
    }
    onFinishConfirmationSave();
  };

  const columnsView = useMemo(() => {
    const columns: IEnhancedColumn<BatchAcknowledgementElementDto>[] = [
      {
        key: 'documentTitle',
        name: 'Title',
        data: StringColumn,
        minWidth: 400,
        onRender(item) {
          return item.relativeUrl ? (
            <a
              href={buildDocumentLink(item.relativeUrl)}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
              onClick={() => onOpenDocumentHandler(item)}>
              {item.documentTitle}
            </a>
          ) : (
            <span>{item.documentTitle}</span>
          );
        }
      },
      {
        key: 'documentId',
        name: 'Document Id',
        data: StringColumn,
        minWidth: 220
      },
      {
        key: 'languageCode',
        name: 'Language',
        data: StringColumn,
        minWidth: 145
      },
      {
        key: 'opened',
        name: 'Opened',
        data: OtherColumn,
        minWidth: 120,
        onRender(item) {
          return (
            <Checkbox disabled={true} className={styles.select} defaultChecked={item.opened} />
          );
        }
      },
      {
        key: 'confirmed',
        name: 'Acknowledged',
        data: OtherColumn,
        minWidth: 120,
        onRender(item) {
          return item.opened ? (
            <Checkbox
              className={styles.select}
              onChange={(ev, checked) => onConfirmHandler(item, !!checked)}
              defaultChecked={item.confirmed}
              disabled={isReadOnly}
            />
          ) : (
            <Checkbox
              className={styles.select}
              onChange={() =>
                setMessageBar({
                  errorMsg: (
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                      Sorry but you can't confirm until it is opend
                    </MessageBar>
                  )
                })
              }
              disabled={isReadOnly}
              checked={false}
            />
          );
        }
      }
    ];
    return columns;
  }, [isReadOnly, onConfirmHandler, onOpenDocumentHandler]);

  useEffect(() => {
    getAcknowledgementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let contentJSX: JSX.Element;
  if (isLoading) {
    contentJSX = <Spinner />;
  } else if (!isLoading && acknowledgementData) {
    const {comment, process, elements, readOnly} = acknowledgementData;

    const deadlineHeaderJSX = (
      <div className={styles.acknowledgementDetails}>
        <h6>Deadline</h6>
        <p>{acknowledgementData.deadline?.split('T')[0]}</p>
      </div>
    );

    const commentJSX = comment && (
      <div className={styles.acknowledgementDetails}>
        <h6>Background / Reason </h6>
        <div
          dangerouslySetInnerHTML={{
            __html: comment
          }}></div>
      </div>
    );

    const processDetailsJSX = (
      <div className={styles.acknowledgementDetails}>
        <h6>Process Details</h6>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/unit/${process.unitId}/process/${process.id}`}>
            {process.title}
          </a>
        </p>
      </div>
    );

    contentJSX = (
      <>
        <h4 className={styles.acknowledgementExplanation}>
          You are required to acknowledge that you have read and understood the below documents
        </h4>
        {commentJSX}
        {deadlineHeaderJSX}
        {processDetailsJSX}
        {messageBar?.errorMsg}
        <EnhancedDetailsList
          key={renderKey}
          items={elements}
          columns={columnsView}
          hideSearch
          frozen
          pageSize={30}
        />
        {!readOnly && (
          <footer>
            <TheButton primary onClick={onFinishConfirmationHandler} disabled={btnFinishDisable}>
              Confirm Complete
            </TheButton>
          </footer>
        )}
      </>
    );
  } else {
    contentJSX = <h4>No data to show</h4>;
  }
  return (
    <main className={styles.main}>
      <div className={styles.mainWrapper}>
        <HeadingWithDecoration text="Acknowledgement" />
        {contentJSX}
      </div>
    </main>
  );
};
