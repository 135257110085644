import {Unit} from '../../../models/Unit';
import {UnitMinimalDto, UnitStatus} from '../../../services/generated';
import {Links} from '../../../services/generated/models/Links';
import {IUnitRowValue} from './ITypes';

export class TreeUnitsModel {
  private _id: number;
  private _parentId: number | null;
  private _title: string | null;
  private _countryCode: string | null;
  public implementProcess: boolean;
  private _disabled: boolean;
  private initialIsChecked: boolean;
  public isChecked: boolean;
  private initialUnitTypeId: number;
  public unitTypeId: number;
  private _link: Links[] | null;
  private cannotBeUncheked: boolean;
  private _status: UnitStatus;
  public get rowValue(): IUnitRowValue {
    return {
      id: this._id,
      parentId: this._parentId,
      title: this._title,
      countryCode: this._countryCode,
      implementProcess: this.implementProcess,
      disabled: this.disabled,
      initialIsChecked: this.initialIsChecked,
      isChecked: this.isChecked,
      initialUnitTypeId: this.initialUnitTypeId,
      unitTypeId: this.unitTypeId,
      link: this._link,
      cannotBeUncheked: this.cannotBeUncheked
    };
  }

  public get id() {
    return this._id;
  }

  public get parentId() {
    return this._parentId;
  }

  public get link() {
    return this._link;
  }

  public get title() {
    return this._title;
  }

  public get countryCode() {
    return this._countryCode;
  }

  public get disabled() {
    return this._disabled;
  }

  public get isModified() {
    return (
      this.initialIsChecked !== this.isChecked ||
      (this.isChecked && this.unitTypeId !== this.initialUnitTypeId)
    );
  }
  public get status() {
    return this._status;
  }
  constructor(unit: Unit, unitDtoMinimal?: UnitMinimalDto) {
    if (unitDtoMinimal && unit.id !== unitDtoMinimal.id)
      throw new Error(
        `Cannot merge unit objects because ids doesn't match ${unit.id} and ${unitDtoMinimal.id})`
      );
    this._id = unit.id;
    this._parentId = unitDtoMinimal ? unitDtoMinimal.parentId : unit.parentId;
    this._title = unitDtoMinimal ? unitDtoMinimal.title : unit.title;
    this._countryCode = unit.countryCode;
    this.implementProcess = unitDtoMinimal ? unitDtoMinimal.implementProcess : false;
    this._disabled = this.initialIsChecked = unitDtoMinimal
      ? unitDtoMinimal.implementProcess
      : false;
    this.isChecked = unitDtoMinimal ? unitDtoMinimal.implementProcess : false;
    this.initialUnitTypeId = unitDtoMinimal ? unitDtoMinimal.unitTypeId : unit.unitTypeId;
    this.unitTypeId = unitDtoMinimal ? unitDtoMinimal.unitTypeId : unit.unitTypeId;
    this._link = unitDtoMinimal ? unitDtoMinimal.link : unit.link;
    this.cannotBeUncheked = unitDtoMinimal?.id === unit.id ? false : true;
    this._status = unit.status;
  }
}
