import {
  DescriptionDto,
  EditExternalLinkVersionDto,
  ExternalLinkDto,
  FunctionDto,
  ProcessActiveContentDto,
  UserDto
} from '../services/generated';
import {ExternalLink} from './ExternalLink';
import {ProcessBaseType} from './ProcessBaseType';

interface IProcessBaseViewDto extends ProcessBaseType {
  deleted: boolean;
  descriptions: Array<DescriptionDto> | null;
  externalLinks: (ExternalLinkDto | EditExternalLinkVersionDto)[] | null;
  function: FunctionDto | null;
  nextUpdate: string;
  parentProcessId: number | null;
  processActiveContents: Array<ProcessActiveContentDto> | null;
  processOwner: UserDto | null;
  secondaryProcessOwner: UserDto | null;
}

export class ProcessBaseView extends ProcessBaseType {
  public deleted: boolean;
  public descriptions: Array<DescriptionDto> | null;
  public externalLinks: ExternalLink[] | null;
  public function: FunctionDto | null;
  public nextUpdate: string;
  public parentProcessId: number | null;
  public processActiveContents: Array<ProcessActiveContentDto> | null;
  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;

  public description: string | null = null;

  constructor(process: IProcessBaseViewDto) {
    super(process);
    this.deleted = process.deleted;
    this.descriptions = process.descriptions;
    this.externalLinks =
      process.externalLinks?.map(link => new ExternalLink({...link, removed: false})) || null;
    this.function = process.function;
    this.nextUpdate = process.nextUpdate;
    this.parentProcessId = process.parentProcessId;
    this.processActiveContents = process.processActiveContents;
    this.processOwner = process.processOwner;
    this.secondaryProcessOwner = process.secondaryProcessOwner;

    function getDescription() {
      const myDescription: DescriptionDto | undefined = process.descriptions?.find(
        desc => desc.ownerProcessId === process.id
      );
      if (!myDescription || !myDescription.content || myDescription.deleted) return null;
      const div = document.createElement('div');
      div.innerHTML = myDescription.content;
      const text = div.innerText.replace(/\s/g, '').replace(/\u200B/g, '');
      if (!text.length) return null;
      return myDescription.content;
    }
    this.description = getDescription();
  }
}
