import {FC} from 'react';
import {Accordion} from '../../../common/Accordion';

interface IDescriptionProps {
  description: {content: string | null; unitTitle?: string | null};
  unitTitle?: string | null;
  isOpen?: boolean;
  className?: string;
  customStyles?: {
    stylesHeader?: React.CSSProperties;
    stylesDescription?: React.CSSProperties;
  };
}

export const Description: FC<IDescriptionProps> = ({
  description,
  unitTitle,
  className,
  isOpen = true,
  customStyles
}) => {
  if (!description.content) return null;
  return (
    <Accordion
      isOpen={isOpen}
      header={unitTitle || description.unitTitle || ''}
      customStyles={customStyles}>
      <div
        className={`sun-editor sun-editor-editable no-border can-copy ${className || ''}`}
        dangerouslySetInnerHTML={{
          __html: description.content
        }}
      />
    </Accordion>
  );
};
