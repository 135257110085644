import {
  DiagramElementsDto,
  DiagramProcessGroup,
  Links,
  MinimalProcessForDiagramDto
} from '../services/generated';

export class ProcessDiagramItem implements DiagramElementsDto {
  public group: DiagramProcessGroup | null;
  public child: MinimalProcessForDiagramDto | null;
  public customValue: string | null;
  public imageBase64: string | null;
  public row: number | null;
  public column: number | null;
  public order: number;
  public id: number;
  public link: Links[] | null;
  public height: number | null;
  public page: number | null;
  public toolbar: number | null;
  public view: string | null;
  public zoom: number | null;

  public get groupName() {
    switch (this.group) {
      case DiagramProcessGroup.MainNode:
        return 'Main Node';
      case DiagramProcessGroup.SupportingNode:
        return 'Supporting Node';
      case DiagramProcessGroup.MatrixStage:
        return 'Matrix Stage';
      case DiagramProcessGroup.MatrixFunction:
        return 'Matrix Function';
      default:
        return '';
    }
  }

  constructor(item: DiagramElementsDto) {
    this.group = item.group;
    this.child = item.child;
    this.customValue = item.customValue;
    this.imageBase64 = item.imageBase64;
    this.row = item.row;
    this.column = item.column;
    this.order = item.order;
    this.id = item.id;
    this.link = item.link;
    this.height = item.height;
    this.page = item.page;
    this.toolbar = item.toolbar;
    this.view = item.view;
    this.zoom = item.zoom;
  }
}
