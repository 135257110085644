import {FC, useCallback, useState} from 'react';
import {TheButton} from '../../../../../common/TheButton';
import {ProcessDescriptions} from '../../../../Process/components/ProcessDescriptions';
import {IProcessRowDescriptionsProps} from './IProcessRowDescriptionsProps';
import styles from './ProcessRowDescriptions.module.scss';
import {Richtext} from '../../../../../common/Richtext';

export const ProcessRowDescriptions: FC<IProcessRowDescriptionsProps> = ({model, disabled}) => {
  const currentDescription = model.currentProcessDescription;
  const [description, setDescription] = useState(currentDescription?.content || '');
  const [processDraft, setProcessDraft] = useState(model.processDraft);
  const [isEdit, setIsEdit] = useState(false);
  const onEdit = useCallback(() => setIsEdit(true), [setIsEdit]);
  const onCancel = useCallback(() => {
    setDescription(currentDescription?.content || '');
    setIsEdit(false);
  }, [currentDescription, setDescription, setIsEdit]);
  const onSave = useCallback(() => {
    if (currentDescription) {
      currentDescription.content = description;
    }
    model.description = description;
    setProcessDraft(model.processDraft);
    setIsEdit(false);
  }, [currentDescription, description, model, setIsEdit, setProcessDraft]);
  return (
    <div>
      <ProcessDescriptions
        process={processDraft}
        processUnitTitle={model.processDraft.unitTitle || ''}
        defaultClosed
      />
      {!isEdit ? <TheButton disabled={disabled} onClick={onEdit}>Edit description</TheButton> : null}
      {isEdit ? (
        <>
          <Richtext defaultValue={description} onChange={setDescription} />
          <div className={styles.buttons}>
            <TheButton onClick={onCancel}>Cancel changes</TheButton>
            <TheButton onClick={onSave} primary>
              Update field
            </TheButton>
          </div>
        </>
      ) : null}
    </div>
  );
};
