import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UnitsDescriptionDto,
  UnitsProcessDto,
  UserDto,
  BusinessLineDto
} from '../services/generated';
import {Activity} from './Activity';
import {DocumentSlim} from './DocumentSlim';
import {ExternalLink} from './ExternalLink';
import {ProcessBaseType} from './ProcessBaseType';

export class UnitsProcess extends ProcessBaseType implements UnitsProcessDto {
  public created: string | null;
  public createdBy: UserDto | null;
  public deleted: boolean;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public version: number;

  public url: string = '';
  public functionString: string = '';

  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;
  public parentUnitTitle: string | null;
  public descriptions: UnitsDescriptionDto[] | null;
  public documents: DocumentSlim[] | null;
  public externalLinks: ExternalLink[] | null;
  public function: FunctionDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public nextUpdate: string;
  public published: string | null;
  public publishedBy: UserDto | null;
  public draftExists: boolean;

  public activityInputs: Activity[] | null;
  public activityOutputs: Activity[] | null;

  public businessLines: BusinessLineDto[] | null;

  public get description() {
    return this.descriptions?.find(desc => desc.ownerProcessId === this.id);
  }

  constructor(unitProcess: UnitsProcessDto, unitId: number) {
    super(unitProcess);
    this.created = unitProcess.created;
    this.createdBy = unitProcess.createdBy;
    this.deleted = unitProcess.deleted;
    this.modified = unitProcess.modified;
    this.modifiedBy = unitProcess.modifiedBy;
    this.version = unitProcess.version;
    this.processOwner = unitProcess.processOwner;
    this.secondaryProcessOwner = unitProcess.secondaryProcessOwner;
    this.parentUnitTitle = unitProcess.parentUnitTitle;
    this.descriptions = unitProcess.descriptions;
    this.documents = unitProcess.documents?.map(document => new DocumentSlim(document)) || null;
    this.externalLinks =
      unitProcess.externalLinks?.map(link => new ExternalLink({...link, removed: false})) || null;
    this.function = unitProcess.function;
    this.projectSizeCategories = unitProcess.projectSizeCategories;
    this.industries = unitProcess.industries;
    this.specialities = unitProcess.specialities;
    this.nextUpdate = unitProcess.nextUpdate;
    this.published = unitProcess.published;
    this.publishedBy = unitProcess.publishedBy;
    this.draftExists = unitProcess.draftExists;
    this.businessLines = unitProcess.businessLines;

    this.activityInputs = unitProcess.activityInputs?.map(item => new Activity(item)) || null;
    this.activityOutputs = unitProcess.activityOutputs?.map(item => new Activity(item)) || null;

    Object.defineProperty(this, 'url', {
      get() {
        return `/unit/${unitId}/process/${this.id}`;
      }
    });

    Object.defineProperty(this, 'functionString', {
      get() {
        return unitProcess.function?.value || '';
      }
    });
  }
}
