import {useMemo} from 'react';

import {Process, ProcessChange, ProcessDiagram, ProcessMinimal} from '../../models';
import {ProcessSave} from '../../models/ProcessSave';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {
  BulkEditDto,
  ProcessDto,
  ProcessService,
  SaveProcessDiagramDto
} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {EditProcessVersion} from '../../models/EditProcessVersion';
import {ManageProcessDetails} from '../../models/ManageProcessDetails';
import {ProcessServicePDFDiagram} from '../../services/ProcessServicePDFDiagram';

export const useProcess = () => {
  const requestWrapper = useApiService();
  /** Returns a list of all processes */
  const getProcesses = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Process[]> => {
    return await requestWrapper(
      ProcessService.getApiProcess1(),
      result => result.map(item => new Process(item)),
      log
    );
  };

  /** get single process */
  const getProcess = async (
    processId: number,
    log: LogMessageType = {
      error: false,
      pageException: true,
      pageExceptionMessage: `Process (${processId}) Not Found`
    }
  ): ApiResponsePending<Process> => {
    return await requestWrapper(
      ProcessService.getApiProcess(processId),
      result => new Process(result),
      log
    );
  };

  /** TODO create process */
  const createProcess = async (log?: LogMessageType): ApiResponsePending<Process> => {
    throw new Error('TODO'); // TODO
  };

  /** TODO delete process */
  const deleteProcess = async (log?: LogMessageType): ApiResponsePending<undefined> => {
    throw new Error('TODO'); // TODO
  };

  /** get all version of process */
  const getProcessChanges = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessChange[]> => {
    return await requestWrapper(
      ProcessService.getApiProcessChanges(processId),
      result => result.map(item => new ProcessChange(item)),
      log
    );
  };

  /** Draft process by ID */
  const getProcessVersionsDraft = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<EditProcessVersion> => {
    return await requestWrapper(
      ProcessService.getApiProcessDraft(processId),
      result => new EditProcessVersion(result),
      log
    );
  };

  /** update process as draft without publishing */
  const updateProcessDraft = async (
    processId: number,
    process: Partial<ProcessSave>,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Process (${processId}) updated successfully`
    }
  ): ApiResponsePending<ProcessSave> => {
    return await requestWrapper(
      ProcessService.putApiProcess(processId, process as ProcessDto),
      result => new ProcessSave(result),
      log
    );
  };

  /** Publish process */
  const publishProcess = async (
    processId: number,
    sendNotification: boolean,
    process: Partial<ProcessSave>,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Process (${processId}) published successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.putApiProcessPublish(processId, sendNotification, process as ProcessDto),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const updateProcessBulk = async (
    sendNotification: boolean,
    requestBody: BulkEditDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Processes ${
        requestBody.publish ? 'published' : 'saved as draft'
      } successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.putApiProcessBulkEdit(sendNotification, requestBody),
      (result, apiCode) => !apiCode,
      log
    );
  };

  /** Get process breadcrumbs */
  const getBreadcrumbs = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessMinimal[]> => {
    return await requestWrapper(
      ProcessService.getApiProcessParents(processId),
      result => result.map(item => new ProcessMinimal(item)),
      log
    );
  };

  /** Get process subscription */
  const getSubscription = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.getApiProcessSubscription(processId),
      result => result,
      log
    );
  };

  /** Update process subscription */
  const putSubscription = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.putApiProcessSubscription(processId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  /** Update process and its children subscription */
  const putSubscriptionBranch = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.putApiProcessSubscribeBranch(processId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  /** Delete process subscription */
  const deleteSubscription = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.deleteApiProcessSubscription(processId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  /** Delete process and its children subscription */
  const deleteSubscriptionBranch = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ProcessService.deleteApiProcessSubscribeBranch(processId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  /* Get current process but for different unit */
  const getProcessForUnit = (
    unitId: number,
    masterProcessId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Process> => {
    return requestWrapper(
      ProcessService.getApiProcessUnit(unitId, masterProcessId),
      result => new Process(result),
      log
    );
  };

  /** Returns a list of processes in current unit where I am process owner */
  const getProcessesForUnitForOwner = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ManageProcessDetails[]> => {
    return await requestWrapper(
      ProcessService.getApiProcessProcessDraftFlat(unitId),
      result => result.map(item => new ManageProcessDetails(item)),
      log
    );
  };

  const getProcessDiagram = (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessDiagram> => {
    return requestWrapper(
      ProcessService.getApiProcessProcessDiagram(processId),
      result => new ProcessDiagram(result),
      log
    );
  };

  const getProcessDiagramBlob = (
    diagramId: number,
    log: LogMessageType = {
      error: true
    }
  ): Promise<Blob> => {
    return ProcessServicePDFDiagram.getApiProcessProcessDiagramGetPdfFile(diagramId);
  };

  const getProcessDiagramPreviewBlob = (
    url: string,
    log: LogMessageType = {
      error: true
    }
  ): Promise<Blob> => {
    return ProcessServicePDFDiagram.postApiProcessProcessDiagramGetPreviewPdfFile(url);
  };

  const saveProcessDiagram = (
    processId: number,
    diagram: SaveProcessDiagramDto,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessDiagram> => {
    return requestWrapper(
      ProcessService.putApiProcessManageProcessDiagram(processId.toString(), diagram),
      result => new ProcessDiagram(result),
      log
    );
  };

  return useMemo(
    () => ({
      getProcesses,
      createProcess,
      deleteProcess,
      getProcess,
      getProcessChanges,
      getProcessVersionsDraft,
      updateProcessDraft,
      publishProcess,
      getBreadcrumbs,
      getProcessForUnit,
      getSubscription,
      putSubscription,
      putSubscriptionBranch,
      deleteSubscription,
      deleteSubscriptionBranch,
      getProcessDiagram,
      getProcessDiagramBlob,
      getProcessDiagramPreviewBlob,
      saveProcessDiagram,
      updateProcessBulk,
      getProcessesForUnitForOwner
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
