import {FC, useEffect, useState} from 'react';
import {TheButton} from '../../../common/TheButton';
import {UserDto} from '../../../../services/generated';
import {useUser} from '../../../../hooks/services/useUser';

interface ISendMailProps {
  unitId: number;
  processId: number;
  processTitle: string;
  processOwner?: UserDto;
}

export const SendEmail: FC<ISendMailProps> = ({unitId, processId, processTitle, processOwner}) => {
  const {getUserById} = useUser();
  const [processOwnerName, setProcessOwnerName] = useState('');
  useEffect(() => {
    if (!processOwner) return;
    (async () => {
      const user = await getUserById(processOwner?.azureId);
      setProcessOwnerName(user?.displayName || '');
    })();
  }, [getUserById, processOwner]);
  const email = processOwner?.userPrincipalName;
  const subject = encodeURIComponent(`IMS ${processId} ${processTitle}`);
  const body = encodeURIComponent(`Hi${processOwnerName ? ` ${processOwnerName}` : ''},

${processTitle} (${window.location.origin}/unit/${unitId}/process/${processId})

`);
  return (
    <TheButton
      iconProps={{iconName: 'NewMail'}}
      href={`mailto:${email}?subject=${subject}&body=${body}`}>
      Contact
    </TheButton>
  );
};
