import {BooleanFilters, ColumnType} from '../IEnhancedDetailsList';
import {ChoiceGroup, IColumn, IComboBoxOption} from '@fluentui/react';
import {EnhancedColumn} from './EnhancedColumn';

export class BooleanColumn extends EnhancedColumn {
  public static type = ColumnType.Boolean;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return <>{value ? 'Yes' : 'No'}</>;
  }

  public static hasActiveFilter(value: any): boolean {
    return value === BooleanFilters.True || value === BooleanFilters.False;
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void
  ): JSX.Element {
    return (
      <ChoiceGroup
        label="Filter"
        onChange={(e, option) => onChange(option?.key)}
        defaultSelectedKey={defaultValue}
        options={[
          {
            key: BooleanFilters.All,
            text: 'All'
          },
          {
            key: BooleanFilters.True,
            text: 'Yes'
          },
          {
            key: BooleanFilters.False,
            text: 'No'
          }
        ]}
      />
    );
  }

  public static filterFunction(itemValue: boolean, filterValue: BooleanFilters): boolean {
    if (filterValue === BooleanFilters.All) return true;
    if (filterValue === BooleanFilters.True) return itemValue === true;
    if (filterValue === BooleanFilters.False) return itemValue === false;
    return false;
  }

  public static searchFunction(itemValue: boolean, lowerCaseSearch: string): boolean {
    return `${itemValue || false}`.includes(lowerCaseSearch);
  }
  public static getColumnValueForExcel(itemValue: boolean): string {
    return itemValue ? 'True' : 'False';
  }
}
