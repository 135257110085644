import {
  DiagramProcessGroup,
  DiagramType,
  MinimalProcessForDiagramDto,
  SaveDiagramElementsDto
} from '../../../services/generated';
import {IDropdownOption} from '@fluentui/react';
import {IMatrixValue} from './MatrixDiagram';
import {INormalValue} from './NormalDiagram';
import {ProcessDiagram, ProcessFlatStructure} from '../../../models';
import {ProcessType} from '../../../enums';
import {sortByFakePrefix, sortByOrderPrefix} from '../../../helpers/arrayMehods';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useProcess} from '../../../hooks/services/useProcess';
import {useUnit} from '../../../hooks/services/useUnit';
import {DictionariesContext} from '../../../providers';

export interface IProcessOption {
  key: number;
  text: string;
  data: {
    type: ProcessType;
    masterProcessId: number;
    parentMasterProcessId: number | null;
  };
}

export const useDiagram = (
  processId: number,
  unitId: number,
  returnType: (type: DiagramType) => void
) => {
  const {globalFiltersFunction} = useContext(DictionariesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const [normalValuesInitial, setNormalValuesInitial] = useState<INormalValue[]>([]);
  const [matrixValuesInitial, setMatrixValuesInitial] = useState<IMatrixValue[]>([]);
  const [imageUrlInitial, setImageUrlInitial] = useState('');
  const [typeInitial, setTypeInitial] = useState<DiagramType>(DiagramType.None);
  const [inheritedInitial, setInheritedInitial] = useState<boolean>(false);
  const [inherited, setInherited] = useState<boolean>(false);

  const [normalValues, setNormalValues] = useState<INormalValue[]>([]);
  const setNormalValuesWithEffect = useCallback(
    (values: INormalValue[]) => {
      setNormalValues(values);
      if (inherited) setInherited(false);
    },
    [inherited, setInherited, setNormalValues]
  );
  const [matrixValues, setMatrixValues] = useState<IMatrixValue[]>([]);
  const setMatrixValuesWithEffect = useCallback(
    (values: IMatrixValue[]) => {
      setMatrixValues(values);
      if (inherited) setInherited(false);
    },
    [inherited, setInherited, setMatrixValues]
  );
  const [customValue, setCustomValue] = useState('');
  const setCustomValueWithEffect = useCallback(
    (values: string) => {
      setCustomValue(values);
      if (inherited) setInherited(false);
    },
    [inherited, setInherited, setCustomValue]
  );
  const [height, setHeight] = useState<number | null>(null);
  const [page, setPage] = useState<number | null>(null);
  const [toolbar, setToolbar] = useState<0 | 1 | null>(null);
  const [view, setView] = useState<'FitV' | 'FitH' | 'Fit' | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);
  const [diagramId, setDiagramId] = useState<number | null>(null);

  const {getProcessDiagram, saveProcessDiagram} = useProcess();

  const [type, setType] = useState<DiagramType>(DiagramType.None);

  const [isProcessesLoading, setIsProcessesLoading] = useState(false);
  const [processes, setProcesses] = useState<ProcessFlatStructure[] | null>(null);
  const {getProcessesPublishedForUnitFlat} = useUnit();

  useEffect(() => {
    (async () => {
      setIsProcessesLoading(true);
      const processResponse = await getProcessesPublishedForUnitFlat(unitId);
      if (!processResponse.result) {
        setProcesses(null);
      } else {
        const processesMapped = processResponse.result.filter(
          processMapped => !processMapped.deleted
        );
        for (const processMapped of processesMapped) {
          const parent = processesMapped.find(
            item => item.masterProcessId === processMapped.parentMasterProcessId
          );
          if (parent) {
            processMapped.setParent(parent);
          }
        }
        setProcesses(processesMapped.sort(sortByOrderPrefix));
      }
      setIsProcessesLoading(false);
    })();
  }, [getProcessesPublishedForUnitFlat, setProcesses, unitId]);

  const onDropdownChange = useMemo(
    () => (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
      setType((option?.key as DiagramType) || null);
      setInherited(option?.key === DiagramType.Inherited);
    },
    []
  );
  const options = useMemo(
    () => [
      {
        key: DiagramType.Normal,
        text: 'Normal'
      },
      {
        key: DiagramType.Matrix,
        text: 'Matrix'
      },
      {
        key: DiagramType.Activity,
        text: 'Activity'
      },
      {
        key: DiagramType.PDF,
        text: 'PDF'
      },
      {
        key: DiagramType.Inherited,
        text: 'Inherited'
      },
      {
        key: DiagramType.None,
        text: 'None'
      }
    ],
    []
  );

  const processesOptions: IProcessOption[] = useMemo(
    () =>
      processes?.sort(sortByFakePrefix).map(process => ({
        key: process.id,
        text: process.title || '',
        data: {
          masterProcessId: process.masterProcessId,
          parentMasterProcessId: process.parentMasterProcessId,
          type: process.processType
        }
      })) || [],
    [processes]
  );

  const processesOptionsFiltered: IProcessOption[] = useMemo(
    () =>
      processes
        ?.sort(sortByFakePrefix)
        .filter(globalFiltersFunction)
        .map(process => ({
          key: process.id,
          text: process.title || '',
          data: {
            masterProcessId: process.masterProcessId,
            parentMasterProcessId: process.parentMasterProcessId,
            type: process.processType
          }
        })) || [],
    [processes, globalFiltersFunction]
  );

  const setDiagramVariables = useMemo(
    () => (diag: ProcessDiagram) => {
      setType(diag.type);
      setInherited(diag.isInherited);
      setInheritedInitial(diag.isInherited);
      setTypeInitial(diag.type);
      if (diag.elements) {
        const filteredElements = diag.elements.filter(elem => elem.child);
        switch (diag.type) {
          case DiagramType.Normal:
            const vals = filteredElements
              .map(elem =>
                elem.child
                  ? {
                      childId: elem.child.id,
                      child: elem.child,
                      group: elem.group,
                      order: elem.order
                    }
                  : null
              )
              .filter(val => val) as {
              childId: number;
              child: MinimalProcessForDiagramDto;
              group: DiagramProcessGroup | null;
              order: number;
            }[];
            setNormalValues(vals);
            setNormalValuesInitial([...vals]);
            break;
          case DiagramType.Matrix:
            const values: IMatrixValue[] = [];
            for (const elem of diag.elements) {
              if (elem.child || elem.customValue) {
                const existingValue = values.find(
                  value => value.column === elem.column && value.row === elem.row
                );
                if (existingValue && elem.child) {
                  existingValue.processes[elem.order] = elem.child;
                } else if (existingValue && elem.customValue) {
                  existingValue.customValue = elem.customValue;
                } else {
                  values.push({
                    row: elem.row || 0,
                    column: elem.column || 0,
                    processes: elem.child ? [elem.child] : [],
                    customValue: elem.customValue
                  });
                }
              }
            }
            const valuesCopy = values.map(val => ({
              ...val,
              processes: val.processes.map(process => ({...process}))
            }));
            setMatrixValues(values);
            setMatrixValuesInitial(valuesCopy);
            break;
          case DiagramType.Activity:
            if (diag.elements[0]?.customValue) {
              setCustomValue(diag.elements[0].customValue);
            }
            if (diag.elements[0].imageBase64) {
              setImageUrlInitial(diag.elements[0].imageBase64);
            }
            break;
          case DiagramType.PDF:
            const firstElement = diag.elements[0];
            if (!firstElement) break;
            if (firstElement.customValue) {
              setCustomValue(firstElement.customValue);
              setImageUrlInitial(firstElement.customValue);
            }
            setDiagramId(firstElement.id);
            setToolbar(firstElement.toolbar as 0 | 1 | null);
            setPage(firstElement.page);
            setHeight(firstElement.height);
            setView(firstElement.view as 'FitV' | 'FitH' | 'Fit' | null);
            setZoom(firstElement.zoom);
            break;
          case DiagramType.None:
          case DiagramType.Inherited:
            break;
        }
      }
      if (!diag.elements.length) {
        returnType(DiagramType.None);
      } else {
        returnType(diag.type);
      }
    },
    [returnType]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const diagramResponse = await getProcessDiagram(processId);
      if (diagramResponse.result) {
        setDiagramVariables(diagramResponse.result);
      }
      setIsLoading(false);
    })();
  }, [getProcessDiagram, setDiagramVariables, processId]);

  const saveDiagram = useMemo(
    () => async () => {
      setIsLoading(true);
      let elements: SaveDiagramElementsDto[] = [];
      if (type === DiagramType.Normal) {
        elements = normalValues.map(value => ({
          childId: value.childId,
          group: value.group,
          order: value.order,
          column: null,
          row: null,
          customValue: null,
          height: null,
          page: null,
          toolbar: null,
          view: null,
          zoom: null
        }));
      } else if (type === DiagramType.Matrix) {
        for (const value of matrixValues) {
          if (value.customValue) {
            elements.push({
              childId: null,
              column: value.column,
              row: value.row,
              order: 0,
              group:
                value.row === 0
                  ? DiagramProcessGroup.MatrixStage
                  : value.column === 0
                  ? DiagramProcessGroup.MatrixFunction
                  : DiagramProcessGroup.MainNode,
              customValue: value.customValue,
              height: null,
              page: null,
              toolbar: null,
              view: null,
              zoom: null
            });
          } else {
            for (let order = 0; order < value.processes.length; order++) {
              const processId = value.processes[order].id;
              elements.push({
                childId: processId,
                column: value.column,
                row: value.row,
                order,
                group:
                  value.row === 0
                    ? DiagramProcessGroup.MatrixStage
                    : value.column === 0
                    ? DiagramProcessGroup.MatrixFunction
                    : DiagramProcessGroup.MainNode,
                customValue: null,
                height: null,
                page: null,
                toolbar: null,
                view: null,
                zoom: null
              });
            }
          }
        }
      } else if (type === DiagramType.Activity) {
        elements.push({
          order: 1,
          group: DiagramProcessGroup.MainNode,
          customValue,
          childId: null,
          column: null,
          row: null,
          height: null,
          page: null,
          toolbar: null,
          view: null,
          zoom: null
        });
      } else if (type === DiagramType.PDF) {
        elements.push({
          order: 1,
          group: DiagramProcessGroup.MainNode,
          customValue,
          childId: null,
          column: null,
          row: null,
          height,
          page,
          toolbar,
          view,
          zoom
        });
      }
      const diagramResponse = await saveProcessDiagram(processId, {
        processId,
        type,
        elements
      });
      if (diagramResponse.result) {
        setDiagramVariables(diagramResponse.result);
      }
      setIsLoading(false);
      setIsEdit(false);
    },
    [
      type,
      saveProcessDiagram,
      processId,
      normalValues,
      matrixValues,
      customValue,
      height,
      page,
      toolbar,
      view,
      zoom,
      setDiagramVariables
    ]
  );

  const onDismiss = useMemo(
    () => () => {
      setType(typeInitial);
      setInherited(inheritedInitial);
      setCustomValue(imageUrlInitial);
      setMatrixValues(
        matrixValuesInitial.map(val => ({
          ...val,
          processes: val.processes.map(process => ({...process}))
        }))
      );
      setNormalValues([...normalValuesInitial]);
      setIsEdit(false);
    },
    [
      setType,
      setCustomValue,
      setMatrixValues,
      setNormalValues,
      setIsEdit,
      typeInitial,
      inheritedInitial,
      imageUrlInitial,
      matrixValuesInitial,
      normalValuesInitial
    ]
  );

  return useMemo(
    () => ({
      onDismiss,
      type,
      options,
      onDropdownChange,
      isLoading,
      isProcessesLoading,
      processesOptions,
      processesOptionsFiltered,
      isEdit,
      setIsEdit,
      saveDiagram,
      normalValues,
      setNormalValuesWithEffect,
      matrixValues,
      setMatrixValuesWithEffect,
      customValue,
      setCustomValueWithEffect,
      diagramId,
      height,
      setHeight,
      page,
      setPage,
      toolbar,
      setToolbar,
      view,
      setView,
      zoom,
      setZoom,
      normalValuesInitial,
      matrixValuesInitial,
      imageUrlInitial,
      typeInitial,
      inherited
    }),
    [
      onDismiss,
      type,
      options,
      onDropdownChange,
      isLoading,
      isProcessesLoading,
      processesOptions,
      processesOptionsFiltered,
      isEdit,
      setIsEdit,
      saveDiagram,
      normalValues,
      setNormalValuesWithEffect,
      matrixValues,
      setMatrixValuesWithEffect,
      customValue,
      setCustomValueWithEffect,
      diagramId,
      height,
      setHeight,
      page,
      setPage,
      toolbar,
      setToolbar,
      view,
      setView,
      zoom,
      setZoom,
      normalValuesInitial,
      matrixValuesInitial,
      imageUrlInitial,
      typeInitial,
      inherited
    ]
  );
};
