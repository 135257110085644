import {rangeHelper} from '../../../helpers';
import styles from './Pagination.module.scss';

interface IProps {
  currentPage: 'All' | number;
  total: number;
  limit: number;
  onPageChange: (value: 'All' | number) => void;
}

export const Pagination = ({currentPage, total, limit, onPageChange}: IProps) => {
  const pagesCount = Math.ceil(total / limit);
  const pages = rangeHelper(1, pagesCount);

  return pages.length > 1 ? (
    <div className={styles.wrapper}>
      <ul className={styles.pagination}>
        <li
          onClick={() => onPageChange('All')}
          className={`${styles.pageItem} ${'All' === currentPage ? styles.active : ''}`}>
          <span className={styles.pageLink}>{'Show all'}</span>
        </li>
        {pages.map(page => (
          <li
            key={page}
            onClick={() => onPageChange(page)}
            className={`${styles.pageItem} ${page === currentPage ? styles.active : ''}`}>
            <span className={styles.pageLink}>{page}</span>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};
