import {FC, PropsWithChildren, useRef, useCallback, useEffect, useMemo, useState} from 'react';
import {useMasterProcessDictionaries} from '../components/Pages/MasterProcessList/common/useMasterProcessDictionaries';
import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../services/generated';

import {DictionariesContext, IGlobalFiltersFunction} from './DictionariesContext';
import {Unit, ViewModel} from '../models';
import {useUnit} from '../hooks/services/useUnit';
import {IComboBoxOption} from '@fluentui/react';
import {useViews} from '../hooks/services/useViews';

export const DictionariesProvider: FC<PropsWithChildren> = ({children}) => {
  const {getUnits} = useUnit();
  const {getViews, deleteDefaultView, updateDefaultView} = useViews();
  const [clearCounter, setClearCounter] = useState(0);
  const [units, setUnits] = useState<Unit[]>([]);
  useEffect(() => {
    (async () => {
      const response = await getUnits();
      if (response.result) {
        setUnits(response.result);
      }
    })();
  }, [getUnits]);

  const [views, setViews] = useState<ViewModel[]>([]);

  const {
    optionsFunction,
    optionsBusinessLine,
    optionsProjectSizeCategory,
    optionsIndustry,
    optionsSpeciality
  } = useMasterProcessDictionaries();

  const [businessLine, setBL] = useState<BusinessLineDto[]>([]);
  const setBusinessLine = useCallback((value: BusinessLineDto[] | undefined) => {
    localStorage.setItem('dictionariesProviderBusinessLine', JSON.stringify(value || ''));
    setBL(value || []);
  }, []);

  const [func, setFunc] = useState<FunctionDto[]>([]);
  const setFunction = useCallback((value: FunctionDto[] | undefined) => {
    localStorage.setItem('dictionariesProviderFunc', JSON.stringify(value || ''));
    setFunc(value || []);
  }, []);

  const [projectSizeCategory, setPSC] = useState<ProjectSizeCategoryDto[]>([]);
  const setProjectSizeCategory = useCallback((value: ProjectSizeCategoryDto[] | undefined) => {
    localStorage.setItem('dictionariesProviderProjectSizeCategory', JSON.stringify(value || ''));
    setPSC(value || []);
  }, []);

  const [industry, setSeg] = useState<IndustryDto[]>([]);
  const setIndustry = useCallback((value: IndustryDto[] | undefined) => {
    localStorage.setItem('dictionariesProviderIndustry', JSON.stringify(value || ''));
    setSeg(value || []);
  }, []);

  const [speciality, setSpec] = useState<SpecialityDto[]>([]);
  const setSpeciality = useCallback((value: SpecialityDto[] | undefined) => {
    localStorage.setItem('dictionariesProviderSpeciality', JSON.stringify(value || ''));
    setSpec(value || []);
  }, []);

  const [excludeItemsApplicableToAll, setExclude] = useState<boolean>(true);
  const setExcludeItemsApplicableToAll = useCallback((value: boolean) => {
    localStorage.setItem('dictionariesProviderIncludeEmpty', JSON.stringify(value || ''));
    setExclude(value || false);
  }, []);

  const isExcluded = useCallback(
    <DICTIONARYITEM extends {id: number}>(
      filters: DICTIONARYITEM[],
      itemValues: DICTIONARYITEM[] | null
    ) => {
      if (!filters.length) return false;
      if (excludeItemsApplicableToAll && (!itemValues || !itemValues.length)) return true;
      return (
        itemValues &&
        itemValues.length &&
        itemValues.every(prop => !filters.some(filter => filter.id === prop.id))
      );
    },
    [excludeItemsApplicableToAll]
  );

  const isFunctionExcluded = useCallback(
    <DICTIONARYITEM extends {id: number}>(
      filters: DICTIONARYITEM[],
      itemValues: DICTIONARYITEM | null
    ) => {
      if (!filters.length) return false;
      if (excludeItemsApplicableToAll && !itemValues) return true;
      return itemValues && !filters.some(filter => filter.id === itemValues.id);
    },
    [excludeItemsApplicableToAll]
  );

  const globalFiltersFunction = useCallback<IGlobalFiltersFunction>(
    item => {
      if (isFunctionExcluded(func, item.function)) return false;
      if (isExcluded(businessLine, item.businessLines ?? [])) return false;
      if (isExcluded(projectSizeCategory, item.projectSizeCategories)) return false;
      if (isExcluded(industry, item.industries ?? [])) return false;
      if (isExcluded(speciality, item.specialities)) return false;
      return true;
    },
    [isExcluded, isFunctionExcluded, func, businessLine, projectSizeCategory, industry, speciality]
  );

  const [view, setView] = useState<number>();
  const optionsView: IComboBoxOption[] | null = useMemo(
    () =>
      views.map(viewModel => ({
        key: viewModel.id,
        text: viewModel.title || '',
        data: viewModel
      })),
    [views]
  );

  const clearView = useCallback(() => {
    setView(undefined);
    setBusinessLine([]);
    setFunction([]);
    setProjectSizeCategory([]);
    setIndustry([]);
    setSpeciality([]);
    setExcludeItemsApplicableToAll(false);
    setView(undefined);
    localStorage.setItem('dictionariesProviderView', '');
    setClearCounter(clearCounter + 1);
  }, [
    setBusinessLine,
    setFunction,
    setProjectSizeCategory,
    setIndustry,
    setSpeciality,
    setExcludeItemsApplicableToAll,
    clearCounter
  ]);

  const onViewChange = useCallback(
    (event: any, option?: IComboBoxOption | undefined) => {
      if (option?.key === -1) {
        clearView();
        return;
      }

      const key: number = option?.key as number;
      setView(key);
      const selectedView = optionsView?.find(option => option.key === key);
      if (selectedView) {
        const viewModel = selectedView.data as ViewModel;
        setFunction(viewModel.functions || undefined);
        setProjectSizeCategory(viewModel.projectSizeCategories || undefined);
        setIndustry(viewModel.industries || undefined);
        setSpeciality(viewModel.specialities || undefined);
        setBusinessLine(viewModel.businessLines || undefined);
        setExcludeItemsApplicableToAll(viewModel.excludeItemsApplicableToAll || false);
      }
      localStorage.setItem('dictionariesProviderView', JSON.stringify(key || ''));
      setClearCounter(clearCounter + 1);
    },
    [
      optionsView,
      clearCounter,
      clearView,
      setFunction,
      setProjectSizeCategory,
      setIndustry,
      setSpeciality,
      setBusinessLine,
      setExcludeItemsApplicableToAll
    ]
  );

  const onFavouriteChange = useCallback(async () => {
    const response = await getViews();
    if (response.result) {
      setViews(response.result);
    }
  }, [getViews]);

  const currentView = views.find(item => item.id === view);

  const hasFilters = currentView
    ? (currentView.functions?.length || 0) !== (func.length || 0) ||
      currentView.functions?.some(item => !func?.some(elem => item.id === elem.id)) ||
      (currentView.projectSizeCategories?.length || 0) !== (projectSizeCategory.length || 0) ||
      currentView.projectSizeCategories?.some(
        item => !projectSizeCategory?.some(elem => item.id === elem.id)
      ) ||
      (currentView.industries?.length || 0) !== (industry.length || 0) ||
      currentView.industries?.some(item => !industry?.some(elem => item.id === elem.id)) ||
      (currentView.specialities?.length || 0) !== (speciality.length || 0) ||
      currentView.specialities?.some(item => !speciality?.some(elem => item.id === elem.id)) ||
      (currentView.businessLines?.length || 0) !== (businessLine.length || 0) ||
      currentView.businessLines?.some(item => !businessLine?.some(elem => item.id === elem.id)) ||
      currentView.excludeItemsApplicableToAll !== excludeItemsApplicableToAll
    : Boolean(
        func.length ||
          projectSizeCategory.length ||
          industry.length ||
          speciality.length ||
          businessLine.length ||
          excludeItemsApplicableToAll
      );

  const hasDefaultBeenChanged =
    hasFilters || !currentView || (currentView && !currentView?.isDefault) || false;

  const clearFilters = useCallback(() => {
    if (!view) return clearView();
    const viewModel = views.find(item => item.id === view);
    setFunction(viewModel?.functions || []);
    setProjectSizeCategory(viewModel?.projectSizeCategories || []);
    setIndustry(viewModel?.industries || []);
    setSpeciality(viewModel?.specialities || []);
    setBusinessLine(viewModel?.businessLines || []);
    setExcludeItemsApplicableToAll(viewModel?.excludeItemsApplicableToAll || false);
    setClearCounter(clearCounter + 1);
  }, [
    view,
    clearView,
    views,
    setFunction,
    setProjectSizeCategory,
    setIndustry,
    setSpeciality,
    setBusinessLine,
    setExcludeItemsApplicableToAll,
    clearCounter
  ]);

  const hasBeenInitialized = useRef(false);

  const setInitialValuesFromLocalStorage = useCallback(() => {
    if (hasBeenInitialized.current) {
      return;
    }
    hasBeenInitialized.current = true;
    const storageFunc = localStorage.getItem('dictionariesProviderFunc');
    if (storageFunc) {
      setFunc(JSON.parse(storageFunc) as FunctionDto[]);
    }
    const storageBusinessLine = localStorage.getItem('dictionariesProviderBusinessLine');
    if (storageBusinessLine) {
      setBL(JSON.parse(storageBusinessLine) as BusinessLineDto[]);
    }
    const storageProjectSizeCategory = localStorage.getItem(
      'dictionariesProviderProjectSizeCategory'
    );
    if (storageProjectSizeCategory) {
      setPSC(JSON.parse(storageProjectSizeCategory) as ProjectSizeCategoryDto[]);
    }
    const storageIndustry = localStorage.getItem('dictionariesProviderIndustry');
    if (storageIndustry) {
      setSeg(JSON.parse(storageIndustry) as IndustryDto[]);
    }
    const storageSpeciality = localStorage.getItem('dictionariesProviderSpeciality');
    if (storageSpeciality) {
      setSpec(JSON.parse(storageSpeciality) as SpecialityDto[]);
    }
    const storageIncludeEmpty = localStorage.getItem('dictionariesProviderIncludeEmpty');
    if (storageIncludeEmpty) {
      setExclude(storageIncludeEmpty === 'true');
    }
    const storageView = localStorage.getItem('dictionariesProviderView');
    if (storageView) {
      setView(JSON.parse(storageView) as number);
    }
  }, []);

  setInitialValuesFromLocalStorage();

  const resetViews = useCallback(async () => {
    const response = await getViews();
    if (response.result) {
      setViews(response.result);
    }
    return response.result;
  }, [getViews]);

  useEffect(() => {
    (async () => {
      const views = await resetViews();
      if (views) {
        const def = views.find(view => view.isDefault);
        if (def) {
          onViewChange(undefined, {
            key: def.id || -1,
            text: `${def.id || -1}`
          });
          setFunction(def.functions || undefined);
          setExcludeItemsApplicableToAll(def.excludeItemsApplicableToAll || false);
          setProjectSizeCategory(def.projectSizeCategories || undefined);
          setIndustry(def.industries || undefined);
          setSpeciality(def.specialities || undefined);
          setBusinessLine(def.businessLines || undefined);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetViews]);

  const saveAsDefault = useCallback(async () => {
    await updateDefaultView({
      id: 0,
      link: null,
      title: 'Default',
      projectSizeCategories: projectSizeCategory,
      industries: industry,
      specialities: speciality,
      functions: func,
      unit: null,
      includeEmpty: !excludeItemsApplicableToAll,
      isFavorite: true,
      isDefault: true,
      masterViewId: view || null,
      businessLines: businessLine || null
    });
    const views = await resetViews();
    const defView = views?.find(view => view.isDefault);
    if (defView) {
      setView(defView.id);
    }
  }, [
    updateDefaultView,
    projectSizeCategory,
    industry,
    speciality,
    func,
    excludeItemsApplicableToAll,
    view,
    businessLine,
    resetViews
  ]);

  const removeDefaultView = useCallback(async () => {
    await deleteDefaultView();
    await resetViews();
  }, [deleteDefaultView, resetViews]);

  return (
    <DictionariesContext.Provider
      value={{
        units,

        globalFiltersFunction,
        hasFilters,
        clearFilters,
        clearView,
        clearCounter,

        view,
        onViewChange,
        optionsView,
        onFavouriteChange,

        func,
        setFunction,
        optionsFunction,

        businessLine,
        setBusinessLine,
        optionsBusinessLine,

        projectSizeCategory,
        setProjectSizeCategory,
        optionsProjectSizeCategory,

        industry,
        setIndustry,
        optionsIndustry,

        speciality,
        setSpeciality,
        optionsSpeciality,

        excludeItemsApplicableToAll,
        setExcludeItemsApplicableToAll,
        saveAsDefault,
        removeDefaultView,
        hasDefaultBeenChanged
      }}>
      {children}
    </DictionariesContext.Provider>
  );
};
