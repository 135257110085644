import {TooltipHost} from '@fluentui/react';
import {Activity} from '../../../models';
import {OpenColumn, OtherColumn, SelectColumn, ToggleColumn} from '../EnhancedDetailsList';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../EnhancedDetailsList/IEnhancedDetailsList';
import {OrderInput} from '../OrderInput';
import {CustomToggle} from './CustomToggle';
import {TheButton} from '../TheButton';
import {MultiselectColumn} from '../EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {ActivityVersion} from '../../../models/ActivityVersion';
import {ActivityWithProcess} from '../../../models/ActivityWithProcess';
import {LinksListColumn} from '../EnhancedDetailsList/ColumnTypes/LinksListColumn';
import {UnitDto} from '../../../services/generated';

const isSmart = typeof new URLSearchParams(document.location.search).get('smart') === 'string';

function columnSmartFilter<TYPE extends Activity>(column: IEnhancedColumn<TYPE> | undefined) {
  return !!column && (isSmart || ['ownerProcessId', 'id'].indexOf(column.key) === -1);
}

function mapToClass(item: {[key: string]: any}) {
  return new Activity(item as Activity);
}

const columnsViewList = [
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  'projectSizeCategories',
  ColumnKey.FunctionString,
  'specialities'
];
const columnsCenterList = [...columnsViewList, ColumnKey.Edit, ColumnKey.Delete];
const columnsEditList = [
  ColumnKey.Removed,
  ColumnKey.Active,
  ColumnKey.Order,
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  'projectSizeCategories',
  ColumnKey.FunctionString,
  'specialities',
  SpecialColumnKey.Edit
];
const columnsAddList = [
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  'projectSizeCategories',
  ColumnKey.FunctionString,
  'specialities'
];
const columnsAdminList = [
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  'projectSizeCategories',
  ColumnKey.FunctionString,
  'specialities',
  ColumnKey.Edit,
  ColumnKey.Delete
];

function getColumns<TYPE extends Activity>(
  changedData?: Activity[],
  ownerProcessId?: number,
  onEditClick?: (item: TYPE) => void,
  onDeleteClick?: (item: TYPE) => void,
  isOnProcess?: boolean,
  unitPortalOwner?: UnitDto[]
): IEnhancedColumn<TYPE>[] {
  return [
    {
      key: ColumnKey.Removed,
      name: '🗑️',
      onRender: (item: Activity) =>
        item.ownerProcessId === ownerProcessId ? (
          <CustomToggle<Activity>
            property={'removed'}
            changedItems={changedData}
            item={item}
            mapToClass={mapToClass}
          />
        ) : (
          <></>
        ),
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle activities to be removed'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Active,
      name: '👁',
      onRender: (item: Activity) => (
        <CustomToggle<Activity>
          property={'active'}
          changedItems={changedData}
          item={item}
          mapToClass={mapToClass}
        />
      ),
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle activities to be active (visible)'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Order,
      name: 'Order',
      isResizable: false,
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: Activity) =>
        changedData ? (
          <OrderInput
            defaultValue={item.order}
            onChange={value => {
              const changedDocument = changedData.filter(
                link => link.id === item.id && link.ownerProcessId === item.ownerProcessId
              )[0];
              if (changedDocument) {
                changedDocument.order = value;
              } else {
                changedData.push(new Activity({...item, order: value}));
              }
            }}
          />
        ) : (
          <></>
        ),
      data: OtherColumn
    },
    {
      key: ColumnKey.Url,
      name: '',
      data: OpenColumn
    },
    {
      key: ColumnKey.Title,
      name: 'Title',
      exportToExcel: true,
      minWidth: 290,
      flexGrow: 1
    },
    {
      key: ColumnKey.Id,
      name: 'Link ID',
      minWidth: 130
    },
    {
      key: ColumnKey.OwnerProcessId,
      name: 'Process ID',
      minWidth: 130
    },
    {
      key: SpecialColumnKey.Edit,
      name: '',
      onRender: (item: TYPE) => {
        let disabled = !!isOnProcess;
        if (isOnProcess && item instanceof ActivityVersion) {
          disabled =
            (item.createdByProcess?.id !== ownerProcessId &&
              !unitPortalOwner?.some(unit => unit.id === item.createdByProcess?.processUnitId)) ||
            false;
        }
        return (
          <TheButton
            iconProps={{
              iconName: 'Edit'
            }}
            onClick={() => (onEditClick ? onEditClick(item) : undefined)}
            primary
            disabled={disabled}>
            Edit
          </TheButton>
        );
      },
      data: OtherColumn,
      minWidth: 100
    },
    {
      key: SpecialColumnKey.Delete,
      name: '',
      onRender: (item: TYPE) => (
        <TheButton
          iconProps={{
            iconName: 'Delete'
          }}
          onClick={() => (onDeleteClick ? onDeleteClick(item) : undefined)}
          primary>
          Delete
        </TheButton>
      ),
      data: OtherColumn,
      minWidth: 100
    },
    {
      key: 'projectSizeCategories',
      name: 'Project Size Category',
      exportToExcel: true,
      data: MultiselectColumn,
      minWidth: 190
    },
    {
      key: ColumnKey.FunctionString,
      name: 'Function',
      exportToExcel: true,
      data: SelectColumn,
      minWidth: 105
    },
    {
      key: 'specialities',
      name: 'Specialty',
      exportToExcel: true,
      data: MultiselectColumn,
      minWidth: 110
    }
  ];
}

export function getColumnsLinksCenter(
  onEditClick: (item: Activity) => void,
  onDeleteClick: (item: Activity) => void
) {
  return columnsCenterList
    .map(columnKey =>
      getColumns(undefined, undefined, onEditClick, onDeleteClick).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<Activity>[];
}

export function getColumnsView(changed?: Activity[], ownerProcessId?: number) {
  return columnsViewList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as IEnhancedColumn<Activity>[];
}
export function getColumnsEdit(
  changed?: Activity[],
  ownerProcessId?: number,
  onEditClick?: (item: Activity) => void,
  unitPortalOwner?: UnitDto[]
) {
  return columnsEditList
    .map(columnKey =>
      getColumns(changed, ownerProcessId, onEditClick, undefined, true, unitPortalOwner).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<Activity>[];
}

export function getColumnsAdd(changed?: Activity[], ownerProcessId?: number) {
  return columnsAddList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as any as IEnhancedColumn<Activity>[];
}

export function getColumnsAdminAdd(changed?: Activity[], ownerProcessId?: number) {
  return columnsAddList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as any as IEnhancedColumn<ActivityWithProcess>[];
}

export function getColumnsActivitiesCenter(
  onEditClick: (item: ActivityWithProcess) => void,
  onDeleteClick: (item: ActivityWithProcess) => void
) {
  const columns = columnsAdminList
    .map(columnKey =>
      getColumns<ActivityWithProcess>(undefined, undefined, onEditClick, onDeleteClick).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<ActivityWithProcess>[];
  columns.splice(columns.length - 2, 0, {
    key: 'processes',
    name: 'Process(es)',
    exportToExcel: true,
    minWidth: 300,
    data: LinksListColumn
  });
  return columns;
}
