import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {DocumentInUnit, Unit} from '../../../models';
import {EnhancedDetailsList} from '../../common/EnhancedDetailsList';
import {Icon, Spinner, Toggle} from '@fluentui/react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {
  getColumnsAuditableView,
  getColumnsCenterView
} from '../../common/ProcessSections/documentAndFileColumns';
import {useUnit} from '../../../hooks/services/useUnit';
import {IDocumentsCenterProps} from './IDocumentsCenterProps';
import {useDocument} from '../../../hooks/services/useDocument';
import {ApiResponse} from '../../../services/ApiResponseType';
import {Helmet} from 'react-helmet';
import {DocumentsCenterModal} from './DocumentsCenterModal';
import {DocumentUpdateDto} from '../../../services/generated/models/DocumentUpdateDto';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {FormFooter} from '../../common/FormFooter';
import styles from './Documents.module.scss';

function mapChangedDocumentToDocument(changedItem: DocumentInUnit): DocumentInUnit {
  return changedItem;
}

export const DocumentsCenter: FC<IDocumentsCenterProps> = ({
  unitId,
  documentId,
  languageCode,
  showEditAuditable,
  showEditDetails,
  showHeader,
  isRelatedView,
  frozen
}) => {
  const {getAdminDocument} = useAdmin();
  const {getUnitCenterDocuments, getUnit, putUnitManageAuditable} = useUnit();
  const {getRelatedDocuments} = useDocument();

  const [editItem, setEditItem] = useState<DocumentInUnit | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [documents, setDocuments] = useState<DocumentInUnit[]>([]);
  const [helmetTitle, setHelmetTitle] = useState<string>();
  const [key, setKey] = useState<number>(0);
  const [selectedDocs, setSelectedDocs] = useState<DocumentInUnit[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [auditableState, setAuditableState] = useState<boolean>(false);

  const onRowCheck = useCallback(
    (document: DocumentInUnit, isChecked: boolean) => {
      if (isChecked) {
        const docs = [...new Set([...selectedDocs, document])];
        setSelectedDocs(docs);
      } else {
        const docs = selectedDocs.filter(doc => doc.id !== document.id);
        setSelectedDocs(docs);
      }
    },
    [selectedDocs]
  );

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      const correctTitle =
        unit.parentTitle && unit.unitTypeId === 2
          ? `${unit.parentTitle} ${unit.title}`
          : unit.title;
      return `IMS ${correctTitle} Documents Center`;
    }
    return 'IMS Documents Center';
  };

  const refresh = useCallback(async () => {
    setIsLoading(true);
    let response: ApiResponse<DocumentInUnit[]>;
    if (unitId) {
      response = await getUnitCenterDocuments(unitId);
    } else if (documentId && languageCode) {
      response = await getRelatedDocuments(documentId, languageCode);
    } else {
      response = await getAdminDocument();
    }
    if (response.result) {
      setDocuments(response.result);
    }
    setIsLoading(false);
  }, [unitId, documentId,languageCode, getRelatedDocuments, getUnitCenterDocuments, getAdminDocument]);

  const setTitle = useCallback(async () => {
    if (unitId) {
      const unit = await getUnit(unitId);
      if (unit.result) setHelmetTitle(prepareUnitTitle(unit.result));
    } else {
      setHelmetTitle('IMS Documents Center');
    }
  }, [getUnit, unitId]);

  useEffect(() => {
    refresh();
    setTitle();
  }, [refresh, setTitle]);

  const onClose = useCallback(() => {
    setEditItem(null);
    setIsModalOpen(false);
  }, []);

  const onEditDetailsClick = useCallback((item: DocumentInUnit) => {
    setEditItem(item);
    setIsModalOpen(true);
  }, []);

  const onUpdated = useCallback(
    (updatedItem: DocumentUpdateDto) => {
      const newDocRow = documents.find(item => item.id === updatedItem.id);
      if (newDocRow) {
        newDocRow.documentId = updatedItem.documentId || null;
        newDocRow.languageCode = updatedItem.languageCode || null;
        setKey(prev => prev + 1);
      }
      setEditItem(null);
      setIsModalOpen(false);
    },
    [documents]
  );

  const columns = useMemo(
    () => getColumnsCenterView(showEditDetails,isRelatedView, onEditDetailsClick, onRowCheck),
    [showEditDetails, onEditDetailsClick, onRowCheck,isRelatedView]
  );
  const columnsAuditable = useMemo(() => getColumnsAuditableView(), []);

  const onManageAuditable = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsModalVisible(false);
    setSelectedDocs([]);
    setKey(prev => prev + 1);
  }, []);

  const onConfirmAuditable = useMemo(
    () => async () => {
      if (!unitId) {
        setSelectedDocs([]);
        setKey(prev => prev + 1);
        setIsModalVisible(false);
        setAuditableState(false);
        return;
      }
      const docs = selectedDocs.map(item => item.id);
      const processes = selectedDocs.map(item => item.processes?.map(x => x.id));
      const processesArr = Array.prototype.concat.apply([], processes);
      const data = {
        documentIds: docs,
        processIds: processesArr,
        auditable: auditableState
      };
      const response = await putUnitManageAuditable(unitId, data);

      if (!response) return;
      const refreshData =
        documents?.map(item =>
          docs.includes(item.id) ? {...item, auditable: auditableState} : item
        ) || null;
      setDocuments(refreshData);
      setSelectedDocs([]);
      setKey(prev => prev + 1);
      setIsModalVisible(false);
      setAuditableState(false);
    },
    [auditableState, documents, putUnitManageAuditable, selectedDocs, unitId]
  );
  return (
    <>
      <>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        {showHeader && <HeadingWithDecoration text={'Documents Center'} />}
        {isLoading ? <Spinner /> : null}
        {isLoading !== undefined ? (
          <>
            <EnhancedDetailsList<DocumentInUnit, DocumentInUnit, DocumentInUnit>
              key={key}
              columns={columns}
              items={documents}
              manageAuditable={showEditAuditable}
              onManageAuditable={onManageAuditable}
              frozenClassName={showEditAuditable ? "frozenUnitFiles" : ""}
              excelExport
              excelFileName="Exported Documents List"
              frozen={frozen}
              mapChangedItemToItem={mapChangedDocumentToDocument}
            />
            {editItem && (
              <DocumentsCenterModal
                isVisible={isModalOpen}
                onClose={onClose}
                editItem={editItem}
                onUpdated={onUpdated}
              />
            )}
          </>
        ) : null}
      </>
      <ModalWithHeader
        header="Manage Auditable"
        isVisible={isModalVisible}
        dismiss={() => setIsModalVisible(false)}>
        <>
          {selectedDocs.length ? (
            <>
              <EnhancedDetailsList<DocumentInUnit, DocumentInUnit, DocumentInUnit>
                columns={columnsAuditable}
                items={selectedDocs}
                pageSize={10}
                hideSearch
                className={styles.manageAuditableModalContent}
              />
              <div className={styles.manageAuditableNote}>
                <Icon iconName="WarningSolid" />
                The change of attribute 'Auditable' will be reflected in the processes in the above
                listed documents
              </div>
              <div className={styles.manageAuditableDecision}>
                <Toggle
                  onText="On"
                  offText="Off"
                  className={styles.toggle}
                  label="Set Auditable state for all listed documents"
                  onChange={(ev, value) => setAuditableState(!!value)}
                  defaultChecked={auditableState}
                />
              </div>
            </>
          ) : (
            'Nothing to do here. First, select items from the list of documents.'
          )}

          <FormFooter onCancel={onCloseModal} isDisabled={selectedDocs.length === 0} onSubmit={onConfirmAuditable} saveLabel="Confirm" />
        </>
      </ModalWithHeader>
    </>
  );
};
