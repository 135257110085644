import styles from './TreeBuilderFull.module.scss';
import {FormFooter} from '../../FormFooter';
import {ITreeBuilderFullProps} from './ITreeBuilderFullProps';
import {ITreeItem} from '../ITreeItem';
import {ModalWithHeader} from '../../ModalWithHeader';
import {PropsWithChildren} from 'react';
import {TheButton} from '../../TheButton';
import {TreeBuilder} from '../TreeBuilder';
import {TreeBuilderBulkRow} from './TreeBuilderBulkRow';
import {useTreeBuilderFull} from './useTreeBuilderFull';

export const TreeBuilderFull = <ITEM extends ITreeItem>({
  children,
  columns,
  isLoading,
  itemsFlat,
  initialOpen,
  hideTitles,
  keepInView,
  exportToExcel,
  repeatModeTimes = 1,
  bulkHeader,
  editColumnPosition,
  commands,
  hideButtons,
  onCancel,
  onSubmit,
  getBulkModel,
  excelFileName,
  additionalButtons,
  filterDeleted,
  onlyVisibleItemsToExcel,
  addGroupingBy,
  turnOffEditNonProcess
}: PropsWithChildren<ITreeBuilderFullProps<ITEM>>) => {
  const {
    wrapperRef,
    isBulkOpen,
    onBulkOpen,
    onBulkClose,
    onExportExcel,
    bulkCounter,
    idsForEdit,
    columnsWithEdit,
    onConfirmForm,
    model,
    showErrors,
    setActiveProperty,
    openedItems,
    setOpenedItems,
    maxHeight,
    onMaxHeightCalc,
    exportMenuProps
  } = useTreeBuilderFull(
    itemsFlat,
    editColumnPosition,
    columns,
    getBulkModel,
    initialOpen,
    excelFileName,
    filterDeleted,
    hideButtons,
    onlyVisibleItemsToExcel,
    addGroupingBy,
    turnOffEditNonProcess
  );
  const internalButtons = (
    <>
      {!hideButtons ? (
        <TheButton disabled={isLoading || !idsForEdit.length} onClick={onBulkOpen} primary>
          Bulk edit
        </TheButton>
      ) : null}
      {exportToExcel ? (
        <TheButton
          disabled={isLoading}
          onClick={onExportExcel}
          primary
          split
          menuProps={!hideButtons ? exportMenuProps : undefined}>
          Export to Excel
        </TheButton>
      ) : null}
    </>
  );

  return (
    <>
      {!hideButtons && model ? (
        <ModalWithHeader dismiss={onBulkClose} isVisible={isBulkOpen} header={bulkHeader}>
          <div className={styles.wrapper}>
            <div>Field</div>
            <div>Should change</div>
            <div>Value</div>
            {columns.map(column =>
              column.type === 'bulk' ? (
                <TreeBuilderBulkRow
                  key={column.key}
                  column={column}
                  idsForEdit={idsForEdit}
                  setActiveProperty={setActiveProperty}
                  showErrors={showErrors}
                  model={model}
                />
              ) : null
            )}
          </div>
          <FormFooter onCancel={onBulkClose} onSubmit={onConfirmForm} saveLabel="Update fields" />
        </ModalWithHeader>
      ) : null}
      <div ref={wrapperRef}>
        <div className={styles.commands}>
          {commands ? commands(internalButtons) : internalButtons}
        </div>
        <TreeBuilder
          key={bulkCounter}
          wrapper={wrapperRef.current}
          columns={columnsWithEdit}
          hideTitles={hideTitles}
          initialOpen={openedItems.current}
          onOpenChange={setOpenedItems}
          isLoading={isLoading}
          itemsFlat={itemsFlat}
          keepInView={keepInView}
          repeatModeTimes={repeatModeTimes}
          maxHeight={maxHeight.current}
          onMaxHeightCalc={onMaxHeightCalc}>
          {children}
        </TreeBuilder>
        {!hideButtons ? (
          <FormFooter isDisabled={isLoading} onCancel={onCancel} onSubmit={onSubmit}>
            {additionalButtons}
          </FormFooter>
        ) : null}
      </div>
    </>
  );
};
