import {InputValidationError} from '../../Input/ValidationError';
import {ITreeColumnBulk} from '../TreeColumn';
import {ITreeItem} from '../ITreeItem';
import {Label, Toggle} from '@fluentui/react';
import {useState} from 'react';
import styles from './TreeBuilderFull.module.scss';

interface ITreeBuilderBulkRowProps<ITEM extends ITreeItem> {
  column: ITreeColumnBulk<ITEM>;
  idsForEdit: number[];
  model: ITEM;
  showErrors: boolean;
  setActiveProperty: (column: ITreeColumnBulk<ITEM>, isActive: boolean) => void;
}

export const TreeBuilderBulkRow = <ITEM extends ITreeItem>({
  column,
  idsForEdit,
  model,
  showErrors,
  setActiveProperty
}: ITreeBuilderBulkRowProps<ITEM>) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <Label required={column.required}>{column.title}</Label>
      <Toggle
        defaultChecked={false}
        onChange={() => {
          setIsActive(!isActive);
          setActiveProperty(column, !isActive);
        }}
      />
      <div className={styles.inputWrapper}>
        {column.onRenderBulk
          ? column.onRenderBulk(model, column, idsForEdit, !isActive)
          : column.onRenderColumn(model, column, !isActive)}
        {isActive && showErrors && column.required && !(model as any)[column.property] ? (
          <InputValidationError
            message={`This value could not be empty. Choose ${column.title}.`}
          />
        ) : null}
      </div>
    </>
  );
};
