import {UserDto} from '../services/generated';

export class CreatedModifiedModel {
  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;

  constructor(item: CreatedModifiedModel) {
    this.created = item.created;
    this.createdBy = item.createdBy;
    this.modified = item.modified;
    this.modifiedBy = item.modifiedBy;
  }
}
