import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Spinner,
  ValidationState
} from '@fluentui/react';
import {MgtPersonCard} from '@microsoft/mgt-react';

import styles from './PeoplePicker.module.scss';
import {useGroupPicker} from './useGroupPicker';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested Groups',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'Group Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts'
};

type IPeoplePicker = {
  defaultSelectedGroup?: IPersonaProps[];
  defaultSelectedGroupId?: string;
  defaultSelectedGroupIds?: string[];
  onSelectGroup?: (person: IPersonaProps[] | undefined, itemId: number) => void;
  onChangeValidationStatus?: (status: boolean) => void;
  isDisabled?: boolean;
  isReadonly?: boolean;
  itemId?: number;
  itemLimit?: number;
  className?: string;
  showRemoved?: boolean;
};

MgtPersonCard.config.sections.profile = false;
MgtPersonCard.config.useContactApis = false;
MgtPersonCard.config.sections.files = false;
MgtPersonCard.config.sections.organization = true;
MgtPersonCard.config.sections.mailMessages = false;

export const GroupsPicker = ({
  defaultSelectedGroup,
  defaultSelectedGroupId,
  defaultSelectedGroupIds,
  onSelectGroup,
  isDisabled = false,
  isReadonly = false,
  itemId = 0,
  itemLimit = 1,
  className
}: IPeoplePicker) => {
  const {defaultSelectedGroupResult, isLoading, selected, onFilterChanged, onChange} =
    useGroupPicker(
      onSelectGroup,
      itemId,
      defaultSelectedGroup,
      defaultSelectedGroupId,
      defaultSelectedGroupIds
    );

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > itemLimit) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isReadonly ? (
        defaultSelectedGroupResult.map(group => (
          <Persona
            key={group.id}
            text={group.text}
            secondaryText={group.secondaryText}
            size={PersonaSize.size24}
          />
        ))
      ) : (
        <>
          <NormalPeoplePicker
            key={'normal'}
            itemLimit={itemLimit}
            disabled={isDisabled || isReadonly}
            onResolveSuggestions={value => onFilterChanged(value)}
            onValidateInput={validateInput}
            getTextFromItem={group => group.text || ''}
            pickerSuggestionsProps={suggestionProps}
            pickerCalloutProps={{
              className: `${styles.peoplePicker}${className ? ` ${className}` : ''}`
            }}
            selectedItems={selected}
            className={`${styles.peoplePicker}${className ? ` ${className}` : ''}`}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};
