/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {ActivityWithProcessDto} from '../models/ActivityWithProcessDto';
import type {BrokenLinkDto} from '../models/BrokenLinkDto';
import type {DocumentUsedDto} from '../models/DocumentUsedDto';
import type {ParentUnitDto} from '../models/ParentUnitDto';
import type {SettingDto} from '../models/SettingDto';
import type {UnitDto} from '../models/UnitDto';
import type {UnitMinimalDto} from '../models/UnitMinimalDto';
import type {ViewDto} from '../models/ViewDto';
import type {ViewProcessCommentDto} from '../models/ViewProcessCommentDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {ExternalLinksWithProcessDto} from '../models/ExternalLinksWithProcessDto';
import { LibraryDto } from '../models/LibraryDto';

export class AdminService {
  /**
   * @param requestBody
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static postApiAdminUnit(requestBody?: UnitDto): CancelablePromise<UnitDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Admin/unit',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static getApiAdminUnit(): CancelablePromise<Array<UnitDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/unit'
    });
  }

  /**
   * @param requestBody
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static postApiAdminView(requestBody?: ViewDto): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Admin/view',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param viewId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiAdminView(viewId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Admin/view/{viewId}',
      path: {
        viewId: viewId
      }
    });
  }

  /**
   * @param viewId
   * @param requestBody
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static putApiAdminView(viewId: number, requestBody?: ViewDto): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Admin/view/{viewId}',
      path: {
        viewId: viewId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @returns ActivityWithProcessDto Success
   * @throws ApiError
   */
  public static getApiAdminActivityInput(): CancelablePromise<Array<ActivityWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/activityInput'
    });
  }

  /**
   * @returns ActivityWithProcessDto Success
   * @throws ApiError
   */
  public static getApiAdminActivityOutput(): CancelablePromise<Array<ActivityWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/activityOutput'
    });
  }

  /**
   * @returns ViewProcessCommentDto Success
   * @throws ApiError
   */
  public static getApiAdminComments(): CancelablePromise<Array<ViewProcessCommentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/comments'
    });
  }

  /**
   * @returns ParentUnitDto Success
   * @throws ApiError
   */
  public static getApiAdminUnitParents(): CancelablePromise<Array<ParentUnitDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/unit/parents'
    });
  }

  /**
   * @param unitId
   * @returns ParentUnitDto Success
   * @throws ApiError
   */
  public static getApiAdminUnitParents1(unitId: number): CancelablePromise<Array<ParentUnitDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/unit/parents/{unitId}',
      path: {
        unitId: unitId
      }
    });
  }

  /**
   * @returns DocumentUsedDto Success
   * @throws ApiError
   */
  public static getApiAdminDocument(): CancelablePromise<Array<DocumentUsedDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/document'
    });
  }

  /**
   * @returns ExternalLinksWithProcessDto Success
   * @throws ApiError
   */
  public static getApiAdminExternalLink(): CancelablePromise<Array<ExternalLinksWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/externalLink'
    });
  }

  /**
   * @returns boolean Success
   * @throws ApiError
   */
  public static getApiAdmin(): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin'
    });
  }

  /**
   * @returns SettingDto Success
   * @throws ApiError
   */
  public static getApiAdminSetting(): CancelablePromise<Array<SettingDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/setting'
    });
  }

     /**
   * @returns LibraryDto Success
   * @throws ApiError
   */
     public static getApiAdminLibrary(): CancelablePromise<Array<LibraryDto>> {
      return __request(OpenAPI, {
        method: 'GET',
        url: '/api/Admin/library'
      });
    }

  /**
   * @param requestBody
   * @returns SettingDto Success
   * @throws ApiError
   */
  public static putApiAdminSetting(
    requestBody?: Array<SettingDto>
  ): CancelablePromise<Array<SettingDto>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Admin/setting',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

    /**
   * @param requestBody
   * @returns LibraryDto Success
   * @throws ApiError
   */
    public static putApiAdminLibrary(
      requestBody?: Array<LibraryDto>
    ): CancelablePromise<Array<LibraryDto>> {
      return __request(OpenAPI, {
        method: 'PUT',
        url: '/api/Admin/library',
        body: requestBody,
        mediaType: 'application/json'
      });
    }

  /**
   * @returns UnitMinimalDto Success
   * @throws ApiError
   */
  public static getApiAdminUnitAssignment(): CancelablePromise<Array<UnitMinimalDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/unitAssignment'
    });
  }

  /**
   * @returns BrokenLinkDto Success
   * @throws ApiError
   */
  public static getApiAdminBrokenLinks(): CancelablePromise<Array<BrokenLinkDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/brokenLinks'
    });
  }

  /**
   * @param brokenLinkId
   * @returns any Success
   * @throws ApiError
   */
  public static putApiAdminBrokenLinks(brokenLinkId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Admin/brokenLinks/{brokenLinkId}',
      path: {
        brokenLinkId: brokenLinkId
      }
    });
  }

  /**
   *
   * @returns string
   * @throws ApiError
   */
  public static refreshToken(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Admin/refreshToken'
    });
  }
}
