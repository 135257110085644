import {FC} from 'react';
import {Description} from './Description';

interface IDescriptionsProps {
  descriptions: {
    ownerProcessId: number;
    content: string | null;
    unitTitle: string | null;
  }[];
  isOpen?: boolean;
}

export const Descriptions: FC<IDescriptionsProps> = ({descriptions, isOpen}) => {
  if (!descriptions.some(description => description.content)) return null;
  return (
    <>
      {descriptions.map(item => (
        <Description
          key={item.ownerProcessId}
          unitTitle={item.unitTitle}
          description={item}
          isOpen={isOpen}
        />
      ))}
    </>
  );
};
