import {Label, Spinner, TextField} from '@fluentui/react';
import {Activity} from '../../../models';
import {FC, useCallback, useContext, useState} from 'react';
import {TheButton} from '../TheButton';
import {useActivities} from '../../../hooks/services/useActivities';
import {SaveActivityDto, UpdateActivityDto} from '../../../services/generated';
import styles from './LinksListCreate.module.scss';
import {ApiResponse} from '../../../services/ApiResponseType';
import {DictionaryMultiselectDropdown} from '../DictionaryDropdown/DictionaryMultiselectDropdown';
import {DictionariesContext} from '../../../providers';
import {DictionaryDropdown} from '../DictionaryDropdown';
import {ActivityVersion} from '../../../models/ActivityVersion';

interface IActivitiesListCreateProps {
  onCreated: (addedLink?: Activity, modifiedLink?: Activity) => void;
  allowAdd?: boolean;
  editItem?: Activity;
  isInput: boolean;
  ownerProcessId: number | null;
}

export const ActivitiesListCreate: FC<IActivitiesListCreateProps> = ({
  onCreated,
  allowAdd,
  editItem,
  isInput,
  ownerProcessId
}) => {
  const {optionsFunction, optionsProjectSizeCategory, optionsSpeciality} =
    useContext(DictionariesContext);
  const {addActivityInput, addActivityOutput, updateActivityInput, updateActivityOutput} =
    useActivities();

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(editItem?.title || '');
  const [url, setUrl] = useState(editItem?.url || '');
  const [func, setFunc] = useState(editItem?.function);
  const [projectSizeCategories, setProjectSizeCategories] = useState(
    editItem?.projectSizeCategories
  );
  const [specialities, setSpecialities] = useState(editItem?.specialities);
  const isValid = !!title;
  const onSubmit = useCallback(
    async (isAdd?: boolean) => {
      if (!isValid) return;
      setIsLoading(true);
      const newActivity: SaveActivityDto = {
        id: 0,
        link: [],
        created: null,
        createdBy: null,
        deleted: false,
        modified: null,
        modifiedBy: null,
        version: 0,
        title,
        url,
        function: func || null,
        projectSizeCategories: projectSizeCategories || null,
        specialities: specialities || null,
        createdByProcessId: ownerProcessId || null
      };
      const updateActivity: UpdateActivityDto = {
        title,
        url,
        function: func || null,
        projectSizeCategories: projectSizeCategories || null,
        specialities: specialities || null
      };
      let response: ApiResponse<SaveActivityDto>;
      if (editItem) {
        response = await (isInput
          ? updateActivityInput(editItem.id, updateActivity)
          : updateActivityOutput(editItem.id, updateActivity));
      } else {
        response = await (isInput ? addActivityInput : addActivityOutput)(newActivity);
      }
      if (response.result) {
        onCreated(
          isAdd
            ? new ActivityVersion({
                ...response.result,
                active: true,
                ownerProcessId: 0,
                order: 0,
                createdByProcess: {
                  id: response.result.createdByProcessId || 0,
                  processUnitId: 0,
                  title: ""
                }
              })
            : undefined,
          !isAdd
            ? new ActivityVersion({
                ...response.result,
                active: true,
                ownerProcessId: 0,
                order: 0,
                createdByProcess: {
                  id: response.result.createdByProcessId || 0,
                  processUnitId: 0,
                  title: ""
                }
              })
            : undefined
        );
      }
      setIsLoading(false);
    },
    [
      addActivityInput,
      addActivityOutput,
      editItem,
      func,
      isInput,
      isValid,
      onCreated,
      ownerProcessId,
      projectSizeCategories,
      specialities,
      title,
      updateActivityInput,
      updateActivityOutput,
      url
    ]
  );
  return (
    <div>
      <TextField
        label="Title"
        defaultValue={editItem?.title || undefined}
        onChange={(e, value) => setTitle(value || '')}
        required
      />
      <TextField
        label="Url"
        defaultValue={editItem?.url || undefined}
        onChange={(e, value) => setUrl(value || '')}
      />
      <Label>Project Size Category</Label>
      {optionsProjectSizeCategory ? (
        <DictionaryMultiselectDropdown
          className={styles.input}
          options={optionsProjectSizeCategory}
          onChange={setProjectSizeCategories}
          defaultValues={projectSizeCategories}
        />
      ) : (
        <Spinner />
      )}
      <Label>Function</Label>
      {optionsFunction ? (
        <DictionaryDropdown
          className={styles.input}
          options={optionsFunction}
          onChange={setFunc}
          defaultValue={func}
        />
      ) : (
        <Spinner />
      )}
      <Label>Specialty</Label>
      {optionsSpeciality ? (
        <DictionaryMultiselectDropdown
          className={styles.input}
          options={optionsSpeciality}
          onChange={setSpecialities}
          defaultValues={specialities}
        />
      ) : (
        <Spinner />
      )}
      <div className={styles.buttons}>
        {allowAdd ? (
          <TheButton primary disabled={isLoading || !isValid} onClick={() => onSubmit(true)}>
            {editItem ? 'Update & Add' : ' Create & Add'}
          </TheButton>
        ) : null}
        <TheButton primary disabled={isLoading || !isValid} onClick={() => onSubmit()}>
          {editItem ? 'Update' : 'Create'}
        </TheButton>
      </div>
      {isLoading ? <Spinner /> : null}
    </div>
  );
};
