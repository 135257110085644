import {MessageBar} from '@fluentui/react';
import {FC, useContext} from 'react';
import {LoggerContext} from '../../../providers';
import {TheButton} from '../TheButton';
import styles from './LogsBar.module.scss';

export const LogsBar: FC = () => {
  const {logs, deleteLog} = useContext(LoggerContext);
  return logs.length ? (
    <div className={styles.wrapper}>
      {logs.map(log => (
        <MessageBar
          className={styles.bar}
          key={log.key}
          messageBarType={log.messageType}
          isMultiline={false}
          actions={
            <TheButton isIcon iconProps={{iconName: 'Cancel'}} onClick={() => deleteLog(log.key)} />
          }>
          {log.message}
        </MessageBar>
      ))}
    </div>
  ) : null;
};
