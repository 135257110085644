import {createContext} from 'react';
import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../services/generated';
import {Unit} from '../models';
import {IComboBox, IComboBoxOption} from '@fluentui/react';

export type IGlobalFiltersFunction = <
  ITEM extends {
    function: FunctionDto | null;
    businessLines?: BusinessLineDto[] | null;
    projectSizeCategories: ProjectSizeCategoryDto[] | null;
    industries?: IndustryDto[] | null;
    specialities: SpecialityDto[] | null;
  }
>(
  item: ITEM
) => boolean;

interface IDictionariesContext {
  units: Unit[];

  globalFiltersFunction: IGlobalFiltersFunction;
  hasFilters: boolean;
  clearFilters: () => void;
  clearView: () => void;
  clearCounter: number;

  view: number | undefined;
  onViewChange: (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined) => void;
  optionsView: IComboBoxOption[] | null;
  onFavouriteChange: () => void;

  func: FunctionDto[];
  setFunction: (value: FunctionDto[] | undefined) => void;
  optionsFunction: FunctionDto[] | null;

  businessLine: BusinessLineDto[] | null;
  setBusinessLine: (value: BusinessLineDto[] | undefined) => void;
  optionsBusinessLine: BusinessLineDto[] | null;

  projectSizeCategory: ProjectSizeCategoryDto[];
  setProjectSizeCategory: (value: ProjectSizeCategoryDto[] | undefined) => void;
  optionsProjectSizeCategory: ProjectSizeCategoryDto[] | null;

  industry: IndustryDto[];
  setIndustry: (value: IndustryDto[] | undefined) => void;
  optionsIndustry: IndustryDto[] | null;

  speciality: SpecialityDto[];
  setSpeciality: (value: SpecialityDto[] | undefined) => void;
  optionsSpeciality: SpecialityDto[] | null;

  excludeItemsApplicableToAll: boolean;
  setExcludeItemsApplicableToAll: (value: boolean) => void;

  saveAsDefault: () => void;
  removeDefaultView: () => void;
  hasDefaultBeenChanged: boolean;
}

export const DictionariesContext = createContext<IDictionariesContext>({
  units: [],

  globalFiltersFunction: () => true,
  hasFilters: false,
  clearFilters: () => {},
  clearView: () => {},
  clearCounter: 0,

  view: undefined,
  onViewChange: () => {},
  optionsView: null,
  onFavouriteChange: () => {},

  func: [],
  setFunction: () => {},
  optionsFunction: null,

  businessLine: null,
  setBusinessLine: () => {},
  optionsBusinessLine: null,

  projectSizeCategory: [],
  setProjectSizeCategory: () => {},
  optionsProjectSizeCategory: null,

  industry: [],
  setIndustry: () => {},
  optionsIndustry: null,

  speciality: [],
  setSpeciality: () => {},
  optionsSpeciality: null,

  excludeItemsApplicableToAll: false,
  setExcludeItemsApplicableToAll: () => {},

  saveAsDefault: () => {},
  removeDefaultView: () => {},
  hasDefaultBeenChanged: false
});
