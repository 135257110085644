import {CSSProperties, FC, useMemo} from 'react';

import {Process} from '../../../../models';
import {
  DescriptionDto,
  DescriptionVersionDto,
  UnitsDescriptionDto
} from '../../../../services/generated';
import {Accordion} from '../../../common/Accordion';
import styles from '../Process.module.scss';
import {Descriptions} from './Descriptions';

interface IProcessDescriptionsProps {
  process: {
    id: Process['id'];
    descriptions: DescriptionDto[] | DescriptionVersionDto[] | UnitsDescriptionDto[] | null;
    processActiveContents?: Process['processActiveContents'];
  };
  processUnitTitle: string;
  defaultClosed?: boolean;
  customStyles?: CSSProperties;
  skipCurrent?: boolean;
}

export const ProcessDescriptions: FC<IProcessDescriptionsProps> = ({
  process,
  processUnitTitle,
  defaultClosed,
  customStyles,
  skipCurrent
}) => {
  const unifiedDescriptions: {
    ownerProcessId: number;
    content: string | null;
    isCurrent?: boolean;
    unitTitle: string;
  }[] = useMemo(
    () =>
      (process.descriptions || [])
        .map(description => {
          const ownerProcessId = description.ownerProcessId;
          const content = description.content;
          const isCurrent = description.ownerProcessId === process.id;
          let unitTitle: string;
          const unitTitleFromProp = (description as DescriptionVersionDto | UnitsDescriptionDto)
            .unitTitle;
          if (unitTitleFromProp) {
            unitTitle = unitTitleFromProp;
          } else if (isCurrent && processUnitTitle) {
            unitTitle = processUnitTitle;
          } else {
            const processActiveContent = process.processActiveContents?.find(
              process => process.parentProcessId === description.ownerProcessId
            );
            unitTitle = processActiveContent?.unit || '';
          }
          return {
            ownerProcessId,
            content,
            isCurrent,
            unitTitle
          };
        })
        .filter(description => !skipCurrent || !description.isCurrent),
    [process, processUnitTitle, skipCurrent]
  );
  if (!unifiedDescriptions.some(description => description.content)) return null;
  return (
    <div className={styles.description} style={customStyles}>
      <Accordion isOpen={!defaultClosed} header={'Description'}>
        <Descriptions descriptions={unifiedDescriptions} isOpen={!defaultClosed} />
      </Accordion>
    </div>
  );
};
