import {useContext, useEffect, useState} from 'react';

import {useService} from '../../../hooks';
import {IUnitManage} from '../../../interfaces';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';

export const useUnitManagement = (unitId: number) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getUnitManage} = useService();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unit, setUnit] = useState<IUnitManage | null>(null);

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const unit = await getUnitManage(unitId);
        setUnit(unit);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  return {isLoading, unit};
};
