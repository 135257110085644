/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = ProcessOwner
 *
 * 2 = UnitOwner
 *
 * 3 = Admin
 *
 * 4 = SecondaryProcessOwner
 */
export enum Role {
  ProcessOwner = 1,
  UnitOwner = 2,
  Admin = 3,
  SecondaryProcessOwner = 4,
}
