import {IViewsPickerProps} from './IViewsPickerProps';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  ISelectableOption,
  Icon,
  Spinner
} from '@fluentui/react';
import {useContext, useMemo, FC, useCallback, FormEvent} from 'react';
import {DictionariesContext} from '../../../providers';
import {useParams} from 'react-router';
import {ViewModel} from '../../../models';
import stylesFile from './ViewsPicker.module.scss';
import {useViews} from '../../../hooks/services/useViews';
import {useLocation} from 'react-router-dom';
const styles: Partial<IComboBoxStyles> = {
  root: {
    fontSize: 'var(--abb-default-font-size)',
    border: '1px solid transparent',
    '::after': {
      border: 'none'
    },
    ':hover': {
      borderColor: 'black'
    }
  },
  optionsContainer: {
    button: {
      fontSize: 'var(--abb-default-font-size)'
    }
  }
};

export const ViewsPicker: FC<IViewsPickerProps> = ({className}) => {
  const {unitId: unitIdStr} = useParams();
  let unitId = Number(unitIdStr);
  const {search} = useLocation();
  const paramUnit = new URLSearchParams(search).get('unitId');
  if (!unitId && paramUnit) {
    unitId = Number(paramUnit);
  }
  const {view, onViewChange, optionsView, clearCounter, onFavouriteChange, removeDefaultView} =
    useContext(DictionariesContext);
  const {addToFavorite, deleteFavorite} = useViews();

  const options = useMemo(() => {
    if (optionsView) {
      const epmtyOption: IComboBoxOption[] = [{id: '-1', key: -1, text: ''}];
      let options: IComboBoxOption[];
      if (unitId) {
        options = optionsView.filter(
          view =>
            !(view.data as ViewModel)?.unitId ||
            (view.data as ViewModel)?.unitId === unitId
        );
      } else {
        options = optionsView.filter(view => !(view.data as ViewModel)?.unitId);
      }
      return epmtyOption.concat(options)
      
    } else return [];
  }, [optionsView, unitId]);

  const saveAsFavorite = useCallback(
    async (viewId: string | number) => {
      if (viewId) {
        const result = await addToFavorite(Number(viewId));
        if (result) {
          onFavouriteChange();
        }
      }
    },
    [addToFavorite, onFavouriteChange]
  );

  const removeFavorite = useCallback(
    async (viewId: string | number) => {
      if (viewId) {
        const result = await deleteFavorite(Number(viewId));
        if (result) {
          onFavouriteChange();
        }
      }
    },
    [deleteFavorite, onFavouriteChange]
  );

  const onRenderItem = useCallback(
    (props: ISelectableOption | undefined): JSX.Element => {
      return (
        <div className={stylesFile.optionsWrapper} key={props ? `star${props.key}` : 'star-1'}>
          {props && props.text ? (
            <>
              <div className={stylesFile.starIcons}>
                <Icon
                  iconName="FavoriteStar"
                  className={`${
                    !props.data.isFavorite ? stylesFile.activeStar : stylesFile.inactiveStar
                  }`}
                  onClick={props.data.isDefault ? undefined : () => saveAsFavorite(props.key)}
                />
                <Icon
                  iconName="FavoriteStarFill"
                  className={` ${
                    props.data.isFavorite ? stylesFile.activeStar : stylesFile.inactiveStar
                  }`}
                  onClick={props.data.isDefault ? undefined : () => removeFavorite(props.key)}
                />
              </div>
              <div
                onClick={e => onViewChange(e as unknown as FormEvent<IComboBox>, props)}
                className={stylesFile.option}>
                {props.text}
              </div>
              {props.data.isDefault ? (
                <div className={stylesFile.trash}>
                  <Icon iconName="Trash" onClick={removeDefaultView} />
                </div>
              ) : null}
            </>
          ) : (
            <div
              onClick={e =>
                onViewChange(e as unknown as FormEvent<IComboBox>, {id: '-1', key: -1, text: ''})
              }
              className={stylesFile.option}></div>
          )}
        </div>
      );
    },
    [onViewChange, removeDefaultView, removeFavorite, saveAsFavorite]
  );

  return !options ? (
    <Spinner />
  ) : (
    <>
      <ComboBox
        onRenderItem={item => onRenderItem(item)}
        key={clearCounter}
        className={className}
        allowFreeform={true}
        autoComplete={'on'}
        options={options}
        onChange={onViewChange}
        selectedKey={view}
        placeholder="View"
        styles={styles}
      />
    </>
  );
};
