import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {TheButton} from '../../common/TheButton';
import {BooleanColumn, EnhancedDetailsList, OtherColumn} from '../../common/EnhancedDetailsList';
import {Unit, ViewModel} from '../../../models';
import {Spinner} from '@fluentui/react';
import styles from './ViewsCenter.module.scss';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {Helmet} from 'react-helmet';
import {useViews} from '../../../hooks/services/useViews';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {MultiselectColumn} from '../../common/EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {ViewsCenterAddModal} from './ViewsCenterAddModal';
import {useUnit} from '../../../hooks/services/useUnit';

export const ViewsCenter: FC<{unitId?: number}> = ({unitId}) => {
  const {getViews, deleteView, deleteUnitView} = useViews();
  const {getUnit} = useUnit();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [views, setViews] = useState<ViewModel[]>([]);
  const [editItem, setEditItem] = useState<ViewModel>();
  const [helmetTitle, setHelmetTitle] = useState<string>('IMS Views Center');

  const [isAddModal, setIsAddModal] = useState(false);
  const onOpen = useCallback(() => setIsAddModal(true), []);
  const onClose = useCallback(() => {
    setEditItem(undefined);
    setIsAddModal(false);
  }, []);
  const onEditClick = useCallback(
    (item: ViewModel) => {
      setEditItem(item);
      onOpen();
    },
    [onOpen]
  );
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const onOpenDelete = useCallback(() => setIsDeleteModal(true), []);
  const onCloseDelete = useCallback(() => {
    setEditItem(undefined);
    setIsDeleteModal(false);
  }, []);
  const onDeleteClick = useCallback(
    (item: ViewModel) => {
      setEditItem(item);
      onOpenDelete();
    },
    [onOpenDelete]
  );

  const prepareHelmetTitle = (unit: Unit): string => {
    if (unit.title) {
      const unitTitle =
        unit.parentTitle && unit.unitTypeId === 2
          ? `${unit.parentTitle} ${unit.title}`
          : unit.title;

      return `IMS ${unitTitle} Views Center`;
    }
    return '';
  };


  const columnsView = useMemo(() => {
    const columns: IEnhancedColumn<ViewModel>[] = [
      {
        key: SpecialColumnKey.Edit,
        name: '',
        onRender: (item: ViewModel) => (
          <TheButton
            iconProps={{
              iconName: 'Edit'
            }}
            onClick={() => (onEditClick ? onEditClick(item) : undefined)}
            disabled={!!(unitId && item.isGlobal)}
            primary>
            Edit
          </TheButton>
        ),
        data: OtherColumn,
        minWidth: 100
      },
      {
        key: SpecialColumnKey.Delete,
        name: '',
        onRender: (item: ViewModel) => (
          <TheButton
            iconProps={{
              iconName: 'Delete'
            }}
            onClick={() => (onDeleteClick ? onDeleteClick(item) : undefined)}
            disabled={!!(unitId && item.isGlobal)}
            primary>
            Delete
          </TheButton>
        ),
        data: OtherColumn,
        minWidth: 100
      },
      {
        key: ColumnKey.Id,
        name: 'View ID',
        minWidth: 130
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 290,
        flexGrow: 1
      },
      {
        key: 'isGlobal',
        name: 'Is global',
        minWidth: 100,
        data: BooleanColumn
      },
      {
        key: 'projectSizeCategories',
        name: 'Project Size Category',
        data: MultiselectColumn,
        minWidth: 190
      },
      {
        key: 'businessLines',
        name: 'Business Line',
        data: MultiselectColumn,
        minWidth: 190
      },
      {
        key: 'functions',
        name: 'Function',
        data: MultiselectColumn,
        minWidth: 130
      },
      {key: 'industries', name: 'Industry', data: MultiselectColumn, minWidth: 130},
      {
        key: 'specialities',
        name: 'Specialty',
        data: MultiselectColumn,
        minWidth: 110
      },
      {
        key: 'excludeItemsApplicableToAll',
        name: 'Exclude items applicable to all',
        data: BooleanColumn,
        minWidth: 250
      }
    ];
    return columns;
  }, [unitId, onEditClick, onDeleteClick]);

  const refresh = useCallback(async () => {
    setIsLoading(true);
    const response = await getViews();
    if (response.result) {
      setViews(
        response.result.filter(view => !view.isDefault && (view.unitId === unitId || view.isGlobal))
      );
    }
    setIsLoading(false);
  }, [getViews, unitId]);

  const onCreated = useCallback(() => {
    onClose();
    refresh();
  }, [onClose, refresh]);

  const onDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    if (editItem) {
      const result = await (unitId ? deleteUnitView(unitId, editItem.id) : deleteView(editItem.id));
      if (!result.apiCode) {
        onCloseDelete();
      }
    }
    refresh();
  }, [unitId, editItem, deleteUnitView, deleteView, onCloseDelete, refresh]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (unitId) {
        const unit = await getUnit(unitId);
        if (unit && unit.result) {
          setHelmetTitle(prepareHelmetTitle(unit.result));
        }
      }
    })();
  }, [getUnit, unitId]);

  return (
    <>
      {helmetTitle && <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>}
      <HeadingWithDecoration text="Views Center" />
      <div className={styles.buttons}>
        <TheButton primary iconProps={{iconName: 'add'}} onClick={onOpen}>
          Create new
        </TheButton>
      </div>
      <ViewsCenterAddModal
        unitId={unitId}
        isVisible={isAddModal}
        onClose={onClose}
        onCreated={onCreated}
        editItem={editItem}
      />
      <ModalWithHeader header={'Delete view'} isVisible={isDeleteModal} dismiss={onCloseDelete}>
        <p>
          View <b>{editItem?.title}</b> will be permanently deleted. Are you sure?
        </p>
        <div className={styles.buttons}>
          <TheButton onClick={onCloseDelete} disabled={isLoading}>
            Cancel
          </TheButton>
          <TheButton
            primary
            iconProps={{iconName: 'delete'}}
            onClick={onDeleteConfirm}
            disabled={isLoading}>
            Confirm {isLoading ? <Spinner /> : null}
          </TheButton>
        </div>
      </ModalWithHeader>
      {isLoading ? <Spinner /> : null}
      {isLoading !== undefined ? (
        <EnhancedDetailsList<ViewModel, ViewModel, ViewModel>
          onCreateItemClick={onOpen}
          columns={columnsView}
          items={views}
        />
      ) : null}
    </>
  );
};
