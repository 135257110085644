import {
  Dropdown,
  IDropdownOption,
  Label,
  MessageBar,
  MessageBarType,
  Spinner,
  TextField,
  Toggle
} from '@fluentui/react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import {SettingDto} from '../../../services/generated';
import {FormFooter} from '../../common/FormFooter';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {Richtext} from '../../common/Richtext';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {TheButton} from '../../common/TheButton';
import styles from './GlobalSettings.module.scss';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {LibraryDto} from '../../../services/generated/models/LibraryDto';
import {RefreshDictonariesModal} from './RefreshDictonaries/RefreshDictonariesModal';
import {useDictionary} from '../../../hooks/services/useDictionary';
import {RefreshDictonariesDto} from '../../../services/generated/models/RefreshDictonariesDto';

export const GlobalSettings = () => {
  const {globalSettings, isLoading, setGlobalSettings} = useContext(GlobalSettingsContext);
  const navigate = useNavigate();
  const {refreshDictonaries} = useDictionary();
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState<boolean>(false);
  const {putGlobalSettings, putLibrariesSettings, refreshToken} = useAdmin();
  const [settings, setSettings] = useState<SettingDto[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [savingResult, setSavingResult] = useState<string>('');
  const [libraries, setLibraries] = useState<LibraryDto[]>([]);
  const [refrestDictonariesResult, setRefreshDictonariesResult] = useState<RefreshDictonariesDto>();
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const changeSetting = useCallback(
    (settingName: string, newValue: string | boolean | undefined) => {
      const currentSettings: SettingDto[] = settings.map(s => s);
      const setting = currentSettings.find(s => s.name === settingName);
      if (setting) {
        setting.value = newValue || typeof newValue == 'boolean' ? newValue.toString() : '';
        setSettings(currentSettings);
      }
    },
    [settings]
  );

  const resetSettings = useCallback(() => {
    if (globalSettings && globalSettings.settingsFlat && globalSettings.libraries)
      setSettings(
        globalSettings.settingsFlat.map(s => ({
          id: s.id,
          link: s.link,
          name: s.name,
          value: s.value
        }))
      );
    setLibraries(
      globalSettings.libraries.map(l => ({
        id: l.id,
        link: l.link,
        internalName: l.internalName,
        userFriendlyName: l.userFriendlyName,
        active: l.active
      }))
    );
    const librarySelectedKeys = globalSettings.libraries?.filter(x => x.active).map(lib => lib.id);
    setSelectedKeys(librarySelectedKeys);
  }, [globalSettings]);

  const onLibraryChange = useCallback(
    (
      event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
      index?: number | undefined
    ) => {
      if (option) {
        const selectedItems = option.selected
          ? [...selectedKeys, +option.key]
          : selectedKeys.filter(key => key !== option.key);
        setSelectedKeys(selectedItems);
      }
    },
    [selectedKeys]
  );

  const libraryOptions = useMemo<IDropdownOption[]>(
    () =>
      libraries?.map(option => ({
        key: option.id,
        text: option.userFriendlyName || '',
        data: option,
        title: option.userFriendlyName || ''
      })),
    [libraries]
  );

  const onFormCancel = useCallback(() => {
    resetSettings();
    navigate(`/admin`);
  }, [navigate, resetSettings]);

  const onRefreshToken = () => {
    setIsOpenModal(true);
  };

  const redirectToRefreshTokenPage = useCallback(async () => {
    const response = await refreshToken();
    if (response && response.result) window.location.replace(response.result);
  }, [refreshToken]);

  const clickRefreshDictonaries = useCallback(async () => {
    setIsSaving(true)
    const response = await refreshDictonaries();
    if (response && response.result) {
      setRefreshDictonariesResult(response.result);
      setIsRefreshModalOpen(true);
    }
    setIsSaving(false)
  }, [refreshDictonaries]);

  const onFormSave = useCallback(async () => {
    setIsSaving(true);
    const activeLibraries = libraries.filter(x => selectedKeys.includes(x.id));
    const settingsResult = await putGlobalSettings(settings);
    const updatedLibraries = await putLibrariesSettings(activeLibraries);
    const result = settingsResult.result;
    const libraryResult = updatedLibraries.result;
    if (result && libraryResult) {
      setGlobalSettings(result, false, libraryResult);
    }
    setSavingResult(result?.length ? 'success' : 'error');
    setIsSaving(false);
  }, [
    settings,
    selectedKeys,
    libraries,
    putLibrariesSettings,
    putGlobalSettings,
    setGlobalSettings
  ]);

  useEffect(() => {
    resetSettings();
  }, [globalSettings, resetSettings]);
  return (
    <>
      <Helmet>
        <title>IMS Global Settings</title>
      </Helmet>
      <HeadingWithDecoration text="Global application settings" />
      {isLoading || isSaving ? (
        <Spinner />
      ) : (
        <>
          {savingResult === 'success' && (
            <MessageBar messageBarType={MessageBarType.success}>Changes were saved.</MessageBar>
          )}
          {savingResult === 'error' && (
            <MessageBar messageBarType={MessageBarType.error}>
              Sorry, but the form was not saved. Check changes and try one more time.
            </MessageBar>
          )}
          <ModalWithHeader
            header="Information:"
            isVisible={isOpenModal}
            dismiss={() => setIsOpenModal(false)}>
            <>
              <div>
                You will be redirect to other page to obtain Access Token to PDC Search. Token is
                Valid for 90 days. Please click <strong>"Trust It"</strong> button on next page.
              </div>
              <div className={styles.buttons}>
                <TheButton
                  primary
                  iconProps={{iconName: 'navigateForward'}}
                  onClick={() => redirectToRefreshTokenPage()}>
                  Ok, Redirect
                </TheButton>
              </div>
            </>
          </ModalWithHeader>
          {settings &&
            settings.map(setting => {
              switch (setting.name) {
                case 'turnOffNotifications':
                  return (
                    <Toggle
                      key={setting.id}
                      label={'Turn off notifications'}
                      inlineLabel
                      checked={setting.value === 'true' ? true : false}
                      onChange={(ev, newValue) => {
                        changeSetting(setting.name ?? '', newValue);
                      }}
                    />
                  );
                case 'raiseToOpex':
                  return (
                    <Toggle
                      key={setting.id}
                      label={'Turn on raise to Opex feature'}
                      inlineLabel
                      checked={setting.value === 'true' ? true : false}
                      onChange={(ev, newValue) => {
                        changeSetting(setting.name ?? '', newValue);
                      }}
                    />
                  );
                case 'homePageTitle':
                  return (
                    <div key={setting.id}>
                      <Label>Title text page</Label>
                      <Richtext
                        defaultValue={setting.value || ''}
                        onChange={content => {
                          changeSetting(setting.name ?? '', content);
                        }}
                      />
                    </div>
                  );
                case 'imsReports':
                  return (
                    <div key={setting.id}>
                      <Label className={styles.label}>IMS Reports link</Label>
                      <TextField
                        className={styles.textField}
                        resizable={false}
                        value={setting.value || ''}
                        onChange={(e, value) => {
                          changeSetting(setting.name ?? '', value);
                        }}></TextField>
                    </div>
                  );
                  case 'contactUs':
                    return (
                      <div key={setting.id}>
                        <Label className={styles.label}>Contact Us  link</Label>
                        <TextField
                          className={styles.textField}
                          resizable={false}
                          value={setting.value || ''}
                          onChange={(e, value) => {
                            changeSetting(setting.name ?? '', value);
                          }}></TextField>
                      </div>
                    );
                case 'tokenExpirationDate':
                  return (
                    <div key={setting.id}>
                      <Label className={styles.label}>Search token expiration date</Label>
                      <div className={styles.container}>
                        <TextField
                          className={styles.textField}
                          readOnly={true}
                          resizable={false}
                          value={setting.value || ''}></TextField>
                        <TheButton primary onClick={onRefreshToken}>
                          {'Refresh Token'}
                        </TheButton>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          <Label className={styles.label}>Libraries</Label>
          <Dropdown
            className={styles.dropdown}
            onChange={onLibraryChange}
            options={libraryOptions}
            defaultSelectedKeys={selectedKeys}
            multiSelect
          />
          <div className={styles.label}>
            <TheButton primary onClick={clickRefreshDictonaries}>
              {'Refresh Dictionaries'}
            </TheButton>
          </div>
          <RefreshDictonariesModal
            isVisible={isRefreshModalOpen}
            refreshResult={refrestDictonariesResult}
            onClose={() => setIsRefreshModalOpen(false)}
          />
          <FormFooter onSubmit={onFormSave} onCancel={onFormCancel} />
        </>
      )}
    </>
  );
};
