/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessLineDto } from '../models/BusinessLineDto';
import type { DictionaryToManageDto } from '../models/DictionaryToManageDto';
import type { FunctionDto } from '../models/FunctionDto';
import type { IndustryDto } from '../models/IndustryDto';
import type { ProjectSizeCategoryDto } from '../models/ProjectSizeCategoryDto';
import type { SpecialityDto } from '../models/SpecialityDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { RefreshDictonariesDto } from '../models/RefreshDictonariesDto';

export class DictonaryService {

  /**
   * @returns FunctionDto Success
   * @throws ApiError
   */
  public static getApiDictonaryFunction(): CancelablePromise<Array<FunctionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dictonary/function',
    });
  }

  /**
   * @returns ProjectSizeCategoryDto Success
   * @throws ApiError
   */
  public static getApiDictonaryProjectSizeCategory(): CancelablePromise<Array<ProjectSizeCategoryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dictonary/projectSizeCategory',
    });
  }

  /**
   * @returns BusinessLineDto Success
   * @throws ApiError
   */
  public static getApiDictonaryBusinessLine(): CancelablePromise<Array<BusinessLineDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dictonary/businessLine',
    });
  }

  /**
   * @returns IndustryDto Success
   * @throws ApiError
   */
  public static getApiDictonaryIndustry(): CancelablePromise<Array<IndustryDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dictonary/industry',
    });
  }

  /**
   * @returns SpecialityDto Success
   * @throws ApiError
   */
  public static getApiDictonarySpeciality(): CancelablePromise<Array<SpecialityDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Dictonary/speciality',
    });
  }

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static refreshApiDictonary(

): CancelablePromise<RefreshDictonariesDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Dictonary/refresh',
    });
  }


}
