import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {GlobalSettingsContext} from './GlobalSettingsContext';
import {SettingDto} from '../services/generated';
import {useAdmin} from '../hooks/services/useAdmin';
import {useUnit} from '../hooks/services/useUnit';
import {useLocation} from 'react-router-dom';
import {LibraryDto} from '../services/generated/models/LibraryDto';

interface settingModel {
  [key: string]: string;
}
export interface GlobalSettings {
  settings: settingModel;
  settingsFlat: SettingDto[];
  unitTurnOffNotification: boolean;
  libraries: LibraryDto[];
}

export const GlobalSettingsProvider: FC<PropsWithChildren> = ({children}) => {
  const {getGlobalSettings, getAdminLibraries} = useAdmin();
  const {getUnit} = useUnit();
  const {pathname} = useLocation();

  const [globalSettingsModel, setGlobalSettingsModel] = useState<GlobalSettings>({
    settings: {},
    settingsFlat: [],
    unitTurnOffNotification: false,
    libraries: []
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const convertSettingsFlatToObject = useCallback((settingsFlat: SettingDto[]) => {
    const settings: settingModel = {};
    settingsFlat.forEach(setting => {
      if (setting.name && setting.value) settings[setting.name] = setting.value;
    });
    return settings;
  }, []);

  const setGlobalSettings = useCallback(
    (settingsFlat: SettingDto[], turnOffNotification: boolean, libraries: LibraryDto[]) => {
      const settings = convertSettingsFlatToObject(settingsFlat);
      setGlobalSettingsModel({
        settingsFlat: settingsFlat,
        settings,
        unitTurnOffNotification: turnOffNotification,
        libraries: libraries
      });
    },
    [convertSettingsFlatToObject]
  );

  const getTurnOffNotificationFromUnit = useCallback(async (): Promise<boolean> => {
    const regex = /\/unit\/(\d+)/;
    const match = pathname.match(regex);
    const valueFromUnit =
      match === null || match[1] === null
        ? undefined
        : (await getUnit(+match[1])).result?.turnOffNotifications;
    return valueFromUnit === undefined ? false : valueFromUnit;
  }, [getUnit, pathname]);

  const getSettingsAsync = useCallback(async () => {
    setIsLoading(true);
    const settingsFlat: SettingDto[] = (await getGlobalSettings()).result ?? [];
    const turnOffNotification: boolean = await getTurnOffNotificationFromUnit();
    const libraries: LibraryDto[] = (await getAdminLibraries()).result ?? [];
    setGlobalSettings(settingsFlat, turnOffNotification, libraries);
    setIsLoading(false);
  }, [getGlobalSettings, setGlobalSettings, getTurnOffNotificationFromUnit, getAdminLibraries]);

  useEffect(() => {
    getSettingsAsync();
  }, [getSettingsAsync]);

  return (
    <GlobalSettingsContext.Provider
      value={{globalSettings: globalSettingsModel, isLoading, setGlobalSettings}}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};
