/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {BulkEditDto} from '../models/BulkEditDto';
import type {ChangeDto} from '../models/ChangeDto';
import type {EditProcessVersionDto} from '../models/EditProcessVersionDto';
import type {ManageProcessDetailsDto} from '../models/ManageProcessDetailsDto';
import type {MinimalViewProcessDto} from '../models/MinimalViewProcessDto';
import type {ProcessDto} from '../models/ProcessDto';
import type {SaveExternalLinkDto} from '../models/SaveExternalLinkDto';
import type {SaveProcessDiagramDto} from '../models/SaveProcessDiagramDto';
import type {UpdateExternalLinkDto} from '../models/UpdateExternalLinkDto';
import type {ViewProcessDiagramDto} from '../models/ViewProcessDiagramDto';
import type {ViewProcessDto} from '../models/ViewProcessDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';

export class ProcessService {
  /**
   * @param id
   * @returns boolean Success
   * @throws ApiError
   */
  public static getApiProcessSubscription(id: number): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}/subscription',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static putApiProcessSubscription(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}/subscription',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiProcessSubscription(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Process/{id}/subscription',
      path: {
        id: id
      }
    });
  }

  /**
   * @param sendNotification
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiProcessBulkEdit(
    sendNotification: boolean = true,
    requestBody?: BulkEditDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/BulkEdit',
      query: {
        sendNotification: sendNotification
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @param sendNotification
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiProcessPublish(
    id: number,
    sendNotification: boolean = true,
    requestBody?: ProcessDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}/Publish',
      path: {
        id: id
      },
      query: {
        sendNotification: sendNotification
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns ViewProcessDto Success
   * @throws ApiError
   */
  public static getApiProcess(id: number): CancelablePromise<ViewProcessDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns ProcessDto Success
   * @throws ApiError
   */
  public static putApiProcess(id: number, requestBody?: ProcessDto): CancelablePromise<ProcessDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns ViewProcessDiagramDto Success
   * @throws ApiError
   */
  public static getApiProcessProcessDiagram(id: number): CancelablePromise<ViewProcessDiagramDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}/processDiagram',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns EditProcessVersionDto Success
   * @throws ApiError
   */
  public static getApiProcessDraft(id: number): CancelablePromise<EditProcessVersionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}/Draft',
      path: {
        id: id
      }
    });
  }

  /**
   * @returns ViewProcessDto Success
   * @throws ApiError
   */
  public static getApiProcess1(): CancelablePromise<Array<ViewProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process'
    });
  }

  /**
   * @param id
   * @returns ChangeDto Success
   * @throws ApiError
   */
  public static getApiProcessChanges(id: number): CancelablePromise<Array<ChangeDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}/Changes',
      path: {
        id: id
      }
    });
  }

  /**
   * @param unitId
   * @returns ManageProcessDetailsDto Success
   * @throws ApiError
   */
  public static getApiProcessProcessDraftFlat(
    unitId: number
  ): CancelablePromise<Array<ManageProcessDetailsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{unitId}/ProcessDraftFlat',
      path: {
        unitId: unitId
      }
    });
  }

  /**
   * @param id
   * @returns MinimalViewProcessDto Success
   * @throws ApiError
   */
  public static getApiProcessParents(id: number): CancelablePromise<Array<MinimalViewProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{id}/Parents',
      path: {
        id: id
      }
    });
  }

  /**
   * @param unitId
   * @param masterProcessId
   * @returns ViewProcessDto Success
   * @throws ApiError
   */
  public static getApiProcessUnit(
    unitId: number,
    masterProcessId: number
  ): CancelablePromise<ViewProcessDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/{masterProcessId}/unit/{unitId}',
      path: {
        unitId: unitId,
        masterProcessId: masterProcessId
      }
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static putApiProcessSubscribeBranch(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}/subscribeBranch',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiProcessSubscribeBranch(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Process/{id}/subscribeBranch',
      path: {
        id: id
      }
    });
  }

  /**
   * @param externalLinkid
   * @param id
   * @param requestBody
   * @returns SaveExternalLinkDto Success
   * @throws ApiError
   */
  public static putApiProcessExternalLink(
    externalLinkid: number,
    id: string,
    requestBody?: UpdateExternalLinkDto
  ): CancelablePromise<SaveExternalLinkDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}/externalLink/{externalLinkid}',
      path: {
        externalLinkid: externalLinkid,
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static getApiProcessProcessDiagramGetPdfFile(id: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/processDiagram/getPdfFile/{id}',
      path: {
        id: id
      }
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiProcessProcessDiagramGetPreviewPdfFile(
    requestBody?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Process/processDiagram/getPreviewPdfFile',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns ViewProcessDiagramDto Success
   * @throws ApiError
   */
  public static putApiProcessManageProcessDiagram(
    id: string,
    requestBody?: SaveProcessDiagramDto
  ): CancelablePromise<ViewProcessDiagramDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Process/{id}/manage/processDiagram',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }
}
