import {CreatedByProcessDto, ExternalLinksWithProcessDto, UserDto} from '../services/generated';
import {ExternalLink} from './ExternalLink';
import {ProcessUsedInLibraries} from './ProcessUsedInLibraries';

export class ExternalLinkWithProcess extends ExternalLink implements ExternalLinksWithProcessDto {
  public processes: ProcessUsedInLibraries[] | null;
  public createdByProcess: CreatedByProcessDto | null;
  public rawUrl: string | null;
  public createdByProcessOwner: UserDto | null;

  constructor(link: ExternalLinksWithProcessDto) {
    super(link);
    this.processes = link.processes?.map(process => new ProcessUsedInLibraries(process)) || null;
    this.createdByProcess = link.createdByProcess;
    this.rawUrl = link.rawUrl;
    this.createdByProcessOwner = link.createdByProcessOwner
  }
}
