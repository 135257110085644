/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RolePermissionDto } from '../models/RolePermissionDto';
import type { UnitDto } from '../models/UnitDto';
import type { UserModel } from '../models/UserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

  /**
   * @returns UserModel Success
   * @throws ApiError
   */
  public static readUser(): CancelablePromise<UserModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/User/current',
    });
  }

  /**
   * @param unitId 
   * @param processId 
   * @returns RolePermissionDto Success
   * @throws ApiError
   */
  public static readUserPermissions(
unitId?: number,
processId?: number,
): CancelablePromise<Array<RolePermissionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/User/permissions',
      query: {
        'unitId': unitId,
        'processId': processId,
      },
    });
  }

  /**
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static readUserIsPortalOwner(): CancelablePromise<Array<UnitDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/User/units',
    });
  }

}
