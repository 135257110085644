import {useState} from 'react';
import {TheButton} from '../TheButton';
import {BaseItem} from './IEnhancedDetailsList';
interface IEnhancedRemoveButtonProps<Item extends BaseItem> {
  item: Item;
  removedIds: number[];
  removeElement: (item: Item) => void;
  disabled?: boolean;
}
export const EnhancedRemoveButton = <Item,>({
  item,
  removedIds,
  disabled,
  removeElement
}: IEnhancedRemoveButtonProps<Item & BaseItem>) => {
  const [clicked, setClicked] = useState(false);
  const alreadyRemoved = clicked || removedIds.some(id => id === item.id);
  return (
    <TheButton
      primary
      onClick={() => {
        removeElement(item);
        setClicked(true);
      }}
      style={{minWidth: '90px'}}
      disabled={alreadyRemoved || disabled}>
      {alreadyRemoved ? 'Removed' : 'Remove'}
    </TheButton>
  );
};

