import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {
  BooleanColumn,
  DateTimeColumn,
  EnhancedDetailsList,
  OtherColumn,
  ProcessesColumn,
  SelectColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {Icon} from '@fluentui/react';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {BrokenLinks} from '../../../models/BrokenLinks';
import {Person, PersonViewType} from '@microsoft/mgt-react';
import {TheButton} from '../../common/TheButton';
import {Helmet} from "react-helmet";
import styles from './BrokenLinksCenter.module.scss';

export const BrokenLinksCenter: FC = () => {
  const {getBrokenLinks, putBrokenLink} = useAdmin();

  const [brokenLinks, setBrokenLinks] = useState<BrokenLinks[]>([]);

  const refresh = useCallback(async () => {
    const response = await getBrokenLinks();
    if (response.result) {
      setBrokenLinks(response.result);
    }
  }, [getBrokenLinks]);

  const retryAction = useCallback(
    async (id: number) => {
      if (!id) return;
      await putBrokenLink(id);
      refresh();
    },
    [putBrokenLink, refresh]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns = useMemo(() => {
    const cols: IEnhancedColumn<BrokenLinks>[] = [
      {
        key: ColumnKey.Url,
        name: 'Link',
        minWidth: 300,
        data: OtherColumn,
        onRender: (model: BrokenLinks) => {
          return model.url ? (
            <>
              <Icon
                title={'Click to copy the URL to clipboard'}
                className={styles.copyIcon}
                iconName="copy"
                onClick={() =>
                  navigator.clipboard
                    .writeText(model.url || '')
                    .then(() => {
                      console.log('copied');
                    })
                    .catch(err => {
                      console.error('failed to copy', err);
                    })
                }
              />
              <a
                title={model.url}
                href={model.url}
                className={styles.link}
                target="_blank"
                rel="noreferrer">
                {model.url}
              </a>
            </>
          ) : (
            <></>
          );
        }
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 290,
        flexGrow: 1,
        data: OtherColumn,
        onRender: (model: BrokenLinks) => {
          return model.title ? (
            <>
              <Icon
                title={'Click to copy the Title to clipboard'}
                className={styles.copyIcon}
                iconName="copy"
                onClick={() =>
                  navigator.clipboard
                    .writeText(model.title || '')
                    .then(() => {
                      console.log('copied');
                    })
                    .catch(err => {
                      console.error('failed to copy', err);
                    })
                }
              />
              <span title={model.title}>{model.title}</span>
            </>
          ) : (
            <></>
          );
        }
      },
      {
        key: ColumnKey.TypeString,
        name: 'Type',
        minWidth: 130,
        data: SelectColumn
      },
      {
        key: ColumnKey.Processes,
        name: 'Process',
        minWidth: 300,
        data: ProcessesColumn
      },
      {
        key: ColumnKey.ModifiedBy,
        name: 'Modified By',
        minWidth: 180,
        data: UserDtoColumn,
        onRender: (model: BrokenLinks) => {
          return model.modifiedBy?.azureId ? (
            <Person
              userId={model.modifiedBy?.azureId}
              view={PersonViewType.oneline}
              avatarSize={'small'}
              key={model.modified}
            />
          ) : (
            <></>
          );
        }
      },
      {
        key: ColumnKey.Modified,
        name: 'Modified',
        minWidth: 120,
        data: DateTimeColumn,
        onRender: (model: BrokenLinks) => {
          return <>{model.modified && model.modified.split('T')[0]}</>;
        }
      },
      {
        key: ColumnKey.Working,
        name: 'Working',
        minWidth: 100,
        data: BooleanColumn,
        defaultFilterValue: 'False'
      },
      {
        key: SpecialColumnKey.Edit,
        name: 'Action',
        minWidth: 250,
        data: OtherColumn,
        onRender: (model: BrokenLinks) => {
          const whatType = model.working;
          return (
            <>
              <TheButton
                primary
                iconProps={{
                  iconName: whatType ? 'NotExecuted' : 'PlaybackRate1x'
                }}
                onClick={() => retryAction(model.id)}>
                {whatType ? 'Mark as unworking' : 'Mark as working'}
              </TheButton>
            </>
          );
        }
      }
    ];
    return cols;
  }, [retryAction]);
  return (
    <>
      <Helmet>
        <title>IMS Broken Links Center</title>
      </Helmet>
      <HeadingWithDecoration text="Broken Links Center" />
      <EnhancedDetailsList<BrokenLinks, BrokenLinks, BrokenLinks>
        columns={columns}
        items={brokenLinks}
      />
    </>
  );
};
