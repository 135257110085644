import {ApiResponse} from '../../../services/ApiResponseType';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';
import {IProcessControl, IUnitManage} from '../../../interfaces';
import {ITag} from '@fluentui/react';
import {PositionEnum} from '../../../enums/ProcessEnum';
import {ProcessFlatStructure, UnitsList} from '../../../models';
import {UnitProcessNavigator} from '../../../models';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useService} from '../../../hooks';
import {ProcessEnum} from '../../../enums';

export const useProcessNavigator = (unitId: number) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getUnitManage, getUnitManageProcessNavigator, getProcessesPublishedForUnitFlat} =
    useService();
  const [unit, setUnit] = useState<IUnitManage | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unitTitle, setUnitTitle] = useState<string>();
  const [processNavigatorData, setProcessNavigatorData] = useState<UnitProcessNavigator[]>();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] =
    useState<ApiResponse<ProcessFlatStructure[]>>();

  const publishedProcesses = publishedProcessesForUnit?.result?.filter(x => x.deleted === false);

  const getUnit = async () => {
    const unit = await getUnitManage(unitId);
    return unit;
  };

  const getData = async () => {
    const currentUserData = await getUnitManageProcessNavigator(Number(unitId));
    return currentUserData;
  };

  const getUnitProcessList = async () => {
    const data = await getProcessesPublishedForUnitFlat(Number(unitId));
    return data;
  };

  const prepareUnitTitle = (unit: UnitsList): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const unit = await getUnit();
        if(unit.result)
        {
          setUnitTitle(prepareUnitTitle(unit.result))
        }
        const processes = await getData();
        const processList = await getUnitProcessList();

        setUnit(unit);
        setIsLoading(false);
        setProcessNavigatorData(processes?.result || []);
        setPublishedProcessesForUnit(processList);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  const getITagsFromIProcess = (data: UnitProcessNavigator[]): ITag[] => {
    return data.map(item =>
      item.processId
        ? {key: item.processId || '', name: item.processTitle || ''}
        : {key: item.customLink || '', name: item.linkTitle || ''}
    );
  };

  const getITagsFromPublishedProcess = useCallback((data: ProcessFlatStructure[]): ITag[] => {
    return data.map(item => ({key: item.id, name: item.title || ''}));
  },[]);

  const getResultUrl = useCallback(() => {
    return processNavigatorData?.find(item => item.group === ProcessEnum.Result)?.customLink;
  },[processNavigatorData]);

  const getDataFiltred = useCallback((type: string, position?: string) => {
    let result: ITag[] = [];
    if (!processNavigatorData) {
      return;
    }
    if (position) {
      const data = processNavigatorData.filter(
        item => item.group === type && item.position !== null && item.position === position
      );
      result = getITagsFromIProcess(data);
    } else {
      const data = processNavigatorData.filter(item => item.group === type);
      result = getITagsFromIProcess(data);
    }
    return result;
  },[processNavigatorData]);

  const createIProcess = useCallback((
    source: ITag[] | undefined,
    dest: IProcessControl[],
    groupId: number,
    position: PositionEnum | null = null
  ) => {
    source?.forEach((element: ITag) => {
      const processId = Number(element.key);
      const isProcess = !Number.isNaN(processId);
      const item: IProcessControl = {
        groupId: groupId,
        processId: isProcess ? processId : undefined,
        customLink: !isProcess ? (element.key as string) : undefined,
        linkTitle: !isProcess ? element.name : undefined,
        position: position
      };
      dest.push(item);
    });
  },[]);

  const createResultControl = useCallback((
    source: string | undefined,
    dest: IProcessControl[],
    groupId: number
  ) => {
    if (source?.length) {
      const item: IProcessControl = {
        groupId: groupId,
        customLink: source
      };
      dest.push(item);
    }
  },[]);

  return useMemo(
    () => ({
    isLoading,
    unit,
    unitTitle,
    publishedProcesses,
    createIProcess,
    createResultControl,
    getITagsFromPublishedProcess,
    getDataFiltred,
    getResultUrl
  }),
  [
    isLoading,
    unit,
    unitTitle,
    publishedProcesses,
    createIProcess,
    createResultControl,
    getITagsFromPublishedProcess,
    getDataFiltred,
    getResultUrl
  ]);
};
