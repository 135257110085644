import {Shimmer} from '@fluentui/react';
import {TheButton} from '../TheButton';
import styles from './Subscription.module.scss';
import {useSubscription} from './useSubscription';

type ISubscription = {
  processId: number;
};

export const Subscription = ({processId}: ISubscription) => {
  const {
    subscription,
    subscribe,
    unsubscribe,
    subscribeBranch,
    unsubscribeBranch,
    isLoading,
    apiCode
  } = useSubscription(processId);

  return (
    <>
      {isLoading ? (
        <Shimmer className={styles.shimmer} />
      ) : (
        !apiCode && (
          <TheButton
            primary
            iconProps={{
              iconName: subscription ? 'MailUndelivered' : 'MailReminder'
            }}
            onClick={subscription ? unsubscribe : subscribe}
            split
            menuProps={{
              items: [
                {
                  key: 'branch',
                  text: subscription ? 'Unsubscribe branch' : 'Subscribe branch',
                  iconProps: {
                    iconName: 'DOM'
                  },
                  onClick: subscription ? unsubscribeBranch : subscribeBranch
                }
              ]
            }}>
            {subscription ? 'Unsubscribe' : 'Subscribe'}
          </TheButton>
        )
      )}
    </>
  );
};
