/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = Normal
 *
 * 2 = Matrix
 *
 * 3 = Activity
 *
 * 4 = None
 *
 * 5 = Inherited
 *
 * 6 = PDF
 */
export enum DiagramType {
  Normal = 1,
  Matrix = 2,
  Activity = 3,
  None = 4,
  Inherited = 5,
  PDF = 6
}
