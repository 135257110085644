import {CanBeElevateStatusEnum, ElevateStatusEnum} from '../enums';
import {Links, ProcessCommentDto, UserCommentThumbStatus, UserDto} from '../services/generated';

export class ProcessComment implements ProcessCommentDto {
  public id: number;
  public link: Links[] | null;
  public created: string;
  public comment: string | null;
  public createdBy: UserDto | null;
  public canDeletedByMe: boolean | null;
  public thumbUpCount: number | null;
  public thumbDownCount: number | null;
  public userCommentThumbStatus: UserCommentThumbStatus | null;
  public replyTo: string | null;
  public replyToId: number | null;
  public resolved: boolean;
  public elevatedStatus: ElevateStatusEnum;
  public canBeElevateStatus: CanBeElevateStatusEnum;

  constructor(comment: ProcessCommentDto) {
    this.id = comment.id;
    this.link = comment.link;
    this.created = comment.created;
    this.comment = comment.comment;
    this.createdBy = comment.createdBy;
    this.canDeletedByMe = comment.canDeletedByMe;
    this.thumbUpCount = comment.thumbUpCount;
    this.thumbDownCount = comment.thumbDownCount;
    this.userCommentThumbStatus = comment.userCommentThumbStatus;
    this.replyTo = comment.replyTo;
    this.replyToId = comment.replyToId;
    this.resolved = comment.resolved;
    this.elevatedStatus = comment.elevatedStatus;
    this.canBeElevateStatus = comment.canBeElevateStatus;
  }
}
