/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {SearchResultDto} from '../models/SearchResultDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import { SearchParams } from '../models/SearchParams';

export class SearchService {
  /**
   * @param keyword
   * @returns SearchResultDto Success
   * @throws ApiError
   */
  public static getApiSearch(searchParams: SearchParams): CancelablePromise<Array<SearchResultDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Search/word',
      body: searchParams,
      mediaType: 'application/json'
    });
  }

}
