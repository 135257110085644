import {CancelablePromise, OpenAPI} from './generated';
import {request} from './requestWithBlob';

export class ProcessServicePDFDiagram {
  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static getApiProcessProcessDiagramGetPdfFile(id: number): CancelablePromise<Blob> {
    return request(OpenAPI, {
      method: 'GET',
      url: '/api/Process/processDiagram/getPdfFile/{id}',
      path: {
        id: id
      },
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiProcessProcessDiagramGetPreviewPdfFile(
    requestBody?: string
  ): CancelablePromise<Blob> {
    return request(OpenAPI, {
      method: 'POST',
      url: '/api/Process/processDiagram/getPreviewPdfFile',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
