import {createContext} from 'react';

import {AccountInfo} from '@azure/msal-common';

interface IUserContext {
  currentUserData: AccountInfo | null;
  isUserAdmin: boolean;
  isUserLoading: boolean;
  logout: () => void;
}

export const UserContext = createContext<IUserContext>({
  currentUserData: null,
  isUserAdmin: false,
  isUserLoading: true,
  logout: () => {}
});
