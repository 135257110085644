// import {IUnitNested} from '../interfaces';
import {ProcessTree} from '../models';

export const documentSort = (docA: {order?: number | null}, docB: {order?: number | null}) => {
  if (docA.order === null || docA.order === 0) return 1;
  if (docB.order === null || docB.order === 0) return -1;
  return (docA.order || 0) - (docB.order || 0);
};

// let flatStucture: IData[] = [];
export const flattenProcesses = (
  data: ProcessTree[],
  flatStucture: ProcessTree[] = []
): ProcessTree[] => {
  if (data === null || data === undefined) {
    return [];
  }
  data.forEach(process => {
    flatStucture.push(process);
    if (process.children && process.children.length) {
      flattenProcesses(process.children, flatStucture);
    }
  });
  return flatStucture;
};

export const symmetricDifference = (arr1: any[], arr2: any[]) => {
  return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
};

// export const buildNestedArrayOfObjects = (
//   items: IUnitNested[],
//   id: number | null = null
// ): IUnitNested[] => {
//   return items
//     .filter(item => item.parentId === id)
//     .map(item => ({
//       ...item,
//       children: buildNestedArrayOfObjects(items, item.id)
//     }));
// };

export const getUniqueListOfOjectsByKey = (arrayToCheck: any[], key: string) => {
  return [...new Map(arrayToCheck.map(item => [item[key], item])).values()];
};

export const sortByText = <T extends {text: string | null}>(a: T, b: T): number => {
  const textA = a.text || '';
  const textB = b.text || '';
  if (textA === textB) return 0;
  if (textA < textB) return -1;
  return 1;
};

export const sortByTitle = <T extends {title: string | null}>(a: T, b: T): number => {
  const titleA = a.title?.toLocaleLowerCase() || '';
  const titleB = b.title?.toLocaleLowerCase() || '';
  if (titleA === titleB) return 0;
  if (titleA < titleB) return -1;
  return 1;
};

export const sortByOrderPrefix = <T extends {orderPrefix: string}>(a: T, b: T): number => {
  const titleA = a.orderPrefix;
  const titleB = b.orderPrefix;
  if (titleA === titleB) return 0;
  if (titleA < titleB) return -1;
  return 1;
};

export const sortByFakePrefix = <T extends {fakePrefix: string}>(a: T, b: T): number => {
  const prefixA = a.fakePrefix;
  const prefixB = b.fakePrefix;
  if (prefixA === prefixB) return 0;
  if (prefixA < prefixB) return -1;
  return 1;
};
