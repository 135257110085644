import {FC, useCallback, useEffect, useMemo, useState} from 'react';

import {Unit} from '../../../models';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {TreeBuilder} from '../../common/TreeBuilder';
import {ITreeColumn} from '../../common/TreeBuilder/TreeColumn';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {TheButton} from '../../common/TheButton';
import {UnitAssignmentModal} from './UnitAssignmentModal';
import {useUnitAssignmentPage} from './useUnitAssignmentPage';
import {UnitAssignmentMasterProcessDto, UnitMinimalDto} from '../../../services/generated';
import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {UnitToMasterProcessAssign} from './UnitToMasterProcessAssign';
import {Spinner} from '@fluentui/react';
import styles from './UnitAssignmentPage.module.scss';

export const UnitAssignmentPage: FC = () => {
  const {getUnitsList} = useAdmin();
  const {isDataLoading, masterProcessData, adminUnitAssignData} = useUnitAssignmentPage();
  const {getApiMasterProcessUnitAssignment} = useMasterProcess();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unitsList, setUnitsList] = useState<Unit[]>([]);
  const [selected, setSelected] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [modifiedUnits, setModifiedUnits] = useState<UnitMinimalDto[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modalData, setModalData] = useState<UnitToMasterProcessAssign[]>([]);


  const refreshData = useCallback(async () => {
    setIsLoading(true);
    const unitsPromise = await getUnitsList();
    if (unitsPromise && unitsPromise.result) {
      const units = unitsPromise.result;
      setUnitsList(units);
    }
    setIsLoading(false);
  }, [getUnitsList]);
  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const columns = useMemo(() => {
    const cols: ITreeColumn<Unit>[] = [
      {
        key: 1,
        title: '',
        type: 'title',
        showCheckbox: true,
        getTitle: (model: Unit) => model.title || '',
        property: 'title'
      }
    ];
    return cols;
  }, []);

  const buildUnitProcessStructure = useCallback(
    (unitData: UnitAssignmentMasterProcessDto[]) => {
      const data = unitData.map(unit => {
        const process = masterProcessData.find(item => item.id === unit.id);
        return new UnitToMasterProcessAssign(unit, process);
      });
      setModalData(data);
    },
    [masterProcessData]
  );

  const getMasterProcessUnitData = useCallback(
    async (modified: Unit[]) => {
      const list = modified.map(item => item.id);
      const unitData = modified && (await getApiMasterProcessUnitAssignment(list));
      if (unitData.result && !unitData.apiCode) {
        buildUnitProcessStructure(unitData.result);
      }
      setLocalLoading(false);
    },
    [buildUnitProcessStructure, getApiMasterProcessUnitAssignment]
  );

  const onOpenModal = useCallback(async () => {
    const modified = unitsList.filter(item => item.isChecked);
    const modifiedTitles: UnitMinimalDto[] = modified.map(item =>
      adminUnitAssignData.find(admin => admin.id === item.id)
    ) as any as UnitMinimalDto[];
    setModifiedUnits(modifiedTitles);
    setIsEdit(true);
    setLocalLoading(true);
    await getMasterProcessUnitData(modified);
  }, [adminUnitAssignData, getMasterProcessUnitData, unitsList]);

 
  const toggleSelect = useCallback((changed?: boolean) => {
    const checkedValue = changed !== undefined ? changed : !selected;
    const modifiedUnits: Unit[] = unitsList.map(item => ({
      ...item,
      isChecked: checkedValue ,
    })) as any as Unit[];
    setUnitsList(modifiedUnits)
    setSelected(checkedValue)
  },[selected, unitsList])

  const onDismiss = useCallback(() => {
    toggleSelect(false)
    setIsEdit(false);
    setModifiedUnits([]);
  }, [toggleSelect]);




  return (
    <>
      {isDataLoading || isLoading ? (
        <Spinner />
      ) : (
        <>
        <div className={styles.buttons} >
          <TheButton
            primary
            onClick={() => {
              onOpenModal();
            }}>
            Assign to Unit
          </TheButton>
          <TheButton
            primary
            onClick={() => {
              toggleSelect();
            }}>
            {selected ? 'Unselect All' : 'Select All'}
          </TheButton>
          </div>
          <TreeBuilder
            columns={columns}
            itemsFlat={unitsList}
            isLoading={isLoading || isDataLoading}
            initialOpen={unitsList.map(item => item.id)}
          />
        </>
      )}
      <ModalWithHeader
        header={`Assign Unit to Master Process:`}
        isVisible={isEdit}
        dismiss={onDismiss}>
        {modifiedUnits.length ? (
          <UnitAssignmentModal
            data={modalData}
            isLoadingData={localLoading}
            closeModal={onDismiss}
            unitsToBuildColumns={modifiedUnits}
            refreshData={refreshData}
          />
        ) : (
          <p>Please, choose units first</p>
        )}
      </ModalWithHeader>
    </>
  );
};
