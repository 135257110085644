import {FC} from 'react';
import {useParams} from 'react-router';

import {MiddleTopBarUnitManage} from '../../../common/MiddleTopBarUnitManage';
import {ViewsCenter} from '../../ViewsCenter';

export const UnitManagementViewsCenter: FC = () => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);

  return (
    <main className={'noPaddingTop'}>
      <MiddleTopBarUnitManage notUseHelmet unitId={unitId} active={'viewsCenter'} />
      <div>
        <ViewsCenter unitId={unitId} />
      </div>
    </main>
  );
};
