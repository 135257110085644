import {actions, roles} from '../enums/Permissions';

const mappings = new Map();

mappings.set(actions.READ, [
  roles.READER,
  roles.EDITOR,
  roles.CREATOR,
  roles.EDITOR_CREATOR,
  roles.REMOVER,
  roles.EDITOR_REMOVER,
  roles.CREATOR_REMOVER,
  roles.GOD
]);
mappings.set(actions.UPDATE, [roles.EDITOR, roles.EDITOR_CREATOR, roles.EDITOR_REMOVER, roles.GOD]);
mappings.set(actions.CREATE, [
  roles.CREATOR,
  roles.EDITOR_CREATOR,
  roles.CREATOR_REMOVER,
  roles.GOD
]);
mappings.set(actions.DELETE, [
  roles.REMOVER,
  roles.EDITOR_REMOVER,
  roles.CREATOR_REMOVER,
  roles.GOD
]);

export const hasPermission = (what: {accessLevel: number}, action: actions): boolean => {
  if (!what?.accessLevel) {
    return false;
  }

  const level = what.accessLevel.toString();
  if (mappings.has(action)) {
    return mappings.get(action).includes(level);
  }

  return false;
};

export default hasPermission;
export {actions, roles};
