import {FC, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useUser} from '../../../hooks/services/useUser';
import {checkPermissions} from '../../../helpers/permissionsHtml';
import {PermissionToCheck, actions} from '../../../enums';
import {Spinner} from '@fluentui/react';
import {LoggerContext} from '../../../providers';

interface IPermissionsCheckProps extends PropsWithChildren {
  unitId: number;
  permission: PermissionToCheck;
  action: actions;
  exceptionMessage?: string;
}

export const PermissionsCheck: FC<IPermissionsCheckProps> = ({
  children,
  unitId,
  permission,
  action,
  exceptionMessage
}) => {
  const {setPageException} = useContext(LoggerContext);
  const {getUserPermissionsPage} = useUser();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      const response = await getUserPermissionsPage(unitId);
      if (response?.result) {
        const canStillAccess = checkPermissions(permission, action, response.result);
        if (!canStillAccess) setPageException(exceptionMessage ?? 'Not Enough Permissions');
      }
      setIsLoading(false);
    })();
  }, [action, exceptionMessage, getUserPermissionsPage, permission, setPageException, unitId]);
  return isLoading ? <Spinner /> : <>{children}</>;
};
