import { Link } from 'react-router-dom';

import styles from './AccessDenied.module.scss';

export const AccessDenied = () => {
  return (
    <main>
      <div className={styles.wrapper}>
        <div className={styles.accessDeniedNumbers}>
          <span className={styles.accessDeniedNumbersRed}>403</span>
        </div>
        <div className={styles.accessDeniedHeader}>We are Sorry...</div>
        <div className={styles.accessDeniedText}>
          The page you're trying to access has restrictred access.
        </div>
        <div className={styles.accessDeniedText}>
          Please refer to your system administrator.
        </div>
        <div className={styles.accessDeniedBtn}>
          <Link to="/">Take me home</Link>
        </div>
      </div>
    </main>
  );
};
