import {Spinner} from '@fluentui/react';
import {FC, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../../../../helpers/arrayMehods';
import {useService} from '../../../../../../hooks';
import {Document} from '../../../../../../models';
import {EnhancedDetailsList, OtherColumn} from '../../../../../common/EnhancedDetailsList';
import {SpecialColumnKey} from '../../../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {getColumnsAdd} from '../../../../../common/ProcessSections/documentAndFileColumns';
import {IProcessRowDocuments} from './IProcessRowDocuments';
import { EnhancedRemoveButton } from '../../../../../common/EnhancedDetailsList/EnchancedRemoveButton';

function mapRemovedDocumentToDocument(changedItem: Document): Document {
  return changedItem;
}

export const ProcessRowRemoveDocuments: FC<IProcessRowDocuments> = ({model, disabled}) => {
  const {getDocuments} = useService();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const documentsResponse = await getDocuments();
      if (!documentsResponse.result) return;
      setDocuments(documentsResponse.result.sort(sortByTitle));
      setIsLoading(false);
    })();
  }, [getDocuments]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [newItemsIds, setNewItemsIds] = useState<number[]>([]);
  const columnsAdd = useMemo(
    () => [
      {
        key: SpecialColumnKey.Remove,
        name: '',
        isResizable: false,
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: Document) => (
          <EnhancedRemoveButton
            item={item}
            removedIds={newItemsIds}
            removeElement={newItem => {
              const mappedItem = newItem;
              mappedItem.active = true;
              model.removedDocuments.push(newItem);
              setNewItemsIds([...newItemsIds, newItem.id]);
            }}
            disabled={disabled}
          />
        ),
        data: OtherColumn
      },
      ...getColumnsAdd(model.removedDocuments as any)
    ],
    [newItemsIds, setNewItemsIds, disabled, model]
  );
  return isLoading ? (
    <Spinner />
  ) : (
    <EnhancedDetailsList<Document, Document, Document>
      items={documents}
      allowAdd
      isAddModal
      columns={columnsAdd}
      changedItems={model.removedDocuments}
      mapChangedItemToItem={mapRemovedDocumentToDocument}
      newItemsIds={newItemsIds}
      pageSize={5}
    />
  );
};
