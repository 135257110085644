import {Toggle} from '@fluentui/react';
import {useState} from 'react';
import styles from './List.module.scss';

interface ICustomToggle<T> {
  changedItems: T[] | undefined;
  item: T;
  property: 'removed' | 'active' | 'auditable' | 'checkedAcknowledgementItem';
  mapToClass: (value: {[key: string]: any}) => T;
}

export const CustomToggle = <
  T extends {
    id: number;
    ownerProcessId: number;
    active?: boolean;
    removed?: boolean;
    auditable?: boolean;
    checkedAcknowledgementItem?: boolean;
  }
>({
  changedItems,
  item,
  property,
  mapToClass
}: ICustomToggle<T>) => {
  const [value, setValue] = useState<boolean | undefined>((item as {[key: string]: any})[property]);
  if (!changedItems) return <></>;
  const className = value ? styles[property] : '';
  return (
    <Toggle
      className={`${styles.toggle} ${className}`}
      defaultChecked={item[property]}
      onChange={(e, checked) => {
        setValue(checked);
        const changedDocument = changedItems.filter(
          document => document.id === item.id && document.ownerProcessId === item.ownerProcessId
        )[0];
        if (changedDocument) {
          changedDocument[property] = !!checked;
        } else {
          changedItems.push(mapToClass({...item, [property]: !!checked}));
        }
      }}
    />
  );
};
