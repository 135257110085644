import {createContext} from 'react';

import {AuthenticationResult} from '@azure/msal-common';

interface IAuthenticationContext {
  auth: AuthenticationResult | null;
  isLoading: boolean;
  acquireToken: (scopes?: string[]) => Promise<string | void>;
  logout: () => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  auth: null,
  isLoading: true,
  acquireToken: async () => {},
  logout: () => {}
});
