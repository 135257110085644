import {ProcessBase} from './ProcessBase';
import {ProcessType} from '../enums';

export class ProcessBaseType extends ProcessBase {
  public type: number;

  public typeString?: ProcessType;

  constructor(base: ProcessBaseType) {
    super(base);
    this.type = base.type;

    Object.defineProperty(this, 'typeString', {
      get() {
        switch (this.type) {
          case ProcessType.NonProcess:
            return 'Non-process';
          case ProcessType.Process:
            return 'Process';
          default:
            return '';
        }
      }
    });
  }
}
