// import {IColumn} from '@fluentui/react';

import {IColumn} from '@fluentui/react';
import {SplitButton} from '../../SplitButton';
import { ColumnType } from '../IEnhancedDetailsList';
import { EnhancedColumn } from './EnhancedColumn';

export class OpenPDCColumn extends EnhancedColumn {
  public static type = ColumnType.Open;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 112;
  public static maxWidth = 112;
  public static renderer(value: string, column: IColumn, item?: any): JSX.Element {
    const siteAbsoluteUrlRegex = value && value.match(/.+\/ims\/[^/]+\//);
    const siteAbsoluteUrl = siteAbsoluteUrlRegex ? siteAbsoluteUrlRegex[0] : undefined;
    const viewInPDC = {
        key: 'ViewinPDC',
        text: 'View in PDC',
        iconProps: {
          iconName: 'EntitlementPolicy'
        },
        href: `${siteAbsoluteUrl}Forms/${process.env.REACT_APP_PDC_SHAREPOINT_VIEW_URL}?FilterField1=ABB_Coll_DocumentId&FilterValue1=${item.documentId}&FilterField2=ABB_Coll_LanguageCode&FilterValue2=${item.languageCode}`,
        target: '_blank'
      }
    
    const editInPdc = {
      key: 'EditinPDC',
      text: 'Edit in PDC',
      iconProps: {
        iconName: 'PageEdit'
      },
      href: `${siteAbsoluteUrl}Forms/${process.env.REACT_APP_PDC_SHAREPOINT_VIEW_URL}?FilterField1=ABB_Coll_DocumentId&FilterValue1=${item.documentId}&FilterField2=ABB_Coll_LanguageCode&FilterValue2=${item.languageCode}&FilterField3=EditStatus&FilterValue3=In%20Progress`,
      target: '_blank'
    };
    const extraMenuProps =
      item.documentId && item.languageCode && item.contentType > 0 //External Document link // DynamicLink
        ? [viewInPDC]
        : item.documentId && item.languageCode && item.contentType === 0
        ? [viewInPDC, editInPdc]
        : undefined;
        return value ? (<SplitButton url={value} documentId={item.documentId} languageCode={item.languageCode} extraMenuProps={extraMenuProps} />)
        : <></>;
  }
}
