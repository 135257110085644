import {CreatedByProcessDto, EditExternalLinkVersionDto} from '../services/generated';
import {ExternalLink} from './ExternalLink';

export class ExternalLinkVersion extends ExternalLink implements EditExternalLinkVersionDto {
  public createdByProcess: CreatedByProcessDto | null;

  constructor(link: EditExternalLinkVersionDto) {
    super({...link, removed: false});
    this.createdByProcess = link.createdByProcess;
    this.createdByProcessOwner = link.createdByProcessOwner
  }
}
