import {FC, PropsWithChildren, useState} from 'react';
import {useProcess} from '../hooks/services/useProcess';

import {Process} from '../models';
import {ProcessContext} from './ProcessContext';

export const ProcessProvider: FC<PropsWithChildren> = ({children}) => {
  const [process, setProcess] = useState<Process | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getProcess: getProcessService} = useProcess();

  const getProcess = async (processId: number, refresh?: boolean) => {
    if (!refresh && processId === process?.id) return;
    setIsLoading(true);
    const newProcess = await getProcessService(processId);
    setProcess(newProcess.result);
    setIsLoading(false);
  };

  return (
    <ProcessContext.Provider value={{process, isLoading, setProcess, setIsLoading, getProcess}}>
      <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>{children}</div>
    </ProcessContext.Provider>
  );
};
