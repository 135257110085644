import { ITag } from '@fluentui/react';
import {useCallback, useMemo, useState} from 'react';

export const useDocumentAdditionalAttributes = (
  setValue: React.Dispatch<React.SetStateAction<ITag[] | undefined>>,
  defaultItems: ITag[] | undefined
) => {

  const [keyWord, setKeyWord] = useState('');
  const [removed, setRemoved] = useState<ITag[]>([]);
  const setNewSelectedTags = useCallback(
    (tags: ITag[]) => {
      setValue(tags);
    },
    [setValue]
  );
  const onRemoveItems = useCallback(
    (removedItem: ITag) => {
      if (defaultItems) {
        const removedElement: ITag | undefined = defaultItems.find(
          item => item.key === removedItem.key
        );
        const newList = defaultItems.filter(item => item.key !== removedItem.key);
        if (removedElement) setRemoved([...removed, removedElement]);
        setValue(newList);
      }
    },
    [defaultItems, removed, setValue]
  );

  const onChange = useCallback(
    (items?: ITag[] | undefined) => {
      if (defaultItems && defaultItems.length > (items?.length || 0)) {
        const removedItem = defaultItems.find(
          selected => !items?.some(item => item.key === selected.key)
        );
        if (removedItem) setRemoved([...removed, removedItem]);
      } else {
        setRemoved(
          removed.filter(removedItem => !items?.some(item => item.key === removedItem.key))
        );
      }
      setValue(items || []);
    },
    [defaultItems, removed, setValue]
  );

  const getTextFromItem = useCallback((item: ITag) => item.name, []);


  const onKeyWordChange = useCallback(
    (e: any, value: string | undefined) => setKeyWord(value || ''),
    []
  );

  const saveLink = useCallback(() => {
    if (keyWord === "") {
      return;
    }
    const newItem = {
      key: keyWord,
      name: keyWord
    };

    console.log('defaultItems', defaultItems);
    const newTagsData = [...(defaultItems || []), newItem];
    setValue(newTagsData);
    setNewSelectedTags(newTagsData);
    setKeyWord('');
  }, [keyWord, setKeyWord, defaultItems, setValue, setNewSelectedTags]);

  return useMemo(
    () => ({
      getTextFromItem,
      onChange,
      onKeyWordChange,
      saveLink,
      keyWord,
      removed,
      onRemoveItems,
      setNewSelectedTags,
    }),
    [
      getTextFromItem,
      onChange,
      onKeyWordChange,
      saveLink,
      keyWord,
      removed,
      onRemoveItems,
      setNewSelectedTags,
    ]
  );
};
