import styles from './PageNotFound.module.scss';
import {Link} from 'react-router-dom';

export const AnyNotFound = ({title = 'Oops! Page not found'}) => {
  return (
    <main>
      <div className={styles.wrapper}>
        <div className={styles.notFoundNumbers}>
          202 x 2 = <span className={styles.notFoundNumbersRed}>404</span>
        </div>
        <div className={styles.notFoundText}>{title}</div>
        <div className={styles.notFoundBtn}>
          <Link to="/">Take me home</Link>
        </div>
      </div>
    </main>
  );
};
