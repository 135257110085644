import {Links, SaveDocumentDto} from '../services/generated';

export class DocumentSave implements SaveDocumentDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public deleted: boolean;
  public removed: boolean;
  public version: number;
  public active: boolean;
  public auditable: boolean;
  public order: number;
  public ownerProcessId: number;

  constructor(document: SaveDocumentDto) {
    this.id = document.id;
    this.link = document.link;
    this.deleted = document.deleted;
    this.removed = document.removed;
    this.version = document.version;
    this.active = document.active;
    this.auditable = document.auditable;
    this.order = document.order;
    this.ownerProcessId = document.ownerProcessId;
  }
}
