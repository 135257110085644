import {ITreeColumnAdvancedEdit} from '../TreeColumn';
import {ITreeItem} from '../ITreeItem';
import {ITreeRowProps} from './ITreeRowProps';
import {useCallback, useMemo} from 'react';

export const useTreeInheritanceCheckboxStatus = <ITEM extends ITreeItem>(
  props: ITreeRowProps<ITEM>
) => {
  const {item, checkedIds, setCheckedIds, itemsFlat} = props;

  const updateCheckboxRecursive = useCallback(
    (isChecked: boolean, item: ITEM, checkedIdsTemp: number[]): number[] => {
      if (item.isChecked === isChecked) return checkedIdsTemp;
      item.isChecked = isChecked;
      let filtered = checkedIdsTemp.filter(id => id !== item.id);
      if (isChecked) {
        filtered.push(item.id);
        const parent = itemsFlat.find(itemFlat => itemFlat.id === item.parentId);
        if (parent) {
          return updateCheckboxRecursive(isChecked, parent, filtered);
        }
      } else {
        const children = itemsFlat.filter(itemFlat => itemFlat.parentId === item.id);
        for (const child of children) {
          filtered = updateCheckboxRecursive(isChecked, child, filtered);
        }
      }
      return filtered;
    },
    [itemsFlat]
  );
  const onCheckboxAdvancedClick = useCallback(
    (
      column: ITreeColumnAdvancedEdit<ITEM>,
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      checked?: boolean | undefined
    ) => {
      const checkedIdsTemp = [...checkedIds];
      const checkedIdsNew = updateCheckboxRecursive(!!checked, item, checkedIdsTemp);
      const changedItemIds = getSymmetricDifference(checkedIds, checkedIdsNew);
      const changedItems = itemsFlat.filter(x => changedItemIds.includes(x.id));
      setCheckedIds(checkedIdsNew);
      column.onChange(changedItems, checkedIdsNew);
    },
    [checkedIds, updateCheckboxRecursive, item, itemsFlat, setCheckedIds]
  );

  const getSymmetricDifference = <T>(array1: T[], array2: T[]): T[] => {
    return array1.filter(x => !array2.includes(x)).concat(array2.filter(x => !array1.includes(x)));
  };

  const updateEdit = useCallback(
    (itemsToChange: ITEM[], isEdit: boolean, column: ITreeColumnAdvancedEdit<ITEM>) => {
      for (const element of itemsToChange) {
        element.isChecked = isEdit;
      }
      const newEditIds = isEdit
        ? [...checkedIds, ...itemsToChange.map(itemFlat => itemFlat.id)]
        : checkedIds.filter(id => !itemsToChange.some(itemFlat => itemFlat.id === id));
      setCheckedIds(newEditIds);
      column.onChange(itemsToChange, newEditIds);
    },
    [checkedIds, setCheckedIds]
  );

  const directChildren = useCallback(
    (isEdit: boolean, column: ITreeColumnAdvancedEdit<ITEM>) => {
      const childItems = itemsFlat.filter(itemFlat => itemFlat.parentId === item.id);
      const allItems = [...childItems, item];
      updateEdit(allItems, isEdit, column);
    },
    [itemsFlat, item, updateEdit]
  );

  const allChildren = useCallback(
    (isEdit: boolean, column: ITreeColumnAdvancedEdit<ITEM>) => {
      let allItems = [item];
      let parentItems = [item];
      while (parentItems.length) {
        // eslint-disable-next-line no-loop-func
        parentItems = itemsFlat.filter(flatItem =>
          parentItems.some(parentItem => parentItem.id === flatItem.parentId)
        );
        allItems = [...allItems, ...parentItems];
      }
      updateEdit(allItems, isEdit, column);
    },
    [itemsFlat, item, updateEdit]
  );

  const directChildrenSelect = useCallback(
    (column: ITreeColumnAdvancedEdit<ITEM>) => directChildren(true, column),
    [directChildren]
  );
  const allChildrenSelect = useCallback(
    (column: ITreeColumnAdvancedEdit<ITEM>) => allChildren(true, column),
    [allChildren]
  );
  const allChildrenUnSelect = useCallback(
    (column: ITreeColumnAdvancedEdit<ITEM>) => allChildren(false, column),
    [allChildren]
  );
  const editAdvancedSubOptions = useCallback(
    (column: ITreeColumnAdvancedEdit<ITEM>) => ({
      items: [
        {
          key: 'children',
          text: 'Select with direct children',
          iconProps: {
            iconName: 'ChevronRight'
          },
          onClick: () => directChildrenSelect(column)
        },
        {
          key: 'childrenAll',
          text: 'Select with all children',
          iconProps: {
            iconName: 'DoubleChevronRight'
          },
          onClick: () => allChildrenSelect(column)
        },
        {
          key: 'unchildrenAll',
          text: 'Unselect with all children',
          iconProps: {
            iconName: 'DoubleChevronRight8'
          },
          onClick: () => allChildrenUnSelect(column)
        }
      ]
    }),
    [directChildrenSelect, allChildrenSelect, allChildrenUnSelect]
  );
  return useMemo(
    () => ({
      onCheckboxAdvancedClick,
      editAdvancedSubOptions
    }),
    [onCheckboxAdvancedClick, editAdvancedSubOptions]
  );
};
