import {DefaultFilters} from '../PreferencesForm/DefaultFilters';
import {ViewsPicker} from '../ViewsPicker';
import styles from './MiddleTopBarWrapper.module.scss';
import {FC, PropsWithChildren} from 'react';

export const MiddleTopBarWrapper: FC<
  PropsWithChildren<{farItems?: JSX.Element; li?: JSX.Element; hideFilters?: boolean}>
> = ({children, farItems, li, hideFilters}) => (
  <div className={styles.middleTopBarWrapper}>
    <div className={styles.middleTopBarMenu}>
      <div className={styles.leftWrapper}>
        {children}
        {li ? <ul className={styles.innerWrapper}>{li}</ul> : null}
        {!hideFilters ? <ViewsPicker className={styles.viewsPicker} /> : null}
      </div>
      {farItems}
    </div>
    {!hideFilters ? <DefaultFilters /> : null}
  </div>
);
