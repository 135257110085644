import {Links, UnitDto, UnitStatus} from '../services/generated';

export class Unit implements UnitDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public title: string | null;
  public parentId: number | null;
  public parentTitle: string | null;
  public processValidityInMonth: number;
  public processesCount: number;
  public unitTypeId: number;
  public pdcLink: string | null;
  public pdcOperatingUnit: string | null;
  public countryCode: string | null;
  public status: UnitStatus;
  public initialIsChecked: boolean;
  public isChecked: boolean;
  public initialUnitTypeId: number;
  public turnOffNotifications : boolean;

  public get keyString() {
    return this.id.toString();
  }

  public get isModified() {
    return (
      this.initialIsChecked !== this.isChecked ||
      (this.isChecked && this.unitTypeId !== this.initialUnitTypeId)
    );
  }

  constructor(unit: UnitDto) {
    this.id = unit.id;
    this.link = unit.link;
    this.title = unit.title;
    this.parentId = unit.parentId;
    this.parentTitle = unit.parentTitle;
    this.processValidityInMonth = unit.processValidityInMonth;
    this.unitTypeId = unit.unitTypeId;
    this.processesCount = unit.processesCount;
    this.pdcLink = unit.pdcLink;
    this.pdcOperatingUnit = unit.pdcOperatingUnit;
    this.countryCode = unit.countryCode;
    this.status = unit.status;
    this.initialIsChecked = false;
    this.isChecked = false;
    this.initialUnitTypeId = unit.unitTypeId;
    this.turnOffNotifications = unit.turnOffNotifications;
  }
}
