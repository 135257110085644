import { IDropdownOption } from "@fluentui/react";

export enum InquiryImpact {
  Low = 0,
  Medium = 1,
  High = 2,
  VeryHigh = 3
}

export const inquiryImpactOptions: IDropdownOption[] = [
  {key: 0, text: InquiryImpact[0]},
  {key: 1, text: InquiryImpact[1]},
  {key: 2, text: InquiryImpact[2]},
  {key: 3, text: "Very High"}
];