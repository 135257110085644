import styles from './ProcessTiles.module.scss';
import {ProcessType} from '../../../enums/ProcessEnum';
import {UnitProcessNavigator} from '../../../models';
import {NavLink} from 'react-router-dom';

export const ProcessTiles = (props: {
  title: string;
  data: UnitProcessNavigator[];
  unitId: number;
}) => {
  const {data, unitId} = props;
  return (
    <div className={styles.box}>
      {/* <h4 className={styles.header}>{title}</h4> */}
      <div className={styles.boxInner}>
        {data.map(item =>
          item.processType === ProcessType.Process ? (
            <NavLink
              to={`/unit/${unitId}/process/${item.processId}`}
              className={styles.tile}
              key={item.processTitle}>
              {item.processTitle}
            </NavLink>
          ) : item.customLink ? (
            <a
              href={item.customLink}
              className={styles.tile}
              key={item.customLink}
              target="_blank"
              rel="noreferrer">
              {item.linkTitle}
            </a>
          ) : (
            <div className={`${styles.tile} ${styles.nonProcess}`} key={item.processTitle}>
              {item.processTitle}
            </div>
          )
        )}
      </div>
    </div>
  );
};
