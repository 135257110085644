import {FC, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react';

import {useService} from '../hooks';
import {AuthenticationContext} from './AuthenticationContext';
import {UserContext} from './UserContext';
import {AccountInfo} from '@azure/msal-common';

export const UserProvider: FC<PropsWithChildren> = ({children}) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const [currentUserData, setUserData] = useState<AccountInfo | null>(null);
  const [isUserAdminState, setIsUserAdminState] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);

  const {getCurrentUser, getCurrentUserAdminDetails, logout} = useService();

  const getUserData = async () => {
    const currentUserData = await getCurrentUser();
    setUserData(currentUserData);
    const adminDetails = await getCurrentUserAdminDetails();
    setIsUserAdminState(Boolean(adminDetails.result));
    if (currentUserData) {
      setIsUserLoading(false);
    }
  };

  useEffect(() => {
    if (isUserLoading && !isAzureLoading) {
      getUserData();
    }
    /* eslint-disable*/
  }, [isUserLoading, isAzureLoading]);

  const isUserAdmin: boolean = useMemo<boolean>(() => {
    return isUserAdminState;
  }, [isUserAdminState]);

  return (
    <UserContext.Provider value={{currentUserData, isUserAdmin, isUserLoading, logout}}>
      {children}
    </UserContext.Provider>
  );
};
