import {useMemo} from 'react';

import {MasterProcess, MasterProcessManage} from '../../models';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {
  BatchElementDto,
  ManageMasterProcessDto,
  MasterProcessService,
  OwningUnitDto,
  UnitAssignmentMasterProcessDto
} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {Batch} from '../../models/Batch';

export const useMasterProcess = () => {
  const requestWrapper = useApiService();

  /** Returns a list of all master processes */
  const getMasterProcess = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<MasterProcess[]> => {
    return await requestWrapper(
      MasterProcessService.getApiMasterProcess(),
      result => result.map(item => new MasterProcess(item)),
      log
    );
  };

  /** Returns a list of master processes details*/
  const getApiMasterProcessUnitAssignment = async (
    masterProcessId: number[],
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UnitAssignmentMasterProcessDto[]> => {
    return await requestWrapper(
      MasterProcessService.postApiMasterProcessUnitAssignment(masterProcessId),
      result => result,
      log
    );
  };


  const getMasterProcessManage = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<MasterProcessManage[]> => {
    return await requestWrapper(
      MasterProcessService.getApiMasterProcessManage(),
      result => result.map(item => new MasterProcessManage(item)),
      log
    );
  };

  /** Creates a new master process */
  const createMasterProcess = async (
    masterProcess: ManageMasterProcessDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Master process created successfully`
    }
  ): ApiResponsePending<null> => {
    return await requestWrapper(
      MasterProcessService.putApiMasterProcessManage(undefined, [masterProcess]),
      result => null,
      log
    );
  };

  /** Updates master processes */
  const updateMasterProcess = async (
    masterProcess: ManageMasterProcessDto[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Master processes updated successfully`
    }
  ): ApiResponsePending<null> => {
    return await requestWrapper(
      MasterProcessService.putApiMasterProcessManage(undefined, masterProcess),
      result => null,
      log
    );
  };

  const createIndependentProcessInUnit = async (
    unitId: number,
    data: OwningUnitDto[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Units assigned successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      MasterProcessService.postApiMasterProcessCreateIndependentProcess(unitId, data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const setMasterProcessBatch = async (
    data: BatchElementDto[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Job is scheduled. Please, be patient. More details available in batches center.`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      MasterProcessService.postApiMasterProcessBatch(data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const getBatchList = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Batch[]> => {
    return await requestWrapper(
      MasterProcessService.getApiMasterProcessBatch(),
      result => result.map(item => new Batch(item)),
      log
    );
  };

  const setMasterProcessSingleBatch = async (
    batchId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Job is scheduled. Please, be patient.`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      MasterProcessService.putApiMasterProcessBatch(batchId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  return useMemo(
    () => ({
      getMasterProcess,
      createMasterProcess,
      updateMasterProcess,
      getMasterProcessManage,
      getApiMasterProcessUnitAssignment,
      createIndependentProcessInUnit,
      setMasterProcessBatch,
      getBatchList,
      setMasterProcessSingleBatch
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
