import {graphUrl} from '../../authConfig';
import {IPersonaProps, MessageBarType} from '@fluentui/react';
import {LoggerContext} from '../../providers';
import {useContext, useMemo} from 'react';
import {AdminService, UnitDto, UserService} from '../../services/generated';
import {RolePermission} from '../../models';
import {useApiService} from '../../services/helpers';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {LogMessageType} from '../../services/LogMessagesType';
import {AuthenticationContext} from '../../providers/AuthenticationContext';
import {AccountInfo} from '@azure/msal-common';
import {IGraphUser} from '../../interfaces';
import {MainStoreContext} from '../../providers/MainStoreContext';

export const useUser = () => {
  const requestWrapper = useApiService();
  const {addLog} = useContext(LoggerContext);
  const {auth, acquireToken, logout} = useContext(AuthenticationContext);
  const {users, rolePermissions} = useContext(MainStoreContext);

  const scopes = useMemo(
    () => [
      'openid',
      'profile',
      'User.Read',
      'User.Read.All',
      'User.ReadBasic.All',
      'offline_access'
    ],
    []
  );

  /** get current user details */
  const getCurrentUser = async (): Promise<AccountInfo | null> => {
    return auth?.account || null;
  };

  /** get current user details if user is admin */
  const getCurrentUserAdminDetails = async (
    log: LogMessageType = {
      error: false
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(AdminService.getApiAdmin(), (result, apiCode) => !apiCode, log);
  };

  const getUserPermissionsPage = async (
    unitId: number,
    processId?: number,
    force?: boolean,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<RolePermission[]> => {
    const cachedRole = force
      ? null
      : rolePermissions.find(role => role.unitId === unitId && role.processId === processId);
    if (cachedRole) {
      return cachedRole.rolePermissions;
    }
    const response = await requestWrapper(
      UserService.readUserPermissions(unitId, processId),
      result => result.map(item => new RolePermission(item)),
      log
    );
    if (!response.apiCode) {
      const existing = rolePermissions.find(
        permission => unitId === permission.unitId && processId === permission.processId
      );
      if (existing) {
        existing.rolePermissions = response;
      } else {
        rolePermissions.push({
          unitId,
          processId,
          rolePermissions: response
        });
      }
    }
    return response;
  };

  const getUserIsPortalOwner = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UnitDto[]> => {
    return requestWrapper(UserService.readUserIsPortalOwner(), result => result, log);
  };

  const getUserById = async (userId: string): Promise<IGraphUser | undefined> => {
    const userToken = await acquireToken(scopes);
    try {
      if (!users.cacheById[userId]) {
        users.cacheById[userId] = fetch(`${graphUrl}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`
          },
          method: 'GET'
        }).then(response => response.json());
      }
      return users.cacheById[userId];
    } catch (error) {
      addLog(MessageBarType.error, undefined, error);
      return undefined;
    }
  };

  const getAllUsersList = async (): Promise<IPersonaProps[]> => {
    const userToken = await acquireToken(scopes);
    try {
      const response = await fetch(`${graphUrl}/users?$top=100`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        method: 'GET'
      });
      const resp = await response.json();
      return resp;
    } catch (error) {
      addLog(MessageBarType.error, undefined, error);
      return [];
    }
  };

  const getFilteredUsersList = async (filter?: string): Promise<IPersonaProps[]> => {
    const userToken = await acquireToken(scopes);
    if (!filter || filter.length < 2) {
      return [];
    }

    try {
      const response = await fetch(
        `${graphUrl}/users?$filter=userType ne 'Guest'
        AND endsWith(mail, 'abb.com')
        AND accountEnabled eq true&$search="displayName:${filter}" OR "mail:${filter}"`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            ConsistencyLevel: 'eventual'
          },
          method: 'GET'
        }
      );
      if (response.ok) {
        let result = await response.json();

        const people: IPersonaProps[] = result.value.map(
          (item: {id: number; displayName: string; mail: string; userPrincipalName: string}) => {
            return {
              id: item.id,
              text: item.displayName,
              secondaryText: item.mail,
              key: item.id,
              userPrincipalName: item.userPrincipalName
            };
          }
        );
        return people;
      } else {
        return [];
      }
    } catch (error) {
      addLog(MessageBarType.error, undefined, error);
      return [];
    }
  };

  return useMemo(
    () => ({
      acquireToken,
      getCurrentUser,
      getCurrentUserAdminDetails,
      getUserPermissionsPage,
      getUserById,
      getAllUsersList,
      getFilteredUsersList,
      getUserIsPortalOwner,
      logout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
