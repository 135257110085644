import styles from './Diagram.module.scss';
import {ActivityDiagram} from './ActivityDiagram';
import {DiagramType} from '../../../services/generated';
import {Dropdown, MessageBar, MessageBarType, Shimmer} from '@fluentui/react';
import {FC} from 'react';
import {IDiagramProps} from './IDiagram';
import {MatrixDiagram} from './MatrixDiagram';
import {ModalWithHeader} from '../ModalWithHeader';
import {NormalDiagram} from './NormalDiagram';
import {TheButton} from '../TheButton';
import {useDiagram} from './useDiagram';
import {PDFDiagram} from './PDFDiagram';

export const Diagram: FC<IDiagramProps> = ({
  processId,
  processTitle,
  unitId,
  canEdit,
  returnType
}) => {
  const {
    type,
    options,
    onDropdownChange,
    isLoading,
    isProcessesLoading,
    processesOptions,
    processesOptionsFiltered,
    isEdit,
    setIsEdit,
    saveDiagram,
    normalValues,
    setNormalValuesWithEffect,
    matrixValues,
    setMatrixValuesWithEffect,
    customValue,
    setCustomValueWithEffect,
    diagramId,
    height,
    setHeight,
    page,
    setPage,
    toolbar,
    setToolbar,
    view,
    setView,
    zoom,
    setZoom,
    imageUrlInitial,
    matrixValuesInitial,
    normalValuesInitial,
    typeInitial,
    onDismiss,
    inherited
  } = useDiagram(processId, unitId, returnType);

  if (isLoading)
    return (
      <>
        <Shimmer />
        <Shimmer width="75%" />
        <Shimmer width="50%" />
      </>
    );
  return (
    <>
      <div>
        {typeInitial === DiagramType.Normal ? (
          <NormalDiagram
            isEdit={false}
            isProcessesLoading={isProcessesLoading}
            processTitle={processTitle}
            unitId={unitId}
            processesOptions={processesOptions}
            processesOptionsFiltered={processesOptionsFiltered}
            normalValues={normalValuesInitial}
            setNormalValues={setNormalValuesWithEffect}
          />
        ) : null}
        {typeInitial === DiagramType.Matrix ? (
          <MatrixDiagram
            isEdit={false}
            isProcessesLoading={isProcessesLoading}
            processTitle={processTitle}
            unitId={unitId}
            processesOptions={processesOptions}
            processesOptionsFiltered={processesOptionsFiltered}
            matrixValues={matrixValuesInitial}
            setMatrixValues={setMatrixValuesWithEffect}
          />
        ) : null}
        {typeInitial === DiagramType.Activity ? (
          <ActivityDiagram
            isEdit={false}
            imageUrl={imageUrlInitial}
            setImageUrl={setCustomValueWithEffect}
          />
        ) : null}
        {typeInitial === DiagramType.PDF ? (
          <PDFDiagram
            isEdit={false}
            diagramId={diagramId}
            imageUrl={imageUrlInitial}
            setImageUrl={setCustomValueWithEffect}
            height={height}
            setHeight={setHeight}
            page={page}
            setPage={setPage}
            toolbar={toolbar}
            setToolbar={setToolbar}
            view={view}
            setView={setView}
            zoom={zoom}
            setZoom={setZoom}
          />
        ) : null}
        {canEdit ? (
          <TheButton className={styles.button} primary onClick={() => setIsEdit(true)}>
            Edit diagram
          </TheButton>
        ) : null}
      </div>
      <ModalWithHeader header="Edit diagram" isVisible={isEdit} dismiss={onDismiss}>
        <div>
          <Dropdown
            label="Select type"
            className={styles.typeDropdown}
            selectedKey={inherited ? DiagramType.Inherited : type}
            onChange={onDropdownChange}
            options={options}
          />
          {inherited && type !== DiagramType.None && type !== DiagramType.Inherited ? (
            <MessageBar messageBarType={MessageBarType.warning} className={styles.warning}>
              Changing values below will break inheritance
            </MessageBar>
          ) : null}
          {type === DiagramType.Normal ? (
            <NormalDiagram
              isEdit={true}
              isProcessesLoading={isProcessesLoading}
              processTitle={processTitle}
              unitId={unitId}
              processesOptions={processesOptions}
              processesOptionsFiltered={processesOptionsFiltered}
              normalValues={normalValues}
              setNormalValues={setNormalValuesWithEffect}
            />
          ) : null}
          {type === DiagramType.Matrix ? (
            <MatrixDiagram
              isEdit={true}
              isProcessesLoading={isProcessesLoading}
              processTitle={processTitle}
              unitId={unitId}
              processesOptions={processesOptions}
              processesOptionsFiltered={processesOptionsFiltered}
              matrixValues={matrixValues}
              setMatrixValues={setMatrixValuesWithEffect}
            />
          ) : null}
          {type === DiagramType.Activity ? (
            <ActivityDiagram
              isEdit={true}
              imageUrl={customValue}
              setImageUrl={setCustomValueWithEffect}
            />
          ) : null}
          {type === DiagramType.PDF ? (
            <PDFDiagram
              isEdit={true}
              imageUrl={customValue}
              setImageUrl={setCustomValueWithEffect}
              height={height}
              setHeight={setHeight}
              page={page}
              setPage={setPage}
              toolbar={toolbar}
              setToolbar={setToolbar}
              view={view}
              setView={setView}
              zoom={zoom}
              setZoom={setZoom}
            />
          ) : null}
          {isEdit ? (
            <TheButton className={styles.button} primary onClick={saveDiagram}>
              Save
            </TheButton>
          ) : null}
        </div>
      </ModalWithHeader>
    </>
  );
};
