import {MasterProcessManage} from '../../../models';
import {UnitAssignmentMasterProcessDto, UnitMinimalDto} from '../../../services/generated';
import {IAssignMasterProcess} from './ITypes';

export class UnitToMasterProcessAssign {
  public id: number;
  public processTitle: string | null;
  public title: string | null;
  public prefix: string | null | undefined;
  public units: UnitMinimalDto[] | null;
  public parentId: number | null;
  public isChecked: boolean;
  public unitsDetails:
    | {
        id: number;
        masterProcessId: number;
        parentId: number | null;
        isChecked: boolean;
        disabled: boolean;
        isInitialChecked: boolean;
      }[];

  public get rowValue(): IAssignMasterProcess {
    return {
      id: this.id,
      parentId: this.parentId,
      title: this.title,
      units: this.units,
      isChecked: this.isChecked
    };
  }

  constructor(
    processUnitAssignment: UnitAssignmentMasterProcessDto,
    process?: MasterProcessManage
  ) {
    if (process && process.id !== processUnitAssignment.id) {
      throw new Error(
        `Cannot merge unit objects because ids doesn't match ${processUnitAssignment.id} and ${process.id})`
      );
    }
    this.id = process?.id || 0;
    this.processTitle = process?.title || '';
    this.parentId = process?.parentId || null;
    this.title = process?.title || '';
    this.prefix = process?.prefix;
    this.units = processUnitAssignment.units;
    this.unitsDetails =
      processUnitAssignment.units?.map(item => ({
        masterProcessId: process?.id || 0,
        id: item.id,
        parentId: item.parentId,
        isChecked: item.implementProcess,
        isInitialChecked: item.implementProcess,
        disabled: false
      })) ?? [];
    this.isChecked = !!processUnitAssignment.units?.some(item => item.implementProcess === true);
  }

  public get prefixedTitle(): string {
    return `${this.prefix ? `${this.prefix} ` : ''}${this.title}`;
  }

  public getUnit(unitId: number | undefined) {
    const unitDataForProcess = this.unitsDetails.find(item => item.id === unitId);
    if (!unitDataForProcess) {
      throw new Error(`Unit data with id=${unitId} for process with id=${this.id} not found.`);
    }
    return unitDataForProcess;
  }
}
