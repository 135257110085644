/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = ExternalLink
 *
 * 2 = Document
 *
 * 3 = ProcessDescription
 */
export enum BrokenLinkType {
  ExternalLink = 1,
  Document = 2,
  ProcessDescription = 3,
}
