import {
  ExternalLinkType,
  FunctionDto,
  ProjectSizeCategoryDto,
  SearchResultDto,
  IndustryDto,
  SpecialityDto,
  UserDto,
  BusinessLineDto,
  ProcessDtoUsedInLibraries,
  SearchType
} from '../services/generated';
import {ProcessUsedInLibraries} from './ProcessUsedInLibraries';

export class SearchResult implements SearchResultDto {
  public title: string | null;
  public type: SearchType;
  public itemId: number;
  public unitId: number | null;
  public unitTitle: string | null;
  public unit: string | undefined;
  public url: string | null;
  public auditable: boolean;
  public documentOwner: string | null;
  public content: string | null;
  public library: string | null;
  public external: boolean;
  public function: FunctionDto | null;
  public language: string | null;
  public processes: ProcessDtoUsedInLibraries[] | null;
  public get languageCode() {
    return this.language;
  }
  public operatingUnit: string | null;
  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public relativeUrl: string | null;
  public revision: string | null;
  public relevantToId: number | null;
  public relevantTo: string | null;
  public securityLevel: string | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public documentId: string | null;
  public documentKind: string | null;
  public approved: string | null;
  public externalLinkType: ExternalLinkType;
  public isExternal: boolean | null;
  public documentInReview: boolean;
  public businessLines: BusinessLineDto[] | null;
  public keyWords: string[] | null;
  public get id() {
    return this.itemId + this.type * 1000000000;
  }

  public get preparedUrl() {
    return !(this.type === SearchType.Process)
      ? this.url || ''
      : `/unit/${this.unitId}/process/${this.itemId}`;
  }

  public get typeString() {
    switch (this.type) {
      case SearchType.Process:
        return 'Process';
      case SearchType.Document:
        return 'Document';
      case SearchType.ExternalLink:
        return 'External Link';
      case SearchType.PDC:
        return 'PDC';
    }
    return '';
  }

  public get externalLinkTypeString() {
    switch (this.externalLinkType) {
      case ExternalLinkType.IMSProcess:
        return 'IMS Process';
      case ExternalLinkType.Site:
        return 'Site';
      case ExternalLinkType.SoftwareApplication:
        return 'Software Application';
      case ExternalLinkType.Examples:
        return 'Examples';
      case ExternalLinkType.ELearning:
        return 'e-Learning';
      default:
        return '';
    }
  }

  public functionString: string = '';

  constructor(searchResult: SearchResultDto, unit?: string) {
    this.title = searchResult.title;
    this.type = searchResult.type;
    this.itemId = searchResult.itemId;
    this.unitId = searchResult.unitId;
    this.unitTitle = searchResult.unitTitle;
    this.unit = unit;
    this.url = searchResult.url;
    this.auditable = searchResult.auditable;
    this.documentOwner = searchResult.documentOwner;
    this.content = searchResult.content;
    this.library = searchResult.library;
    this.external = searchResult.external;
    this.function = searchResult.function;
    this.language = searchResult.language;
    this.operatingUnit = searchResult.operatingUnit;
    this.processOwner = searchResult.processOwner;
    this.secondaryProcessOwner = searchResult.secondaryProcessOwner;
    this.projectSizeCategories = searchResult.projectSizeCategories;
    this.relativeUrl = searchResult.relativeUrl;
    this.securityLevel = searchResult.securityLevel;
    this.industries = searchResult.industries;
    this.specialities = searchResult.specialities;
    this.documentId = searchResult.documentId;
    this.documentKind = searchResult.documentKind;
    this.revision = searchResult.revision;
    this.relevantToId = searchResult.relevantToId;
    this.relevantTo = searchResult.relevantTo;
    this.approved = searchResult.approved;
    this.externalLinkType = searchResult.externalLinkType;
    this.isExternal = searchResult.external;
    this.documentInReview = searchResult.documentInReview;
    this.businessLines = searchResult.businessLines;
    this.keyWords = searchResult.keyWords;
    this.processes =
      searchResult.processes?.map(process => new ProcessUsedInLibraries(process)) || null;
    Object.defineProperty(this, 'functionString', {
      get() {
        return searchResult.function?.value || '';
      }
    });
  }
}
