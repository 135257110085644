import {NavLink} from 'react-router-dom';

import {MiddleTopBar} from '../MiddleTopBar/MiddleTopBar';
import {Helmet} from "react-helmet";
import styles from '../MiddleTopBar/MiddleTopBar.module.scss';
import { useUnit } from '../../../hooks/services/useUnit';
import { useEffect, useState } from 'react';
import { Unit } from '../../../models';

type IMiddleTopBarUnitManage = {
  unitId: number | undefined;
  active: string;
  notUseHelmet?: boolean
};

const middleTopBarMenuData: {
  name: string;
  where: string;
}[] = [
  {
    name: 'Dashboard',
    where: ''
  },
  {
    name: 'Process Assignment',
    where: 'processes'
  },
  {
    name: 'Processes details',
    where: 'processesDetails'
  },
  {
    name: 'Navigator control',
    where: 'navigatorControl'
  },
  {
    name: 'Unit Settings',
    where: 'unitSettings'
  },
  {
    name: 'Views Center',
    where: 'viewsCenter'
  },
  {
    name: 'Documents Center',
    where: 'documentsCenter'
  },
  {
    name: 'Feedback Center',
    where: 'feedbackCenter'
  },
  {
    name: 'Subscriptions',
    where: 'subscriptions'
  }
];

export const MiddleTopBarUnitManage = ({unitId, active,notUseHelmet}: IMiddleTopBarUnitManage) => {
  const staticPartOfRoute = `/unit/${unitId}/manage`;


  const {getUnit} = useUnit()
  const [unitTitle, setUnitTitle] = useState<string>();

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
       const response = await getUnit(Number(unitId))
       if(response && response.result)
    {
      setUnitTitle(prepareUnitTitle(response.result))
    }
    })();
  },[getUnit,unitId])


  return (
    <MiddleTopBar unitId={unitId} hideFilters={true}>
      <>
        {!notUseHelmet && unitTitle && (
          <Helmet>
            <title>IMS {unitTitle} Dashboard</title>
          </Helmet>
        )}
        {middleTopBarMenuData.map(item => (
          <li key={item.name}>
            <NavLink className={styles.link} to={`${staticPartOfRoute}/${item.where}`}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </>
    </MiddleTopBar>
  );
};
