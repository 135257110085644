import {
  DocumentDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  UserDto,
  BusinessLineDto
} from '../services/generated';
import {DocumentBase} from './DocumentBase';

export class Document extends DocumentBase implements DocumentDto {
  public ownerProcessId: number;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public relativeUrl: string | null;
  public industries: IndustryDto[] | null;

  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public isBroken: boolean = false;
  public businessLines: Array<BusinessLineDto> | null;
  public removed: boolean = false;
  public keyWords: string[] | null;

  constructor(document: DocumentDto) {
    super(document);
    this.ownerProcessId = document.ownerProcessId;
    this.projectSizeCategories = document.projectSizeCategories;
    this.relativeUrl = document.relativeUrl;
    this.industries = document.industries;
    this.created = document.created;
    this.createdBy = document.createdBy;
    this.modified = document.modified;
    this.modifiedBy = document.modifiedBy;
    this.businessLines = document.businessLines;
    this.removed = document.removed;
    this.keyWords = document.keyWords;
  }
}
