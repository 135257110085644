import {ActivityWithProcessDto, CreatedByProcessDto} from '../services/generated';
import {Activity} from './Activity';
import {ProcessUsedInLibraries} from './ProcessUsedInLibraries';

export class ActivityWithProcess extends Activity implements ActivityWithProcessDto {
  public processes: ProcessUsedInLibraries[] | null;
  public createdByProcess: CreatedByProcessDto | null;

  constructor(link: ActivityWithProcessDto) {
    super(link);
    this.processes = link.processes?.map(process => new ProcessUsedInLibraries(process)) || null;
    this.createdByProcess = link.createdByProcess;
  }
}
