import React from 'react';

import { HeadingWithDecoration } from '../../common/HeadingWithDecoration';

export const Help = () => {
  return (
    <main>
      <HeadingWithDecoration text="Help" />
    </main>
  );
};
