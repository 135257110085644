import styles from '../UnitManagementProcess.module.scss';
import {FC, useState} from 'react';
import {InputValidationError} from '../../../../common/Input/ValidationError';
import {PeoplePicker} from '../../../../common/PeoplePicker';

interface IProcessRowOwnerProps {
  model: {
    processOwnerAzureId: string | undefined;
    secondaryProcessOwnerAzureId?: string | undefined;
    isChecked?: boolean;
  };
  ownerType?: 'processOwnerAzureId' | 'secondaryProcessOwnerAzureId';
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
}

export const ProcessRowOwner: FC<IProcessRowOwnerProps> = ({
  model,
  ownerType = 'processOwnerAzureId',
  required = true,
  readonly,
  disabled
}) => {
  const owner = model[ownerType];

  const [ownerAzureId, setOwnerAzureId] = useState<string | undefined>(owner);
  const isError = !readonly && required && model.isChecked && !ownerAzureId;
  const isTheSameOwner =
    ownerType === 'secondaryProcessOwnerAzureId' &&
    model.processOwnerAzureId === owner &&
    model.processOwnerAzureId !== undefined &&
    model.isChecked;

  return (
    <div className={styles.inputWrapper}>
      <PeoplePicker
        isDisabled={disabled}
        isReadonly={readonly}
        defaultSelectedPersonId={owner}
        onSelectPerson={person => {
          const azureId = person ? person[0]?.id : undefined;
          model[ownerType] = azureId;
          setOwnerAzureId(azureId);
        }}
        className={`${styles.regularOwner}${isError ? ` ${styles.error}` : ''}`}
      />
      {isError ? (
        <InputValidationError message={'This value could not be empty. Choose Process Owner.'} />
      ) : null}
      {isTheSameOwner && (
        <InputValidationError
          message={"Secondary Process Owner couldn't be the same as Process Owner"}
        />
      )}
    </div>
  );
};
