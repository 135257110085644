import {CreatedByProcessDto, EditActivityVersionDto} from '../services/generated';
import {Activity} from './Activity';

export class ActivityVersion extends Activity implements EditActivityVersionDto {
  public createdByProcess: CreatedByProcessDto | null;

  constructor(item: EditActivityVersionDto) {
    super({...item, removed: false});
    this.createdByProcess = item.createdByProcess;
  }
}
