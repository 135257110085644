import {
  DescriptionVersionDto,
  FunctionDto,
  ProcessVersionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UserDto,
  BusinessLineDto
} from '../services/generated';
import {ActivityVersion} from './ActivityVersion';
import {DocumentVersionEdit} from './DocumentVersionEdit';
import {ExternalLinkVersion} from './ExternalLinkVersion';
import {ProcessBaseView} from './ProcessBaseView';

export class ProcessVersion extends ProcessBaseView implements ProcessVersionDto {
  public descriptions: Array<DescriptionVersionDto> | null;
  public documents: Array<DocumentVersionEdit> | null;
  public function: FunctionDto | null;
  public parentProcessId: number | null;
  public processId: number;
  public status: string | null;
  public version: number;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public industries: IndustryDto[] | null;
  public activityInputs: ActivityVersion[] | null;
  public activityOutputs: ActivityVersion[] | null;
  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public published: string | null;
  public publishedBy: UserDto | null;
  public externalLinks: ExternalLinkVersion[] | null;
  public businessLines: BusinessLineDto[] | null;
  public isMirrorCopy: boolean;

  constructor(processVersion: ProcessVersionDto) {
    super(processVersion);
    this.descriptions = processVersion.descriptions;
    this.documents =
      processVersion.documents?.map(document => new DocumentVersionEdit(document)) || null;
    this.function = processVersion.function;
    this.parentProcessId = processVersion.parentProcessId;
    this.processId = processVersion.processId;
    this.status = processVersion.status;
    this.version = processVersion.version;
    this.projectSizeCategories = processVersion.projectSizeCategories;
    this.specialities = processVersion.specialities;
    this.industries = processVersion.industries;
    this.activityInputs =
      processVersion.activityInputs?.map(item => new ActivityVersion(item)) || null;
    this.activityOutputs =
      processVersion.activityOutputs?.map(item => new ActivityVersion(item)) || null;
    this.created = processVersion.created;
    this.createdBy = processVersion.createdBy;
    this.modified = processVersion.modified;
    this.modifiedBy = processVersion.modifiedBy;
    this.published = processVersion.published;
    this.publishedBy = processVersion.publishedBy;
    this.externalLinks =
      processVersion.externalLinks?.map(link => new ExternalLinkVersion(link)) || null;
    this.businessLines = processVersion.businessLines;
    this.isMirrorCopy = processVersion.isMirrorCopy;
  }
}
