export enum actions {
  READ = 'READ',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE'
}

export enum roles {
  READER = '0',
  EDITOR = '1',
  CREATOR = '2',
  EDITOR_CREATOR = '3',
  REMOVER = '4',
  EDITOR_REMOVER = '5',
  CREATOR_REMOVER = '6',
  GOD = '7'
}

export enum PermissionToCheck {
  ProcessForm = 'ProcessForm',
  UnitForm = 'UnitForm',
  UnitManageButton = 'UnitManageButton',
  UnitFormProcessessUnitSelector = 'UnitFormProcessessUnitSelector',
  EditProcessDiagramButton = 'EditProcessDiagramButton',
  ChangeProcessOwner = 'ChangeProcessOwner',
  DocumentInReviewFlag = 'DocumentInReviewFlag',
  AccessUnitDraft = 'AccessUnitDraft',
  EditProcessParent = 'EditProcessParent',
  ElevateOrResolveComment = 'ElevateOrResolveComment'
}

//https://css-tricks.com/handling-user-permissions-in-javascript/
