import {IPersonaProps} from '@fluentui/react';
import {useContext, useEffect, useState} from 'react';
import {useService} from '../../../hooks';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';

export const usePeoplePicker = (
  onSelectPerson: ((person: IPersonaProps[] | undefined, itemId: number) => void) | undefined,
  itemId: number,
  defaultSelectedPerson?: IPersonaProps[],
  defaultSelectedPersonId?: string,
  defaultSelectedPersonIds?: string[]
) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);

  const {getUserById} = useService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [defaultSelectedPersonResult, setDefaultSelectedPersonResult] = useState<IPersonaProps[]>(
    []
  );

  const [selected, setSelected] = useState<IPersonaProps[]>([]);
  const [removed, setRemoved] = useState<IPersonaProps[]>([]);

  const defaultPerson: () => Promise<IPersonaProps[] | undefined> = async () => {
    if (defaultSelectedPerson) {
      return defaultSelectedPerson;
    }
    if (defaultSelectedPersonId) {
      const person = await getUserById(defaultSelectedPersonId);
      if (!person) return undefined;
      return [
        {
          id: person.id,
          text: person.displayName,
          secondaryText: person.mail,
          key: person.id
        }
      ];
    }
    if (defaultSelectedPersonIds) {
      const people: IPersonaProps[] = [];
      defaultSelectedPersonIds.forEach(async id => {
        const person = await getUserById(id);
        if (!person) return undefined;
        people.push({
          id: person.id,
          text: person.displayName,
          secondaryText: person.mail,
          key: person.id
        });
      });
      return people;
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const person = await defaultPerson();
        if (person) {
          setDefaultSelectedPersonResult(person);
          setSelected(person);
        } else {
          setDefaultSelectedPersonResult([]);
          setSelected([]);
        }
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {getFilteredUsersList} = useService();

  const onFilterChanged = async (filterText: string): Promise<IPersonaProps[]> => {
    if (filterText.length > 1) {
      const users = await getFilteredUsersList(filterText);
      const uniqueUsers = users.filter(user =>
        selected.every(existingUser => existingUser.id !== user.id)
      );
      return uniqueUsers;
    } else {
      return [];
    }
  };

  const onChange = (items?: IPersonaProps[] | undefined) => {
    if (selected.length > (items?.length || 0)) {
      const removedItem = selected.find(selected => !items?.some(item => item.id === selected.id));
      if (removedItem) setRemoved([...removed, removedItem]);
    } else {
      setRemoved(removed.filter(removedItem => !items?.some(item => item.id === removedItem.id)));
    }
    if (onSelectPerson) onSelectPerson(items, itemId);
    setSelected(items || []);
    //onChangeValidationStatus(false);
  };

  return {defaultSelectedPersonResult, isLoading, selected, removed, onFilterChanged, onChange};
};
