import {useContext, useEffect, useMemo, useState} from 'react';

import {actions, PermissionToCheck} from '../../../../enums';
import {checkPermissions} from '../../../../helpers/permissionsHtml';
import {useService} from '../../../../hooks';
import {useUser} from '../../../../hooks/services/useUser';
import {ProcessTree, Unit} from '../../../../models';
import {EditProcessVersion} from '../../../../models/EditProcessVersion';
import {AuthenticationContext} from '../../../../providers/AuthenticationContext';
import {ApiResponse} from '../../../../services/ApiResponseType';
import {useUnit} from '../../../../hooks/services/useUnit';
import {IDropdownOption} from '@fluentui/react';
import {sortByTitle} from '../../../../helpers/arrayMehods';

export const useEdit = (processId: number, unitId: number) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getProcessVersionsDraft, getUserById, getMenuProcessesPublishedForUnit, getUnit} =
    useService();
  const {getUserPermissionsPage} = useUser();
  const {getUnitsImplementingProcess} = useUnit();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] =
    useState<ApiResponse<ProcessTree[]>>();
  const [unitDetails, setUnitDetails] = useState<ApiResponse<Unit>>();
  const [unitsListForParentProcess, setUnitsListForParentProcess] = useState<
    IDropdownOption<number>[]
  >([]);
  useEffect(() => {
    (async () => {
      const processList = await getMenuProcessesPublishedForUnit(unitId);
      setPublishedProcessesForUnit(processList);
      const unit = await getUnit(unitId);
      setUnitDetails(unit);
    })();
    /* eslint-disable*/
  }, [getMenuProcessesPublishedForUnit, unitId, setPublishedProcessesForUnit]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [processData, setProcessData] = useState<ApiResponse<EditProcessVersion>>();
  const [processOwner, setProcessOwner] =
    useState<{id: string; text: string; key: string; secondaryText: string}[]>();
  const [secondaryProcessOwner, setSecondaryProcessOwner] =
    useState<{id: string; text: string; key: string; secondaryText: string}[]>();
  secondaryProcessOwner;
  const [canSelectProcessOwner, setCanSelectProcessOwner] = useState(false);
  const [canChangeProcessParent, setCanChangeProcessParent] = useState(false);

  const updateProcessVersionsDraft = useMemo(
    () => async () => {
      setIsLoading(true);
      if (!isAzureLoading) {
        const process = await getProcessVersionsDraft(processId);
        setProcessData(process);
        setIsLoading(false);
      }
    },
    [isAzureLoading, processId, getProcessVersionsDraft]
  );
  useEffect(() => {
    updateProcessVersionsDraft();
  }, [updateProcessVersionsDraft]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let canSelectParentProcess: boolean = false;
      const response = await getUserPermissionsPage(unitId, processId);
      if (response?.result) {
        const canStillAccess = checkPermissions(
          PermissionToCheck.ChangeProcessOwner,
          actions.UPDATE,
          response.result
        );
        setCanSelectProcessOwner(canStillAccess);
        canSelectParentProcess = checkPermissions(
          PermissionToCheck.EditProcessParent,
          actions.UPDATE,
          response.result
        );
        setCanChangeProcessParent(canSelectParentProcess);
      } else {
        setCanSelectProcessOwner(false);
        setCanChangeProcessParent(false);
      }
      if (processData?.result) {
        
        if (canSelectParentProcess) {
          const unitsList = await getUnitsImplementingProcess(
            unitId,
            processData.result.masterProcessId
          );
          if (unitsList.result)
            setUnitsListForParentProcess(
              unitsList.result.sort(sortByTitle).map(unit => ({
                key: unit.unitId,
                text: `${unit.unitTitle || ''}${unit.canNotBeImplemented ? '*' : ''}`,
                data: unit.id,
                disabled: unit.canNotBeImplemented
              }))
            );
        }

        if (processData.result?.processOwner) {
          const user = processData.result?.processOwner.azureId
            ? await getUserById(processData.result?.processOwner.azureId)
            : undefined;
          const person = user
            ? [
                {
                  id: user.id,
                  text: user.displayName,
                  secondaryText: user.mail,
                  key: user.id
                }
              ]
            : undefined;
          setProcessOwner(person);
        } else {
          setProcessOwner(undefined);
        }
        if (processData.result?.secondaryProcessOwner) {
          const user = processData.result?.secondaryProcessOwner.azureId
            ? await getUserById(processData.result?.secondaryProcessOwner.azureId)
            : undefined;
          const person = user
            ? [
                {
                  id: user.id,
                  text: user.displayName,
                  secondaryText: user.mail,
                  key: user.id
                }
              ]
            : undefined;
          setSecondaryProcessOwner(person);
        } else {
          setSecondaryProcessOwner(undefined);
        }
      }

      setIsLoading(false);
    })();
  }, [processData, getUserById]);

  return {
    isLoading,
    processData,
    unitsListForParentProcess,
    processOwner,
    secondaryProcessOwner,
    publishedProcessesForUnit,
    canSelectProcessOwner,
    canChangeProcessParent,
    updateProcessVersionsDraft,
    unitDetails
  };
};
