import {BusinessLineDto, EditProcessVersionDto, UserDto} from '../services/generated';
import {ProcessVersion} from './ProcessVersion';

export class EditProcessVersion extends ProcessVersion implements EditProcessVersionDto {
  public created: string | null;
  public createdBy: UserDto | null;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public version: number;
  public published: string | null;
  public publishedBy: UserDto | null;
  public maxDate: string;
  public nextUpdateDateBlock: boolean;
  public masterProcessId: number;
  public businessLines: BusinessLineDto[] | null;

  constructor(editProcessVersion: EditProcessVersionDto) {
    super(editProcessVersion);
    this.created = editProcessVersion.created;
    this.createdBy = editProcessVersion.createdBy;
    this.modified = editProcessVersion.modified;
    this.modifiedBy = editProcessVersion.modifiedBy;
    this.version = editProcessVersion.version;
    this.published = editProcessVersion.published;
    this.publishedBy = editProcessVersion.publishedBy;
    this.maxDate = editProcessVersion.maxDate;
    this.nextUpdateDateBlock = editProcessVersion.nextUpdateDateBlock;
    this.masterProcessId = editProcessVersion.masterProcessId;
    this.businessLines = editProcessVersion.businessLines;
  }
}
