import {Label, MessageBar, MessageBarType, Spinner} from '@fluentui/react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {FC, useCallback, useContext, useEffect, useState} from 'react';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import {SettingDto} from '../../../services/generated';
import {FormFooter} from '../../common/FormFooter';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {Richtext} from '../../common/Richtext';
import {TheButton} from '../../common/TheButton';
import {useNavigate} from 'react-router-dom';
import {Helmet} from "react-helmet";

export const ReleasePage: FC = () => {
  const {globalSettings, isLoading, setGlobalSettings} = useContext(GlobalSettingsContext);
  const navigate = useNavigate();
  const {putGlobalSettings} = useAdmin();
  const [settings, setSettings] = useState<SettingDto[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingResult, setSavingResult] = useState<string>('');
  const changeSetting = useCallback(
    (settingName: string, newValue: string | undefined) => {
      const currentSettings: SettingDto[] = settings.map(s => s);
      const setting = currentSettings.find(s => s.name === settingName);
      if (setting) {
        setting.value = newValue || null;
        setSettings(currentSettings);
      }
    },
    [settings]
  );

  const resetSettings = useCallback(() => {
    if (globalSettings && globalSettings.settingsFlat)
      setSettings(
        globalSettings.settingsFlat.map(s => ({
          id: s.id,
          link: s.link,
          name: s.name,
          value: s.value
        }))
      );
  }, [globalSettings]);

  const onFormCancel = useCallback(() => {
    resetSettings();
    navigate(`/admin`);
  }, [navigate, resetSettings]);

  const onFormSave = useCallback(async () => {
    setIsSaving(true);
    const settingsResult = await putGlobalSettings(settings);
    const result = settingsResult.result;
    if (result) {
      setGlobalSettings(result, false,[]);
    }
    setSavingResult(result?.length ? 'success' : 'error');
    setIsSaving(false);
  }, [settings, putGlobalSettings, setGlobalSettings]);

  const onFormPublish = useCallback(async () => {
    changeSetting('LastReleaseDate', new Date().toISOString());
    onFormSave();
  }, [changeSetting, onFormSave]);

  useEffect(() => {
    resetSettings();
  }, [globalSettings, resetSettings]);
  return (
    <>
      <Helmet>
        <title>IMS Release notes</title>
      </Helmet>
      <HeadingWithDecoration text="Release notes" />
      {isLoading || isSaving ? (
        <Spinner />
      ) : (
        <>
          {savingResult === 'success' && (
            <MessageBar messageBarType={MessageBarType.success}>Changes were saved.</MessageBar>
          )}
          {savingResult === 'error' && (
            <MessageBar messageBarType={MessageBarType.error}>
              Sorry, but the form was not saved. Check changes and try one more time.
            </MessageBar>
          )}
          {settings &&
            settings.map(setting => {
              switch (setting.name) {
                case 'ReleaseNotes':
                  return (
                    <div key={setting.id}>
                      <Label>Release banner notes</Label>
                      <Richtext
                        defaultValue={setting.value || ''}
                        onChange={content => {
                          changeSetting(setting.name ?? '', content);
                        }}
                      />
                    </div>
                  );
                // case 'LastReleaseDate':
                //   const date = setting.value ? new Date(setting.value) : undefined;
                //   const validDate =
                //     date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
                //   return (
                //     <div key={setting.id}>
                //       <Label>Last release banner publish date</Label>
                //       <DatePicker disabled value={validDate} />
                //     </div>
                //   );
                default:
                  return null;
              }
            })}
          <FormFooter onSubmit={onFormSave} onCancel={onFormCancel} saveLabel="Publish">
            <TheButton onClick={onFormPublish} primary>
              Publish and show banner to all users
            </TheButton>
          </FormFooter>
        </>
      )}
    </>
  );
};
