/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalLinksWithProcessDto } from '../models/ExternalLinksWithProcessDto';
import type { SaveExternalLinkDto } from '../models/SaveExternalLinkDto';
import type { UpdateExternalLinkDto } from '../models/UpdateExternalLinkDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExternalLinkService {

  /**
   * @param requestBody 
   * @returns SaveExternalLinkDto Success
   * @throws ApiError
   */
  public static postExternalLink(
requestBody?: SaveExternalLinkDto,
): CancelablePromise<SaveExternalLinkDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/externalLink',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ExternalLinksWithProcessDto Success
   * @throws ApiError
   */
  public static getExternalLink(): CancelablePromise<Array<ExternalLinksWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/externalLink',
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static deleteExternalLink(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/externalLink/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns SaveExternalLinkDto Success
   * @throws ApiError
   */
  public static putExternalLink(
id: number,
requestBody?: UpdateExternalLinkDto,
): CancelablePromise<SaveExternalLinkDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/externalLink/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
