import {useCallback, useEffect, useMemo, useState} from 'react';
import {useUnit} from '../../../../hooks/services/useUnit';
import {ProcessSubscriptionDto} from '../../../../services/generated/models/ProcessSubscriptionDto';
import {
  ProcessSubscription,
  GroupedProcessSubscription,
  subscriptionUser
} from '../../../../models/ProcessSubscription';
import {ProcessFlatStructure} from '../../../../models';
import {useParams} from 'react-router-dom';
import {ProcessType} from '../../../../services/generated';
import {sortByOrderPrefix} from '../../../../helpers/arrayMehods';

export const useUnitManagementSubscriptions = () => {
  const {
    saveUnitManageProcessSubscription,
    getUnitManageProcessSubscription,
    getProcessesPublishedForUnitFlatForManage
  } = useUnit();
  const {unitId} = useParams();
  const [unitProcesses, setUnitProcesses] = useState<ProcessFlatStructure[]>([]);
  const [initialUnitProcessesSubscriptions, setInitialUnitProcessesSubscriptions] = useState<
    ProcessSubscription[]
  >([]);
  const [groupedUnitProcessesSubscriptions, setGroupedUnitProcessesSubscriptions] = useState<
    GroupedProcessSubscription[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadData = useCallback(
    async (unitId: number) => {
      setIsLoading(true);
      const subscriptions = await getUnitManageProcessSubscription(unitId);
      const processes = await getProcessesPublishedForUnitFlatForManage(unitId);
      if (subscriptions.result) setInitialUnitProcessesSubscriptions(subscriptions.result);
      if (processes.result) setUnitProcesses(processes.result);
      setIsLoading(false);
    },
    [getUnitManageProcessSubscription, getProcessesPublishedForUnitFlatForManage]
  );

  useEffect(() => {
    if (unitId) loadData(Number.parseInt(unitId));
  }, [unitId, loadData]);

  useEffect(() => {
    unitProcesses.forEach(process => {
      let parent: ProcessFlatStructure | undefined;
      if (process.parentMasterProcessId)
        parent = unitProcesses.find(p => p.masterProcessId === process.parentMasterProcessId);
      if (parent) process.setParent(parent);
    });
    const groupedSubscriptions: GroupedProcessSubscription[] = unitProcesses
      .filter(p => p.processType === ProcessType.Process && !p.deleted)
      .sort(sortByOrderPrefix)
      .map(process => {
        const processAsProcessSubscriptionDto: ProcessSubscriptionDto = {
          id: 0,
          isDeleted: false,
          processId: process.id,
          processOwnerAzureId: process.processOwner ? process.processOwner.azureId : '',
          processTitle: process.title,
          link: [],
          userAzureId: ''
        };
        const newGroup: GroupedProcessSubscription = new GroupedProcessSubscription(
          processAsProcessSubscriptionDto,
          [],
          process.processOwner
        );
        return newGroup;
      });
    initialUnitProcessesSubscriptions.forEach(sub => {
      if (!sub.userAzureId) return;
      let groupedSubscription: GroupedProcessSubscription | undefined = groupedSubscriptions.find(
        group => group.processId === sub.processId
      );
      const subscription: subscriptionUser = {
        userAzureId: sub.userAzureId,
        subscriptionId: sub.id
      };
      if (groupedSubscription) groupedSubscription.subscriptionUsers.push(subscription);
    });

    setGroupedUnitProcessesSubscriptions(groupedSubscriptions);
  }, [initialUnitProcessesSubscriptions, unitProcesses]);

  const saveSubscriptions = useCallback(
    async (groupedSubscripitions: GroupedProcessSubscription[]) => {
      setIsLoading(true);
      const currentSubscriptions: ProcessSubscription[] = [];
      const modifiedSubscriptions: ProcessSubscription[] = [];
      groupedSubscripitions.forEach(group => {
        group.subscriptionUsers.forEach(user => {
          currentSubscriptions.push({
            id: user.subscriptionId,
            userAzureId: user.userAzureId,
            processId: group.processId,
            link: group.link,
            processTitle: group.processTitle,
            processOwnerAzureId: group.processOwnerAzureId,
            isDeleted: false
          });
        });
      });
      const currentSubscriptionsIds = new Set(currentSubscriptions.map(sub => sub.id));
      const deletedSubscriptions = initialUnitProcessesSubscriptions.filter(
        initial => !currentSubscriptionsIds.has(initial.id)
      );
      deletedSubscriptions.forEach(deleted => {
        deleted.isDeleted = true;
        modifiedSubscriptions.push(deleted);
      });

      currentSubscriptions.filter(cs => cs.id === 0).forEach(cs => modifiedSubscriptions.push(cs));
      if (unitId) {
        await saveUnitManageProcessSubscription(Number.parseInt(unitId), modifiedSubscriptions);
        loadData(Number.parseInt(unitId));
      }
    },
    [initialUnitProcessesSubscriptions, unitId, loadData, saveUnitManageProcessSubscription]
  );
  return useMemo(
    () => ({
      groupedUnitProcessesSubscriptions,
      unitId,
      isLoading,
      saveSubscriptions
    }),
    [groupedUnitProcessesSubscriptions, unitId, isLoading, saveSubscriptions]
  );
};
