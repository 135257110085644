import {useMemo} from 'react';
import {useApiService} from '../../services/helpers';
import {AdminService, SettingDto} from '../../services/generated';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {LogMessageType} from '../../services/LogMessagesType';
import {DocumentInUnit, Unit, ExternalLinkWithProcess} from '../../models';
import {ParentUnit} from '../../models/UnitParent';
import {ActivityWithProcess} from '../../models/ActivityWithProcess';
import {BrokenLinks} from '../../models/BrokenLinks';
import { LibraryDto } from '../../services/generated/models/LibraryDto';

export const useAdmin = () => {
  const requestWrapper = useApiService();

  const getGlobalSettings = async (
    log: LogMessageType = {
      error: false
    }
  ): ApiResponsePending<SettingDto[]> => {
    return await requestWrapper(AdminService.getApiAdminSetting(), result => result ?? [], log);
  };

  const getAdminLibraries = async (
    log: LogMessageType = {
      error: false
    }
  ): ApiResponsePending<LibraryDto[]> => {
    return await requestWrapper(AdminService.getApiAdminLibrary(), result => result ?? [], log);
  };

  const putGlobalSettings = async (
    settings: SettingDto[],
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.putApiAdminSetting(settings),
      result => result ?? [],
      log
    );
  };

  const putLibrariesSettings = async (
    settings: LibraryDto[],
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.putApiAdminLibrary(settings),
      result => result ?? [],
      log
    );
  };

  const getAdminDocument = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.getApiAdminDocument(),
      result => result.map(item => new DocumentInUnit(item)),
      log
    );
  };

  const getAdminExternalLink = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.getApiAdminExternalLink(),
      result => result.map(item => new ExternalLinkWithProcess(item)),
      log
    );
  };

  const getUnitsList = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.getApiAdminUnit(),
      result => result.map(item => new Unit(item)),
      log
    );
  };

  const createUnit = async (
    unit: Unit,
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.postApiAdminUnit(unit),
      result => new Unit(result),
      log
    );
  };

  const getParentUnitsList = async (
    pareuntUnitId?: number,
    log: LogMessageType = {
      error: false
    }
  ) => {
    if (pareuntUnitId)
      return await requestWrapper(
        AdminService.getApiAdminUnitParents1(pareuntUnitId),
        result => result.map(u => new ParentUnit(u)),
        log
      );
    else
      return await requestWrapper(
        AdminService.getApiAdminUnitParents(),
        result => result.map(u => new ParentUnit(u)),
        log
      );
  };

  const getAdminActivityInput = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.getApiAdminActivityInput(),
      result => result.map(item => new ActivityWithProcess(item)),
      log
    );
  };

  const getAdminActivityOutput = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.getApiAdminActivityOutput(),
      result => result.map(item => new ActivityWithProcess(item)),
      log
    );
  };

  const getAdminUnitAssignment = async (
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(AdminService.getApiAdminUnitAssignment(), result => result, log);
  };

  const getBrokenLinks = async (log: LogMessageType = {error: false}) => {
    return await requestWrapper(
      AdminService.getApiAdminBrokenLinks(),
      result => result.map(item => new BrokenLinks(item)),
      log
    );
  };

  const putBrokenLink = async (
    linkId: number,
    log: LogMessageType = {
      error: false
    }
  ) => {
    return await requestWrapper(
      AdminService.putApiAdminBrokenLinks(linkId),
      result => new BrokenLinks(result),
      log
    );
  };

  const refreshToken = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<any> => {
    return await requestWrapper(
      AdminService.refreshToken(),
      result =>  result,
      log
    );
  };

  return useMemo(
    () => ({
      getGlobalSettings,
      putGlobalSettings,
      putLibrariesSettings,
      getAdminLibraries,
      getAdminDocument,
      getAdminExternalLink,
      getUnitsList,
      createUnit,
      refreshToken,
      getParentUnitsList,
      getAdminActivityInput,
      getAdminActivityOutput,
      getAdminUnitAssignment,
      getBrokenLinks,
      putBrokenLink
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
