import {FC, ReactNode} from 'react';
import {Label, values} from '@fluentui/react';
import styles from './RefreshDictonariesModal.module.scss';

import {ModalWithHeader} from '../../../common/ModalWithHeader';
import {TheButton} from '../../../common/TheButton';
import {RefreshDictonariesDto} from '../../../../services/generated/models/RefreshDictonariesDto';
import {UpdateDictonaryDto} from '../../../../services/generated/models/UpdateDictonaryDto';
import {DeleteFunctionDto} from '../../../../services/generated/models/DeleteFunctionDto';

interface IRefreshDictonariesModalProps {
  isVisible: boolean;
  refreshResult: RefreshDictonariesDto | undefined;
  onClose: () => void;
  children?: ReactNode;
}
export const RefreshDictonariesModal: FC<IRefreshDictonariesModalProps> = ({
  isVisible,
  refreshResult,
  onClose
}) => (
  <>
    <ModalWithHeader header="Refresh Dictionaries Result" isVisible={isVisible} dismiss={onClose}>
      {refreshResult?.modified ? (
        <>
          <DictonaryManage
            label="Added Functions:"
            dictonariesToMap={refreshResult?.addedFunctions || []}></DictonaryManage>
          <DictonaryUpdated
            label="Updated Functions:"
            dictonariesToMap={refreshResult?.updatedFunctions || []}></DictonaryUpdated>
          <FunctionDeleted
            label="Deleted Functions:"
            dictonariesToMap={refreshResult?.deletedFunctions || []}></FunctionDeleted>
          <DictonaryManage
            label="Added Industries:"
            dictonariesToMap={refreshResult?.addedIndustries || []}></DictonaryManage>
          <DictonaryUpdated
            label="Updated Industries:"
            dictonariesToMap={refreshResult?.updatedIndustries || []}></DictonaryUpdated>
          <DictonaryManage
            label="Deleted Industries:"
            dictonariesToMap={refreshResult?.deletedIndustries || []}></DictonaryManage>
          <DictonaryManage
            label="Added Specialities:"
            dictonariesToMap={refreshResult?.addedSpecialities || []}></DictonaryManage>
          <DictonaryUpdated
            label="Updated Specialities:"
            dictonariesToMap={refreshResult?.updatedSpecialities || []}></DictonaryUpdated>
          <DictonaryManage
            label="Deleted Specialities:"
            dictonariesToMap={refreshResult?.deletedSpecialities || []}></DictonaryManage>
          <DictonaryManage
            label="Added Business Lines:"
            dictonariesToMap={refreshResult?.addedBusinessLines || []}></DictonaryManage>
          <DictonaryUpdated
            label="Updated Business Lines:"
            dictonariesToMap={refreshResult?.updatedBusinessLines || []}></DictonaryUpdated>
          <DictonaryManage
            label="Deleted Business Lines:"
            dictonariesToMap={refreshResult?.deletedBusinessLines || []}></DictonaryManage>
          <DictonaryManage
            label="Added Project Size Categories:"
            dictonariesToMap={refreshResult?.addedProjectSizeCategories || []}></DictonaryManage>
          <DictonaryUpdated
            label="Updated Project Size Categories:"
            dictonariesToMap={refreshResult?.updatedProjectSizeCategories || []}></DictonaryUpdated>
          <DictonaryManage
            label="Deleted Project Size Categories:"
            dictonariesToMap={refreshResult?.deletedProjectSizeCategories || []}></DictonaryManage>
        </>
      ) : (
        <p>Everything is up to date</p>
      )}
      <div className={styles.buttons}>
        <TheButton primary onClick={onClose}>
          Confirm
        </TheButton>
      </div>
    </ModalWithHeader>
  </>
);

interface IDictonaryManageProps {
  label: string;
  dictonariesToMap: string[];
}

const DictonaryManage: FC<IDictonaryManageProps> = ({label, dictonariesToMap}) => {
  return (
    <>
      {dictonariesToMap.length > 0 && (
        <>
          <Label className={styles.label}>{label}</Label>
          {dictonariesToMap.map((value: string, index: number) => (
            <p className={styles.pStyle} key={index}>
              {value}
            </p>
          ))}
        </>
      )}
    </>
  );
};

interface IFunctionDeletedProps {
  label: string;
  dictonariesToMap: DeleteFunctionDto[];
}

const FunctionDeleted: FC<IFunctionDeletedProps> = ({label, dictonariesToMap}) => {
  return (
    <>
      {dictonariesToMap.length > 0 && (
        <>
          <Label className={styles.label}>{label}</Label>
          {dictonariesToMap.map((value: DeleteFunctionDto, index: number) => (
            <>
              {value.masterProcessWhereFunctionStillExists.length > 0 ? (
              <>
                <p className={styles.pStyle} key={index}>
                  {`${value.functionName} (Cannot mark function as delete because is used in below Master Process(es))`}
                </p>
                <ul>
                  {value.masterProcessWhereFunctionStillExists.map((process, index) => (
                    <li className={styles.pStyle} key={index}>
                      {process}
                    </li>
                  ))}
                </ul>
                </>
              ) : (
              <p className={styles.pStyle} key={index}>
                {value.functionName}
              </p>
              )}
            </>
          ))}
        </>
      )}
    </>
  );
};

interface IDictonaryUpdatedProps {
  label: string;
  dictonariesToMap: UpdateDictonaryDto[];
}
const DictonaryUpdated: FC<IDictonaryUpdatedProps> = ({label, dictonariesToMap}) => {
  return (
    <>
      {dictonariesToMap.length > 0 && (
        <>
          <Label className={styles.label}>{label}</Label>
          <table>
            <thead>
              <tr>
                <th className={styles.thStyle}>Old Value</th>
                <th className={styles.thStyle}>New Value</th>
              </tr>
            </thead>
            <tbody>
              {dictonariesToMap.map((value: UpdateDictonaryDto, index: number) => (
                <tr key={index}>
                  <td className={styles.tdStyle}>{value.oldValue}</td>
                  <td className={styles.tdStyle}>{value.newValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
