export enum ElevateStatusEnum {
  None = 1,
  ToParent = 2,
  ToDivision = 3,
  ToOpex = 4
}
export enum CanBeElevateStatusEnum {
  None = 1,
  ToParent = 2,
  ToDivision = 3,
  ToBoth = 4
}
