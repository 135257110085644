import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto
} from '../../../../services/generated';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDictionary} from '../../../../hooks/services/useDictionary';
import {UnitBusinessLine} from '../../../../models';

export const useMasterProcessDictionaries = () => {
  const {getFunction, getBusinessLine, getProjectSizeCategory, getIndustry, getSpeciality} =
    useDictionary();

  const [optionsFunction, setOptionsFunction] = useState<FunctionDto[] | null>(null);
  const getOptionsFunction = useCallback(async () => {
    const options = await getFunction();
    setOptionsFunction(options.result);
  }, [getFunction]);

  const [optionsBusinessLine, setOptionsBusinessLine] = useState<UnitBusinessLine[] | null>(null);
  const getOptionsBusinessLine = useCallback(async () => {
    const options = await getBusinessLine();
    setOptionsBusinessLine(options.result || null);
  }, [getBusinessLine]);

  const [optionsProjectSizeCategory, setOptionsProjectSizeCategory] = useState<
    ProjectSizeCategoryDto[] | null
  >(null);
  const getOptionsProjectSizeCategory = useCallback(async () => {
    const options = await getProjectSizeCategory();
    setOptionsProjectSizeCategory(options.result);
  }, [getProjectSizeCategory]);

  const [optionsIndustry, setOptionsIndustry] = useState<IndustryDto[] | null>(null);
  const getOptionsIndustry = useCallback(async () => {
    const options = await getIndustry();
    setOptionsIndustry(options.result);
  }, [getIndustry]);

  const [optionsSpeciality, setOptionsSpeciality] = useState<SpecialityDto[] | null>(null);
  const getOptionsSpeciality = useCallback(async () => {
    const options = await getSpeciality();
    setOptionsSpeciality(options.result);
  }, [getSpeciality]);

  useEffect(() => {
    getOptionsFunction();
    getOptionsBusinessLine();
    getOptionsProjectSizeCategory();
    getOptionsIndustry();
    getOptionsSpeciality();
  }, [
    getOptionsFunction,
    getOptionsBusinessLine,
    getOptionsProjectSizeCategory,
    getOptionsIndustry,
    getOptionsSpeciality
  ]);

  return useMemo(
    () => ({
      optionsFunction,
      optionsBusinessLine,
      optionsProjectSizeCategory,
      optionsIndustry,
      optionsSpeciality
    }),
    [
      optionsFunction,
      optionsBusinessLine,
      optionsProjectSizeCategory,
      optionsIndustry,
      optionsSpeciality
    ]
  );
};
