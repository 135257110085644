import {FC, useCallback} from 'react';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {TheButton} from '../../../common/TheButton';

export const MasterProcessInputAddChild: FC<{
  model: MasterProcessItemModel;
  onAddChild: (parentId: number) => void;
}> = ({model, onAddChild}) => {
  const onClick = useCallback(() => onAddChild(model.id), [onAddChild, model]);
  return (
    <TheButton
      isIcon
      ariaLabel="Add child master process"
      iconProps={{
        iconName: 'Add'
      }}
      onClick={onClick}
    />
  );
};
