import {IContextualMenuItem} from '@fluentui/react';
import {TheButton} from '../TheButton';
import styles from './SplitButton.module.scss';
import {useState} from 'react';
import {ModalWithHeader} from '../ModalWithHeader';
import {DocumentsCenter} from '../../Pages/DocumentsCenter';
import {useDocument} from '../../../hooks/services/useDocument';

interface IProps {
  url: string;
  extraMenuProps?: IContextualMenuItem[] | undefined;
  documentId?: string;
  languageCode?: string;
}

/**
 * List of protocols https://learn.microsoft.com/en-us/office/client-developer/office-uri-schemes#14-scheme-names-and-office-application-registrations
 */
function getProtocol(extension: string | null): string | undefined {
  if (!extension) return;
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
    case 'wll':
    case 'wwl':
      return 'ms-word:';
    case 'ppt':
    case 'pot':
    case 'pps':
    case 'ppa':
    case 'ppam':
    case 'pptx':
    case 'pptm':
    case 'potx':
    case 'potm':
    case 'ppsx':
    case 'ppsm':
    case 'sldx':
    case 'sldm':
    case 'pa':
      return 'ms-powerpoint:';
    case 'xls':
    case 'xlt':
    case 'xlm':
    case 'xll_':
    case 'xla_':
    case 'xla5':
    case 'xla8':
    case 'xlsx':
    case 'xlsm':
    case 'xltx':
    case 'xltm':
    case 'xlsb':
    case 'xla':
    case 'xlam':
    case 'xll':
    case 'xlw':
      return 'ms-excel:';
    case 'vsd':
    case 'vss':
    case 'vst':
    case 'vdw':
    case 'vdx':
    case 'vsx':
    case 'vtx':
    case 'vsdx':
    case 'vsdm':
    case 'vssx':
    case 'vssm':
    case 'vstx':
    case 'vstm':
      return 'ms-visio:';
    case 'adn':
    case 'accdb':
    case 'accdr':
    case 'accdt':
    case 'accda':
    case 'mdw':
    case 'accde':
    case 'mam':
    case 'maq':
    case 'mar':
    case 'mat':
    case 'maf':
    case 'laccdb':
    case 'ade':
    case 'adp':
    case 'mdb':
    case 'cdb':
    case 'mda':
    case 'mdn':
    case 'mdf':
    case 'mde':
    case 'ldb':
      return 'ms-access:';
    case 'mpp':
    case 'mpt':
    case 'mpd':
    case 'mpw':
    case 'mpx':
    case 'mspdi':
      return 'ms-project:';
    case 'pub':
      return 'ms-publisher:';
    case 'dwt':
    case 'fwp':
      return 'ms-spd:';
    case 'xsn':
      return 'ms-infopath:';
  }
}
function getIconName(extension: string | null): string {
  if (!extension) return 'Page';
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
    case 'wll':
    case 'wwl':
      return 'WordDocument';
    case 'ppt':
    case 'pot':
    case 'pps':
    case 'ppa':
    case 'ppam':
    case 'pptx':
    case 'pptm':
    case 'potx':
    case 'potm':
    case 'ppsx':
    case 'ppsm':
    case 'sldx':
    case 'sldm':
    case 'pa':
      return 'PowerPointDocument';
    case 'xls':
    case 'xlt':
    case 'xlm':
    case 'xll_':
    case 'xla_':
    case 'xla5':
    case 'xla8':
    case 'xlsx':
    case 'xlsm':
    case 'xltx':
    case 'xltm':
    case 'xlsb':
    case 'xla':
    case 'xlam':
    case 'xll':
    case 'xlw':
      return 'ExcelDocument';
    case 'vsd':
    case 'vss':
    case 'vst':
    case 'vdw':
    case 'vdx':
    case 'vsx':
    case 'vtx':
    case 'vsdx':
    case 'vsdm':
    case 'vssx':
    case 'vssm':
    case 'vstx':
    case 'vstm':
      return 'VisioDocument';
    case 'adn':
    case 'accdb':
    case 'accdr':
    case 'accdt':
    case 'accda':
    case 'mdw':
    case 'accde':
    case 'mam':
    case 'maq':
    case 'mar':
    case 'mat':
    case 'maf':
    case 'laccdb':
    case 'ade':
    case 'adp':
    case 'mdb':
    case 'cdb':
    case 'mda':
    case 'mdn':
    case 'mdf':
    case 'mde':
    case 'ldb':
      return 'AccessLogo';
    case 'mpp':
    case 'mpt':
    case 'mpd':
    case 'mpw':
    case 'mpx':
    case 'mspdi':
      return 'ProjectDocument';
    case 'pub':
      return 'PublisherLogo';
    case 'dwt':
    case 'fwp':
      return 'Design';
    case 'ase':
    case 'art':
    case 'bmp':
    case 'blp':
    case 'cd5':
    case 'cit':
    case 'cpt':
    case 'cr2':
    case 'cut':
    case 'dds':
    case 'dib':
    case 'djvu':
    case 'egt':
    case 'exif':
    case 'gif':
    case 'gpl':
    case 'grf':
    case 'icns':
    case 'ico':
    case 'iff':
    case 'jng':
    case 'jpeg':
    case 'jpg':
    case 'jfif':
    case 'jp2':
    case 'jps':
    case 'lbm':
    case 'max':
    case 'miff':
    case 'mng':
    case 'msp':
    case 'nef':
    case 'nitf':
    case 'ota':
    case 'pbm':
    case 'pc1':
    case 'pc2':
    case 'pc3':
    case 'pcf':
    case 'pcx':
    case 'pdn':
    case 'pgm':
    case 'PI1':
    case 'PI2':
    case 'PI3':
    case 'pict':
    case 'pct':
    case 'pnm':
    case 'pns':
    case 'ppm':
    case 'psb':
    case 'psd':
    case 'pdd':
    case 'psp':
    case 'px':
    case 'pxm':
    case 'pxr':
    case 'qfx':
    case 'raw':
    case 'rle':
    case 'sct':
    case 'sgi':
    case 'rgb':
    case 'int':
    case 'bw':
    case 'tga':
    case 'tiff':
    case 'tif':
    case 'vtf':
    case 'xbm':
    case 'xcf':
    case 'xpm':
    case '3dv':
    case 'amf':
    case 'ai':
    case 'awg':
    case 'cgm':
    case 'cdr':
    case 'cmx':
    case 'dxf':
    case 'e2d':
    case 'eps':
    case 'fs':
    case 'gbr':
    case 'odg':
    case 'svg':
    case 'stl':
    case 'vrml':
    case 'x3d':
    case 'sxd':
    case 'v2d':
    case 'vnd':
    case 'wmf':
    case 'emf':
    case 'xar':
    case 'png':
    case 'webp':
    case 'jxr':
    case 'hdp':
    case 'wdp':
    case 'cur':
    case 'ecw':
    case 'liff':
    case 'nrrd':
    case 'pam':
    case 'pgf':
    case 'rgba':
    case 'inta':
    case 'sid':
    case 'ras':
    case 'sun':
    case 'heic':
    case 'heif':
      return 'FileImage';
  }
  return 'Page';
}

//TODO finish it more as component for full usage
export const SplitButton = ({url, extraMenuProps, documentId, languageCode}: IProps) => {
  // https://abb.sharepoint.com/sites/IMS_BU_OGC_Portal/HBU-Americas/LBL-AR/QADocuments/Venta%20de%20Repuestos.docx
  const siteAbsoluteUrlRegex = url.match(/.+\/sites\/[^/]+\//);
  const siteAbsoluteUrl = siteAbsoluteUrlRegex ? siteAbsoluteUrlRegex[0] : undefined;
  const extensionRegex = url.match(/\.(\w{2,5})(?:$|\?)/);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {getDynamicLink} = useDocument();
  const extension = extensionRegex ? extensionRegex[1] : null;
  const openLink =
    extension === 'pdf' || !extension ? url : `${url}${url.indexOf('?') === -1 ? '?' : '&'}web=1`;
  const isFile = extension && !['aspx', 'html'].includes(extension);
  const downloadLink =
    siteAbsoluteUrl && isFile
      ? `${siteAbsoluteUrl}/_layouts/download.aspx?SourceUrl=${url.replace('&', '%26')}`
      : null;
  const iconName = getIconName(extension);
  const protocol = getProtocol(extension);
  const appLink = protocol ? `${protocol}ofv|u|${url}` : undefined;
  const whatAsDefaultAction = appLink || openLink;
  const shouldUseOpenLink = !!appLink;
  const isSplit = shouldUseOpenLink || downloadLink || extraMenuProps?.length;
  const handleOpen = () => {
    setIsEdit(true);
  };
  const onDismiss = () => {
    setIsEdit(false);
  };
  const copyLink: () => void = async () => {
    if (documentId && languageCode) {
      var body = {
        documentId: documentId,
        languageCode: languageCode
      };
      const data = await getDynamicLink(body);
      const dynamicUrl = data.result ? data.result : '';
      navigator.clipboard.writeText(dynamicUrl);
    } else {
      navigator.clipboard.writeText('');
    }
  };

  return (
    <div className={styles.wrapperBtn}>
      <TheButton
        href={whatAsDefaultAction}
        onClick={() => {}}
        target="_blank"
        menuProps={
          isSplit
            ? {
                items: [
                  ...(shouldUseOpenLink
                    ? [
                        {
                          key: 'OpenOnline',
                          text: 'Open Online',
                          iconProps: {
                            iconName: 'OpenFile'
                          },
                          href: openLink,
                          target: '_blank'
                        }
                      ]
                    : []),
                  ...(downloadLink
                    ? [
                        {
                          key: 'Download',
                          text: 'Download',
                          iconProps: {
                            iconName: 'Installation'
                          },
                          href: downloadLink,
                          target: '_blank'
                        }
                      ]
                    : []),
                  {
                    key: 'CopyLink',
                    text: 'Copy Link',
                    iconProps: {
                      iconName: 'Copy'
                    },
                    onClick: () => copyLink()
                  },
                  ...(extraMenuProps?.length ? extraMenuProps.map(item => item) : []),
                  {
                    key: 'ViewRelevantDocument',
                    text: 'View Related Children',
                    iconProps: {
                      iconName: 'ChangeEntitlements'
                    },
                    onClick: () => handleOpen()
                  }
                ]
              }
            : undefined
        }
        iconProps={{
          iconName
        }}
        classNameButton={`${styles.button}${isSplit ? ` ${styles.splitButton}` : ''}`}
        className={styles.button}
        split>
        Open
      </TheButton>
      {isEdit && (
        <ModalWithHeader header={`Related Documents`} isVisible={isEdit} dismiss={onDismiss}>
          <DocumentsCenter
            showEditAuditable={false}
            showEditDetails={true}
            isRelatedView={true}
            documentId={documentId}
            languageCode={languageCode}
            showHeader={false}
            frozen
          />
        </ModalWithHeader>
      )}
    </div>
  );
};
