import {Icon} from '@fluentui/react/lib/Icon';
import {useState} from 'react';

import styles from './Accordion.module.scss';

type Props = {
  header?: string;
  isOpen?: boolean;
  children?: JSX.Element | JSX.Element[] | string | string[];
  onToggle?: (isOpen: boolean) => void;
  customStyles?: {
    stylesHeader?: React.CSSProperties;
    stylesDescription?: React.CSSProperties;
  };
};

export const Accordion = ({
  header,
  isOpen: isOpenDefault,
  onToggle,
  children,
  customStyles
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault || false);
  const handleOpen = () => {
    if (onToggle) onToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.accordion}>
      <div className={styles.title} onClick={handleOpen} style={{...customStyles?.stylesHeader}}>
        <Icon
          iconName="ChevronDownMed"
          className={`${styles.chevronIcon} ${isOpen ? styles.isOpen : ''}`}
        />
        <span className={styles.titleText}>{header}</span>
      </div>
      <div
        className={`${styles.content} ${isOpen ? styles.contentOpen : ''}`}
        style={{...customStyles?.stylesDescription}}>
        <div className={`${styles.contentText} ${isOpen ? styles.contentTextOpen : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};
