import styles from './Arrow.module.scss';

interface IArrowProps {
  text?: string | null;
  primary?: boolean;
  url?: string;
  targetBlank?: boolean;
}

export const Arrow: React.FC<IArrowProps> = ({text, primary, url, targetBlank}) => {
  const inner = (
    <>
      <svg preserveAspectRatio="none" viewBox="0 0 10 30" xmlns="http://www.w3.org/2000/svg">
        <path strokeWidth="0" d="M0,0L10,15L0,30L10,30L10,0Z" />
      </svg>
      <div className={`${styles.body} ${styles.defaultBody} ${!url ? styles.noLink : ''}`}>
        {text}
      </div>
      <svg preserveAspectRatio="none" viewBox="0 0 10 30" xmlns="http://www.w3.org/2000/svg">
        <path strokeWidth="0" d="M0,0L10,15L0,30Z" />
      </svg>
    </>
  );
  const wrapperClassName = `${styles.arrow} ${primary ? styles.primary : styles.default} ${
    url ? styles.link : ''
  }`;
  return url ? (
    <a
      className={wrapperClassName}
      href={url}
      target={targetBlank ? '_blank' : undefined}
      rel={targetBlank ? 'noreferrer' : undefined}>
      {inner}
    </a>
  ) : (
    <div className={wrapperClassName}>{inner}</div>
  );
};
