import styles from '../../UnitManagementProcess.module.scss';
import {Dropdown, Spinner} from '@fluentui/react';
import {FC} from 'react';
import {IUnitDropdownProps} from './IUnitDropdownProps';
import {useUnitDropdown} from './useUnitDropdown';

export const UnitDropdown: FC<IUnitDropdownProps> = props => {
  const {disabled, defaultSelectedUnitId, onChange} = props;
  const {isLoading, onFocus, options} = useUnitDropdown(props);
  return isLoading ? (
    <Spinner />
  ) : (
    <Dropdown
      className={styles.regular}
      defaultSelectedKey={defaultSelectedUnitId}
      onChange={onChange}
      onFocus={onFocus}
      placeholder="Select an option"
      options={options}
      disabled={disabled}
    />
  );
};
