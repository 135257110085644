import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  Label,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Spinner,
  ValidationState
} from '@fluentui/react';
import {MgtPersonCard} from '@microsoft/mgt-react';

import styles from './PeoplePicker.module.scss';
import {usePeoplePicker} from './usePeoplePicker';
import {TheButton} from '../TheButton';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts'
};

type IPeoplePicker = {
  defaultSelectedPerson?: IPersonaProps[];
  defaultSelectedPersonId?: string;
  defaultSelectedPersonIds?: string[];
  onSelectPerson?: (person: IPersonaProps[] | undefined, itemId: number) => void;
  onChangeValidationStatus?: (status: boolean) => void;
  isDisabled?: boolean;
  isReadonly?: boolean;
  itemId?: number;
  itemLimit?: number;
  className?: string;
  showRemoved?: boolean;
};

MgtPersonCard.config.sections.profile = false;
MgtPersonCard.config.useContactApis = false;
MgtPersonCard.config.sections.files = false;
MgtPersonCard.config.sections.organization = true;
MgtPersonCard.config.sections.mailMessages = false;

export const PeoplePicker = ({
  defaultSelectedPerson,
  defaultSelectedPersonId,
  defaultSelectedPersonIds,
  onSelectPerson,
  isDisabled = false,
  isReadonly = false,
  itemId = 0,
  onChangeValidationStatus,
  itemLimit = 1,
  className,
  showRemoved
}: IPeoplePicker) => {
  const {defaultSelectedPersonResult, isLoading, selected, removed, onFilterChanged, onChange} =
    usePeoplePicker(
      onSelectPerson,
      itemId,
      defaultSelectedPerson,
      defaultSelectedPersonId,
      defaultSelectedPersonIds
    );

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > itemLimit) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isReadonly ? (
        defaultSelectedPersonResult.map(person => (
          <Persona
            key={person.id}
            text={person.text}
            secondaryText={person.secondaryText}
            size={PersonaSize.size24}
          />
        ))
      ) : (
        <>
          <NormalPeoplePicker
            key={'normal'}
            itemLimit={itemLimit}
            disabled={isDisabled || isReadonly}
            onResolveSuggestions={value => onFilterChanged(value)}
            onValidateInput={validateInput}
            getTextFromItem={persona => persona.text || ''}
            pickerSuggestionsProps={suggestionProps}
            pickerCalloutProps={{
              className: `${styles.peoplePicker}${className ? ` ${className}` : ''}`
            }}
            selectedItems={selected}
            className={`${styles.peoplePicker}${className ? ` ${className}` : ''}`}
            onChange={onChange}
          />
          {showRemoved && removed.length ? (
            <div className={styles.removedWrapper}>
              <Label>You have just removed: </Label>
              {removed.map(item => (
                <div key={item.id} className={styles.removedWrapper}>
                  <Persona
                    text={item.text}
                    secondaryText={item.secondaryText}
                    size={PersonaSize.size24}
                  />
                  <TheButton
                    isIcon
                    iconProps={{iconName: 'RevToggleKey'}}
                    onClick={() => onChange([...selected, item])}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
