import {IBasePickerSuggestionsProps, ITag} from '@fluentui/react';
import {useCallback, useMemo, useState} from 'react';

export const useProcessNavigatorInput = (
  processes: ITag[] | null | undefined,
  setValue: React.Dispatch<React.SetStateAction<ITag[] | undefined>>,
  defaultItems: ITag[] | undefined
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [editedIndex, setEditedIndex] = useState<number>();
  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = useCallback(() => {
    setTitle('');
    setUrl('');
    setEditedIndex(undefined);
    setIsOpen(false);
  }, [setIsOpen]);

  const [removed, setRemoved] = useState<ITag[]>([]);

  const isEditting = useMemo(() => editedIndex !== undefined, [editedIndex]);

  const setNewSelectedTags = useCallback(
    (tags: ITag[]) => {
      setValue(tags);
    },
    [setValue]
  );
  const onRemoveItems = useCallback(
    (removedItem: ITag) => {
      if (defaultItems) {
        const removedElement: ITag | undefined = defaultItems.find(
          item => item.key === removedItem.key
        );
        const newList = defaultItems.filter(item => item.key !== removedItem.key);
        if (removedElement) setRemoved([...removed, removedElement]);
        setValue(newList);
      }
    },
    [defaultItems, removed, setValue]
  );

  const onChange = useCallback(
    (items?: ITag[] | undefined) => {
      if (defaultItems && defaultItems.length > (items?.length || 0)) {
        const removedItem = defaultItems.find(
          selected => !items?.some(item => item.key === selected.key)
        );
        if (removedItem) setRemoved([...removed, removedItem]);
      } else {
        setRemoved(
          removed.filter(removedItem => !items?.some(item => item.key === removedItem.key))
        );
      }
      setValue(items || []);
    },
    [defaultItems, removed, setValue]
  );

  const suggestionsProps: IBasePickerSuggestionsProps = useMemo(
    () => ({
      suggestionsHeaderText: 'Suggested process',
      noResultsFoundText: 'No process found'
    }),
    []
  );

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || tagList.length === 0) {
      return false;
    }
    return tagList.some(compareTag => compareTag.key === tag.key);
  };

  const getTextFromItem = useCallback((item: ITag) => item.name, []);

  const filterSuggestedTags = useCallback(
    (filterText: string, tagList: ITag[] | undefined): ITag[] => {
      return filterText
        ? processes!.filter(
            tag =>
              tag.name?.toLowerCase().includes(filterText.toLowerCase()) &&
              !listContainsTagList(tag, tagList)
          )
        : [];
    },
    [processes]
  );

  const onTitleChange = useCallback(
    (e: any, value: string | undefined) => setTitle(value || ''),
    []
  );
  const onUrlChange = useCallback((e: any, value: string | undefined) => setUrl(value || ''), []);

  const editLink = useCallback(
    (item: ITag, index: number) => {
      setUrl(item.key.toString());
      setTitle(item.name);
      setEditedIndex(index);
      openModal();
    },
    [openModal]
  );

  const saveLink = useCallback(() => {
    setUrlError(!url);
    setTitleError(!title);
    if (!url || !title) {
      return;
    }
    const newItem = {
      key: url,
      name: title
    };
    let newTagsData = [];
    if (editedIndex !== undefined && defaultItems) {
      let currentItem = defaultItems[editedIndex];
      currentItem.key = newItem.key;
      currentItem.name = newItem.name;
      newTagsData = [...defaultItems];
    } else newTagsData = [...(defaultItems || []), newItem];
    setValue(newTagsData);
    setNewSelectedTags(newTagsData);
    closeModal();
  }, [url, title, defaultItems, editedIndex, setValue, setNewSelectedTags, closeModal]);

  return useMemo(
    () => ({
      filterSuggestedTags,
      getTextFromItem,
      suggestionsProps,
      onChange,
      isOpen,
      openModal,
      closeModal,
      onTitleChange,
      onUrlChange,
      saveLink,
      titleError,
      removed,
      onRemoveItems,
      setNewSelectedTags,
      urlError,
      editLink,
      url,
      title,
      isEditting
    }),
    [
      filterSuggestedTags,
      getTextFromItem,
      suggestionsProps,
      onChange,
      isOpen,
      openModal,
      closeModal,
      onTitleChange,
      onUrlChange,
      saveLink,
      titleError,
      removed,
      onRemoveItems,
      setNewSelectedTags,
      urlError,
      editLink,
      url,
      title,
      isEditting
    ]
  );
};
