import {createContext} from 'react';

import {Users} from '../store/Users';
import {ApiResponse} from '../services/ApiResponseType';
import {RolePermission} from '../models';

interface IMainStoreContext {
  users: Users;
  rolePermissions: {
    unitId: number;
    processId?: number;
    rolePermissions: ApiResponse<RolePermission[]>;
  }[];
}

export const MainStoreContext = createContext<IMainStoreContext>({
  users: new Users(),
  rolePermissions: []
});
