import {UnitDataDto} from '../services/generated';
import {Unit} from './Unit';

export class UnitsList extends Unit implements UnitDataDto {
  public nonProcessessCount: number | null;

  constructor(unit: UnitDataDto) {
    super(unit);
    this.nonProcessessCount = unit.nonProcessessCount;
  }
}
