import {
  BrokenLinkDto,
  BrokenLinkType,
  Links,
  MinimumProcessDto,
  UserDto
} from '../services/generated';
import {ProcessMinimum} from './ProcessMinimum';

export class BrokenLinks implements BrokenLinkDto {
  public id: number;
  public link: Links[] | null;
  public url: string | null;
  public type: BrokenLinkType;
  public process: MinimumProcessDto | null;
  public title: string | null;
  public working: boolean;
  public modifiedBy: UserDto | null;
  public modified: string | null;
  public processes: ProcessMinimum | null;

  public get typeString() {
    switch (this.type) {
      case BrokenLinkType.ExternalLink:
        return 'External Link';
      case BrokenLinkType.Document:
        return 'Document';
      case BrokenLinkType.ProcessDescription:
        return 'Process Description';
      default:
        return '';
    }
  }

  constructor(brokenLink: BrokenLinkDto) {
    this.id = brokenLink.id;
    this.link = brokenLink.link;
    this.url = brokenLink.url;
    this.type = brokenLink.type;
    this.processes = brokenLink.process ? new ProcessMinimum(brokenLink.process) : null;
    this.process = brokenLink.process;
    this.title = brokenLink.title;
    this.working = brokenLink.working;
    this.modified = brokenLink.modified;
    this.modifiedBy = brokenLink.modifiedBy;
  }
}
