import {useMemo} from 'react';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {ViewModel} from '../../models/ViewModel';
import {AdminService, UnitService, ViewDto, ViewService} from '../../services/generated';

export const useViews = () => {
  const requestWrapper = useApiService();

  const getViews = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ViewModel[]> => {
    return await requestWrapper(
      ViewService.getApiView(),
      result => result.map(item => new ViewModel(item)),
      log
    );
  };

  const createView = async (
    view: ViewDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View created successfully`
    }
  ): ApiResponsePending<ViewModel> => {
    return await requestWrapper(
      AdminService.postApiAdminView(view),
      result => new ViewModel(result),
      log
    );
  };

  const updateView = async (
    viewId: number,
    view: ViewDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View updated successfully`
    }
  ): ApiResponsePending<ViewModel> => {
    return await requestWrapper(
      AdminService.putApiAdminView(viewId, view),
      result => new ViewModel(result),
      log
    );
  };

  const deleteView = async (
    viewId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View deleted successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(AdminService.deleteApiAdminView(viewId), result => result, log);
  };

  const createUnitView = async (
    unitId: number,
    view: ViewDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View created successfully`
    }
  ): ApiResponsePending<ViewModel> => {
    return await requestWrapper(
      UnitService.postApiUnitManageView(unitId, view),
      result => new ViewModel(result),
      log
    );
  };

  const updateUnitView = async (
    unitId: number,
    viewId: number,
    view: ViewDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View updated successfully`
    }
  ): ApiResponsePending<ViewModel> => {
    return await requestWrapper(
      UnitService.putApiUnitManageView(unitId, viewId, view),
      result => new ViewModel(result),
      log
    );
  };

  const deleteUnitView = async (
    unitId: number,
    viewId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View deleted successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      UnitService.deleteApiUnitManageView(viewId, unitId.toString()),
      result => result,
      log
    );
  };

  const addToFavorite = async (
    viewId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(ViewService.postApiViewFavorite(viewId), result => result, log);
  };

  const deleteFavorite = async (
    viewId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(ViewService.deleteApiViewFavorite(viewId), result => result, log);
  };

  const updateDefaultView = async (
    view: ViewDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View updated successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(ViewService.putApiViewDefaultView(view), result => !!result, log);
  };

  const deleteDefaultView = async (
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `View updated successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(ViewService.deleteApiViewDefaultView(), result => !!result, log);
  };

  return useMemo(
    () => ({
      getViews,
      createView,
      updateView,
      deleteView,
      createUnitView,
      updateUnitView,
      deleteUnitView,
      addToFavorite,
      deleteFavorite,
      updateDefaultView,
      deleteDefaultView
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
