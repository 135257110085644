import {PermissionToCheck} from '../enums';
import {RolePermissionDto} from '../services/generated';

export class RolePermission implements RolePermissionDto {
  public readonly title: PermissionToCheck | null;
  public readonly accessLevel: number;

  constructor(permissions: RolePermissionDto) {
    this.title = permissions.title as PermissionToCheck;
    this.accessLevel = permissions.accessLevel;
  }
}
