import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useUnit} from '../../../../../../hooks/services/useUnit';
import {IDropdownOption} from '@fluentui/react';
import {IUnitDropdownBulkProps} from './IUnitDropdownBulkProps';
import {sortByText} from '../../../../../../helpers/arrayMehods';

export const useUnitDropdownBulk = ({
  masterIdsForEdit,
  model,
  onUnitsDownloaded
}: IUnitDropdownBulkProps) => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IDropdownOption<number>[]>([]);

  const {getUnitsImplementingProcesses} = useUnit();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getUnitsImplementingProcesses(unitId, masterIdsForEdit);
      if (response.result) {
        const allOptions: IDropdownOption[] = [];
        for (const key in response.result) {
          const units = response.result[key];
          for (const unit of units) {
            if (!allOptions.some(option => option.key === unit.unitId)) {
              allOptions.push({
                key: unit.unitId,
                text: unit.unitTitle || ''
              });
            }
          }
        }
        onUnitsDownloaded(response.result);
        setOptions(allOptions.sort(sortByText));
      }
      // const list: {[masterProcessId: number]: ParentProcess[]} = {};
      // for (const id of masterIdsForEdit) {
      //   const response = await getUnitsImplementingProcess(unitId, id);
      //   if (response.result) {
      //     list[id] = response.result;
      //     for (const unit of response.result) {
      //       if (!allOptions.some(option => option.key === unit.unitId)) {
      //         allOptions.push({
      //           key: unit.unitId,
      //           text: unit.unitTitle || ''
      //         });
      //       }
      //     }
      //   }
      // }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId, onUnitsDownloaded, getUnitsImplementingProcesses]);

  const onChange = useCallback(
    (
      event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<number> | undefined,
      index?: number | undefined
    ) => {
      const parentUnitId = option?.key as number;
      model.parentUnitId = parentUnitId;
    },
    [model]
  );

  return useMemo(() => ({isLoading, onChange, options}), [isLoading, onChange, options]);
};
