import {useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../../common/HeadingWithDecoration';
import {FormFooter} from '../../../common/FormFooter';
import {useUnitManagementSubscriptions} from './useUnitManagementSubscriptions';
import {MiddleTopBarUnitManage} from '../../../common/MiddleTopBarUnitManage';
import {IPersonaProps, Spinner, Text} from '@fluentui/react';
import {EnhancedDetailsList, OtherColumn, UserDtoColumn} from '../../../common/EnhancedDetailsList';
import {GroupedProcessSubscription} from '../../../../models/ProcessSubscription';
import {IEnhancedColumn} from '../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {PeoplePicker} from '../../../common/PeoplePicker';
import styles from './UnitManagementSubscriptions.module.scss';
import {useUnit} from '../../../../hooks/services/useUnit';
import {Helmet} from "react-helmet";
import {Unit} from '../../../../models';

export const UnitManagementSubscriptions = () => {
  const {groupedUnitProcessesSubscriptions, unitId, isLoading, saveSubscriptions} =
    useUnitManagementSubscriptions();

  const [modifiedGroupedSubscriptions, setModifiedGroupedProcessSubscriptions] = useState<
    GroupedProcessSubscription[]
  >([]);
  const {getUnit} = useUnit();

  const [isResetting, setIsReseting] = useState<boolean>(false);
  const [unitTitle, setUnitTitle] = useState<string>();

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      const response = await getUnit(Number(unitId));
      if (response && response.result) {
        setUnitTitle(prepareUnitTitle(response.result));
      }
    })();
  }, [getUnit, unitId]);

  const cloneGroupedSubscriptions = useCallback((sourceArray: GroupedProcessSubscription[]) => {
    return sourceArray.map(el => ({
      id: el.id,
      isDeleted: el.isDeleted,
      link: el.link,
      processId: el.processId,
      processOwnerAzureId: el.processOwnerAzureId,
      processTitle: el.processTitle,
      processOwner: el.processOwner,
      subscriptionUsers: el.subscriptionUsers.map(user => ({
        subscriptionId: user.subscriptionId,
        userAzureId: user.userAzureId
      }))
    }));
  }, []);

  useEffect(() => {
    if (groupedUnitProcessesSubscriptions.length)
      setModifiedGroupedProcessSubscriptions(
        cloneGroupedSubscriptions(groupedUnitProcessesSubscriptions)
      );
  }, [groupedUnitProcessesSubscriptions, cloneGroupedSubscriptions]);

  const onSubscriptionChange = useCallback(
    (people: IPersonaProps[] | undefined, groupedSubscription: GroupedProcessSubscription) => {
      if ((!people || people.length === 0) && groupedSubscription.subscriptionUsers.length !== 0) {
        groupedSubscription.subscriptionUsers = [];
      } else if (people) {
        const selectedIds = people.map(p => p.id ?? '');
        groupedSubscription.subscriptionUsers = groupedSubscription.subscriptionUsers.filter(g =>
          selectedIds.includes(g.userAzureId)
        );
        people.forEach(person => {
          const shouldBeAdded =
            groupedSubscription.subscriptionUsers.findIndex(
              user => user.userAzureId === person.id
            ) < 0;
          if (shouldBeAdded && person.id)
            groupedSubscription.subscriptionUsers.push({userAzureId: person.id, subscriptionId: 0});
        });
      }
    },
    []
  );

  const columns = useMemo(() => {
    const cols: IEnhancedColumn<GroupedProcessSubscription>[] = [
      {
        key: 'processId',
        name: 'Id',
        maxWidth: 90,
        onRender: item => <Text className={`${styles.title}`}>{item.processId}</Text>
      },
      {
        key: 'processTitle',
        name: 'Process Title',
        flexGrow: 2,
        maxWidth: 350,
        onRender: item => <Text className={`${styles.title}`}>{item.processTitle}</Text>
      },
      {
        key: 'processOwner',
        name: 'Process Owner',
        maxWidth: 200,
        flexGrow: 5,
        data: UserDtoColumn,
        onRender: item => (
          <PeoplePicker defaultSelectedPersonId={item.processOwnerAzureId} isReadonly />
        )
      },
      {
        key: 'subscriptionUsers',
        name: 'Subscribers',
        maxWidth: 500,
        data: OtherColumn,
        onRender: item => {
          return (
            <PeoplePicker
              itemLimit={50}
              defaultSelectedPersonIds={item.subscriptionUsers.map(u => u.userAzureId)}
              onSelectPerson={people => {
                onSubscriptionChange(people, item);
              }}
            />
          );
        }
      }
    ];
    return cols;
  }, [onSubscriptionChange]);

  const onSubscriptionsSave = useCallback(() => {
    saveSubscriptions(modifiedGroupedSubscriptions);
  }, [modifiedGroupedSubscriptions, saveSubscriptions]);

  const onSubscriptionsCancel = useCallback(() => {
    setIsReseting(true);
  }, []);

  useEffect(() => {
    if (isResetting) {
      setModifiedGroupedProcessSubscriptions(
        cloneGroupedSubscriptions(groupedUnitProcessesSubscriptions)
      );
      setIsReseting(false);
    }
  }, [isResetting, cloneGroupedSubscriptions, groupedUnitProcessesSubscriptions]);
  return (
    <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
      {isLoading || isResetting ? (
        <Spinner />
      ) : (
        <>
          <MiddleTopBarUnitManage
            notUseHelmet
            unitId={unitId ? Number.parseInt(unitId) : undefined}
            active={'subscriptions'}
          />
          <HeadingWithDecoration text="Subscriptions" />
          {unitTitle && (
            <Helmet>
              <title>IMS {unitTitle} Subscriptions</title>
            </Helmet>
          )}
          <EnhancedDetailsList<
            GroupedProcessSubscription,
            GroupedProcessSubscription,
            GroupedProcessSubscription
          >
            hideSearch
            pageSize={50}
            columns={columns}
            items={modifiedGroupedSubscriptions}
          />

          <FormFooter
            isDisabled={isLoading}
            onSubmit={onSubscriptionsSave}
            onCancel={onSubscriptionsCancel}
          />
        </>
      )}
    </main>
  );
};
