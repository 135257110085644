import {Icon, Spinner} from '@fluentui/react';
import {useContext, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {MiddleTopBarUnitManage} from '../../common/MiddleTopBarUnitManage';
import styles from './UnitManagement.module.scss';
import {useUnitManagement} from './useUnitManagement';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';

interface IData {
  id: number;
  title: string;
  number?: number | null | undefined;
  icon: string;
  link?: string;
}

export const UnitManagement = () => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const {globalSettings} = useContext(GlobalSettingsContext);
  const {unit, isLoading} = useUnitManagement(unitId);
  const textConst = ' dashboard';

  const unitApiCode = unit?.apiCode;
  const unitDetails = unit?.result;
  const imsReport = globalSettings.settings.imsReports;
  const uniDataExists = unitApiCode === null && unitDetails;
  const data: IData[] = useMemo(
    () => [
      {
        id: 1,
        title: 'Implemented Processes',
        number: unitDetails?.processesCount,
        icon: 'DocumentManagement'
      },
      {
        id: 2,
        title: 'Implemented NonProcesses',
        number: unitDetails?.nonProcessessCount,
        icon: 'TextDocument'
      },
      {
        id: 3,
        title: 'IMS Reports',
        icon: 'ReportDocument',
        link: imsReport
      }
    ],
    [unitDetails, imsReport]
  );

  return (
    <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
      {
        isLoading ? (
          <Spinner />
        ) : uniDataExists ? (
          <>
            <MiddleTopBarUnitManage unitId={unitId} active={'dashboard'} />
            {unitDetails.title && <HeadingWithDecoration text={unitDetails?.title + textConst} />}
            <SimpleTilesWithInfo data={data} />
          </>
        ) : null //<ApiErrorStatus message={unitsApiMsg} />
      }
    </main>
  );
};

interface ITilesData {
  data: IData[];
}

const openReportPage = (item: string | undefined) => {
  if (item === undefined) {
    return;
  }
  var result = item.includes('https') ? item : `https://${item}`;
  window.open(result, 'blank');
};

const SimpleTilesWithInfo = ({data}: ITilesData) => {
  return (
    <div className={styles.tilesWrapper}>
      {data.map((item: IData) => (
        <div
          onClick={() => openReportPage(item?.link)}
          className={`${styles.tile} ${item.link ? styles.link : ''}`}
          key={item.id + item.title}>
          <div className={styles.left}>
            <Icon iconName={item.icon} />
          </div>
          <div className={styles.right}>
            <div className={styles.tileTitle}>{item.title}</div>
            {item.number ? <div className={styles.tileContent}>{item.number}</div> : ''}
          </div>
        </div>
      ))}
    </div>
  );
};
