import styles from './DocumentAdditionalAttributes.module.scss';
import {ITag, ITagItemProps, Label, TagItem, TagPicker, TextField} from '@fluentui/react';

import {TheButton} from '../TheButton';
import {useCallback} from 'react';
import { useDocumentAdditionalAttributes } from './useDocumentAdditionalAttributes';

type IDocumentKeyWords = {
  defaultItems: ITag[] | undefined;
  setValue: React.Dispatch<React.SetStateAction<ITag[] | undefined>>;
  showRemoved?: boolean;
  disabled: boolean;
};

export const DocumentKeyWords = ({
  defaultItems,
  setValue,
  showRemoved,
  disabled
}: IDocumentKeyWords) => {
  const {
    getTextFromItem,
    onChange,
    onKeyWordChange,
    saveLink,
    keyWord,
    removed,
    onRemoveItems,
  } = useDocumentAdditionalAttributes(setValue, defaultItems);

  const onRenderItem = useCallback(
    (props: ITagItemProps, index: number): JSX.Element => {
      return (
        <div draggable key={props.item.name}>
          <TagItem
            onRemoveItem={() => onRemoveItems(props.item)}
            item={props.item}
            index={index}
            title={props.item.name}>
            {props.item.name}
          </TagItem>
        </div>
      );
    },
    [onRemoveItems]
  );

  return (
    <>
      <Label>Add Keyword</Label>
      <div className={styles.inputWrapper}>
      <div className={styles.addFieldContainer}>
        <TextField className={styles.label}
          disabled={disabled}
          onChange={onKeyWordChange}
          value={keyWord}
        />
        <TheButton disabled={!keyWord.length} onClick={saveLink} primary>
          Add
        </TheButton>
        </div>
        <div className={styles.wrapperForRemoved}>
        <Label>Current Keywords</Label>
          <TagPicker
            disabled={disabled}
            removeButtonAriaLabel="Remove"
            onRenderItem={piece => onRenderItem(piece, piece.index)}
            selectionAriaLabel="Selected process"
            onResolveSuggestions={() => []}
            getTextFromItem={getTextFromItem}
            itemLimit={20}
            pickerCalloutProps={{doNotLayer: true}}
            selectedItems={defaultItems}
            onChange={onChange}
          />
          {defaultItems && showRemoved && removed.length ? (
            <div className={styles.removedWrapper}>
              <Label>You have just removed: </Label>
              {removed.map(item => (
                <div key={`removed${item.key}`} className={styles.removedWrapper}>
                  {item.name}
                  <TheButton
                    isIcon
                    disabled={disabled}
                    iconProps={{iconName: 'RevToggleKey'}}
                    onClick={() => onChange([...defaultItems, item])}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
