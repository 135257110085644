import {actions, PermissionToCheck} from '../enums';
import {RolePermission} from '../models';
import hasPermission from '../permissions/Permissions';

export const checkPermissions = (
  elementToCheck: PermissionToCheck,
  permisssionLevel: actions,
  permissions: RolePermission[] | undefined | null
) => {
  const htmlElement = permissions && permissions.find(item => item.title === elementToCheck);
  if (htmlElement) {
    return permissions ? hasPermission(htmlElement, permisssionLevel) : false;
  } else {
    return false;
  }
};
