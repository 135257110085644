import {BatchDto, BatchStatus, Links, UserDto} from '../services/generated';

export class Batch implements BatchDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public created: string;
  public modified: string | null;
  public createdBy: UserDto | null;
  public retried: boolean;
  public status: BatchStatus;
  public reason: Array<string> | null;

  public get statusString() {
    switch (this.status) {
      case BatchStatus.Completed:
        return 'Completed';
      case BatchStatus.Failed:
        return 'Failed';
      case BatchStatus.New:
        return 'New';
      case BatchStatus.Pending:
        return 'Pending';
    }
    return '';
  }

  constructor(item: BatchDto) {
    this.id = item.id;
    this.link = item.link;
    this.created = item.created;
    this.modified = item.modified;
    this.createdBy = item.createdBy;
    this.retried = item.retried;
    this.status = item.status;
    this.reason = item.reason;
  }
}
