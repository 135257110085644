/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityWithProcessDto } from '../models/ActivityWithProcessDto';
import type { SaveActivityDto } from '../models/SaveActivityDto';
import type { UpdateActivityDto } from '../models/UpdateActivityDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActivityService {

  /**
   * @param requestBody 
   * @returns SaveActivityDto Success
   * @throws ApiError
   */
  public static postApiActivityActivityInput(
requestBody?: SaveActivityDto,
): CancelablePromise<SaveActivityDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Activity/activityInput',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody 
   * @returns SaveActivityDto Success
   * @throws ApiError
   */
  public static postApiActivityActivityOutput(
requestBody?: SaveActivityDto,
): CancelablePromise<SaveActivityDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Activity/activityOutput',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param unitId 
   * @returns ActivityWithProcessDto Success
   * @throws ApiError
   */
  public static getApiActivityActivityInput(
unitId: number,
): CancelablePromise<Array<ActivityWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Activity/{unitId}/activityInput',
      path: {
        'unitId': unitId,
      },
    });
  }

  /**
   * @param unitId 
   * @returns ActivityWithProcessDto Success
   * @throws ApiError
   */
  public static getApiActivityActivityOutput(
unitId: number,
): CancelablePromise<Array<ActivityWithProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Activity/{unitId}/activityOutput',
      path: {
        'unitId': unitId,
      },
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiActivityActivityOutput(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Activity/activityOutput/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns SaveActivityDto Success
   * @throws ApiError
   */
  public static putApiActivityActivityOutput(
id: number,
requestBody?: UpdateActivityDto,
): CancelablePromise<SaveActivityDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Activity/activityOutput/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiActivityActivityInput(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Activity/activityInput/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns SaveActivityDto Success
   * @throws ApiError
   */
  public static putApiActivityActivityInput(
id: number,
requestBody?: UpdateActivityDto,
): CancelablePromise<SaveActivityDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Activity/activityInput/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
