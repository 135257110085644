/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = New
 *
 * 2 = Pending
 *
 * 3 = Completed
 *
 * 4 = Failed
 */
export enum BatchStatus {
  New = 1,
  Pending = 2,
  Completed = 3,
  Failed = 4,
}
