import { Images } from '../../../assets/img';
import styles from './DecoratorCircle.module.scss';

interface IDecoratorCircle {
  image: string;
}

export const DecoratorCircle = (props: IDecoratorCircle) => {
  const { image } = props;
  return (
    <div className={styles.outerCircle}>
      <img src={Images[image]} alt="decorator" />
    </div>
  );
};
