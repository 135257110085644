import {FC} from 'react';
import {IDocumentsCenterProps} from './IDocumentsCenterProps';
import {MiddleTopBarUnitManage} from '../../common/MiddleTopBarUnitManage';
import {DocumentsCenter} from './DocumentsCenter';

export const DocumentsCenterUnit: FC<IDocumentsCenterProps> = ({unitId, showEditAuditable, showEditDetails}) => (
  <main className={`noPaddingTop`}>
    <MiddleTopBarUnitManage notUseHelmet unitId={unitId} active={'documentsCenter'} />
    <DocumentsCenter showEditAuditable={showEditAuditable} showEditDetails={showEditDetails} unitId={unitId} showHeader frozen />
  </main>
);
