import {useParams} from 'react-router-dom';
import {useContext} from 'react';
import {PortalDropdownsContext, UserContext} from '../../../providers';
import {useMemo} from 'react';

export const useNavigation = () => {
  const {isUserAdmin, logout} = useContext(UserContext);
  const {favUnit} = useContext(PortalDropdownsContext);
  const {unitId} = useParams();

  return useMemo(
    () => ({
      isUserAdmin,
      isHomeUnitSelected: Boolean(favUnit),
      isHomeUnitCurrent: favUnit === unitId,
      favUnitUrl: `/unit/${favUnit}`,
      logout
    }),
    [favUnit, isUserAdmin, unitId, logout]
  );
};
