import {Label, Toggle, TooltipHost} from '@fluentui/react';
import {useContext, FC} from 'react';
import {DictionariesContext} from '../../../providers';
import {DictionaryMultiselectDropdown} from '../DictionaryDropdown/DictionaryMultiselectDropdown';
import styles from './DefaultFilters.module.scss';
import {TheButton} from '../TheButton';

export const DefaultFilters: FC = () => {
  const {
    businessLine,
    setBusinessLine,
    optionsBusinessLine,
    func,
    setFunction,
    optionsFunction,
    projectSizeCategory,
    setProjectSizeCategory,
    optionsProjectSizeCategory,
    industry,
    setIndustry,
    optionsIndustry,
    speciality,
    setSpeciality,
    optionsSpeciality,
    excludeItemsApplicableToAll,
    setExcludeItemsApplicableToAll,
    hasFilters,
    clearFilters,
    clearCounter,
    saveAsDefault,
    hasDefaultBeenChanged
  } = useContext(DictionariesContext);
  return (
    <div key={clearCounter} className={styles.wrapper}>
      <span className={styles.filtersLabel}>Filters</span>
      <div>
        <DictionaryMultiselectDropdown
          className={styles.dropdown}
          defaultValues={func}
          options={optionsFunction}
          onChange={setFunction}
          checkSelected={true}
          placeholder="Function"
        />
      </div>
      <DictionaryMultiselectDropdown
        className={`${styles.dropdown} ${styles.fitPlaceholder}`}
        defaultValues={projectSizeCategory}
        options={optionsProjectSizeCategory}
        onChange={setProjectSizeCategory}
        checkSelected={true}
        placeholder="Project Size Category"
      />
      <div>
        <DictionaryMultiselectDropdown
          className={styles.dropdown}
          defaultValues={businessLine}
          options={optionsBusinessLine}
          onChange={setBusinessLine}
          checkSelected={true}
          placeholder="Business Line"
        />
      </div>
      <div className={styles.dropdownWithTooltip}>
        <DictionaryMultiselectDropdown
          className={styles.dropdown}
          defaultValues={industry}
          options={optionsIndustry}
          onChange={setIndustry}
          checkSelected={true}
          placeholder="Industry"
        />
        {/* <div className={styles.tooltip}>
          <InfoTooltip content="Industry usage, table to be developed" />
        </div> */}
      </div>
      <DictionaryMultiselectDropdown
        className={styles.dropdown}
        defaultValues={speciality}
        options={optionsSpeciality}
        onChange={setSpeciality}
        checkSelected={true}
        placeholder="Speciality"
      />
      <div className={styles.toggleGroup}>
        <Label className={styles.label}>Exclude items applicable to all</Label>
        <Toggle
          className={styles.toggle}
          defaultChecked={excludeItemsApplicableToAll}
          onChange={(event, checked) => setExcludeItemsApplicableToAll(!!checked)}
        />
      </div>
      <div className={styles.button}>
        <TooltipHost content="Return to selected view">
          <TheButton
            iconProps={{
              iconName: 'ClearFilter'
            }}
            disabled={!hasFilters}
            onClick={clearFilters}>
            Clear Filters
          </TheButton>
        </TooltipHost>
      </div>
      <div className={styles.button}>
        <TheButton
          iconProps={{
            iconName: 'FavoriteStar'
          }}
          disabled={!hasDefaultBeenChanged}
          onClick={saveAsDefault}>
          {'Save as Default'.replace(' ', '\u00A0')}
        </TheButton>
      </div>
    </div>
  );
};
