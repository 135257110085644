/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {AvailableProcessDto} from '../models/AvailableProcessDto';
import type {DocumentUsedDto} from '../models/DocumentUsedDto';
import type {ErrorDto} from '../models/ErrorDto';
import type {ManageProcessDetailsDto} from '../models/ManageProcessDetailsDto';
import type {ManageProcessDto} from '../models/ManageProcessDto';
import type {ParentProcessDto} from '../models/ParentProcessDto';
import type {ProcessDto} from '../models/ProcessDto';
import type {ProcessFlatStructureDto} from '../models/ProcessFlatStructureDto';
import type {ProcessSubscriptionDto} from '../models/ProcessSubscriptionDto';
import type {ProcessTreeStructureDto} from '../models/ProcessTreeStructureDto';
import type {UnitDataDto} from '../models/UnitDataDto';
import type {UnitDto} from '../models/UnitDto';
import type {UnitProcessNavigatorDto} from '../models/UnitProcessNavigatorDto';
import type {UnitsProcessDto} from '../models/UnitsProcessDto';
import type {UserDto} from '../models/UserDto';
import type {ViewDto} from '../models/ViewDto';
import type {ViewProcessCommentDto} from '../models/ViewProcessCommentDto';
import type {ViewUnitProcessNavigatorDto} from '../models/ViewUnitProcessNavigatorDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {UpdateDocumentAuditableDto} from '../models/UpdateDocumentAuditableDto';

export class UnitService {
  /**
   * @param id
   * @returns AvailableProcessDto Success
   * @throws ApiError
   */
  public static getApiUnitManageAdminProcess(
    id: string
  ): CancelablePromise<Array<AvailableProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/manage/admin/process',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns AvailableProcessDto Success
   * @throws ApiError
   */
  public static getApiFullUnitPath(id: number): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/fullUnitPath',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static postApiUnitManageView(
    id: number,
    requestBody?: ViewDto
  ): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Unit/{id}/manage/view',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param viewId
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiUnitManageView(viewId: number, id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Unit/{id}/manage/view/{viewId}',
      path: {
        viewId: viewId,
        id: id
      }
    });
  }

  /**
   * @param id
   * @param viewId
   * @param requestBody
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static putApiUnitManageView(
    id: number,
    viewId: number,
    requestBody?: ViewDto
  ): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}/manage/view/{viewId}',
      path: {
        id: id,
        viewId: viewId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns ProcessSubscriptionDto Success
   * @throws ApiError
   */
  public static getApiUnitManageSubscriptions(
    id: number
  ): CancelablePromise<Array<ProcessSubscriptionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/manage/subscriptions',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiUnitManageSubscriptions(
    id: number,
    requestBody?: Array<ProcessSubscriptionDto>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Unit/{id}/manage/subscriptions',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @param sendNotification
   * @param requestBody
   * @returns ErrorDto Success
   * @throws ApiError
   */
  public static putApiUnitManageProcess(
    id: number,
    sendNotification: boolean = true,
    requestBody?: Array<ManageProcessDto>
  ): CancelablePromise<Array<ErrorDto>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}/manage/process',
      path: {
        id: id
      },
      query: {
        sendNotification: sendNotification
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns AvailableProcessDto Success
   * @throws ApiError
   */
  public static getApiUnitManageProcess(id: number): CancelablePromise<Array<AvailableProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/manage/process',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns UnitDataDto Success
   * @throws ApiError
   */
  public static getApiUnitManage(id: number): CancelablePromise<UnitDataDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/manage',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ViewProcessCommentDto Success
   * @throws ApiError
   */
  public static getApiUnitComments(id: number): CancelablePromise<Array<ViewProcessCommentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/comments',
      path: {
        id: id
      }
    });
  }

  /**
   * @param unitId
   * @param masterProcessId
   * @returns ParentProcessDto Success
   * @throws ApiError
   */
  public static getApiUnitMasterProcess(
    unitId: number,
    masterProcessId: number
  ): CancelablePromise<Array<ParentProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{unitId}/masterProcess/{masterProcessId}',
      path: {
        unitId: unitId,
        masterProcessId: masterProcessId
      }
    });
  }

  /**
   * @param unitId
   * @param requestBody
   * @returns ParentProcessDto Success
   * @throws ApiError
   */
  public static postApiUnitMasterProcess(
    unitId: number,
    requestBody?: Array<number>
  ): CancelablePromise<Record<string, Array<ParentProcessDto>>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Unit/{unitId}/masterProcess',
      path: {
        unitId: unitId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param unitId
   * @returns DocumentUsedDto Success
   * @throws ApiError
   */
  public static getApiUnitDocument(unitId: number): CancelablePromise<Array<DocumentUsedDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{unitId}/Document',
      path: {
        unitId: unitId
      }
    });
  }

  /**
   * @param unitId
   * @returns DocumentUsedDto Success
   * @throws ApiError
   */
  public static getApiUnitDocumentCenter(
    unitId: number
  ): CancelablePromise<Array<DocumentUsedDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{unitId}/DocumentCenter',
      path: {
        unitId: unitId
      }
    });
  }

  /**
   * @param id
   * @returns ProcessDto Success
   * @throws ApiError
   */
  public static getApiUnitDraft(id: number): CancelablePromise<Array<ProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/Draft',
      path: {
        id: id
      }
    });
  }

  /**
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static getApiUnit(): CancelablePromise<Array<UnitDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit'
    });
  }

  /**
   * @param id
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static getApiUnit1(id: number): CancelablePromise<UnitDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns UnitDto Success
   * @throws ApiError
   */
  public static putApiUnit(id: number, requestBody?: UnitDto): CancelablePromise<UnitDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns UserDto Success
   * @throws ApiError
   */
  public static getApiUnitDefaultProcessOwner(id: number): CancelablePromise<UserDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/defaultProcessOwner',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiUnitDefaultProcessOwner(
    id: number,
    requestBody?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}/defaultProcessOwner',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns UserDto Success
   * @throws ApiError
   */
  public static getApiUnitOwner(id: number): CancelablePromise<Array<UserDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/owner',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiUnitOwner(id: number, requestBody?: Array<string>): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}/owner',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns ViewUnitProcessNavigatorDto Success
   * @throws ApiError
   */
  public static getApiUnitProcessNavigator(
    id: number
  ): CancelablePromise<Array<ViewUnitProcessNavigatorDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/processNavigator',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ViewUnitProcessNavigatorDto Success
   * @throws ApiError
   */
  public static getApiUnitManageProcessNavigator(
    id: number
  ): CancelablePromise<Array<ViewUnitProcessNavigatorDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/manage/processNavigator',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiUnitManageProcessNavigator(
    id: number,
    requestBody?: Array<UnitProcessNavigatorDto>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{id}/manage/processNavigator',
      path: {
        id: id
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param id
   * @returns UnitsProcessDto Success
   * @throws ApiError
   */
  public static getApiUnitProcess(id: number): CancelablePromise<Array<UnitsProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/Process',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ManageProcessDetailsDto Success
   * @throws ApiError
   */
  public static getApiUnitProcessesDetails(
    id: number
  ): CancelablePromise<Array<ManageProcessDetailsDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/processDetails',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ProcessFlatStructureDto Success
   * @throws ApiError
   */
  public static getApiUnitProcessFlat(
    id: number
  ): CancelablePromise<Array<ProcessFlatStructureDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/ProcessFlat',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ProcessFlatStructureDto Success
   * @throws ApiError
   */
  public static getApiUnitProcessFlatStructure(
    id: number
  ): CancelablePromise<Array<ProcessFlatStructureDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/ProcessFlatStructure',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns ProcessTreeStructureDto Success
   * @throws ApiError
   */
  public static getApiUnitProcessMenu(
    id: number
  ): CancelablePromise<Array<ProcessTreeStructureDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/ProcessMenu',
      path: {
        id: id
      }
    });
  }

  /**
   * @param id
   * @returns DocumentUsedDto Success
   * @throws ApiError
   */
  public static getApiUnitRecentlyAddedDocuments(
    id: number
  ): CancelablePromise<Array<DocumentUsedDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Unit/{id}/recentlyAddedDocuments',
      path: {
        id: id
      }
    });
  }

  /**
   * @param unitId
   * @param requestBody
   * @returns Success
   * @throws ApiError
   */
  public static putApiUnitManageAuditable(
    unitId: number,
    requestBody?: UpdateDocumentAuditableDto
  ): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Unit/{unitId}/auditable',
      path: {
        unitId: unitId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }
}
