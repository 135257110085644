import {createContext} from 'react';

import {Unit} from '../models';

interface IPortalContext {
  portalStructureData: Unit[];
  favUnit: string | undefined;
  setFavUnit: (favUnit: string | undefined) => void;
  isLoading: boolean;
}

export const PortalDropdownsContext = createContext<IPortalContext>({
  portalStructureData: [],
  favUnit: undefined,
  setFavUnit: () => {},
  isLoading: true
});
