import styles from './MiddleTopBar.module.scss';
import {FC, PropsWithChildren, useMemo, useContext} from 'react';
import {IMiddleTopBarProps} from './IMiddleTopBarProps';
import {MiddleTopBarWrapper} from '../MiddleTopBarWrapper';
import {useNavigate} from 'react-router-dom';
import {DictionariesContext} from '../../../providers';
import { Icon } from '@fluentui/react';

export const MiddleTopBar: FC<PropsWithChildren<IMiddleTopBarProps>> = ({
  children,
  unitId,
  farItems,
  hideFilters
}) => {
  const navigate = useNavigate();
  const {units} = useContext(DictionariesContext);

  const currentUnits = useMemo(() => {
    if (!units.length || !unitId) return null;
    const unit = units.find(item => item.id === unitId);
    if (!unit) return [];
    else if (!unit.parentId) return [unit.title || ''];
    else {
      const parentUnit = units.find(item => item.id === unit.parentId);
      if (parentUnit) return [parentUnit.title || '', unit.title || ''];
      else return [unit.title || ''];
    }
  }, [unitId, units]);

  return (
    <MiddleTopBarWrapper
      farItems={farItems}
      li={
        <>
          <li>
          <Icon
              className={styles.homeIcon}
              iconName="Home"
              onClick={() => navigate(unitId ? `/unit/${unitId}` : '/')}
            />
          </li>
          {children}
        </>
      }
      hideFilters={hideFilters}>
      {(currentUnits ?? []).map((item, index) => (
        <div
          className={`${styles.place} ${index + 1 === currentUnits?.length ? styles.last : ''}`}
          key={item}>
          {item}
        </div>
      ))}
    </MiddleTopBarWrapper>
  );
};
