/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Document
 *
 * 1 = DocumentLink
 * 
 * 2 = DynamicLink
 */
export enum DocumentContentType {
  Document = 0,
  DocumentLink = 1,
  DynamicLink = 2,
}
