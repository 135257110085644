import {TextField} from '@fluentui/react';
import {FC} from 'react';

interface IActivityDiagramProps {
  isEdit: boolean;
  imageUrl: string;
  setImageUrl: (url: string) => void;
}

export const ActivityDiagram: FC<IActivityDiagramProps> = ({isEdit, imageUrl, setImageUrl}) => {
  return (
    <>
      {isEdit ? (
        <div>
          <TextField
            label="Image URL"
            value={imageUrl}
            onChange={(e, value) => setImageUrl(value || '')}
          />
        </div>
      ) : null}{' '}
      {imageUrl ? <img src={imageUrl} alt="Activity diagram" /> : null}
    </>
  );
};
