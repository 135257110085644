import {ApiResponsePending} from '../../services/ApiResponseType';
import {
  DictonaryService,
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../../services/generated';
import {LogMessageType} from '../../services/LogMessagesType';
import {useApiService} from '../../services/helpers';
import {useMemo} from 'react';
import {UnitBusinessLine} from '../../models';
import { RefreshDictonariesDto } from '../../services/generated/models/RefreshDictonariesDto';

export const useDictionary = () => {
  const requestWrapper = useApiService();
  const getFunction = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<FunctionDto[]> =>
    requestWrapper(DictonaryService.getApiDictonaryFunction(), result => result, log);
  const getBusinessLine = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<BusinessLineDto[]> =>
    requestWrapper(
      DictonaryService.getApiDictonaryBusinessLine(),
      result => result.map(item => new UnitBusinessLine(item)),
      log
    );
  const getProjectSizeCategory = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProjectSizeCategoryDto[]> =>
    requestWrapper(DictonaryService.getApiDictonaryProjectSizeCategory(), result => result, log);
  const getIndustry = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<IndustryDto[]> =>
    requestWrapper(DictonaryService.getApiDictonaryIndustry(), result => result, log);
  const getSpeciality = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SpecialityDto[]> =>
    requestWrapper(DictonaryService.getApiDictonarySpeciality(), result => result, log);


    const refreshDictonaries = (
      log: LogMessageType = {
        error: true
      }
    ): ApiResponsePending<RefreshDictonariesDto> =>
      requestWrapper(DictonaryService.refreshApiDictonary(), result => result, log);

  return useMemo(
    () => ({
      getFunction,
      getBusinessLine,
      getProjectSizeCategory,
      getIndustry,
      refreshDictonaries,
      getSpeciality
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
