import {Links, ProcessDtoUsedInLibraries} from '../services/generated';

export class ProcessUsedInLibraries implements ProcessDtoUsedInLibraries {
  public id: number;
  public link: Links[] | null;
  public unitId: number;
  public title: string | null;

  public get url() {
    return `/unit/${this.unitId}/process/${this.id}`;
  }

  constructor(process: ProcessDtoUsedInLibraries) {
    this.id = process.id;
    this.link = process.link;
    this.unitId = process.unitId;
    this.title = process.title;
  }
}
