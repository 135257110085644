import {createContext} from 'react';
import {Process} from '../models';

export interface IProcessContext {
  process: Process | null;
  isLoading: boolean;
  setProcess: (process: Process | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  getProcess: (processId: number, refresh?: boolean) => Promise<void>;
}

export const ProcessContext = createContext<IProcessContext>({
  process: null,
  isLoading: false,
  setProcess: () => {},
  setIsLoading: () => {},
  getProcess: async () => {}
});
