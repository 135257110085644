import {useState} from 'react';
import {TheButton} from '../TheButton';
import {BaseItem} from './IEnhancedDetailsList';

interface IEnhancedAddButtonProps<Item extends BaseItem> {
  item: Item;
  addedIds: number[];
  addDocument: (item: Item) => void;
  disabled?: boolean;
}

export const EnhancedAddButton = <Item,>({
  item,
  addedIds,
  disabled,
  addDocument
}: IEnhancedAddButtonProps<Item & BaseItem>) => {
  const [clicked, setClicked] = useState(false);
  const alreadyAdded = clicked || addedIds.some(id => id === item.id);
  return (
    <TheButton
      primary
      onClick={() => {
        addDocument(item);
        setClicked(true);
      }}
      style={{minWidth: '90px'}}
      disabled={alreadyAdded || disabled}>
      {alreadyAdded ? 'Added' : 'Add'}
    </TheButton>
  );
};
