import {useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {TreeBuilder} from '../../common/TreeBuilder';
import {TreeUnitsModel} from '../UnitAssignment/TreeUnitsModel';
import {ITreeColumn} from '../../common/TreeBuilder/TreeColumn';
import {useService} from '../../../hooks';
import {ComboBox, IComboBoxOption, Label, Spinner, Stack, TextField} from '@fluentui/react';
import {UnitStatus} from '../../../services/generated';
import {TheButton} from '../../common/TheButton';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {FormFooter} from '../../common/FormFooter';
import {Unit} from '../../../models';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {Helmet} from 'react-helmet';

import styles from './UnitsCenter.module.scss';
import {InfoTooltip} from '../../common/InfoTooltip';

export const UnitsCenter = () => {
  const {getUnitsList, createUnit, getParentUnitsList,getGlobalSettings} = useAdmin();
  const {updateSingleUnit} = useService();

  const [unitsList, setUnitsList] = useState<Unit[]>([]);
  const [comboUnitsList, setComboUnitsList] = useState<IComboBoxOption[]>([]);
  const [unitsTree, setUnitsTree] = useState<TreeUnitsModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [areParentsLoading, setAreParentsLoading] = useState<boolean>(false);

  const [editedUnitId, setEditedUnitId] = useState<number>();
  const [unitName, setUnitName] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [parentUnit, setParentUnit] = useState<number>();
  const [unitType, setUnitType] = useState<number>();
  const [raiseToOpexFeatureEnabled, setRaiseToOpexFeatureEnabled] = useState(false);

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    const unitsPromise = await getUnitsList();
    if (unitsPromise && unitsPromise.result) {
      const units = unitsPromise.result;
      const treeData = units.map(unit => new TreeUnitsModel(unit));
      setUnitsTree(treeData);
      setUnitsList(units);
    }
    setIsLoading(false);
  }, [getUnitsList]);
  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const openModal = useCallback(
    async (unitId?: number) => {
      setIsModalVisible(true);
      setAreParentsLoading(true);
      const parentUnitsListPromise = await getParentUnitsList(unitId);
      if (parentUnitsListPromise.result) {
        const parentUnitsList = parentUnitsListPromise.result;
        const unitsListFromData: IComboBoxOption[] = parentUnitsList.map(unit => {
          return {
            key: unit.id,
            text: unit.title + (unit.canNotBeImplemented ? '*' : ''),
            disabled: unit.canNotBeImplemented
          };
        });
        unitsListFromData.unshift({key: 0, text: '', disabled: false, data: null});
        setComboUnitsList(unitsListFromData);
      }
      setAreParentsLoading(false);
    },
    [getParentUnitsList]
  );

  useEffect(() => {
      (async () => {
        const settingsResponse = await getGlobalSettings();
        if (settingsResponse?.result) {
          const raiseToOpexFeatureEnabled = settingsResponse.result.find(
            x => x.name === 'raiseToOpex'
          );
          if (raiseToOpexFeatureEnabled) {
            setRaiseToOpexFeatureEnabled(raiseToOpexFeatureEnabled.value === 'true');
          }
        }
      })();
  }, [getGlobalSettings]);



  const columns = useMemo(() => {
    const cols: ITreeColumn<TreeUnitsModel>[] = [
      {
        key: 1,
        title: '',
        type: 'title',
        showCheckbox: false,
        getTitle: (model: TreeUnitsModel) => model.title || '',
        property: 'title'
      },
      {
        key: 2,
        title: 'Status',
        type: 'simple',
        maxCSSWidth: '200px',
        property: 'status',
        onRenderColumn: (unit: TreeUnitsModel) => {
          switch (unit.status) {
            case UnitStatus.Draft:
              return 'Draft';
            case UnitStatus.Published:
              return 'Published';
            default:
              return 'Unknown';
          }
        }
      },
      {
        key: 3,
        title: '',
        type: 'simple',
        property: 'id',
        onRenderColumn: (unit: TreeUnitsModel) => {
          return (
            <TheButton
              iconProps={{
                iconName: 'Edit'
              }}
              onClick={() => {
                setUnitName(unit.title ?? '');
                setCountryCode(unit.countryCode ?? '');
                if (unit.parentId) setParentUnit(unit.parentId);
                setEditedUnitId(unit.id);
                setUnitType(unit.unitTypeId);
                openModal(unit.id);
              }}
              primary>
              Edit
            </TheButton>
          );
        }
      }
    ];
    return cols;
  }, [openModal]);

  const unitTypes: IComboBoxOption[] = useMemo(() => {
    const parent = parentUnit ? unitsList.find(u => u.id === parentUnit) : null;
    const disableDivision: boolean = !!(parent && parent.unitTypeId === 1 && parent.parentId);
    const disableCountry: boolean = !parent;
    const types = [
      {
        key: 1,
        text: 'Division / Hub / BL' + (disableDivision ? '*' : ''),
        disabled: disableDivision
      },
      {
        key: 2,
        text: 'Country' + (disableCountry ? '*' : ''),
        disabled: disableCountry
      }
    ];
    return types;
  }, [parentUnit, unitsList]);

  const clearStates = useCallback((hideModal: boolean = false) => {
    setUnitName(undefined);
    setCountryCode(undefined);
    setEditedUnitId(undefined);
    setParentUnit(undefined);
    setUnitType(undefined);
    if (hideModal) setIsModalVisible(false);
  }, []);

  const saveUnit = useCallback(
    async (publish?: boolean) => {
      setIsLoading(true);
      if (editedUnitId) {
        const existingUnit = unitsList.find(u => u.id === editedUnitId);
        if (!existingUnit) throw new Error('Error during saving unit');
        existingUnit.title = unitName ?? null;
        existingUnit.countryCode = countryCode ?? null;
        existingUnit.parentId = parentUnit ?? null;
        existingUnit.status = publish ? UnitStatus.Published : UnitStatus.Draft;
        existingUnit.unitTypeId = unitType ?? 1;
        await updateSingleUnit(editedUnitId, existingUnit);
      } else {
        let parentUnitModel: Unit | undefined;
        if (parentUnit) {
          parentUnitModel = unitsList.find(u => u.id === parentUnit);
          if (!parentUnitModel) throw new Error('Error during saving unit');
        }

        const unit = new Unit({
          id: 0,
          link: [],
          parentId: parentUnitModel ? parentUnitModel.id : null,
          parentTitle: parentUnitModel ? parentUnitModel.title : null,
          processesCount: 0,
          processValidityInMonth: 12,
          title: unitName ?? null,
          countryCode: countryCode ?? null,
          unitTypeId: unitType ?? 1,
          pdcLink: null,
          pdcOperatingUnit: null,
          status: publish ? UnitStatus.Published : UnitStatus.Draft,
          turnOffNotifications: false
        });

        await createUnit(unit);
      }
      clearStates();
      setIsLoading(false);
      await refreshData();
    },
    [
      clearStates,
      createUnit,
      updateSingleUnit,
      refreshData,
      editedUnitId,
      parentUnit,
      countryCode,
      unitName,
      unitsList,
      unitType
    ]
  );
  const isUnitTypeValid = useCallback(() => {
    const unit = unitsList.find(u => u.id === parentUnit);
    if (unit) {
      switch (unitType) {
        case 1:
          return !unit.parentId;
        case 2:
        default:
          return true;
      }
    } else return unitType ? unitType === 1 : true;
  }, [unitsList, unitType, parentUnit]);
  return (
    <>
      <HeadingWithDecoration text="Units Center" />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Helmet>
            <title>IMS Units Center</title>
          </Helmet>
          <ModalWithHeader
            isVisible={isModalVisible}
            dismiss={() => {
              clearStates(true);
            }}
            header={editedUnitId ? 'Unit Edition' : 'New Unit'}>
            {areParentsLoading ? (
              <Spinner />
            ) : (
              <>
                <ComboBox
                  className={styles.narrowInput}
                  onRenderLabel={() => (
                    <Stack horizontal verticalAlign="center">
                      <Label>Parent Unit</Label>
                      <InfoTooltip
                        content={
                          'Some units may be disabled and marked with an asterisk - impossible to be selected due to potential circular references'
                        }
                      />
                    </Stack>
                  )}
                  allowFreeInput
                  autoComplete="on"
                  defaultSelectedKey={parentUnit}
                  options={comboUnitsList}
                  onChange={(_, option) => {
                    if (option) {
                      const key = option.key as number;
                      setParentUnit(key !== 0 ? key : undefined);
                    }
                  }}
                />
                <ComboBox
                  className={styles.narrowInput}
                  onRenderLabel={() => (
                    <Stack horizontal verticalAlign="center">
                      <Label>Unit Type</Label>
                      <InfoTooltip
                        content={
                          'Some types may be disabled and marked with an asterisk - impossible to be selected due to type conflict with Parent Unit'
                        }
                      />
                    </Stack>
                  )}
                  required
                  allowFreeInput
                  autoComplete="on"
                  defaultSelectedKey={unitType}
                  options={unitTypes}
                  errorMessage={
                    !unitType
                      ? 'This field cannot be empty'
                      : !isUnitTypeValid()
                      ? 'This value cannot be selected with current Parent Unit'
                      : undefined
                  }
                  onChange={(_, option) => {
                    if (option) setUnitType(option.key as number);
                  }}
                />
                <TextField
                  className={styles.narrowInput}
                  label="Unit Name"
                  required
                  errorMessage={
                    !unitName || !unitName.length ? 'This field cannot be empty' : undefined
                  }
                  defaultValue={unitName}
                  onChange={(_, newValue) => setUnitName(newValue)}
                />
                  {raiseToOpexFeatureEnabled && <TextField
                  className={styles.narrowInput}
                  label="Country Code for Opex"
                  defaultValue={countryCode}
                  onChange={(_, newValue) => setCountryCode(newValue)}
                />}
                <FormFooter
                  isDisabled={!(unitName && unitName.length) || !unitType || !isUnitTypeValid()}
                  onCancel={() => {
                    clearStates(true);
                  }}
                  onSubmit={() => {
                    setIsModalVisible(false);
                    saveUnit();
                  }}>
                  <TheButton
                    split
                    primary
                    className={styles.split}
                    disabled={!(unitName && unitName.length) || !unitType || !isUnitTypeValid()}
                    onClick={() => {
                      setIsModalVisible(false);
                      saveUnit(true);
                    }}>
                    Publish
                  </TheButton>
                </FormFooter>
              </>
            )}
          </ModalWithHeader>
          <TheButton iconProps={{iconName: 'Add'}} primary onClick={() => openModal()}>
            Create new
          </TheButton>
          <TreeBuilder
            itemsFlat={unitsTree}
            columns={columns}
            initialOpen={unitsTree
              .filter(item => item.parentId === null || item.isChecked === true)
              .map(item => item.id)}
          />
        </>
      )}
    </>
  );
};
