import {IDropdownOption} from '@fluentui/react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useUnit} from '../../../../../../hooks/services/useUnit';
import {IUnitDropdownProps} from './IUnitDropdownProps';
import {sortByTitle} from '../../../../../../helpers/arrayMehods';

export const useUnitDropdown = ({
  defaultSelectedUnitId,
  defaultSelectedUnitTitle,
  masterProcessId
}: IUnitDropdownProps) => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [options, setOptions] = useState<IDropdownOption<number>[]>([]);
  const {getUnitsImplementingProcess} = useUnit();
  useEffect(() => {
    if (defaultSelectedUnitId && defaultSelectedUnitTitle && !options.length) {
      setOptions([
        {
          key: defaultSelectedUnitId,
          text: defaultSelectedUnitTitle
        }
      ]);
    }
  }, [defaultSelectedUnitId, defaultSelectedUnitTitle, options]);

  const onFocus = useCallback(async () => {
    if (!refresh) return;
    setIsLoading(true);
    const response = await getUnitsImplementingProcess(unitId, masterProcessId);
    if (response.result) {
      setOptions(
        response.result.sort(sortByTitle).map(unit => ({
          key: unit.unitId,
          text: `${unit.unitTitle || ''}${unit.canNotBeImplemented ? '*' : ''}`,
          data: unit.id,
          disabled: unit.canNotBeImplemented
        }))
      );
    }
    setIsLoading(false);
    setRefresh(false);
  }, [getUnitsImplementingProcess, masterProcessId, refresh, unitId]);

  return useMemo(() => ({isLoading, onFocus, options}), [isLoading, onFocus, options]);
};
