import {FC, useCallback, useState} from 'react';
import {Richtext} from '../../../../common/Richtext';
import {TheButton} from '../../../../common/TheButton';
import {useComments} from '../../../../../hooks/services/useComments';
import styles from './Feedback.module.scss';

interface IAddFeedbackProps {
  processId: number;
  onCommentAdded: () => void;
}

export const AddFeedback: FC<IAddFeedbackProps> = ({processId, onCommentAdded}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [counter, setCounter] = useState(1);
  const {createComment} = useComments();

  const onAdd = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await createComment(processId, content);
      setContent('');
      setCounter(counter + 1);
      onCommentAdded();
      setIsLoading(false);
    })();
  }, [content, counter, createComment, onCommentAdded, processId]);

  return (
    <div>
      <Richtext key={counter} onChange={setContent} disable={isLoading} />
      <TheButton
        className={styles.button}
        primary
        disabled={isLoading}
        iconProps={{iconName: 'Add'}}
        onClick={onAdd}>
        Add comment
      </TheButton>
    </div>
  );
};
