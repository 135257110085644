import {useContext, useEffect, useState, useMemo} from 'react';
import {useParams} from 'react-router';
import {actions, PermissionToCheck} from '../../../../enums';
import {checkPermissions} from '../../../../helpers/permissionsHtml';

import {useService} from '../../../../hooks';
import {ProcessTree, RolePermission} from '../../../../models';
import {LoggerContext, ProcessContext} from '../../../../providers';
import {AuthenticationContext} from '../../../../providers/AuthenticationContext';
import {ApiResponse} from '../../../../services/ApiResponseType';
import {useNavigate} from 'react-router-dom';
import {UnitStatus} from '../../../../services/generated';
import { useUnit } from '../../../../hooks/services/useUnit';

export const useProcess = () => {
  const {setPageException, pageException} = useContext(LoggerContext);
  const {processId: processIdParam} = useParams();
  const {getProcess, process} = useContext(ProcessContext);
  const processId: number = Number(processIdParam);
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getUserPermissionsPage, getMenuProcessesPublishedForUnit} = useService();
  const {getUnitFullPath} = useUnit();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [helmetTitle, setHelmetTitle] = useState<string>();
  const [processPermissions, setProcessPermissions] = useState<ApiResponse<RolePermission[]>>();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] =
    useState<ApiResponse<ProcessTree[]>>();

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        if (process?.id !== processId) {
          await getProcess(processId);
        }
        // setApiCode(processData?.apiCode);
        // setApiMsg(processData?.message);
        // setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading, processId]);
  /* eslint-enable*/

  useEffect(() => {
    (async () => {
      if (process && !process.deleted && process.unit?.id) {
        setIsLoading(true);
        const response = await getUnitFullPath(process.unit.id)
        setHelmetTitle(`${response.result}: ${process.title}`);
        const permissions = await getUserPermissionsPage(process.unit.id, process.id);
        if (
          process.unit.status === UnitStatus.Draft &&
          permissions &&
          permissions.result &&
          !checkPermissions(PermissionToCheck.AccessUnitDraft, actions.READ, permissions.result)
        ) {
          navigate('/accessDenied', {replace: true});
          return;
        }

        setProcessPermissions(permissions);
        const processList = await getMenuProcessesPublishedForUnit(process.unit.id);
        setPublishedProcessesForUnit(processList);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [process]);
  /* eslint-enable*/
  useEffect(() => {
    if (!pageException && process?.deleted) {
      setTimeout(() => setPageException(`Process (${process.id}) has been deleted`), 0);
    }
  }, [pageException, setPageException, process]);

  const canEdit = useMemo(
    () =>
      checkPermissions(PermissionToCheck.ProcessForm, actions.UPDATE, processPermissions?.result),
    [processPermissions]
  );

  const canEditDiagram = useMemo(
    () =>
      checkPermissions(
        PermissionToCheck.EditProcessDiagramButton,
        actions.UPDATE,
        processPermissions?.result
      ),
    [processPermissions]
  );

  return useMemo(
    () => ({
      isLoading,
      helmetTitle,
      process,
      publishedProcessesForUnit,
      canEdit,
      canEditDiagram,
    }),
    [isLoading, process,helmetTitle, publishedProcessesForUnit, canEdit, canEditDiagram]
  );
};
