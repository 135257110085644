import {useMemo} from 'react';
import {ExternalLinkWithProcess} from '../../models';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {
  ExternalLinkService,
  SaveExternalLinkDto,
  UpdateExternalLinkDto
} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';

export const useExternalLinks = () => {
  const requestWrapper = useApiService();

  /** Returns a list of all master processes */
  const getExternalLinks = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ExternalLinkWithProcess[]> => {
    return await requestWrapper(
      ExternalLinkService.getExternalLink(),
      result => result.map(item => new ExternalLinkWithProcess(item)),
      log
    );
  };

  const addExternalLink = async (
    link: Partial<SaveExternalLinkDto>,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SaveExternalLinkDto> => {
    return await requestWrapper(
      ExternalLinkService.postExternalLink(link as SaveExternalLinkDto),
      result => result,
      log
    );
  };

  const updateExternalLink = async (
    id: number,
    link: Partial<UpdateExternalLinkDto>,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SaveExternalLinkDto> => {
    return await requestWrapper(
      ExternalLinkService.putExternalLink(id, link as UpdateExternalLinkDto),
      result => result,
      log
    );
  };

  const deleteExternalLink = async (
    id: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SaveExternalLinkDto> => {
    return await requestWrapper(ExternalLinkService.deleteExternalLink(id), result => result, log);
  };

  return useMemo(
    () => ({
      getExternalLinks,
      addExternalLink,
      updateExternalLink,
      deleteExternalLink
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
