import {FC, ReactNode} from 'react';
import {ModalWithHeader} from '../ModalWithHeader';
import {Dropdown, Label, Spinner} from '@fluentui/react';
import styles from './RaiseToOpexModal.module.scss';
import {PeoplePicker} from '../PeoplePicker';
import {TheButton} from '../TheButton';
import {inquiryCategoryOptions} from '../../../enums/InquiryCategory';
import {inquiryImpactOptions} from '../../../enums/InquiryImpact';

interface IRaiseToOpexModalProps {
  isVisible: boolean;
  comment: string;
  title: string;
  userAzureId: string;
  isLoading: boolean;
  handleCategory: (category: number) => void;
  handleInitiator: (initiator: string) => void;
  handleImpact: (impact: number) => void;
  onConfirm: () => void;
  onClose: () => void;
  children?: ReactNode;
}
export const RaiseToOpexModal: FC<IRaiseToOpexModalProps> = ({
  isLoading,
  isVisible,
  comment,
  onClose,
  onConfirm,
  title,
  userAzureId,
  handleInitiator,
  handleImpact,
  handleCategory
}) => (
  
    <>
    {isLoading && <Spinner /> }
    {!isLoading && <ModalWithHeader header="Create Inquiry" isVisible={isVisible} dismiss={onClose}>
    <Label>Title</Label>
    <div className={styles.container}>{title}</div>
    <Label htmlFor={'people'} required>
    Initiator
    </Label>
    <PeoplePicker
      itemLimit={1}
      defaultSelectedPersonId={userAzureId}
      onSelectPerson={value => {
        if (value && value.length > 0 && value[0].id) {
          handleInitiator(value[0].id);
        }
      }}
    />
    <Label htmlFor={'people'} required>
    Category
    </Label>
    <Dropdown
    options={inquiryCategoryOptions}
    defaultSelectedKey={inquiryCategoryOptions[1].key}
    onChange={(e, option) => {
        if (option) {
          handleCategory(option.key as number);
        }
      }}
    />
    <Label htmlFor={'people'} required>
    Impact
    </Label>
    <Dropdown
    options={inquiryImpactOptions}
    defaultSelectedKey={inquiryImpactOptions[0].key}
    onChange={(e, option) => {
      if (option) {
        handleImpact(option.key as number);
      }
    }}
    />
    <Label>Description</Label>
    <div
    className={styles.container}
    dangerouslySetInnerHTML={{
      __html: comment
    }}
    />
    <div className={styles.buttons}>
      <TheButton primary onClick={onClose}>
        Cancel
      </TheButton>

      <TheButton primary onClick={onConfirm}>
        Confirm
      </TheButton>
    </div>
  </ModalWithHeader>}
</>
);
