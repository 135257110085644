import {
  Dropdown,
  IComboBoxOption,
  IDropdownStyles,
  MessageBar,
  MessageBarType,
  Spinner
} from '@fluentui/react';

import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {ModalWithDividedRequests} from '../../common/ModalWithDividedRequests';
import {TheButton} from '../../common/TheButton';
import {TreeBuilder} from '../../common/TreeBuilder';
import styles from './AllExistingProcesses.module.scss';
import {useAllExistingProcesses} from './useAllExistingProcesses';
import {Helmet} from "react-helmet";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {width: 300}
};

export const AllExistingProcesses: React.FunctionComponent = () => {
  const {
    showProcesses,
    divValues,
    countryValues,
    isLoading,
    columns,
    isDisabled,
    onDismiss,
    onCancel,
    isAnythingLoading,
    submitProcesses,
    isModalVisible,
    isInvalid,
    itemsFlat,
    divValue,
    onDivChange,
    onCountryChange,
    progressStatus,
    countryValue
  } = useAllExistingProcesses();

  return (
    <>
      <Helmet>
        <title>IMS Independent Processes</title>
      </Helmet>
      <HeadingWithDecoration text="Independent Processes" />
      <div className={styles.dropdownUnit}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Dropdown
              label="Division/Hub/BL"
              options={divValues}
              key={'div'}
              styles={dropdownStyles}
              onChange={(e, value?: IComboBoxOption) => {
                onDivChange(value ? value : undefined);
              }}
            />
            <Dropdown
              label="Country"
              options={countryValues}
              key={'country'}
              disabled={isDisabled(divValue)}
              styles={dropdownStyles}
              defaultSelectedKey={countryValue?.key}
              onChange={(e, value?: IComboBoxOption) => {
                onCountryChange(value ? value : undefined);
              }}
            />
            <TheButton
              onClick={showProcesses}
              primary
              className={styles.onShowUnit}
              disabled={isDisabled(divValue)}>
              GO!
            </TheButton>
          </>
        )}
      </div>
      {isInvalid && (
        <MessageBar className={styles.errorMessage} messageBarType={MessageBarType.error}>
          Sorry, but the form was not saved. Check changes and try one more time.
        </MessageBar>
      )}
      {isAnythingLoading ? (
        <Spinner />
      ) : (
        itemsFlat.length > 0 && (
          <>
            <TreeBuilder itemsFlat={itemsFlat} columns={columns} />
            <div className={styles.footer}>
              <TheButton onClick={onCancel}>Cancel</TheButton>
              <TheButton onClick={submitProcesses} primary className={styles.theButton}>
                Save
              </TheButton>
            </div>
          </>
        )
      )}

      <ModalWithDividedRequests
        header="Information"
        dismiss={onDismiss}
        isVisible={isModalVisible}
        couldBeClosed={false}
        progressStatus={progressStatus}
        body="Due to technological reasons, the query will be sent in packages. Please, be patient."
      />
    </>
  );
};
