import {FC} from 'react';
import {FormFooter} from '../../../common/FormFooter';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';

interface IMasterProcessConfirmFormProps {
  onDeleteClose: () => void;
  onDeleteConfirm: () => void;
  masterProcesses: MasterProcessItemModel[];
}

export const MasterProcessConfirmForm: FC<IMasterProcessConfirmFormProps> = ({
  onDeleteClose,
  onDeleteConfirm,
  masterProcesses
}) => {
  return (
    <>
      <ul>
        {masterProcesses
          .filter(item => item.deleted && item.isModified)
          .map(item => (
            <li>{item.title}</li>
          ))}
      </ul>
      <FormFooter onCancel={onDeleteClose} onSubmit={onDeleteConfirm} />
    </>
  );
};
