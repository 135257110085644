import {
  FunctionDto,
  MinimumProcessDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../services/generated';
import {ProcessBaseType} from './ProcessBaseType';
import {ProcessType} from '../enums';

export class ProcessMinimum extends ProcessBaseType implements MinimumProcessDto {
  public unitId: number;
  public order: number;
  public parentMasterProcessId: number | null;
  public masterProcessId: number;

  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public function: FunctionDto | null;
  public businessLines: BusinessLineDto[] | null;

  public get preparedUrl() {
    return this.type === ProcessType.Process
      ? `/unit/${this.unitId}/process/${this.id}`
      : undefined;
  }

  public get url() {
    return this.preparedUrl;
  }

  public get preparedUrlAbsolute() {
    return this.preparedUrl ? window.location.origin + this.preparedUrl : undefined;
  }

  public get fakePrefix() {
    const regex = /^(\d+\.)+\d+/;
    const result = this.title?.match(regex);
    if (!result) return this.order >= 10 ? this.order + '' : `0${this.order}`;
    const prefix = result[0];
    return prefix
      .split('.')
      .map(num => (Number(num) >= 10 ? num : `0${num}`))
      .join('.');
  }

  constructor(process: MinimumProcessDto) {
    super(process);
    this.unitId = process.unitId;
    this.order = process.order;
    this.parentMasterProcessId = process.parentMasterProcessId;
    this.masterProcessId = process.masterProcessId;
    this.industries = process.industries;
    this.specialities = process.specialities;
    this.projectSizeCategories = process.projectSizeCategories;
    this.function = process.function;
    this.businessLines = process.businessLines;
  }
}
