import {useCallback, useEffect, useMemo, useState} from 'react';
import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {BatchDto, BatchStatus} from '../../../services/generated';
import {Icon, Spinner} from '@fluentui/react';
import {Person, PersonViewType} from '@microsoft/mgt-react';
import {TheButton} from '../../common/TheButton';
import stylesRow from '../../common/EnhancedDetailsList/EnhancedDetailsList.module.scss';
import {
  DateColumn,
  EnhancedDetailsList,
  OtherColumn,
  SelectColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {Batch} from '../../../models/Batch';

export const BatchCenter = () => {
  const {getBatchList, setMasterProcessSingleBatch} = useMasterProcess();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [batchData, setBatchData] = useState<Batch[]>([]);

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    const batch = await getBatchList();
    if (batch && batch.result) {
      const batchResult = batch.result;
      setBatchData(batchResult);
    }
    setIsLoading(false);
  }, [getBatchList]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const retryAction = useCallback(
    async (id: number) => {
      if (!id) return;
      setIsLoading(true);
      await setMasterProcessSingleBatch(id);
      refreshData();
      setIsLoading(false);
    },
    [refreshData, setMasterProcessSingleBatch]
  );

  const columns = useMemo(() => {
    const cols: IEnhancedColumn<Batch>[] = [
      {
        key: ColumnKey.Id,
        name: 'ID',
        onRender: (model: Batch) => {
          return <>{model.id}</>;
        },
        maxWidth: 150
      },
      {
        key: ColumnKey.Created,
        name: 'Created',
        data: DateColumn,
        onRender: (model: Batch) => {
          return <>{model.created && model.created.split('T')[0]}</>;
        },
        maxWidth: 200
      },
      {
        key: ColumnKey.CreatedBy,
        name: 'Created By',
        maxWidth: 350,
        data: UserDtoColumn,
        onRender: (model: Batch) =>
          model.createdBy?.azureId ? (
            <Person
              userId={model.createdBy?.azureId}
              view={PersonViewType.oneline}
              avatarSize={'small'}
            />
          ) : (
            <span>{model.createdBy?.userPrincipalName}</span>
          )
      },
      {
        key: ColumnKey.StatusString,
        name: 'Status',
        maxWidth: 250,
        onRender: (model: Batch) => {
          return (
            <span style={{color: model.status === 4 ? '#ff000f' : 'inherit'}}>
              {BatchStatus[model.status]}
            </span>
          );
        },
        data: SelectColumn
      },
      {
        key: SpecialColumnKey.Edit,
        name: 'Action',
        maxWidth: 250,
        data: OtherColumn,
        onRender: (model: BatchDto) => {
          return (
            <>
              {model.status === 4 && model.retried === false ? (
                <TheButton primary onClick={() => retryAction(model.id)}>
                  Retry
                </TheButton>
              ) : (
                <TheButton primary disabled={true}>
                  Retry
                </TheButton>
              )}
            </>
          );
        }
      },
      {
        key: ColumnKey.Reason,
        name: 'Reason',
        data: OtherColumn,
        minWidth: 500,
        onRender: (model: BatchDto) => {
          const reasonString = model.reason && model.reason.join(',\n ');
          return reasonString ? (
            <span title={reasonString || ''}>
              <Icon
                title={'Click to copy the reason to clipboard'}
                className={stylesRow.copyIcon}
                iconName="copy"
                onClick={() =>
                  navigator.clipboard
                    .writeText(reasonString)
                    .then(() => {
                      console.log('copied');
                    })
                    .catch(err => {
                      console.error('failed to copy', err);
                    })
                }
              />
              {reasonString}
            </span>
          ) : (
            <span></span>
          );
        }
      }
    ];
    return cols;
  }, [retryAction]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <EnhancedDetailsList<Batch, Batch, Batch> items={batchData} columns={columns} />
      )}
    </>
  );
};
