import styles from './EditProcessNavigator.module.scss';
import {ITag, ITagItemProps, Icon, Label, TagItem, TagPicker, TextField} from '@fluentui/react';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {PositionEnum, ProcessEnum} from '../../../enums/ProcessEnum';
import {TheButton} from '../../common/TheButton';
import {useProcessNavigatorInput} from './useProcessNavigatorInput';
import {useCallback, useRef} from 'react';

type IProcessNavigatorInput = {
  label: ProcessEnum | PositionEnum;
  defaultItems: ITag[] | undefined;
  setValue: React.Dispatch<React.SetStateAction<ITag[] | undefined>>;
  processes: ITag[] | undefined | null;
  dragStartInputLabel?: string | null | undefined;
  setDragStartInputLabel?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  showRemoved?: boolean;
};

export const ProcessNavigatorInput = ({
  label,
  defaultItems,
  setValue,
  processes,
  dragStartInputLabel,
  setDragStartInputLabel,
  showRemoved
}: IProcessNavigatorInput) => {
  const {
    filterSuggestedTags,
    getTextFromItem,
    suggestionsProps,
    onChange,
    isOpen,
    openModal,
    closeModal,
    onTitleChange,
    onUrlChange,
    saveLink,
    urlError,
    titleError,
    removed,
    setNewSelectedTags,
    onRemoveItems,
    editLink,
    url,
    title,
    isEditting
  } = useProcessNavigatorInput(processes, setValue, defaultItems);
  //https://rootstack.com/en/blog/how-do-i-use-drag-and-drop-react

  const enableDropping = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (!dragStartInputLabel || dragStartInputLabel === label) event.preventDefault();
    },
    [dragStartInputLabel, label]
  );

  const dragItem: React.MutableRefObject<number> = useRef(0);
  const dragOverItem: React.MutableRefObject<number> = useRef(0);

  const drop = useCallback(() => {
    if (defaultItems) {
      const copyListItems = [...defaultItems];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = 0;
      dragOverItem.current = 0;
      setNewSelectedTags(copyListItems);
      onChange(copyListItems);
    }
    if (setDragStartInputLabel) setDragStartInputLabel(null);
  }, [defaultItems, onChange, setDragStartInputLabel, setNewSelectedTags]);

  const dragStart = useCallback(
    (position: number) => {
      dragItem.current = position;
      if (setDragStartInputLabel) setDragStartInputLabel(label);
    },
    [setDragStartInputLabel, label]
  );

  const dragEnter = useCallback((position: number) => {
    dragOverItem.current = position;
  }, []);

  const onRenderItem = useCallback(
    (props: ITagItemProps, index: number): JSX.Element => {
      return (
        <div
          onDragStart={() => dragStart(index)}
          onDragEnter={() => dragEnter(index)}
          onDragEnd={drop}
          draggable
          key={props.item.name}>
          <TagItem
            onRemoveItem={() => onRemoveItems(props.item)}
            item={props.item}
            index={index}
            title={props.item.name}>
            {typeof props.item.key === 'string' && isNaN(parseInt(props.item.key, 2)) && (
              <Icon
                iconName={'EditMirrored'}
                className={styles.editIcon}
                onClick={() => {
                  const item = defaultItems?.find(i => i.key === props.item.key);
                  if (item) editLink(item, index);
                }}
              />
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;{props.item.name}
          </TagItem>
        </div>
      );
    },
    [defaultItems, editLink, dragEnter, dragStart, drop, onRemoveItems]
  );

  return (
    <>
      <Label>{label}</Label>
      <div className={styles.inputWrapper}>
        <div className={styles.wrapperForRemoved} onDragOver={enableDropping}>
          <TagPicker
            className={styles.tagPicker}
            removeButtonAriaLabel="Remove"
            onRenderItem={piece => onRenderItem(piece, piece.index)}
            selectionAriaLabel="Selected process"
            onResolveSuggestions={filterSuggestedTags}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionsProps}
            itemLimit={20}
            pickerCalloutProps={{doNotLayer: true}}
            selectedItems={defaultItems}
            onChange={onChange}
          />
          {defaultItems && showRemoved && removed.length ? (
            <div className={styles.removedWrapper}>
              <Label>You have just removed: </Label>
              {removed.map(item => (
                <div key={`removed${item.key}`} className={styles.removedWrapper}>
                  {item.name}
                  <TheButton
                    isIcon
                    iconProps={{iconName: 'RevToggleKey'}}
                    onClick={() => onChange([...defaultItems, item])}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <TheButton
          iconProps={{
            iconName: 'AddLink'
          }}
          onClick={openModal}
        />
      </div>
      <ModalWithHeader
        isVisible={isOpen}
        dismiss={closeModal}
        header={`${isEditting ? 'Edit' : 'Add new'} link`}>
        <TextField
          label="Title"
          onChange={onTitleChange}
          defaultValue={title}
          required
          errorMessage={titleError ? 'Title is required' : undefined}
        />
        <TextField
          label="Url"
          onChange={onUrlChange}
          defaultValue={url}
          required
          errorMessage={urlError ? 'Url is required' : undefined}
        />
        <TheButton onClick={saveLink} className={styles.button} primary>
          {isEditting ? 'Save' : 'Add'}
        </TheButton>
        <TheButton onClick={closeModal} className={styles.button}>
          Cancel
        </TheButton>
      </ModalWithHeader>
    </>
  );
};
