import {Dropdown, IDropdownOption, Spinner, TextField, Toggle} from '@fluentui/react';
import {ExternalLink, ExternalLinkVersion} from '../../../models';
import {FC, useCallback, useMemo, useState} from 'react';
import {TheButton} from '../TheButton';
import {useExternalLinks} from '../../../hooks/services/useExternalLinks';
import {ExternalLinkType} from '../../../enums';
import {SaveExternalLinkDto} from '../../../services/generated';
import styles from './LinksListCreate.module.scss';
import {ApiResponse} from '../../../services/ApiResponseType';

interface ILinksListCreateProps {
  onCreated: (addedLink?: ExternalLink, modifiedLink?: ExternalLink) => void;
  allowAdd?: boolean;
  editItem?: ExternalLink;
  createdByProcessId?: number;
}

export const LinksListCreate: FC<ILinksListCreateProps> = ({
  onCreated,
  allowAdd,
  editItem,
  createdByProcessId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(editItem?.title || '');
  const [url, setUrl] = useState(editItem?.url || '');
  const [type, setType] = useState<ExternalLinkType | undefined>(editItem?.type || undefined);
  const [isExternal, setIsExternal] = useState<boolean>(editItem?.isExternal || false);
  const {addExternalLink, updateExternalLink} = useExternalLinks();
  const onSubmit = useCallback(
    async (isAdd?: boolean) => {
      if (!title || !url || !type) return;
      setIsLoading(true);
      const newLink = {
        title,
        type,
        url,
        isExternal,
        createdByProcessId
      };
      let response: ApiResponse<SaveExternalLinkDto>;
      if (editItem) {
        response = await updateExternalLink(editItem.id, newLink);
      } else {
        response = await addExternalLink(newLink);
      }
      if (response.result) {
        onCreated(
          isAdd
            ? new ExternalLinkVersion({
                active: true,
                deleted: false,
                id: response.result.id,
                isExternal: response.result.isExternal,
                link: [],
                order: 0,
                ownerProcessId: 0,
                title: response.result.title,
                type: response.result.type,
                url: response.result.url,
                version: response.result.version,
                created: response.result.created,
                createdBy: response.result.createdBy,
                modified: response.result.modified,
                modifiedBy: response.result.modifiedBy,
                createdByProcessOwner: null,
                createdByProcess: {
                  id: response.result.createdByProcessId || 0,
                  processUnitId: 0,
                  title: ''
                }
              })
            : undefined,
          !isAdd
            ? new ExternalLinkVersion({
                active: true,
                deleted: false,
                id: response.result.id,
                isExternal: response.result.isExternal,
                link: [],
                order: 0,
                ownerProcessId: 0,
                title: response.result.title,
                type: response.result.type,
                url: response.result.url,
                version: response.result.version,
                created: response.result.created,
                createdBy: response.result.createdBy,
                modified: response.result.modified,
                modifiedBy: response.result.modifiedBy,
                createdByProcessOwner: null,
                createdByProcess: {
                  id: response.result.createdByProcessId || 0,
                  processUnitId: 0,
                  title: ''
                }
              })
            : undefined
        );
      }
      setIsLoading(false);
    },
    [
      addExternalLink,
      updateExternalLink,
      onCreated,
      title,
      type,
      url,
      isExternal,
      editItem,
      createdByProcessId
    ]
  );

  const options: IDropdownOption[] = useMemo(
    () => [
      {
        key: ExternalLinkType._1,
        text: 'IMS Process'
      },
      {
        key: ExternalLinkType._2,
        text: 'Site'
      },
      {
        key: ExternalLinkType._3,
        text: 'Software Application'
      },
      {
        key: ExternalLinkType._4,
        text: 'Examples'
      },
      {
        key: ExternalLinkType._5,
        text: 'e-Learning'
      }
    ],
    []
  );
  return (
    <div>
      <TextField
        label="Title"
        defaultValue={editItem?.title || undefined}
        onChange={(e, value) => setTitle(value || '')}
        required
      />
      <TextField
        label="Url"
        defaultValue={editItem?.url || undefined}
        onChange={(e, value) => setUrl(value || '')}
        required
      />
      <Dropdown
        defaultSelectedKey={editItem?.type || undefined}
        label="Type"
        options={options}
        onChange={(e, value) => setType((value?.key as number) || 6)}
        required
      />
      <Toggle
        label="Is external?"
        defaultChecked={isExternal}
        onChange={(e, checked) => setIsExternal(!!checked)}
      />
      <div className={styles.buttons}>
        {allowAdd ? (
          <TheButton
            primary
            disabled={isLoading || !title || !url || !type}
            onClick={() => onSubmit(true)}>
            {editItem ? 'Update & Add' : ' Create & Add'}
          </TheButton>
        ) : null}
        <TheButton
          primary
          disabled={isLoading || !title || !url || !type}
          onClick={() => onSubmit()}>
          {editItem ? 'Update' : 'Create'}
        </TheButton>
      </div>
      {isLoading ? <Spinner /> : null}
    </div>
  );
};
