import {AvailableProcessDto, Links, ProcessType, SlimMasterProcessDto} from '../services/generated';
import {ProcessFlatStructure} from './ProcessFlatStructure';

export class AvailableProcess implements AvailableProcessDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public title: string | null;
  public deleted: boolean;
  public masterProcess: SlimMasterProcessDto | null;
  public disabled: boolean;

  public isVisible: boolean;

  public get parentId() {
    return this.masterProcess?.parentId;
  }

  public isEdit = false;

  private initialIsChecked: boolean;
  public isChecked: boolean;

  private initialParentUnitId: number | undefined;
  public parentUnitId: number | undefined;

  private initialParentUnitProcessId: number | undefined;
  public parentUnitProcessId: number | undefined;

  private initialType: ProcessType;
  public type: ProcessType;

  private initialProcessOwnerAzureId: string | undefined;
  public processOwnerAzureId: string | undefined;

  public get keyString() {
    return this.id.toString();
  }

  public get isModified() {
    return (
      this.initialIsChecked !== this.isChecked ||
      (this.isChecked &&
        (this.initialType !== this.type ||
          this.initialProcessOwnerAzureId !== this.processOwnerAzureId ||
          this.initialParentUnitId !== this.parentUnitId ||
          this.initialParentUnitProcessId !== this.parentUnitProcessId))
    );
  }

  public get isValid() {
    return !this.isChecked || (typeof this.type === 'number' && this.processOwnerAzureId);
  }

  constructor(process: AvailableProcessDto, processPublished?: ProcessFlatStructure) {
    this.id = process.id;
    this.link = process.link;
    this.title = process.title;
    this.deleted = process.deleted;
    this.masterProcess = process.masterProcess;

    this.isVisible = !process.deleted || (processPublished && !processPublished.deleted) || false;

    const isChecked = (processPublished && !processPublished.deleted) || false;
    this.isChecked = isChecked;
    this.initialIsChecked = isChecked;
    this.disabled = isChecked;

    const parentUnitId = processPublished?.parentUnitId;
    this.parentUnitId = parentUnitId || undefined;
    this.initialParentUnitId = parentUnitId || undefined;

    const parentUnitProcessId = processPublished?.parentUnitId
      ? processPublished.parentProcessId
      : undefined;
    this.parentUnitProcessId = parentUnitProcessId;
    this.initialParentUnitProcessId = parentUnitProcessId;

    const type =
      typeof processPublished?.processType === 'undefined'
        ? ProcessType.Process
        : processPublished.processType;
    this.type = type;
    this.initialType = type;

    const processOwnerAzureId = processPublished?.processOwner?.azureId;
    this.processOwnerAzureId = processOwnerAzureId;
    this.initialProcessOwnerAzureId = processOwnerAzureId;
  }
}
