import {useMemo} from 'react';
import {LogMessageType} from '../../services/LogMessagesType';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {useApiService} from '../../services/helpers';
import {DocumentService} from '../../services/generated';
import {BatchAcknowledgementSaveDto} from '../../services/generated/models/BatchAcknowledgementSaveDto';
import {BatchAcknowledgementDto} from '../../services/generated/models/BatchAcknowledgementDto';

export const useAcknowledgementBatch = () => {
  const requestWrapper = useApiService();

  const getAcknowledgementBatch = (
    batchId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<BatchAcknowledgementDto> => {
    return requestWrapper(
      DocumentService.getApiBatchAcknowledgement(batchId),
      apiCode => apiCode,
      log
    );
  };

  const postAcknowledgementForm = (
    requestBody: BatchAcknowledgementSaveDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Acknowledgement form sent successfully`
    }
  ): ApiResponsePending<BatchAcknowledgementSaveDto[]> => {
    return requestWrapper(
      DocumentService.postApiBatchAcknowledgement(requestBody),
      result => result,
      log
    );
  };

  const putAcknowledgementOpened = async (
    docId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'The document was marked as opened'
    }
  ): ApiResponsePending<string> => {
    return await requestWrapper(
      DocumentService.putAcknowledgementOpened(docId),
      (result, apiCode) => apiCode,
      log
    );
  };

  const putAcknowledgementConfirmed = async (
    id: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'The changes have been saved'
    }
  ): ApiResponsePending<string> => {
    return await requestWrapper(
      DocumentService.putAcknowledgementConfirmed(id),
      (result, apiCode) => apiCode,
      log
    );
  };

  const putAcknowledgementFinished = async (
    batchId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'The Acknowledgement is finished'
    }
  ): ApiResponsePending<string> => {
    return await requestWrapper(
      DocumentService.putAcknowledgementFinished(batchId),
      (result, apiCode) => apiCode,
      log
    );
  };

  return useMemo(
    () => ({
      getAcknowledgementBatch,
      postAcknowledgementForm,
      putAcknowledgementOpened,
      putAcknowledgementConfirmed,
      putAcknowledgementFinished
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
