import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn, IComboBoxOption, TextField} from '@fluentui/react';
import styles from './ProcessesColumn.module.scss';
import {XLSXValue} from '../../../../helpers/excel';
import {ProcessUsedInLibraries} from '../../../../models/ProcessUsedInLibraries';

interface IValue {
  id: number;
  title: string;
  url?: string;
  unitId?: number;
}

export class SearchLinksListColumn extends EnhancedColumn {
  public static type = ColumnType.LinksList;

  public static renderer(values: IValue[] | undefined, column: IColumn): JSX.Element {
    return (
      <>
        {values?.map(value => {
          return value.url ? (
            <a
              key={value.id}
              href={value.url}
              className={styles.link}
              target="_blank"
              rel="noreferrer">
              {value.title}
            </a>
          ) : (
            <div key={value.id} className={styles.nonlink}>
              {value.title}
            </div>
          );
        })}
      </>
    );
  }

  public static getColumnValueForSort(values?: IValue[]): string {
    return `${values?.length || 0}`;
  }

  public static hasActiveFilter(value?: IValue[]): boolean {
    return Boolean(value?.length);
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions?: any[]
  ): JSX.Element {
    return (
      <TextField
        label="Filter"
        defaultValue={defaultValue}
        onChange={(e, newValue) => onChange(newValue)}
      />
    );
  }

  public static filterFunction(itemValue: ProcessUsedInLibraries[], filterValue: any): boolean {
    const lowerCaseFilterValue = ((filterValue as string) || '').toLowerCase();

    if (itemValue.some(x => x.title?.toLowerCase().includes(lowerCaseFilterValue))) {
      return true;
    }
    return false;
  }

  public static searchFunction(itemValue: IValue[], lowerCaseSearch: string): boolean {
    return itemValue
      ? itemValue.some(process => process.title.toLowerCase().includes(lowerCaseSearch))
      : false;
  }

  public static getColumnValueForExcel(itemValue: IValue[]): XLSXValue {
    if (!itemValue || !itemValue.length) return '';
    const text = itemValue.map(item => item.title).join('<br />');
    return itemValue.length > 1
      ? {
          html: text
        }
      : {
          text,
          link: itemValue[0].url || ''
        };
  }
}
