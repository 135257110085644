import {Dropdown} from '@fluentui/react';
import {FC, useState} from 'react';

import {dropdownOptionsTypes, ProcessType} from '../../../../../enums';
import {InputValidationError} from '../../../../common/Input/ValidationError';
import styles from '../UnitManagementProcess.module.scss';

interface IProcessRowTypeProps {
  model: {type: number; isChecked: boolean};
  disabled?: boolean;
}

export const ProcessRowType: FC<IProcessRowTypeProps> = ({model, disabled}) => {
  const [processType, setProcessType] = useState<ProcessType>(model.type);
  return (
    <div className={styles.inputWrapper}>
      <Dropdown
        defaultSelectedKey={processType === undefined ? ProcessType.Process : processType}
        onChange={(ev, option) => {
          const type = option?.key as ProcessType;
          model.type = type;
          setProcessType(type);
        }}
        placeholder="Select an option"
        options={dropdownOptionsTypes}
        disabled={disabled}
        className={`${styles.regular}${
          model.isChecked && typeof processType === 'undefined' ? ` ${styles.error}` : ''
        }`}
      />
      {model.isChecked && typeof processType === 'undefined' ? (
        <InputValidationError message={'This value could not be empty. Choose Process Type.'} />
      ) : null}
    </div>
  );
};
