import {FC} from 'react';
import {Route, Routes, useParams} from 'react-router-dom';

import {actions, PermissionToCheck} from '../../../enums';
import {PermissionsCheck} from '../../common/PermissionsCheck/PermissionsCheck';
import {EditNavigatorControl} from '../ProcessNavigator';
import {UnitSettings} from '../UnitSettings';
import {UnitManagement} from './UnitManagement';
import {UnitManagementProcesses} from './UnitManagementProcesses';
import {UnitManagementProcessesDetails} from './UnitManagementProcessesDetails';
import {UnitManagementSubscriptions} from './UnitManagementSubscriptions';
import {DocumentsCenterUnit} from '../DocumentsCenter';
import {UnitManagementViewsCenter} from './UnitManagementViewsCenter/UnitManagementViewsCenter';
import {UnitManagementFeedbackCenter} from './UnitManagementFeedbackCenter/UnitManagementViewsCenter';

export const UnitManagementRouting: FC = () => {
  const {unitId: unitIdStr} = useParams();
  
  const unitId = Number(unitIdStr);
  return (
    <PermissionsCheck
      unitId={unitId}
      action={actions.READ}
      permission={PermissionToCheck.UnitManageButton}
      exceptionMessage={`Not Enough Permissions To Manage unit (${unitId})`}>
      <Routes>
        <Route path="/" element={<UnitManagement />} />
        <Route path="navigatorControl" element={<EditNavigatorControl />} />
        <Route path="processes" element={<UnitManagementProcesses />} />
        <Route path="processesDetails" element={<UnitManagementProcessesDetails />} />
        <Route path="unitSettings" element={<UnitSettings unitId={unitId} />} />
        <Route path="viewsCenter" element={<UnitManagementViewsCenter />} />
        <Route path="documentsCenter" element={<DocumentsCenterUnit showEditAuditable={true} showEditDetails={false} unitId={unitId} frozen={true}/>} />
        <Route path="feedbackCenter" element={<UnitManagementFeedbackCenter />} />
        <Route path="subscriptions" element={<UnitManagementSubscriptions />} />
      </Routes>
    </PermissionsCheck>
  );
};
