import {Links, ParentUnitDto} from '../services/generated';

export class ParentUnit implements ParentUnitDto {
  id: number;
  link: Links[] | null;
  title: string | null;
  canNotBeImplemented: boolean;
  parentId: number | null;
  unitTypeId: number;

  constructor(parentUnit: ParentUnitDto) {
    this.id = parentUnit.id;
    this.link = parentUnit.link;
    this.title = parentUnit.title;
    this.canNotBeImplemented = parentUnit.canNotBeImplemented;
    this.parentId = parentUnit.parentId;
    this.unitTypeId = parentUnit.unitTypeId;
  }
}
