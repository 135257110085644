import {FC, useContext, useMemo, useState} from 'react';
import {Activity} from '../../../../models';
import {Accordion} from '../../../common/Accordion';
import styles from '../Process.module.scss';
import {ActivitiesList} from '../../../common/ProcessSections/ActivitiesList';
import { DictionariesContext } from '../../../../providers';
import { getControlled } from '../helpers';
import { sortByTitle } from '../../../../helpers/arrayMehods';

interface IProcessActivitiesProps {
  activities: Activity[] | null;
  isInput: boolean;
  unitId: number;
}

export const ProcessActivities: FC<IProcessActivitiesProps> = ({activities, isInput, unitId}) => {
  const {globalFiltersFunction} = useContext(DictionariesContext);

  const [itemsFiltered, setItemsFiltered] = useState<boolean>(false);

  const filteredActivities = useMemo(() => {
    const result = getControlled(activities)?.filter(globalFiltersFunction).sort(sortByTitle) || [];
    if (activities && activities.length > 0 && result.length !== activities.length) {
      setItemsFiltered(true);
    } else {
      setItemsFiltered(false);
    }

    return result;
  }, [activities, globalFiltersFunction]);

  return activities?.length ? (
    <div className={styles.processLinksWrapper}>
      <Accordion
        isOpen={!!filteredActivities.length}
        header={`Activity ${isInput ? 'inputs' : 'outputs'}`}>
        <ActivitiesList itemsFiltered={itemsFiltered} data={filteredActivities} isInput={isInput} unitId={unitId} />
      </Accordion>
    </div>
  ) : null;
};
