import {RefObject} from 'react';

export const scrollIntoElement = (element: RefObject<HTMLDivElement>) => {
  if (element.current) {
    element.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    });
  }
};
