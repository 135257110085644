import styles from './ProcessesColumn.module.scss';
import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn, IComboBoxOption} from '@fluentui/react';
import {NavLink} from 'react-router-dom';
import {ProcessMinimum} from '../../../../models';
import {SelectPicker} from '../../SelectPicker';
import {sortByFakePrefix} from '../../../../helpers/arrayMehods';
import {XLSXValue} from '../../../../helpers/excel';

export class ProcessesColumn extends EnhancedColumn {
  public static type = ColumnType.Processes;
  public static canFilter = true;
  public static canSort = true;

  public static renderer(
    value: ProcessMinimum[] | ProcessMinimum | null,
    column: IColumn
  ): JSX.Element {
    if (!value) return <></>;
    const valueArray = Array.isArray(value) ? value : [value];
    return (
      <>
        {valueArray?.map(process => {
          const text = ProcessesColumn.mapValue(process);
          return process.preparedUrl ? (
            <NavLink key={process.id} to={process.preparedUrl} className={styles.link}>
              {text}
            </NavLink>
          ) : (
            <div key={process.id} className={styles.nonlink}>
              {text}
            </div>
          );
        })}
      </>
    );
  }

  public static hasActiveFilter(value?: ProcessMinimum[]): boolean {
    return Boolean(value?.length);
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: ProcessMinimum[][]
  ): JSX.Element {
    const flatOptions = allOptions.flat();
    const existingOptionIds: number[] = [];
    const uniqueOptions = flatOptions.filter(option => {
      if (existingOptionIds.some(id => option.id === id)) return false;
      existingOptionIds.push(option.id);
      return true;
    });
    let options = uniqueOptions.sort(sortByFakePrefix).map(option => option.title || '');
    options.unshift('')
    return (
      <SelectPicker
        type="combobox"
        multiSelect
        label="Filter"
        defaultValue={defaultValue}
        options={options}
        onChange={(keys, items) => onChange(keys, items)}
        skipSort
      />
    );
  }

  public static filterFunction(
    itemValue: ProcessMinimum[] | ProcessMinimum,
    filterValue: string[]
  ): boolean {
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if(filterVal === '') return Array.isArray(itemValue) ? itemValue.length === 0 : !itemValue;
      if (!filterVal) return !itemValue;
      return Array.isArray(itemValue)
        ? itemValue.some(val => val.title === filterVal)
        : itemValue?.title === filterVal;
    });
  }

  public static searchFunction(
    itemValue: ProcessMinimum[] | ProcessMinimum | null,
    lowerCaseSearch: string
  ): boolean {
    return Array.isArray(itemValue)
      ? itemValue.some(process =>
          ProcessesColumn.mapValue(process).toLowerCase().includes(lowerCaseSearch)
        ) 
      : itemValue !== null ? ProcessesColumn.mapValue(itemValue)?.toLowerCase().includes(lowerCaseSearch) : false;
  }

  public static getColumnValueForExcel(itemValue: ProcessMinimum[]): XLSXValue {
    if (itemValue?.length === 1) {
      const text = ProcessesColumn.mapValueToCompleteString(itemValue[0]);
      return itemValue[0].preparedUrlAbsolute
        ? {
            link: itemValue[0].preparedUrlAbsolute,
            text
          }
        : text;
    }
    return itemValue
      ? {
          html: itemValue
            ?.map(process => ProcessesColumn.mapValueToCompleteString(process))
            .join('<br />').slice(0, 32766)
        }
      : '';
  }

  private static mapValue(value: ProcessMinimum): string {
    return `${value.title}`;
  }

  private static mapValueToCompleteString(process: ProcessMinimum): string {
    return `${ProcessesColumn.mapValue(process)} (${process.preparedUrlAbsolute})`;
  }
}
