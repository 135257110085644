import {
  Dropdown,
  IDropdown,
  IDropdownOption,
  IDropdownProps,
  IRenderFunction,
  ISelectableDroppableTextProps,
  SearchBox
} from '@fluentui/react';
import {FC, useCallback, useState} from 'react';
import styles from './DropdownWithSearch.module.scss';

export const DropdownWithSearch: FC<IDropdownProps> = props => {
  const [query, setQuery] = useState('');
  const onSearchChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
      setQuery(newValue?.toLocaleLowerCase() || '');
    },
    []
  );

  const resetQuery = useCallback(() => setQuery(''), []);

  const onRenderList: IRenderFunction<ISelectableDroppableTextProps<IDropdown, HTMLDivElement>> =
    useCallback(
      (props, defaultRender) =>
        defaultRender && props ? (
          <>
            <SearchBox
              placeholder="Filter options"
              onChange={onSearchChange}
              className={styles.search}
            />
            {defaultRender({
              ...props,
              options: (props.options as IDropdownOption[]).filter(
                item => !query || !item.text || item.text?.toLocaleLowerCase().indexOf(query) !== -1
              )
            })}
          </>
        ) : null,
      [onSearchChange, query]
    );

  return <Dropdown {...props} onRenderList={onRenderList} onFocus={resetQuery} />;
};
