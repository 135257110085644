import {graphUrl} from '../../authConfig';
import {IPersonaProps, MessageBarType} from '@fluentui/react';
import {LoggerContext} from '../../providers';
import {useContext, useMemo} from 'react';
import {AuthenticationContext} from '../../providers/AuthenticationContext';
import {IGraphGroup} from '../../interfaces';

export const useGroup = () => {
  const {addLog} = useContext(LoggerContext);
  const {acquireToken} = useContext(AuthenticationContext);

  const scopes = useMemo(
    () => [
      'openid',
      'profile',
      'User.Read',
      'User.Read.All',
      'User.ReadBasic.All',
      'offline_access'
    ],
    []
  );

  const getFilteredGroupsList = async (filter?: string): Promise<IPersonaProps[]> => {
    const userToken = await acquireToken(scopes);
    if (!filter || filter.length < 2) {
      return [];
    }

    try {
      const response = await fetch(
        `${graphUrl}/groups?$filter=endsWith(mail, 'abb.com') AND mailEnabled eq true&$search="displayName:${filter}" OR "mail:${filter}"`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            ConsistencyLevel: 'eventual'
          },
          method: 'GET'
        }
      );
      if (response.ok) {
        let result = await response.json();

        const groups: IPersonaProps[] = result.value.map(
          (item: {id: number; displayName: string; mail: string; userPrincipalName: string}) => {
            return {
              id: item.id,
              text: item.displayName,
              secondaryText: item.mail,
              key: item.id,
              userPrincipalName: item.userPrincipalName
            };
          }
        );
        return groups;
      } else {
        return [];
      }
    } catch (error) {
      addLog(MessageBarType.error, undefined, error);
      return [];
    }
  };

  const getGroupById = async (groupId: string): Promise<IGraphGroup | undefined> => {
    const userToken = await acquireToken(scopes);
    try {
      const groups = fetch(`${graphUrl}/groups/${groupId}$expand=member`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        method: 'GET'
      }).then(response => response.json());
      return groups;
    } catch (error) {
      addLog(MessageBarType.error, undefined, error);
      return undefined;
    }
  };

  return useMemo(
    () => ({
      acquireToken,
      getFilteredGroupsList,
      getGroupById
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
