import {Icon} from '@fluentui/react';
import {useNavigate} from 'react-router-dom';
import styles from '../Process.module.scss';

type INextProcessButton = {
  unitId: Number | null | undefined;
  nextProcessId: Number | null | undefined;
};

export const NextProcessButton = ({unitId, nextProcessId}: INextProcessButton) => {
  const navigate = useNavigate();
  return (
    <div>
      {nextProcessId && (
        <button
          className={styles.buttonNavNext}
          onClick={() => {
            navigate(`/unit/${unitId}/process/${nextProcessId}`);
          }}>
          Next Process
          <Icon iconName="ChevronRight" />
        </button>
      )}
    </div>
  );
};
