import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {sortByOrderPrefix} from '../../../../helpers/arrayMehods';
import {useCallback, useMemo, useState} from 'react';
import {useMasterProcess} from '../../../../hooks/services/useMasterProcess';

export const useMasterProcessNewForm = (
  masterProcesses: MasterProcessItemModel[],
  onNewClose: () => void,
  onAfterNewCreated?: () => Promise<void>,
  defaultParentId?: number | null
) => {
  const {createMasterProcess} = useMasterProcess();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorFunction, setIsErrorFunction] = useState(false);
  const [isErrorTitle, setIsErrorTitle] = useState(false);
  const model = useMemo(
    () =>
      new MasterProcessItemModel(
        defaultParentId
          ? {
              id: 0,
              parentId: defaultParentId,
              deleted: false,
              title: '',
              order: 1,
              link: [],
              function: {
                id: 0,
                link: null,
                snowflakeId: '',
                value: null
              },
              projectSizeCategories: null,
              specialities: null,
              industries: null,
              prefix: null,
              businessLines: null
            }
          : undefined
      ),
    [defaultParentId]
  );
  const [order, setOrder] = useState(model.order);

  const onSubmitNew = useCallback(async () => {
    if (model.isInvalid) {
      setIsErrorFunction(!model.function?.id);
      setIsErrorTitle(!model.title);
    } else {
      setIsErrorFunction(false);
      setIsErrorTitle(false);
      setIsLoading(true);
      await createMasterProcess(model.rowValue);
      if (onAfterNewCreated) await onAfterNewCreated();
      setIsLoading(false);
      onNewClose();
    }
  }, [createMasterProcess, onNewClose, onAfterNewCreated, model]);

  const parentOptions = useMemo(
    () => masterProcesses.filter(option => option.isChecked).sort(sortByOrderPrefix),
    [masterProcesses]
  );

  const reorder = useCallback((model: MasterProcessItemModel, newOrder: number) => {
    model.order = newOrder;
    setOrder(newOrder);
  }, []);

  return useMemo(
    () => ({
      onSubmitNew,
      isLoading,
      model: model,
      isErrorFunction,
      isErrorTitle,
      parentOptions,
      reorder,
      order
    }),
    [onSubmitNew, isLoading, model, isErrorFunction, isErrorTitle, parentOptions, reorder, order]
  );
};
