import {
  Checkbox,
  Dropdown,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {dropdownOptionsTypes, ProcessType} from '../../../enums';
import {IUnitAssignmentModal} from '../../../interfaces';
import {UnitToMasterProcessAssign} from './UnitToMasterProcessAssign';
import {ITreeColumn} from '../../common/TreeBuilder/TreeColumn';
import styles from './UnitAssignmentPage.module.scss';
import {TreeBuilder} from '../../common/TreeBuilder';
import {TheButton} from '../../common/TheButton';
import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {scrollIntoElement} from '../../../helpers/visual';

export const UnitAssignmentModal = ({
  data,
  unitsToBuildColumns,
  closeModal,
  isLoadingData,
  refreshData
}: IUnitAssignmentModal) => {
  const [basicData, setBasicData] = useState<UnitToMasterProcessAssign[]>(data);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true);
  const [processType, setProcessType] = useState<ProcessType>(ProcessType.Process);
  const [messageNothingChanged, setMessageNothingChanged] = useState<string>('');
  const errorMsgBar = useRef<HTMLDivElement>(null);

  const {setMasterProcessBatch} = useMasterProcess();
  const getProcessById = useCallback(
    (processId: number, dataSet?: UnitToMasterProcessAssign[]) => {
      const process = (dataSet ?? basicData).find(item => item.id === processId);
      if (!process) {
        throw new Error(`Process with id=${processId} not found.`);
      }
      return process;
    },
    [basicData]
  );

  const changeSingleUnitState = useCallback(
    (
      unitId: number | undefined,
      processId: number,
      checked: boolean,
      newBasicData: UnitToMasterProcessAssign[],
      invokedFromChild?: boolean
    ) => {
      const process = getProcessById(processId, newBasicData);
      const unitDataForProcess = process.getUnit(unitId);
      // I'm clicked element
      if (!invokedFromChild) {
        unitDataForProcess.isChecked = checked;
      }
      // I'm parent, no way to know which
      else {
        const findMyAllChildren = newBasicData.filter(item => item.parentId === processId);
        const anyChildrenIsChecked = findMyAllChildren.some(
          item => item.unitsDetails.find(unit => unitId === unit.id)?.isChecked
        );
        unitDataForProcess.disabled = anyChildrenIsChecked;
        unitDataForProcess.isChecked = anyChildrenIsChecked || unitDataForProcess.isInitialChecked;
      }

      if (process.parentId) {
        newBasicData = changeSingleUnitState(unitId, process.parentId, checked, newBasicData, true);
      }
      return newBasicData;
    },
    [getProcessById]
  );

  const onUnitCheckboxClick = useCallback(
    (unitId: number | undefined, processId: number, checked: boolean) => {
      let newBasicData = [...basicData];

      newBasicData = changeSingleUnitState(unitId, processId, checked, newBasicData, false);

      setBasicData(newBasicData);
    },
    [basicData, changeSingleUnitState]
  );

  const selectAll = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
      const modifiedData = basicData.map(item => {
        item.isChecked = !!isChecked;
        item?.unitsDetails.forEach(unit => {
          unit.isChecked = !!isChecked;
        });

        return item;
      });
      setBasicData(modifiedData);
    },
    [basicData]
  );

  useEffect(() => {
    setBasicData(data);
    setIsLoading(false);
  }, [data]);

  const submitData = useCallback(async () => {
    setIsLoading(true);
    const extractUnitsDetails = basicData.flatMap(item =>
      item.unitsDetails.filter(unit => unit.isInitialChecked !== unit.isChecked)
    );
    if (!extractUnitsDetails.length && processType === ProcessType.Process) {
      setMessageNothingChanged('Nothning was changed. Please, do some changes to save data.');
    }
    if (!extractUnitsDetails.length && processType !== ProcessType.Process) {
      const nonChangedCheckboxesButProcess = basicData.flatMap(item =>
        item.unitsDetails.filter(unit => unit.isChecked === true)
      );

      const prepatedDataToSave = nonChangedCheckboxesButProcess.map(item => ({
        masterProcessId: item.masterProcessId,
        unitId: item.id,
        processToDelete: !item.isChecked,
        type: processType,
        id: 0,
        reason: '',
        link: []
      }));
      await setMasterProcessBatch(prepatedDataToSave);
      closeModal();
      refreshData();
      setIsLoading(false);
    }

    if (extractUnitsDetails.length) {
      const prepatedDataToSave = extractUnitsDetails.map(item => ({
        masterProcessId: item.masterProcessId,
        unitId: item.id,
        processToDelete: !item.isChecked,
        type: processType,
        id: 0,
        reason: '',
        link: []
      }));
      await setMasterProcessBatch(prepatedDataToSave);
      closeModal();
      refreshData();
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [basicData, closeModal, processType, refreshData, setMasterProcessBatch]);

  useEffect(() => {
    if (messageNothingChanged) {
      scrollIntoElement(errorMsgBar);
    }
  }, [messageNothingChanged]);

  const onProcessChange = useCallback(
    (processId: number | null | undefined, newBasicData: UnitToMasterProcessAssign[]) => {
      if (processId != null) {
        const process = newBasicData.find(item => item.id === processId);
        const findMyAllChildren = newBasicData.filter(item => item.parentId === processId);

        const parentUnitsShouldBeDisabled = findMyAllChildren
          .flatMap(x => x.unitsDetails)
          .reduce((acc, item) => {
            const id = item.id;
            acc[id] = acc[id] ?? [];
            (
              acc[id] as Array<{
                id: number;
                masterProcessId: number;
                parentId: number | null;
                isChecked: boolean;
                disabled: boolean;
                isInitialChecked: boolean;
              }>
            ).push(item);
            return acc;
          }, [])
          .map(x => {
            if (x == null) return null;
            return (
              x as Array<{
                id: number;
                masterProcessId: number;
                parentId: number | null;
                isChecked: boolean;
                disabled: boolean;
                isInitialChecked: boolean;
              }>
            ).some(y => y.isChecked);
          });
        process?.unitsDetails.forEach(parentUnit => {
          const shouldBeDisabled = !!parentUnitsShouldBeDisabled[parentUnit.id];
          parentUnit.disabled = shouldBeDisabled;
          parentUnit.isChecked = shouldBeDisabled || parentUnit.isInitialChecked;
        });

        newBasicData = onProcessChange(process?.parentId, newBasicData);
      }

      return newBasicData;
    },
    []
  );

  const columns = useMemo(() => {
    const cols: ITreeColumn<UnitToMasterProcessAssign>[] = [
      {
        key: 1,
        title: '',
        getTitle: (model: UnitToMasterProcessAssign) => model.prefixedTitle,
        type: 'inheritStatusCheckbox',
        maxCSSWidth: '450px',
        minCSSWidth: '450px',
        onChange: (items, ids) => {
          let newBasicData = [...basicData];
          const isChecked = items[0].isChecked;
          for (const item of items) {
            item.unitsDetails.forEach(ud => (ud.isChecked = !!isChecked));
            const index = newBasicData.findIndex(x => x.id === item.id);
            newBasicData.splice(index, 1, item);
          }

          newBasicData = onProcessChange(items[0].parentId, newBasicData);

          setBasicData(newBasicData);
        },
        hasChildren: (model: UnitToMasterProcessAssign) => !!model.units?.length
      }
    ];
    const cols2: ITreeColumn<UnitToMasterProcessAssign>[] = unitsToBuildColumns.map(item => ({
      key: item.title || 'column' + item.id,
      type: 'simple',
      title: item.title || '',
      onRenderColumn: (
        model: UnitToMasterProcessAssign,
        column: ITreeColumn<UnitToMasterProcessAssign>
      ) => {
        setIsLocalLoading(true);
        const elementData = model.unitsDetails.find(element => element.id === item.id);
        let isItemChecked = elementData?.isChecked;

        const myCurrentId = model.id;
        const myChildren = basicData.filter(item => item.parentId === myCurrentId);
        if (myChildren.length && elementData) {
          const checkMyChildren = myChildren.map(unit =>
            unit.unitsDetails.find(detail => detail.id === item.id)
          );
          const isAnythingChecked = checkMyChildren.some(item => item?.isChecked);
          elementData.disabled = isAnythingChecked;
        }
        setIsLocalLoading(false);

        const checkboxStyleCondition = elementData?.disabled && elementData.isInitialChecked;

        return isLocalLoading ? (
          <Spinner size={SpinnerSize.xSmall} />
        ) : (
          <Checkbox
            disabled={!model?.isChecked || elementData?.disabled}
            checked={isItemChecked}
            styles={{
              checkmark: checkboxStyleCondition && {
                color: '#c8c6c4'
              },
              checkbox: checkboxStyleCondition && {
                backgroundColor: '#fff'
              }
            }}
            onChange={(ev, checked) => onUnitCheckboxClick(elementData?.id, model.id, !!checked)}
          />
        );
      },
      property: '',
      maxCSSWidth: '50px'
    }));
    return [...cols, ...cols2];
  }, [basicData, isLocalLoading, onProcessChange, onUnitCheckboxClick, unitsToBuildColumns]);

  return (
    <>
      {isLoading || isLoadingData ? (
        <Spinner />
      ) : (
        <>
          <Dropdown
            defaultSelectedKey={ProcessType.Process}
            onChange={(ev, option) => {
              const type = option?.key as ProcessType;
              setProcessType(type);
            }}
            placeholder="Select an option"
            options={dropdownOptionsTypes}
          />
          <Checkbox
            label={'Select All'}
            className={styles.selectAllBtn}
            onChange={(ev, isChecked) => selectAll(ev, isChecked)}
          />
          {messageNothingChanged && (
            <MessageBar messageBarType={MessageBarType.warning} ref={errorMsgBar}>
              {messageNothingChanged}
            </MessageBar>
          )}
          {basicData && basicData.length > 0 && (
            <TreeBuilder itemsFlat={basicData} columns={columns} />
          )}
          <div className={styles.footer}>
            <TheButton onClick={closeModal}>Cancel</TheButton>
            <TheButton className={styles.button} primary onClick={submitData}>
              Save
            </TheButton>
          </div>
        </>
      )}
    </>
  );
};
