import {useCallback, useState} from 'react';

export const useChunkedRequest = <T, S, O = undefined>(
  request: (data: T[], options?: O) => Promise<S>,
  chunkSize: number = 3
) => {
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [moreThanOneChunk, setMoreThanOneChunk] = useState(false);
  const [progressStatus, setProgressStatus] = useState<{allItems: number; readyItems: number}>({
    allItems: 0,
    readyItems: 0
  });

  const getDataChunked = useCallback(
    (data: T[]) => {
      const resultArray: T[][] = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        resultArray.push(data.slice(i, i + chunkSize));
      }
      return resultArray;
    },
    [chunkSize]
  );

  const sendRequests = useCallback(
    async (data: T[], options?: O) => {
      const isBigRequest = data.length > chunkSize;

      if (isBigRequest) {
        setMoreThanOneChunk(true);
      }
      setIsRequestInProgress(true);
      const chunkedData: T[][] = getDataChunked(data);
      setProgressStatus({allItems: chunkedData.length, readyItems: 0});
      let countProgress: number = 0;

      const results = [];

      try {
        for (const dataChunk of chunkedData) {
          const result = await request(dataChunk, options);
          results.push(result);
          countProgress++;
          setProgressStatus({allItems: chunkedData.length, readyItems: countProgress});
        }
      } finally {
        setMoreThanOneChunk(false);
        setIsRequestInProgress(false);
        return results;
      }
    },
    [chunkSize, getDataChunked, request]
  );

  return {
    sendRequests,
    isRequestInProgress,
    moreThanOneChunk,
    progressStatus
  };
};
