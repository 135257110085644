import App from './App';
import {createRoot} from 'react-dom/client';
import {initializeIcons} from '@fluentui/react/lib/Icons';
import './index.css';

console.log('1.0.9');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);
initializeIcons();
root.render(<App />);
