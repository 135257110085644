import {ProjectSizeCategoryDto, IndustryDto, BusinessLineDto} from '../services/generated';
import {DocumentBase, IDocumentBase} from './DocumentBase';

interface IDocumentSystem extends IDocumentBase {
  active: boolean;
  removed?: boolean;
  auditable: boolean;
  order: number;
  ownerProcessId: number;
  industries: IndustryDto[] | null;
  projectSizeCategories: ProjectSizeCategoryDto[] | null;
  businessLines: BusinessLineDto[] | null;
  isBroken?: boolean;
  checkedAcknowledgementItem?: boolean;
}

export class DocumentSystem extends DocumentBase {
  public active: boolean;
  public removed?: boolean;
  public auditable: boolean;
  public order: number;
  public ownerProcessId: number;
  public industries: IndustryDto[] | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public isBroken: boolean = false;
  public checkedAcknowledgementItem?: boolean = false;
  public businessLines: BusinessLineDto[] | null;
  public keyWords: string[] | null;
  constructor(document: IDocumentSystem) {
    super(document);
    this.active = document.active;
    this.removed = document.removed;
    this.auditable = document.auditable;
    this.checkedAcknowledgementItem = document.checkedAcknowledgementItem || false;
    this.order = document.order;
    this.ownerProcessId = document.ownerProcessId;
    this.industries = document.industries;
    this.projectSizeCategories = document.projectSizeCategories;
    this.businessLines = document.businessLines;
    this.keyWords = document.keyWords;
  }
}
