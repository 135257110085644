import {ColumnType} from '../IEnhancedDetailsList';
import {Dropdown, IComboBoxOption, IDropdownOption} from '@fluentui/react';
import {EnhancedColumn} from './EnhancedColumn';

export class DropdownColumn extends EnhancedColumn {
  public static type = ColumnType.Dropdown;
  private static allString = '(all)';

  public static hasActiveFilter(value: string[]): boolean {
    return value && value.length > 0 && value.some(val => val !== DropdownColumn.allString);
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: string[]
  ): JSX.Element {
    const options: IDropdownOption[] = [
      ...new Set([
        DropdownColumn.allString,
        defaultValue?.[0] || DropdownColumn.allString,
        ...allOptions.reduce<string[]>((acc, value) => [...acc, ...value.split(', ')], [])
      ])
    ]
      .sort()
      .map(item => ({
        key: item,
        text: item
      }));
    return (
      <Dropdown
        label="Filter"
        defaultSelectedKey={defaultValue?.[0] || DropdownColumn.allString}
        options={options}
        onChange={(event, option) => onChange([option?.text || ''])}
      />
    );
  }

  public static filterFunction(itemValue: string | null, filterValue: string[]): boolean {
    if (filterValue?.[0] === DropdownColumn.allString) return true;
    const itemValues = (itemValue || '').split(', ');
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if (!filterVal) return !itemValue;
      return itemValues.indexOf(filterVal) !== -1;
    });
  }

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? `${itemValue}`.toLowerCase().includes(lowerCaseSearch) : false;
  }
}
