export const dateToday: Date = new Date(Date.now());
export const dateTodayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
const date = new Date();
date.setHours(0, 0, 0, 0);
export const dateTommorowMidnight: Date = new Date(date.setDate(date.getDate() + 1));

export const addYears = (date: Date, years: number) => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + years);
  return dateCopy;
};

export const substractYears = (date: Date, years: number) => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() - years);
  return dateCopy;
};

export const addMonths = (date: Date, months: number) => {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() + months);
  return dateCopy;
};

export const substractMonths = (date: Date, months: number) => {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() - months);
  return dateCopy;
};

export const toISODate = (date: Date | string) => {
  const newDate = new Date(date);
  if (isNaN(newDate.getTime())) {
    return 'Invalid date';
  }
  newDate.setHours(12);
  return newDate.toISOString();
};
