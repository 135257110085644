import {FC} from 'react';
import {Accordion} from '../../../common/Accordion';

interface IPublicationCommentProps {
  comment: string
  isOpen?: boolean;
  className?: string;
  customStyles?: {
    stylesHeader?: React.CSSProperties;
    stylesComment?: React.CSSProperties;
  };
}

export const PublicationComment: FC<IPublicationCommentProps> = ({
  comment,
  className,
  isOpen = true,
  customStyles
}) => {
  if (!comment) return null;
  return (
    <Accordion
      isOpen={isOpen}
      header="Comment"
      customStyles={customStyles}>
      <div className={className}>
        {comment}
      </div>

    </Accordion>
  );
};
