import {ChangeDto, ChangeElementsDto} from '../services/generated';

export class ProcessChange implements ChangeDto {
  public get id() {
    return this.publishedDate ? this.publishedDate.getTime() : 0;
  }

  public get publishedDate() {
    return this.published ? new Date(this.published) : null;
  }

  public versions: string | null;
  public publishedBy: string | null;
  public published: string | null;
  public changes: ChangeElementsDto[] | null;

  constructor(change: ChangeDto) {
    this.versions = change.versions;
    this.publishedBy = change.publishedBy;
    this.published = change.published;
    this.changes = change.changes;
  }
}
