import 'suneditor/dist/css/suneditor.min.css';

import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  IPersonaProps,
  ITextFieldProps,
  Label,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  Toggle,
  TooltipHost
} from '@fluentui/react';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {sortByTitle} from '../../../../helpers/arrayMehods';
import {dateTommorowMidnight, toISODate} from '../../../../helpers/date';
import {useProcess} from '../../../../hooks/services/useProcess';
import {Activity, ExternalLink, ProcessSave} from '../../../../models';
import {DocumentSave} from '../../../../models/DocumentSave';
import {DocumentSystem} from '../../../../models/DocumentSystem';
import {EditProcessVersion} from '../../../../models/EditProcessVersion';
import {DictionariesContext, ProcessContext} from '../../../../providers';
import {DescriptionVersionDto, UserDto} from '../../../../services/generated';
import {ColumnKey} from '../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {FormFooter} from '../../../common/FormFooter';
import {HeadingWithDecoration} from '../../../common/HeadingWithDecoration';
import {InputValidationError} from '../../../common/Input/ValidationError';
import {LastEditor} from '../../../common/LastEditor';
import {ModalWithHeader} from '../../../common/ModalWithHeader';
import {PeoplePicker} from '../../../common/PeoplePicker';
import {DocumentAndFile} from '../../../common/ProcessSections';
import {ActivitiesList} from '../../../common/ProcessSections/ActivitiesList';
import {LinksList} from '../../../common/ProcessSections/LinksList';
import {Richtext} from '../../../common/Richtext';
import {TheButton} from '../../../common/TheButton';
import {MiddleTopBarUnit} from '../../Unit/MiddleTopBarUnit';
import {getControlled} from '../helpers';
import styles from '../Process.module.scss';
import {useEdit} from './useEdit';
import {DictionaryMultiselectDropdown} from '../../../common/DictionaryDropdown/DictionaryMultiselectDropdown';
import {InfoTooltip} from '../../../common/InfoTooltip';
import {Helmet} from 'react-helmet';
import {useUnit} from '../../../../hooks/services/useUnit';
import {Acknowledgement} from '../components/Acknowledgement';

// Import Sun Editor's CSS File

type SaveForms = 'save' | 'publish' | 'publish_w_o_notification' | 'publish_w_comment';

export const EditProcess = () => {
  const {processId, unitId} = useParams();
  const processIdNum = Number(processId);
  const unitIdNum = Number(unitId);

  const {
    processData,
    unitsListForParentProcess,
    isLoading,
    processOwner,
    secondaryProcessOwner,
    canSelectProcessOwner,
    canChangeProcessParent,
    updateProcessVersionsDraft,
    publishedProcessesForUnit,
    unitDetails
  } = useEdit(processIdNum, unitIdNum);

  const {getUnitFullPath} = useUnit();
  const [processDetails, setProcessDetails] = useState<EditProcessVersion | null>();
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true);
  const [pdcOperatingUnit, setPdcOperatingUnit] = useState<string | null | undefined>();
  const [helmetTitle, setHelmetTitle] = useState<string>();

  useEffect(() => {
    (async () => {
      const unitFullPathResponse = await getUnitFullPath(unitIdNum);
      if (unitFullPathResponse.result && processData && processData?.result) {
        setHelmetTitle(`Edit: ${unitFullPathResponse.result}: ${processData.result.title}`);
      }
    })();
  }, [getUnitFullPath, unitIdNum, processData]);

  useEffect(() => {
    if (processData) {
      setProcessDetails(processData?.result);
    }
    setIsLocalLoading(false);
  }, [processData]);

  useEffect(() => {
    if (unitDetails && unitDetails.result) {
      setPdcOperatingUnit(unitDetails.result.pdcOperatingUnit);
    }
  }, [unitDetails]);
  return (
    <div className={styles.process}>
      <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
        {helmetTitle && (
          <Helmet>
            <title>{helmetTitle}</title>
          </Helmet>
        )}
        <MiddleTopBarUnit
          publishedData={publishedProcessesForUnit?.result}
          unitId={unitId ? Number(unitId) : undefined}
        />
        {isLoading || isLocalLoading ? (
          <Spinner />
        ) : (
          processData &&
          processDetails && (
            <div className={styles.formWrapper}>
              <HeadingWithDecoration text={`Edit process: ${processDetails.title}`} />
              <LastEditor
                personData={processDetails?.modifiedBy?.azureId}
                whenEdited={processDetails?.modified}
                whenPublished={processDetails?.published}
              />
              {processDetails?.modified ? (
                <MessageBar messageBarType={MessageBarType.warning} className={styles.warning}>
                  You are working on an unpublished draft of the process. Please Publish for your
                  changes to be visible to end user
                </MessageBar>
              ) : null}
              <Form
                processDetails={processDetails}
                processId={processIdNum}
                unitId={unitId}
                processOwner={processOwner}
                secondaryProcessOwner={secondaryProcessOwner}
                canSelectProcessOwner={canSelectProcessOwner}
                canChangeProcessParent={canChangeProcessParent}
                updateProcessVersionsDraft={updateProcessVersionsDraft}
                pdcOperatingUnit={pdcOperatingUnit}
                unitsListForParentProcess={unitsListForParentProcess}
              />
            </div>
          )
        )}
      </main>
    </div>
  );
};

type IForm = {
  processDetails: EditProcessVersion;
  processId: number;
  processOwner: IPersonaProps[] | undefined;
  secondaryProcessOwner: IPersonaProps[] | undefined;
  canSelectProcessOwner: boolean;
  canChangeProcessParent: boolean;
  unitId: string | undefined;
  updateProcessVersionsDraft: () => Promise<void>;
  pdcOperatingUnit: string | null | undefined;
  unitsListForParentProcess: IDropdownOption<number>[];
};

export const Form = ({
  processDetails,
  processId,
  processOwner,
  secondaryProcessOwner,
  canSelectProcessOwner,
  canChangeProcessParent,
  unitId,
  updateProcessVersionsDraft,
  pdcOperatingUnit,
  unitsListForParentProcess
}: IForm) => {
  const {updateProcessDraft, publishProcess} = useProcess();
  const {optionsProjectSizeCategory, optionsSpeciality, optionsIndustry} =
    useContext(DictionariesContext);
  const dateTommorow = dateTommorowMidnight;

  const navigate = useNavigate();
  const hiddenColumns = [
    ColumnKey.DocumentOwner,
    ColumnKey.FunctionString,
    ColumnKey.Specialty,
    ColumnKey.ProjectSizeCategory,
    ColumnKey.ApprovedDate,
    ColumnKey.SecurityLevel,
    ColumnKey.Language
  ];

  const {getProcess, process} = useContext(ProcessContext);

  const [processOwnerSelect, setProcessOwnerSelect] = useState<IPersonaProps[] | undefined>(
    processOwner
  );

  const [isMirrorCopy, setIsMirrorCopy] = useState<boolean>(processDetails.isMirrorCopy);
  const [errorMgs, setErrorMsg] = useState<{
    [key: string]: JSX.Element;
  }>({});

  const errorMessageBar = useCallback((message: string) => {
    return <MessageBar messageBarType={MessageBarType.severeWarning}>{message}</MessageBar>;
  }, []);
  const [secondaryProcessOwnerSelect, setSecondaryProcessOwnerSelect] = useState<
    IPersonaProps[] | undefined
  >(secondaryProcessOwner);
  const [processDesc, setProcessDesc] = useState<string | undefined>(undefined);
  const [processDescDefault, setProcessDescDefault] = useState<DescriptionVersionDto[]>(
    processDetails.descriptions || []
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [processDate, setProcessDate] = useState<Date | undefined>(
    new Date(processDetails.nextUpdate)
  );
  const [processDateTemp, setProcessDateTemp] = useState<Date | undefined>(
    new Date(processDetails.nextUpdate)
  );
  const [validationInputDate, setValidationInputDate] = useState<boolean>(false);

  const [activeContents, setActiveContents] = useState(
    processDetails.processActiveContents?.map(ac => ({...ac})) || []
  );
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [processPublicationComment, setProcessPublicationComment] = React.useState('');
  const onProcessPublicationCommentChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue.length <= 1024) setProcessPublicationComment(newValue || '');
    },
    []
  );

  const [customNextProcessUpdate, setCustomNextProcessUpdate] = useState<boolean | undefined>(
    processDetails.nextUpdateDateBlock
  );
  const [selectedKey, setSelectedKey] = useState<string>(isMirrorCopy ? 'inheritance' : 'details');
  const [isNextUpdateDateInvalid, setisNextUpdateDateInvalid] = useState<boolean>(false);

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedKey(item.props.itemKey!);
    }
  };

  const parentIsDeleted = useMemo(
    () =>
      unitsListForParentProcess.find(opt => opt.data === processDetails.parentProcessId)?.key
        ? false
        : true,
    [unitsListForParentProcess, processDetails.parentProcessId]
  );

                      

  useEffect(() => {
    if (selectedKey === 'details' && isNextUpdateDateInvalid)
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
  }, [isNextUpdateDateInvalid, selectedKey]);

  const onModalWithCommentClose = useCallback(() => setIsCommentModalOpen(false), []);
  const documents = useMemo(
    () => processDetails.documents?.sort(sortByTitle) || [],
    [processDetails]
  );
  const changedDocuments = useMemo<DocumentSystem[]>(() => [], []);

  const links = useMemo(
    () => processDetails.externalLinks?.sort(sortByTitle) || [],
    [processDetails]
  );
  const changedLinks = useMemo<ExternalLink[]>(() => [], []);

  const activityInputs = useMemo(
    () => processDetails.activityInputs?.sort(sortByTitle) || [],
    [processDetails]
  );
  const changedActivityInputs = useMemo<Activity[]>(() => [], []);

  const activityOutputs = useMemo(
    () => processDetails.activityOutputs?.sort(sortByTitle) || [],
    [processDetails]
  );
  const changedActivityOutputs = useMemo<Activity[]>(() => [], []);

  const [changedParentProcess, setChangedParentProcess] = useState<IDropdownOption<number>>();
  const [chosenSaveForm, setChosenSaveForm] = useState<SaveForms>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const disableChanges = !!(
    processDetails &&
    changedParentProcess &&
    changedParentProcess.data !== processDetails.parentProcessId
  );



  const disabledPivotItemProps = useMemo(() => {
    return disableChanges || isMirrorCopy
      ? {
          disabled: true,
          style: {backgroundColor: 'var(--abb-grey-5)', cursor: 'default'}
        }
      : undefined;
  }, [disableChanges, isMirrorCopy]);

  const processFunction = processDetails.function;
  const processStatus = processDetails.status;

  const maxDate = useMemo(() => new Date(processDetails.maxDate), [processDetails]);

  const createDescription = useCallback(
    (desc: string | undefined) => {
      if (typeof desc === 'string') {
        const updatedData: DescriptionVersionDto[] =
          processDetails.descriptions?.map(item =>
            item.ownerProcessId === processId ? {...item, content: desc} : item
          ) || [];
        setProcessDescDefault(updatedData);
        return updatedData;
      }
      return processDetails.descriptions;
    },
    [processDetails, processId]
  );

  const updateForm = useCallback(
    async (data: Partial<ProcessSave>) => {
      await updateProcessDraft(processId, data);
    },
    [updateProcessDraft, processId]
  );

  const publishForm = useCallback(
    async (data: Partial<ProcessSave>, notify?: boolean) => {
      return await publishProcess(processId, Boolean(notify), data);
    },
    [publishProcess, processId]
  );

  const onChangeDesc = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      const div = document.createElement('div');
      div.innerHTML = newValue || '';
      if (!div.innerText && newValue?.indexOf('<img') === -1) {
        setProcessDesc('');
      } else {
        setProcessDesc(newValue);
      }
    },
    []
  );

  useEffect(() => {
    if (!process) {
      getProcess(processId);
    }
  }, [process, getProcess, processId]);

  useEffect(() => {
    setProcessDescDefault(processDetails.descriptions || []);
  }, [processDetails]);

  const checkIfDateInThePast = useCallback(() => {
    if (processDate) {
      const minAcceptedDate = dateTommorow.valueOf();
      const nextUpdateToCompate = new Date(processDate).valueOf();
      return minAcceptedDate > nextUpdateToCompate;
    }
  }, [dateTommorow, processDate]);

  const validateParts = useCallback(
    (validateElement: boolean, errorMsg: string, errorStr: string) => {
      if (validateElement) {
        const errorText = errorMessageBar(errorStr);
        setErrorMsg(prev => ({
          ...prev,
          [errorMsg]: errorText
        }));
      } else {
        setErrorMsg(prev => ({
          ...prev,
          [errorMsg]: <></>
        }));
      }
    },
    [errorMessageBar]
  );

  const runValidateAndOpenDialog = useCallback(() => {
    const processOwnerLen = processOwnerSelect?.length;
    setValidationInputDate(!processDate);
    const isDateInThePast = checkIfDateInThePast();
    let error = false;
    if (isDateInThePast && customNextProcessUpdate) {
      const validateDate = isDateInThePast && customNextProcessUpdate;
      const errorNextUpdateDateMsg =
        'Next process update date is in the past, Please choose new next update date.';
      validateParts(validateDate, 'errorNextUpdateDateMsg', errorNextUpdateDateMsg);
      setSelectedKey('details');
      setisNextUpdateDateInvalid(true);
      error = true;
    }

    if (!processOwnerLen) {
      const processOwnerEmpty = !processOwnerLen;
      const errorEmptyProcessOwnerMsg = 'This value could not be empty. Choose Process Owner.';
      validateParts(processOwnerEmpty, 'errorEmptyProcessOwnerMsg', errorEmptyProcessOwnerMsg);
      setSelectedKey('details');
      error = true;
    }
    if (secondaryProcessOwnerSelect?.length && processOwnerLen) {
      const checkTheSameOwners =
        processOwnerSelect[0].secondaryText === secondaryProcessOwnerSelect[0].secondaryText;
      if (checkTheSameOwners) {
        const errorSameOwnersMessageMsg =
          'Process Owner and Secondary Process Owner can not be the same person.';
        validateParts(checkTheSameOwners, 'errorSameOwnersMessageMsg', errorSameOwnersMessageMsg);
        setSelectedKey('details');

        error = true;
      }
    }

    if (!error) {
      setIsCommentModalOpen(true);
    }
  }, [
    processOwnerSelect,
    processDate,
    checkIfDateInThePast,
    customNextProcessUpdate,
    secondaryProcessOwnerSelect,
    validateParts
  ]);

  const saveForm = useCallback(
    async (publish?: boolean, notify?: boolean, publicationComment?: string) => {
      let data: Partial<ProcessSave>;
      setErrorMsg({});

      if ((disableChanges && changedParentProcess) || isMirrorCopy) {
        setIsSaving(true);
        data = {
          id: processDetails.id,
          parentProcessId: changedParentProcess
            ? changedParentProcess.data
            : processDetails.parentProcessId,
          title: processDetails.title,
          type: 1,
          function: processDetails.function,
          processOwner: processDetails.processOwner,
          secondaryProcessOwner: processDetails.secondaryProcessOwner,
          descriptions: processDetails.descriptions,
          externalLinks: processDetails.externalLinks,
          activityInputs: processDetails.activityInputs,
          activityOutputs: processDetails.activityOutputs,
          isMirrorCopy: isMirrorCopy,
          documents: processDetails.documents?.map(doc => {
            const betterDoc = {...doc, removed: !!doc.removed};
            return betterDoc;
          }),
          processActiveContents: processDetails.processActiveContents,
          nextUpdate: processDetails.nextUpdate,
          nextUpdateDateBlock: processDetails.nextUpdateDateBlock,
          publicationComment
        };
      } else {
        const processOwnerLen = processOwnerSelect?.length;
        setValidationInputDate(!processDate);
        const isDateInThePast = checkIfDateInThePast();
        const publishConfirmAndValidity = publish && notify;

        if (
          !processOwnerLen ||
          !processDate ||
          (isDateInThePast && customNextProcessUpdate && publishConfirmAndValidity)
        ) {
          if (isDateInThePast && customNextProcessUpdate && publishConfirmAndValidity) {
            const validateDate = isDateInThePast && customNextProcessUpdate;
            const errorNextUpdateDateMsg =
              'Next process update date is in the past, Please choose new next update date.';
            validateParts(validateDate, 'errorNextUpdateDateMsg', errorNextUpdateDateMsg);
            setSelectedKey('details');
            setisNextUpdateDateInvalid(true);
          }

          if (!processOwnerLen) {
            const processOwnerEmpty = !processOwnerLen;
            const errorEmptyProcessOwnerMsg =
              'This value could not be empty. Choose Process Owner.';
            validateParts(
              processOwnerEmpty,
              'errorEmptyProcessOwnerMsg',
              errorEmptyProcessOwnerMsg
            );
            setSelectedKey('details');
          }
          return;
        }

        let secondaryProcess: UserDto | null = null;
        if (secondaryProcessOwnerSelect?.length && processOwnerLen) {
          const checkTheSameOwners =
            processOwnerSelect[0].secondaryText === secondaryProcessOwnerSelect[0].secondaryText;
          if (checkTheSameOwners) {
            const errorSameOwnersMessageMsg =
              'Process Owner and Secondary Process Owner can not be the same person.';
            validateParts(
              checkTheSameOwners,
              'errorSameOwnersMessageMsg',
              errorSameOwnersMessageMsg
            );
            setSelectedKey('details');

            return;
          } else
            secondaryProcess = {
              azureId: (secondaryProcessOwnerSelect[0].key || undefined) as string,
              userPrincipalName: secondaryProcessOwnerSelect[0].secondaryText || null
            } as UserDto;
        }

        const fullDesc = createDescription(processDesc);

        setIsSaving(true);
        data = {
          id: processDetails.id,
          parentProcessId: processDetails.parentProcessId,
          title: processDetails.title,
          type: 1,
          function: processFunction,
          processOwner: {
            azureId: (processOwnerSelect[0].key || undefined) as string,
            userPrincipalName: processOwnerSelect[0].secondaryText || null
          } as UserDto,
          secondaryProcessOwner: secondaryProcess,
          descriptions: fullDesc,
          externalLinks: changedLinks.map(
            link =>
              new ExternalLink({
                ...link,
                removed: !!link.removed,
                ownerProcessId: link.ownerProcessId || processId
              })
          ),
          activityInputs: changedActivityInputs,
          activityOutputs: changedActivityOutputs,
          documents: changedDocuments.map(
            document =>
              new DocumentSave({
                id: document.id,
                link: document.link,
                deleted: document.deleted,
                removed: document.removed || false,
                version: document.version,
                active: document.active,
                auditable: document.auditable || false,
                order: document.order,
                ownerProcessId: document.ownerProcessId || processId
              })
          ),
          processActiveContents: activeContents,
          nextUpdate: toISODate(processDate),
          isMirrorCopy: isMirrorCopy,
          nextUpdateDateBlock: customNextProcessUpdate,
          publicationComment
        };
      }
      if (publish) {
        const saveRes = await publishForm(data, notify);
        if (saveRes && saveRes.apiCode) await updateProcessVersionsDraft();
        else {
          await getProcess(processId, true);
          navigate(`/unit/${unitId}/process/${processId}`);
        }
      } else {
        await updateForm(data);
        await updateProcessVersionsDraft();
      }
      /*
      await updateProcessVersionsDraft();
      if (process) {
        await getProcess(process?.id, true);
      }
      */
      // setIsSaving(false);
    },
    [
      disableChanges,
      changedParentProcess,
      isMirrorCopy,
      processDetails.id,
      processDetails.title,
      processDetails.function,
      processDetails.processOwner,
      processDetails.secondaryProcessOwner,
      processDetails.descriptions,
      processDetails.externalLinks,
      processDetails.activityInputs,
      processDetails.activityOutputs,
      processDetails.documents,
      processDetails.processActiveContents,
      processDetails.nextUpdate,
      processDetails.nextUpdateDateBlock,
      processDetails.parentProcessId,
      processOwnerSelect,
      validateParts,
      processDate,
      checkIfDateInThePast,
      customNextProcessUpdate,
      secondaryProcessOwnerSelect,
      createDescription,
      processDesc,
      processFunction,
      changedLinks,
      changedActivityInputs,
      changedActivityOutputs,
      changedDocuments,
      activeContents,
      processId,
      publishForm,
      updateProcessVersionsDraft,
      getProcess,
      navigate,
      unitId,
      updateForm
    ]
  );
  const onModalWithCommentSubmit = useCallback(() => {
    saveForm(true, true, processPublicationComment);
  }, [processPublicationComment, saveForm]);

  const onDialogConfirm = useCallback(
    (chosenSaveForm: SaveForms | undefined) => {
      switch (chosenSaveForm) {
        case 'publish':
          return saveForm(true, true);
        case 'publish_w_comment':
          return setIsCommentModalOpen(true);
        case 'publish_w_o_notification':
          return saveForm(true, false);
        case 'save':
          return saveForm();
        default:
          break;
      }
    },
    [saveForm]
  );

  const checkTheSameOwners = (
    value: IPersonaProps[] | undefined,
    owner: IPersonaProps[] | undefined
  ): boolean => {
    return value &&
      value[0] &&
      owner &&
      owner[0] &&
      value[0].secondaryText === owner[0].secondaryText
      ? true
      : false;
  };

  const toggleWithTooltip: JSX.Element = (
    <Stack horizontal verticalAlign="center">
      <Label>Enable mirror content from parent process</Label>
      <InfoTooltip content="Button is disabled when parent is empty" />
    </Stack>
  );  

  return (
    <>
      {isSaving ? (
        <Spinner />
      ) : (
        <>
          <ModalWithHeader
            dismiss={onModalWithCommentClose}
            isVisible={isCommentModalOpen}
            header={'Comment'}>
            <div className={styles.formWrapper}>
              <TextField
                multiline
                resizable={false}
                value={processPublicationComment}
                onChange={onProcessPublicationCommentChange}
                description={processPublicationComment.length.toString() + '/1024'}
                onRenderDescription={(props: ITextFieldProps | undefined) => (
                  <Text variant="small" styles={{root: {float: 'right'}}}>
                    {props?.description}
                  </Text>
                )}></TextField>
              <div className={styles.disclaimer}>
                By publishing a new revision of this process I confirm, that this process page and
                all documentation is up-to-date and does not require any modification.
              </div>
              <p></p>
            </div>
            <FormFooter
              onCancel={onModalWithCommentClose}
              onSubmit={onModalWithCommentSubmit}
              saveLabel="Publish and confirm process validity"
              addTooltip={
                'Publish standard or major change. Next process update will change, notification will be sent and comment can be added.'
              }
            />
          </ModalWithHeader>
          <Dialog
            hidden={!isConfirmationDialogOpen}
            onDismiss={() => setIsConfirmationDialogOpen(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Are you sure?',
              closeButtonAriaLabel: 'Close',
              subText:
                'Do you want to change Parent Process? This change will cause all other unsaved changes to be lost'
            }}>
            <DialogFooter>
              <PrimaryButton onClick={() => onDialogConfirm(chosenSaveForm)} text="Confirm" />
              <DefaultButton onClick={() => setIsConfirmationDialogOpen(false)} text="Cancel" />
            </DialogFooter>
          </Dialog>
          <form>
            <Pivot selectedKey={selectedKey} onLinkClick={handleLinkClick}>
              <PivotItem
                itemKey="details"
                headerText="Details"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <TextField
                  label="Title"
                  value={processDetails.title || undefined}
                  readOnly
                  disabled
                />
                {processDescDefault.map(desc => {
                  let ownerProcessName: string = desc.unitTitle ? desc.unitTitle : desc.id + '';
                  if (!desc.unitTitle && process) {
                    if (desc.ownerProcessId === process.id) {
                      ownerProcessName = process.unit?.title || '';
                    } else {
                      const processActiveContent = process.processActiveContents?.filter(
                        process => process.parentProcessId === desc.ownerProcessId
                      )[0];
                      ownerProcessName = processActiveContent?.unit || '';
                    }
                  }
                  return (
                    <div key={desc.id} className={styles.descriptionWrapper}>
                      <Label>Description: {ownerProcessName}</Label>
                      <Richtext
                        defaultValue={
                          (desc.ownerProcessId !== processId ? undefined : processDesc) ||
                          desc.content ||
                          undefined
                        }
                        readOnly={desc.ownerProcessId !== processId}
                        disable={false}
                        hideToolbar={desc.ownerProcessId !== processId}
                        onChange={content => onChangeDesc(undefined as any, content)}
                      />
                    </div>
                  );
                })}
                {errorMgs.errorEmptyProcessOwnerMsg}
                <Label htmlFor={'people'} required>
                  Process Owner
                </Label>
                <PeoplePicker
                  key={'normal'}
                  defaultSelectedPerson={processOwnerSelect}
                  isDisabled={!canSelectProcessOwner}
                  onSelectPerson={value => {
                    setProcessOwnerSelect(value);
                    const errorSameOwnersMessageMsg =
                      'Process Owner and Secondary Process Owner can not be the same person.';
                    validateParts(
                      checkTheSameOwners(value, secondaryProcessOwnerSelect),
                      'errorSameOwnersMessageMsg',
                      errorSameOwnersMessageMsg
                    );
                    validateParts(false, 'errorEmptyProcessOwnerMsg', '');
                  }}
                />
                {errorMgs.errorSameOwnersMessageMsg}
                <Label htmlFor={'secondaryProcessOwner'}>Secondary Process Owner</Label>
                <PeoplePicker
                  key={'secondaryProcessOwner'}
                  isDisabled={!canSelectProcessOwner}
                  defaultSelectedPerson={secondaryProcessOwnerSelect}
                  onSelectPerson={value => {
                    setSecondaryProcessOwnerSelect(value);
                    const errorSameOwnersMessageMsg =
                      'Process Owner and Secondary Process Owner can not be the same person.';
                    validateParts(
                      checkTheSameOwners(value, processOwnerSelect),
                      'errorSameOwnersMessageMsg',
                      errorSameOwnersMessageMsg
                    );
                  }}
                />
                <TextField
                  label="Function"
                  value={processFunction?.value || undefined}
                  readOnly
                  disabled
                />
                <Label disabled>Project Size Category</Label>
                <DictionaryMultiselectDropdown
                  onChange={() => {}}
                  disabled
                  options={optionsProjectSizeCategory}
                  defaultValues={processDetails.projectSizeCategories}
                />
                <Label disabled>Industry</Label>
                <DictionaryMultiselectDropdown
                  onChange={() => {}}
                  disabled
                  options={optionsIndustry}
                  defaultValues={processDetails.industries}
                />
                <Label disabled>Speciality</Label>
                <DictionaryMultiselectDropdown
                  onChange={() => {}}
                  disabled
                  options={optionsSpeciality}
                  defaultValues={processDetails.specialities}
                />
                <TextField label="Status" value={processStatus || undefined} readOnly disabled />
                {errorMgs.errorNextUpdateDateMsg}
                <Label required>Next process update</Label>
                <DatePicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  minDate={dateTommorow}
                  maxDate={maxDate}
                  onSelectDate={date => {
                    setProcessDate(date || processDate);
                    setProcessDateTemp(date || processDate);
                    setValidationInputDate(false);
                    validateParts(false, 'errorNextUpdateDateMsg', '');
                  }}
                  value={processDate}
                />
                {validationInputDate && (
                  <InputValidationError
                    message={'This value could not be empty. Choose next update.'}
                  />
                )}
                <Checkbox
                  label="Select next process update manually"
                  defaultChecked={processDetails.nextUpdateDateBlock}
                  onChange={(ev, checked) => {
                    setCustomNextProcessUpdate(checked);
                    !checked
                      ? setProcessDate(new Date(processDetails.maxDate))
                      : setProcessDate(processDateTemp);
                    validateParts(false, 'errorNextUpdateDateMsg', '');
                  }}
                />
              </PivotItem>
              <PivotItem
                itemKey="documents"
                headerText="Documents"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <DocumentAndFile
                  allowAdd
                  data={getControlled(documents)}
                  changedDocuments={changedDocuments}
                  hiddenColumns={hiddenColumns}
                  ownerProcessId={processId}
                  pdcOperatingUnit={pdcOperatingUnit}
                />
              </PivotItem>
              <PivotItem
                itemKey="links"
                headerText="Links"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <LinksList
                  allowAdd
                  data={links}
                  changedData={changedLinks}
                  ownerProcessId={processId}
                />
              </PivotItem>
              <PivotItem
                itemKey="inputs"
                headerText="Activity Inputs"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <ActivitiesList
                  allowAdd
                  unitId={Number(unitId)}
                  data={activityInputs}
                  changedData={changedActivityInputs}
                  ownerProcessId={processId}
                  isInput={true}
                />
              </PivotItem>
              <PivotItem
                itemKey="outputs"
                headerText="Activity Outputs"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <ActivitiesList
                  allowAdd
                  unitId={Number(unitId)}
                  data={activityOutputs}
                  changedData={changedActivityOutputs}
                  ownerProcessId={processId}
                  isInput={false}
                />
              </PivotItem>
              <PivotItem
                itemKey="inheritance"
                headerText="Content Inheritance"
                className={styles.tab}>
                <MessageBar messageBarType={MessageBarType.info}>
                  Please save the form to reflect changes in other tabs
                </MessageBar>
                {/* <ComboBox label="Change Parent Process Unit" options={[]} /> */}
                {processDetails && canChangeProcessParent && (
                  <>

                        <Toggle
                          className={styles.toggle}
                          label={toggleWithTooltip}
                          inlineLabel
                          disabled={
                            disableChanges || !processDetails.parentProcessId || parentIsDeleted
                          }
                          defaultChecked={isMirrorCopy}
                          onChange={(ev, newValue) => {
                            setIsMirrorCopy(newValue || false);
                          }}
                        />
                    

                    <div className={styles.table}>
                      <Dropdown
                        label="Parent Process Unit"
                        disabled={isMirrorCopy}
                        defaultSelectedKey={
                          unitsListForParentProcess.find(
                            opt => opt.data === processDetails.parentProcessId
                          )?.key
                        }
                        onRenderLabel={labelProps => (
                          <Stack horizontal verticalAlign="center">
                            <Label>{labelProps?.label}</Label>
                            <InfoTooltip
                              content={
                                'Some units may be disabled and marked with an asterisk - impossible to be selected due to potential circular references'
                              }
                            />
                          </Stack>
                        )}
                        options={unitsListForParentProcess}
                        onChange={(_, option) => {
                          setChangedParentProcess(option);
                        }}
                      />
                    </div>
                    {disableChanges && (
                      <MessageBar messageBarType={MessageBarType.severeWarning}>
                        Parent process change detected. Existing process content modifications
                        cannot be saved during parent process change operation. This operation
                        cannot be saved as draft.
                      </MessageBar>
                    )}
                    {isMirrorCopy && (
                      <MessageBar messageBarType={MessageBarType.severeWarning}>
                        Mirror copy is enabled. All content from the parent process is copied to
                        this process. If you want to make changes, please go to the parent process
                        or turn off Mirror copy.
                      </MessageBar>
                    )}
                  </>
                )}
                <div className={styles.table}>
                  <b className={styles.cell}>Process</b>
                  <b className={styles.cell}>Description</b>
                  <b className={styles.cell}>Documents</b>
                  <b className={styles.cell}>Links</b>
                  <b className={styles.cell}>Activity Inputs</b>
                  <b className={styles.cell}>Activity Outputs</b>
                  {activeContents.map(activeContent => (
                    <React.Fragment key={activeContent.id}>
                      <div className={styles.cell}>{activeContent.unit}</div>
                      <div className={styles.cell}>
                        <Toggle
                          onText="On"
                          offText="Off"
                          inlineLabel
                          disabled={disableChanges || isMirrorCopy}
                          defaultChecked={activeContent.descriptionActive}
                          onChange={(e, checked) => {
                            activeContent.descriptionActive = !!checked;
                            setActiveContents([...activeContents]);
                          }}
                        />
                      </div>
                      <div className={styles.cell}>
                        <Toggle
                          onText="On"
                          offText="Off"
                          inlineLabel
                          disabled={disableChanges || isMirrorCopy}
                          defaultChecked={activeContent.documentsActive}
                          onChange={(e, checked) => {
                            activeContent.documentsActive = !!checked;
                            setActiveContents([...activeContents]);
                          }}
                        />
                      </div>
                      <div className={styles.cell}>
                        <Toggle
                          onText="On"
                          offText="Off"
                          inlineLabel
                          disabled={disableChanges || isMirrorCopy}
                          defaultChecked={activeContent.externalLinksActive}
                          onChange={(e, checked) => {
                            activeContent.externalLinksActive = !!checked;
                            setActiveContents([...activeContents]);
                          }}
                        />
                      </div>
                      <div className={styles.cell}>
                        <Toggle
                          onText="On"
                          offText="Off"
                          inlineLabel
                          disabled={disableChanges || isMirrorCopy}
                          defaultChecked={activeContent.activityInputsActive}
                          onChange={(e, checked) => {
                            activeContent.activityInputsActive = !!checked;
                            setActiveContents([...activeContents]);
                          }}
                        />
                      </div>
                      <div className={styles.cell}>
                        <Toggle
                          onText="On"
                          offText="Off"
                          inlineLabel
                          disabled={disableChanges || isMirrorCopy}
                          defaultChecked={activeContent.activityOutputsActive}
                          onChange={(e, checked) => {
                            activeContent.activityOutputsActive = !!checked;
                            setActiveContents([...activeContents]);
                          }}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </PivotItem>
              <PivotItem
                itemKey="acknowledgement"
                headerText="Acknowledgement"
                className={styles.tab}
                headerButtonProps={disabledPivotItemProps}>
                <Acknowledgement
                  data={getControlled(documents)}
                  ownerProcessId={processId}
                  changedDocuments={changedDocuments}
                  processId={processId}
                  unitId={unitId}
                />
              </PivotItem>
            </Pivot>
            {selectedKey !== 'acknowledgement' && (
              <div className={styles.footer}>
                <div className={styles.footerLeft}>
                  <TheButton
                    onClick={() => {
                      navigate(`/unit/${unitId}/process/${processId}`);
                    }}>
                    Cancel
                  </TheButton>
                </div>
                <div className={styles.footerRight}>
                  <TheButton
                    primary
                    className={styles.buttonsAction}
                    iconProps={{
                      iconName: 'SaveTemplate'
                    }}
                    disabled={disableChanges || isMirrorCopy}
                    onClick={() => {
                      if (
                        changedParentProcess &&
                        changedParentProcess.data !== process?.parentProcessId
                      ) {
                        setChosenSaveForm('save');
                        setIsConfirmationDialogOpen(true);
                      } else saveForm();
                    }}>
                    Save
                  </TheButton>
                  <TooltipHost content={'Publish minor change. No notification will be sent.'}>
                    <TheButton
                      primary
                      className={styles.split}
                      iconProps={{
                        iconName: 'MailPause'
                      }}
                      onClick={() => {
                        if (
                          changedParentProcess &&
                          changedParentProcess.data !== process?.parentProcessId
                        ) {
                          setChosenSaveForm('publish_w_o_notification');
                          setIsConfirmationDialogOpen(true);
                        } else saveForm(true);
                      }}>
                      Publish
                    </TheButton>
                  </TooltipHost>
                  <TooltipHost
                    content={
                      'Publish standard or major change. Next process update will change, notification will be sent and comment can be added.'
                    }>
                    <TheButton
                      primary
                      disabled={isMirrorCopy}
                      className={styles.split}
                      iconProps={{
                        iconName: 'MailAttached'
                      }}
                      onClick={() => {
                        if (
                          changedParentProcess &&
                          changedParentProcess.data !== process?.parentProcessId
                        ) {
                          setChosenSaveForm('publish_w_comment');
                          setIsConfirmationDialogOpen(true);
                        } else runValidateAndOpenDialog();
                      }}>
                      Publish and confirm process validity
                    </TheButton>
                  </TooltipHost>
                </div>
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};
