import styles from '../Process.module.scss';
import {ApiResponse} from '../../../../services/ApiResponseType';
import {Breadcrumbs} from '../../../common/Breadcrumbs';
import {FC, PropsWithChildren, useEffect, useMemo, useState} from 'react';
import {Footer} from '../components/Footer';
import {MessageBar, MessageBarType, Spinner} from '@fluentui/react';
import {MiddleTopBarUnit} from '../../Unit/MiddleTopBarUnit';
import {Navigate, useNavigate} from 'react-router-dom';
import {NextProcessButton} from './NextProcessButton';
import {PreviousProcessButton} from './PreviousProcessButton';
import {Process, ProcessTree, RolePermission} from '../../../../models';
import {ProcessType} from '../../../../enums';
import {TheButton} from '../../../common/TheButton';
import {useService} from '../../../../hooks';

interface IProcessWrapper {
  apiMsg: string | null | undefined;
  isLoading: boolean;
  process: Process | null;
  canEdit: boolean;
  publishedProcessesForUnit: ApiResponse<ProcessTree[]> | undefined;
}

export const ProcessWrapper: FC<PropsWithChildren<IProcessWrapper>> = ({
  apiMsg,
  children,
  isLoading,
  process,
  canEdit,
  publishedProcessesForUnit
}) => {
  const navigate = useNavigate();
  const {getUserPermissionsPage} = useService();
  let nextProcess: undefined | ProcessTree;
  let previousProcess: undefined | ProcessTree;
  const recursiveFindLastChild = (node: ProcessTree): ProcessTree => {
    if (node.children?.length)
      return recursiveFindLastChild(node.children[node.children.length - 1]);
    return node;
  };
  const recursiveNextPrev = (
    processId: number,
    nodes: ProcessTree[],
    findNext: boolean,
    findPrev: boolean,
    parent?: ProcessTree,
    nextSibling?: ProcessTree
  ) => {
    for (let i = 0; i < nodes.length; i++) {
      const processTree = nodes[i];
      if (processTree.id === processId) {
        if (findNext) {
          /* get next process ID */
          const children = nodes[i].children;
          /* if has children - next proces is first child */
          if (children && children[0]) {
            nextProcess = children[0];
          } else if (nodes[i + 1]) {
            /* else if has next sibling - next process is next sibling */
            nextProcess = nodes[i + 1];
          } else if (parent && nextSibling) {
            /* else if has parent - next sibling is parents next sibling */
            nextProcess = nextSibling;
          }
        }
        if (findPrev) {
          /* get prev process ID */
          /* if I am first node - previous process is my parent */
          if (i === 0 && parent) {
            previousProcess = parent;
          } else if (i > 0) {
            /*else - previous process is last grandchild of previous sibling */
            const prevProcess = recursiveFindLastChild(nodes[i - 1]);
            previousProcess = prevProcess;
          }
        }
        return true;
      } else if (processTree.children?.length) {
        const found = recursiveNextPrev(
          processId,
          processTree.children,
          findNext,
          findPrev,
          processTree,
          nodes[i + 1] || nextSibling
        );
        if (found) return true;
      }
    }
    if (findNext) {
      nextProcess = undefined;
    }
    if (findPrev) {
      previousProcess = undefined;
    }
  };
  const recursiveNonDeletedChildren = useMemo(
    () => (children: ProcessTree[]) => {
      const result: ProcessTree[] = [];
      for (const item of children) {
        if (!item.deleted) {
          const newItem = new ProcessTree(item);
          if (newItem.children) {
            newItem.children = recursiveNonDeletedChildren(newItem.children);
          }
          result.push(newItem);
        }
      }
      return result;
    },
    []
  );

  const nonDeletedPublishedProcessesForUnit = useMemo(() => {
    if (!publishedProcessesForUnit?.result) return;
    return recursiveNonDeletedChildren(publishedProcessesForUnit.result);
  }, [publishedProcessesForUnit, recursiveNonDeletedChildren]);

  if (nonDeletedPublishedProcessesForUnit && process?.id) {
    recursiveNextPrev(process.id, nonDeletedPublishedProcessesForUnit, true, true);
    while (nextProcess?.id && nextProcess?.processType === ProcessType.NonProcess) {
      recursiveNextPrev(nextProcess.id, nonDeletedPublishedProcessesForUnit, true, false);
    }
    while (previousProcess?.id && previousProcess?.processType === ProcessType.NonProcess) {
      recursiveNextPrev(previousProcess.id, nonDeletedPublishedProcessesForUnit, false, true);
    }
  }

  const unitId = process?.unit?.id;

  const [unitPermissions, setUnitPermissions] = useState<ApiResponse<RolePermission[]>>();

  useEffect(() => {
    (async () => {
      if (unitId) {
        const permissions = await getUserPermissionsPage(unitId);
        setUnitPermissions(permissions);
      }
    })();
  }, [getUserPermissionsPage, unitId]);
  return (
    <main key={process?.id} className={`noPaddingTop ${isLoading ? 'isLoading' : ''}`}>
      {isLoading ? (
        <Spinner />
      ) : process ? (
        <div className={styles.process}>
          <MiddleTopBarUnit
            publishedData={publishedProcessesForUnit?.result}
            unitId={unitId}
            unitPermissions={unitPermissions}
            farItemsOverride={
              canEdit ? (
                <TheButton primary onClick={() => navigate(`edit`)}>
                  Edit Process
                </TheButton>
              ) : undefined
            }
          />
          {process.type === ProcessType.NonProcess ? (
            <Navigate replace to={`/unit/${unitId || 0}/process/accessDenied`} />
          ) : null}
          <div className={styles.nav}>
            <Breadcrumbs processId={process.id} unitId={unitId || 0} />
            <div className={styles.buttonsNav}>
              <PreviousProcessButton
                unitId={unitId}
                previousProcessId={previousProcess?.id || undefined}
              />
              <NextProcessButton unitId={unitId} nextProcessId={nextProcess?.id || undefined} />
            </div>
          </div>
          {children}
          <Footer
            canEdit={canEdit}
            unitId={unitId}
            nextProcessId={nextProcess?.id || undefined}
            previousProcessId={previousProcess?.id || undefined}
          />
        </div>
      ) : (
        !apiMsg && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            Sorry, no data to show.
          </MessageBar>
        )
      )}
    </main>
  );
};
