import {FC, useCallback, useState} from 'react';
import {ProcessComment, ProcessCommentManage} from '../../../../../models';
import {useComments} from '../../../../../hooks/services/useComments';
import {ModalWithHeader} from '../../../../common/ModalWithHeader';
import {FormFooter} from '../../../../common/FormFooter';

interface IFeedbackDeleteProps {
  isOpen: boolean;
  comment: ProcessComment | ProcessCommentManage;
  onClose: () => void;
  refresh: (skipLoading?: boolean) => void;
}

export const FeedbackDelete: FC<IFeedbackDeleteProps> = ({isOpen, comment, refresh, onClose}) => {
  const {deleteComment} = useComments();
  const [isLoading, setIsLoading] = useState(false);
  const onDelete = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await deleteComment(comment.id);
      await refresh();
    })();
  }, [comment, deleteComment, refresh]);
  return (
    <ModalWithHeader dismiss={onClose} isVisible={isOpen} header="Delete comment?">
      <div
        className={`sun-editor sun-editor-editable no-border`}
        dangerouslySetInnerHTML={{
          __html: comment.comment || ''
        }}
      />
      <FormFooter
        onCancel={onClose}
        onSubmit={onDelete}
        saveLabel="Delete"
        isDisabled={isLoading}
      />
    </ModalWithHeader>
  );
};
