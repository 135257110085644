import {Checkbox, Icon} from '@fluentui/react';

import {TheButton} from '../../TheButton';
import {ITreeItem} from '../ITreeItem';
import styles from '../TreeBuilder.module.scss';
import {ITreeRowProps} from './ITreeRowProps';
import stylesRow from './TreeRow.module.scss';
import {TreeRows} from './TreeRows';
import {useTreeRow} from './useTreeRow';
import {useTreeInheritanceCheckboxStatus} from './useTreeInheritanceCheckboxStatus';

export const TreeRow = <ITEM extends ITreeItem>(props: ITreeRowProps<ITEM>) => {
  const {
    level,
    columns,
    itemsFlat,
    item,
    initialOpen,
    checkedIds,
    setCheckedIds,
    editIds,
    setEditIds,
    onOpenChange,
    customClassName
  } = props;

  const {isOpen, onChevronClick, onCheckboxClick, hasChildren, onEditClick, editSubOptions} =
    useTreeRow(props);

  const {onCheckboxAdvancedClick, editAdvancedSubOptions} = useTreeInheritanceCheckboxStatus(props);
  return (
    <>
      {columns
        .filter(column => !column.hidden)
        .map((column, index) => (
          <div
            key={column.key}
            className={`${styles.displayGridCell}  ${customClassName ? customClassName : ''}`}>
            {index === 0 && (level || !hasChildren) ? (
              <div
                style={{
                  paddingLeft: `${(level + (hasChildren ? 0 : 1)) * 25}px`
                }}
              />
            ) : null}
            {index === 0 && hasChildren && (
              <div className={styles.displayGridToggle}>
                <Icon
                  onClick={onChevronClick}
                  iconName="ChevronDownMed"
                  className={`${stylesRow.chevronIcon} ${isOpen ? stylesRow.isOpen : ''}`}
                />
              </div>
            )}
            {column.type === 'title' ? (
              column.showCheckbox ? (
                <Checkbox
                  disabled={item.disabled}
                  label={column.getTitle ? column.getTitle(item) : undefined}
                  checked={checkedIds.some(id => id === item.id)}
                  onChange={onCheckboxClick}
                />
              ) : (
                <>{column.getTitle ? column.getTitle(item) : undefined}</>
              )
            ) : column.type === 'edit' ? (
              <TheButton
                isCustom
                split
                classNameButton={stylesRow.editButton}
                menuProps={editSubOptions(column)}
                onClick={() => onEditClick(column)}>
                <Checkbox
                  styles={{
                    root: {
                      pointerEvents: 'none'
                    }
                  }}
                  checked={editIds.some(id => id === item.id)}
                />
              </TheButton>
            ) : column.type === 'inheritStatusCheckbox' ? (
              <div className={stylesRow.inheritStatusCheckboxOuterWrapper}>
                <div
                  className={
                    hasChildren
                      ? stylesRow.inheritStatusCheckboxWrapper
                      : stylesRow.inheritStatusCheckboxNoChild
                  }>
                  <Checkbox
                    onChange={(ev, checked) => onCheckboxAdvancedClick(column, ev, checked)}
                    checked={checkedIds.some(id => id === item.id)}
                  />
                  {hasChildren && (
                    <TheButton
                      isCustom
                      classNameButton={stylesRow.editAdvancedButton}
                      menuProps={editAdvancedSubOptions(column)}></TheButton>
                  )}
                </div>
                <span>{column.getTitle ? column.getTitle(item) : undefined}</span>
              </div>
            ) : column.type === 'bulk' ? (
              column.onRenderColumn(item, column, !item.isChecked)
            ) : (
              column.onRenderColumn(item, column, !item.isChecked)
            )}
          </div>
        ))}
      {isOpen && hasChildren ? (
        <TreeRows
          level={level + 1}
          columns={columns}
          itemsFlat={itemsFlat}
          parentId={item.id}
          initialOpen={initialOpen}
          onOpenChange={onOpenChange}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          editIds={editIds}
          setEditIds={setEditIds}
          customClassName={customClassName}
        />
      ) : null}
    </>
  );
};
