import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';

import {AuthenticationContext} from './AuthenticationContext';

import {PublicClientApplication} from '@azure/msal-browser';
import {AuthenticationResult} from '@azure/msal-common';
import {OpenAPI} from '../services/generated';
import {Providers} from '@microsoft/mgt-react';
import {Msal2Provider} from '@microsoft/mgt';
import {Spinner} from '@fluentui/react';

export const AuthenticationProvider: FC<PropsWithChildren> = ({children}) => {
  const [authentication, setAuthentication] = useState<AuthenticationResult | null>(null);
  const msal2 = useMemo(
    () =>
      new Msal2Provider({
        publicClientApplication: new PublicClientApplication({
          auth: {
            clientId: `${process.env.REACT_APP_CLIENT_ID}`,
            authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
            redirectUri: window.location.origin
          },
          cache: {
            cacheLocation: 'localStorage'
          }
        }),
        scopes: [
          `api://${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`,
          'openid',
          'profile',
          'User.Read',
          'User.Read.All',
          'User.ReadBasic.All',
          'offline_access'
        ]
      }),
    []
  );

  const acquireToken = useMemo(
    () => async (scopes?: string[]) => {
      try {
        const tokenResult = await msal2.publicClientApplication.acquireTokenSilent({
          scopes: scopes || [`api://${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`]
        });
        setAuthentication(tokenResult);
        return tokenResult.accessToken;
      } catch (e) {
        await msal2.publicClientApplication.acquireTokenRedirect({
          scopes: scopes || [`api://${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`]
        });
        return '';
      }
    },
    [msal2]
  );

  const logout = useCallback(async () => {
    await msal2.logout();
  }, [msal2]);

  const acquireTokenNoScope = useMemo(() => () => acquireToken(), [acquireToken]);

  useEffect(() => {
    Providers.globalProvider = msal2;
    OpenAPI.BASE = process.env.REACT_APP_API_URL as string;
  }, [msal2]);

  useEffect(() => {
    OpenAPI.TOKEN = acquireTokenNoScope;

    Providers.onProviderUpdated(acquireTokenNoScope);
    acquireTokenNoScope();

    return () => {
      Providers.removeProviderUpdatedListener(acquireTokenNoScope);
    };
  }, [acquireTokenNoScope]);

  return (
    <AuthenticationContext.Provider
      value={{auth: authentication, isLoading: !authentication, acquireToken, logout}}>
      {authentication ? children : <Spinner />}
    </AuthenticationContext.Provider>
  );
};
