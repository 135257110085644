import {useMemo} from 'react';
import {Activity} from '../../models';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {LogMessageType} from '../../services/LogMessagesType';
import {ActivityService, SaveActivityDto, UpdateActivityDto} from '../../services/generated';
import {useApiService} from '../../services/helpers';

export const useActivities = () => {
  const requestWrapper = useApiService();

  const getActivityInputs = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Activity[]> => {
    return await requestWrapper(
      ActivityService.getApiActivityActivityInput(unitId),
      result => result.map(item => new Activity(item)),
      log
    );
  };

  const addActivityInput = async (
    activity: SaveActivityDto,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SaveActivityDto> => {
    return await requestWrapper(
      ActivityService.postApiActivityActivityInput(activity),
      result => result,
      log
    );
  };

  const getActivityOutputs = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Activity[]> => {
    return await requestWrapper(
      ActivityService.getApiActivityActivityOutput(unitId),
      result => result.map(item => new Activity(item)),
      log
    );
  };

  const addActivityOutput = async (
    activity: SaveActivityDto,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<SaveActivityDto> => {
    return await requestWrapper(
      ActivityService.postApiActivityActivityOutput(activity),
      result => result,
      log
    );
  };

  const updateActivityInput = async (
    activityId: number,
    requestBody: UpdateActivityDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Activity Input ${requestBody.title ? 'saved' : 'created'} successfully`
    }
  ): ApiResponsePending<SaveActivityDto> => {
    return await requestWrapper(
      ActivityService.putApiActivityActivityInput(activityId, requestBody),
      (result, apiCode) => result,
      log
    );
  };

  const updateActivityOutput = async (
    activityId: number,
    requestBody: UpdateActivityDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Activity Output ${requestBody.title ? 'saved' : 'created'} successfully`
    }
  ): ApiResponsePending<SaveActivityDto> => {
    return await requestWrapper(
      ActivityService.putApiActivityActivityOutput(activityId, requestBody),
      (result, apiCode) => result,
      log
    );
  };

  const deleteActivityActivityInput = async (
    activityId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ActivityService.deleteApiActivityActivityInput(activityId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const deleteActivityActivityOutput = async (
    activityId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      ActivityService.deleteApiActivityActivityOutput(activityId),
      (result, apiCode) => !apiCode,
      log
    );
  };

  return useMemo(
    () => ({
      getActivityInputs,
      addActivityInput,
      getActivityOutputs,
      addActivityOutput,
      updateActivityInput,
      updateActivityOutput,
      deleteActivityActivityOutput,
      deleteActivityActivityInput
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
