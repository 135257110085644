import {DiagramType} from '../services/generated';

interface IProcessDiagramBase {
  processId: number;
  type: DiagramType | null;
}

export class ProcessDiagramBase {
  public processId: number;
  public type: DiagramType;

  public get typeName() {
    switch (this.type) {
      case DiagramType.Normal:
        return 'Normal';
      case DiagramType.Matrix:
        return 'Matrix';
      case DiagramType.Activity:
        return 'Activity';
      case DiagramType.None:
        return 'None';
      case DiagramType.Inherited:
        return 'Inherited';
      default:
        return '';
    }
  }

  constructor(diagram: IProcessDiagramBase) {
    this.processId = diagram.processId;
    this.type = diagram.type || DiagramType.None;
  }
}
