import styles from '../Process.module.scss';
import {FC, useCallback, useEffect} from 'react';
import {Person, PersonCardInteraction, PersonViewType} from '@microsoft/mgt-react';
import {UserDto} from '../../../../services/generated';

interface IProcessOwnerProps {
  processOwner: UserDto;
}

export const ProcessOwner: FC<IProcessOwnerProps> = ({processOwner}) => {
  const addProcessOwner = useCallback(() => {
    if (processOwner.azureId) {
      setTimeout(() => {
        const element = document
          .querySelector('mgt-person')
          ?.shadowRoot?.querySelector('mgt-flyout');
        if (element) {
          const style = document.createElement('style');
          style.innerHTML = '.line2::after{ content: "Process Owner"; display: block;}';
          element.appendChild(style);
        } else {
          addProcessOwner();
        }
      }, 100);
    }
  }, [processOwner]);

  useEffect(addProcessOwner, [addProcessOwner]);

  return (
    <div className={styles.processOwnerWrapper}>
      {processOwner.azureId && (
        <Person
          userId={processOwner.azureId}
          view={PersonViewType.threelines}
          avatarSize={'large'}
          personCardInteraction={PersonCardInteraction.hover}
        />
      )}
    </div>
  );
};
