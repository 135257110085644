import {useNavigate} from 'react-router-dom';
import {NextProcessButton} from './NextProcessButton';
import {PreviousProcessButton} from './PreviousProcessButton';
import styles from '../Process.module.scss';
import {TheButton} from '../../../common/TheButton';

type IFooter = {
  canEdit: boolean;
  unitId: Number | null | undefined;
  nextProcessId: Number | null | undefined;
  previousProcessId: Number | null | undefined;
};

export const Footer = ({canEdit, unitId, nextProcessId, previousProcessId}: IFooter) => {
  const navigate = useNavigate();
  return (
    <div className={styles.footer}>
      <div className={styles.buttonsAction}>
        {canEdit && (
          <TheButton primary onClick={() => navigate(`edit`)}>
            Edit Process
          </TheButton>
        )}
      </div>
      <div className={styles.buttonsNav}>
        <PreviousProcessButton unitId={unitId} previousProcessId={previousProcessId} />
        <NextProcessButton unitId={unitId} nextProcessId={nextProcessId} />
      </div>
    </div>
  );
};
