import {ProcessCommentManage} from '../../../models';
import {TheButton} from '../../common/TheButton';
import {FC, useCallback, useState} from 'react';
import {FeedbackDelete} from '../Process/components/Feedback/FeedbackDelete';

interface IFeedbackCenterDeleteProps {
  comment: ProcessCommentManage;
  refresh: () => void;
}

export const FeedbackCenterDelete: FC<IFeedbackCenterDeleteProps> = ({comment, refresh}) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const onOpenDelete = useCallback(() => setIsDeleteModal(true), []);
  const onCloseDelete = useCallback(() => {
    setIsDeleteModal(false);
  }, []);
  const onDeleteClick = useCallback(
    (item: ProcessCommentManage) => {
      onOpenDelete();
    },
    [onOpenDelete]
  );
  return (
    <>
      <TheButton
        iconProps={{
          iconName: 'Delete'
        }}
        onClick={() => onDeleteClick(comment)}
        primary>
        Delete
      </TheButton>
      <FeedbackDelete
        isOpen={isDeleteModal}
        comment={comment}
        refresh={refresh}
        onClose={onCloseDelete}
      />
    </>
  );
};
