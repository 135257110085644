import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';

export class StringColumn extends EnhancedColumn {
  public static type = ColumnType.String;

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? `${itemValue}`.toLowerCase().includes(lowerCaseSearch) : false;
  }
}
