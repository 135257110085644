import styles from './DropdownMenu.module.scss';
import {forwardRef, PropsWithChildren, useCallback, useRef, useState} from 'react';

interface IHoverableLiProps {
  className?: string;
  ms?: number;
}

export const HoverableLi = forwardRef<HTMLLIElement, PropsWithChildren<IHoverableLiProps>>(
  ({children, className, ms = 750}, ref) => {
    const [hovered, setHovered] = useState(false);
    const [hasBeenHovered, setHasBeenHovered] = useState(false);
    const debounce = useRef<NodeJS.Timeout | null>(null);
    const onMouseEnter = useCallback(() => {
      const currentLi = (ref as React.RefObject<HTMLLIElement>)?.current;
      if (currentLi) {
        const existingHovers = currentLi.parentNode?.querySelectorAll(`.${styles.hover}`);
        existingHovers?.forEach(liElement => {
          if (liElement !== currentLi) {
            liElement.classList.remove(styles.hover);
          }
        });
        currentLi.classList.add(styles.hover);
      }
      setHovered(true);
      if (!hasBeenHovered) setHasBeenHovered(true);
      if (debounce.current) {
        clearTimeout(debounce.current);
      }
    }, [ref, hasBeenHovered]);
    const onMouseLeave = useCallback(() => {
      debounce.current = setTimeout(() => setHovered(false), ms);
    }, [ms]);
    return (
      <li
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`${className || ''} ${hovered ? styles.hover : ''} ${
          hasBeenHovered ? styles.hasBeenHovered : ''
        }`}>
        {children}
      </li>
    );
  }
);
