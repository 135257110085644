import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styles from './ActivitiesCenter.module.scss';
import {Activity} from '../../../models';
import {EnhancedDetailsList} from '../../common/EnhancedDetailsList';
import {
  getColumnsActivitiesCenter,
  getColumnsAdminAdd
} from '../../common/ProcessSections/activitiesListColumns';
import {ActivitiesListAddModal} from '../../common/ProcessSections/ActivitiesListAddModal';
import {sortByTitle} from '../../../helpers/arrayMehods';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {TheButton} from '../../common/TheButton';
import {Spinner} from '@fluentui/react';
import {useActivitiesCenter} from './useActivitiesCenter';
import {ActivityWithProcess} from '../../../models/ActivityWithProcess';

export const ActivitiesInner: FC<{
  allowAdd?: boolean;
  isInput: boolean;
}> = ({allowAdd, isInput}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isAddModal, setIsAddModal] = useState(false);
  const [activities, setActivities] = useState<ActivityWithProcess[]>([]);
  const [editItem, setEditItem] = useState<Activity>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const onOpen = useCallback(() => setIsAddModal(true), []);
  const onOpenDelete = useCallback(() => setIsDeleteModal(true), []);
  const {getInputs, getOutputs, deleteInputs, deleteOutputs} = useActivitiesCenter();

  const columnsAdd = useMemo(() => getColumnsAdminAdd(undefined, undefined), []);

  const onEditClick = useCallback(
    (item: Activity) => {
      setEditItem(item);
      onOpen();
    },
    [onOpen]
  );
  const onClose = useCallback(() => {
    setEditItem(undefined);
    setIsAddModal(false);
  }, []);

  const onDeleteClick = useCallback(
    (item: Activity) => {
      setEditItem(item);
      onOpenDelete();
    },
    [onOpenDelete]
  );
  const onCloseDelete = useCallback(() => {
    setEditItem(undefined);
    setIsDeleteModal(false);
    setIsLoading(false);
  }, []);

  const refresh = useMemo(
    () => async () => {
      const response = await (isInput ? getInputs : getOutputs)();

      if (!response.result) return;
      setActivities(response.result.sort(sortByTitle));
    },
    [isInput, getInputs, getOutputs]
  );
  const onCreated = useCallback(() => {
    onClose();
    refresh();
  }, [onClose, refresh]);

  const onDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    if (editItem) {
      const result = await (isInput ? deleteInputs(editItem.id) : deleteOutputs(editItem.id));
      if (!result.apiCode) {
        onCloseDelete();
      }
    }
    refresh();
  }, [deleteInputs, deleteOutputs, editItem, isInput, onCloseDelete, refresh]);

  const columnsList = useMemo(
    () => getColumnsActivitiesCenter(onEditClick, onDeleteClick),
    [onEditClick, onDeleteClick]
  );

  useEffect(() => {
    if (!allowAdd) return;
    refresh();
  }, [refresh, allowAdd]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <div className={styles.wrapper}>
      <ActivitiesListAddModal
        isVisible={isAddModal}
        onClose={onClose}
        onCreated={onCreated}
        isInput={isInput}
        allowAdd={false}
        editItem={editItem}
      />
      <ModalWithHeader
        header={'Delete custom link'}
        isVisible={isDeleteModal}
        dismiss={onCloseDelete}>
        <p>
          Link <b>{editItem?.title}</b> (
          <a href={editItem?.url || ''} target="_blank" rel="noreferrer">
            {editItem?.url}
          </a>
          ) will be permanently deleted from all processes. Are you sure?
        </p>
        <div className={styles.deleteButtons}>
          <TheButton onClick={onCloseDelete} disabled={isLoading}>
            Cancel
          </TheButton>
          <TheButton
            primary
            iconProps={{iconName: 'delete'}}
            onClick={onDeleteConfirm}
            disabled={isLoading}>
            Confirm {isLoading ? <Spinner /> : null}
          </TheButton>
        </div>
      </ModalWithHeader>
      {!isLoading ? (
        <EnhancedDetailsList<ActivityWithProcess, ActivityWithProcess, ActivityWithProcess>
          onCreateItemClick={() => setIsAddModal(true)}
          excelExport
          excelFileName="Exported Activities Center List"
          columns={columnsList}
          items={activities}
          allowAdd={allowAdd}
          addLabel="Add activity"
          itemsToAdd={activities}
          mapChangedItemToItem={link => link}
          columnsForAdd={columnsAdd}
          mapNewItemToChangedItem={link => {
            return new ActivityWithProcess(link);
          }}
        />
      ) : null}
    </div>
  );
};
