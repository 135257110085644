import {FC} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Pivot, PivotItem} from '@fluentui/react';
import {UnitAssignmentPage} from './UnitAssignmentPage';
import styles from './UnitAssignmentPage.module.scss';
import {BatchCenter} from './BatchCenter';
import {Helmet} from "react-helmet";

export const UnitsAssignmentCenter: FC = () => {
  const chooseDefaultPivot = window.location.href;
  const currentActivePivot = chooseDefaultPivot.includes('batchcenter') ? 'batchCenter' : '';



  return (
    <>
      <Helmet>
        <title>IMS Unit Process Assignment</title>
      </Helmet>
      <HeadingWithDecoration text="Unit Process Assignment" />
      <Pivot defaultSelectedKey={currentActivePivot}>
        <PivotItem
          itemKey="unitProcessAssignment"
          headerText="Unit Process Assignment"
          className={styles.tab}>
          <UnitAssignmentPage />
        </PivotItem>
        <PivotItem itemKey="batchCenter" headerText="Batch Center" className={styles.tab}>
          <BatchCenter />
        </PivotItem>
      </Pivot>
    </>
  );
};
