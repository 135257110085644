import {IColumn} from '@fluentui/react';

import {SplitButton} from '../../SplitButton';
import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';

export class OpenColumn extends EnhancedColumn {
  public static type = ColumnType.Open;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 112;
  public static maxWidth = 112;

  public static renderer(value: string, column: IColumn, item?: any): JSX.Element {
    return value ? <SplitButton url={value} /> : <></>;
  }
}
