import {Spinner} from '@fluentui/react';

import {Accordion} from '../../common/Accordion';
import {EnhancedDetailsList} from '../../common/EnhancedDetailsList';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {MiddleTopBar} from '../../common/MiddleTopBar/MiddleTopBar';
import {useSearchResultsColumns} from './useSearchResultsColumns';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {Helmet} from 'react-helmet';
import { SearchResult } from '../../../models/SearchResult';
import { useEffect } from 'react';

export const SearchResults = () => {
  const {
    columnsDocuments,
    helmetTitle,
    columnsAIandAO,
    columnsLinks,
    columnsProcess,
    filteredDocumentResults,
    filteredProcessResults,
    filteredExternalLinkResults,
    searchAIResults,
    searchAOResults,
    searchPDCResults,
    columnsPDC,
    isDocumentLoading,
    isPDCLoading,
    isProcessLoading,
    isAILoading,
    isAOLoading,
    isExternalLinkLoading,
    searchValue,
    unit,
    publishedProcessesForUnit,
    unitPermissions,
    preselectedUnitTitle
  } = useSearchResultsColumns();

  return (
    <main className={`noPaddingTop`}>
      {unit ? (
        <MiddleTopBarUnit
          publishedData={publishedProcessesForUnit?.result}
          unitId={unit.id}
          unitPermissions={unitPermissions}
          showManage
        />
      ) : (
        <MiddleTopBar />
      )}
      {helmetTitle && (
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
      )}
      <HeadingWithDecoration text="Search results" />
      <p>
        Search results for: {searchValue}.
        {preselectedUnitTitle ? ` Preselected unit: ${preselectedUnitTitle}.` : ''}
      </p>
        <>
          {(filteredDocumentResults || filteredExternalLinkResults || filteredProcessResults || searchAIResults || searchAOResults || searchPDCResults)  && (
            <>

              <Accordion isOpen={true} header="Documents Attributes">
                {isDocumentLoading  ? (<Spinner />) : (
                  <EnhancedDetailsList<SearchResult, SearchResult, SearchResult>
                    items={filteredDocumentResults}
                    columns={columnsDocuments}
                    hideSearch
                    frozen
                    pageSize={10}
                    />
                  )}
              </Accordion>
              <Accordion isOpen={true} header="Documents Content">
              {isPDCLoading ? (<Spinner />) : (
                <EnhancedDetailsList
                  items={searchPDCResults}
                  columns={columnsPDC}
                  hideSearch
                  pageSize={10}
                  />
                  )}
              </Accordion>
              <Accordion isOpen={true} header="Processes">
                 {isProcessLoading ? (<Spinner />) : (
                <EnhancedDetailsList
                  items={filteredProcessResults}
                  columns={columnsProcess}
                  hideSearch
                  pageSize={10}
                  />
                )}
              </Accordion>
              <Accordion isOpen={true} header="Activity Input">
                 {isAILoading ? (<Spinner />) : (
                <EnhancedDetailsList
                  items={searchAIResults}
                  columns={columnsAIandAO}
                  hideSearch
                  pageSize={10}
                  />
                  )}
              </Accordion>
              <Accordion isOpen={true} header="Activity Output">
                 {isAOLoading ? (<Spinner />) : (
                <EnhancedDetailsList
                  items={searchAOResults}
                  columns={columnsAIandAO}
                  hideSearch
                  pageSize={10}
                  />
                  )}
              </Accordion>
              {filteredExternalLinkResults && (
                
              <Accordion isOpen={true} header="Links">
                 {isExternalLinkLoading ? (<Spinner />) : (
                <EnhancedDetailsList
                  items={filteredExternalLinkResults}
                  columns={columnsLinks}
                  hideSearch
                  pageSize={10}
                  />
                  )}
              </Accordion>
                )}
            </>
          )}
          <div>
            Haven't found what you're looking for?{' '}
            <a
              style={{
                color: 'var(--abb-red)'
              }}
              href={`${process.env.REACT_APP_EXTERNAL_SEARCH_URL}/_layouts/15/osssearchresults.aspx?k=${searchValue}`}
              target="_blank"
              rel="noreferrer">
              Click here
            </a>{' '}
            to get results in PDC instead
          </div>
        </>
    </main>
  );
};
