import {SlimMasterProcessDto, AvailableProcessDto} from '../services/generated';
import {ProcessBase} from './ProcessBase';

export class ProcessSlim extends ProcessBase implements AvailableProcessDto {
  public masterProcess: SlimMasterProcessDto;

  public deleted: boolean;

  constructor(process: AvailableProcessDto) {
    super(process);
    this.masterProcess = process.masterProcess as SlimMasterProcessDto;
    this.deleted = process.deleted;
  }
}
