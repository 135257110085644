import {Icon} from '@fluentui/react';
import {useNavigate} from 'react-router-dom';
import styles from '../Process.module.scss';

type IPreviousProcessButton = {
  unitId: Number | null | undefined;
  previousProcessId: Number | null | undefined;
};

export const PreviousProcessButton = ({unitId, previousProcessId}: IPreviousProcessButton) => {
  const navigate = useNavigate();
  return (
    <div>
      {previousProcessId && (
        <button
          className={styles.buttonNavPrev}
          onClick={() => {
            navigate(`/unit/${unitId}/process/${previousProcessId}`);
          }}>
          <Icon iconName="ChevronLeft" />
          Previous Process
        </button>
      )}
    </div>
  );
};
