import {ExternalLinkType} from '../enums';
import {CreatedByProcessDto, ExternalLinkDto, Links, UserDto} from '../services/generated';
import {CreatedModifiedModel} from './CreatedModified';

export class ExternalLink extends CreatedModifiedModel implements ExternalLinkDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public deleted: boolean;
  public removed: boolean;
  public version: number;
  public active: boolean;
  public ownerProcessId: number;
  public title: string | null;
  public url: string | null;
  public order: number;
  public type: ExternalLinkType;
  public isExternal: boolean;
  public createdByProcess: CreatedByProcessDto | null;
  public createdByProcessOwner: UserDto | null;

  public get typeString() {
    switch (this.type) {
      case ExternalLinkType._1:
        return 'IMS Process';
      case ExternalLinkType._2:
        return 'Site';
      case ExternalLinkType._3:
        return 'Software Application';
      case ExternalLinkType._4:
        return 'Examples';
      case ExternalLinkType._5:
        return 'e-Learning';
      default:
        return '';
    }
  }

  constructor(link: ExternalLinkDto) {
    super(link);
    this.id = link.id;
    this.link = link.link;
    this.deleted = link.deleted;
    this.removed = link.removed;
    this.version = link.version;
    this.active = link.active;
    this.ownerProcessId = link.ownerProcessId;
    this.title = link.title;
    this.url = link.url;
    this.order = link.order;
    this.type = link.type;
    this.isExternal = link.isExternal;
    this.createdByProcess = link.createdByProcess;
    this.createdByProcessOwner = link.createdByProcessOwner;
  }
}
