import {IPersonaProps} from '@fluentui/react';
import {useContext, useEffect, useState} from 'react';
import {useService} from '../../../hooks';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';

export const useGroupPicker = (
  onSelectGroup: ((group: IPersonaProps[] | undefined, itemId: number) => void) | undefined,
  itemId: number,
  defaultSelectedGroup?: IPersonaProps[],
  defaultSelectedGroupId?: string,
  defaultSelectedGroupIds?: string[]
) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);

  const {getGroupById} = useService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [defaultSelectedGroupResult, setDefaultSelectedGroupResult] = useState<IPersonaProps[]>([]);

  const [selected, setSelected] = useState<IPersonaProps[]>([]);
  const [removed, setRemoved] = useState<IPersonaProps[]>([]);

  const defaultGroup: () => Promise<IPersonaProps[] | undefined> = async () => {
    if (defaultSelectedGroup) {
      return defaultSelectedGroup;
    }
    if (defaultSelectedGroupId) {
      const group = await getGroupById(defaultSelectedGroupId);
      if (!group) return undefined;
      return [
        {
          id: group.id,
          text: group.displayName,
          secondaryText: group.mail,
          key: group.id
        }
      ];
    }
    if (defaultSelectedGroupIds) {
      const groups: IPersonaProps[] = [];
      defaultSelectedGroupIds.forEach(async id => {
        const group = await getGroupById(id);
        if (!group) return undefined;
        groups.push({
          id: group.id,
          text: group.displayName,
          secondaryText: group.mail,
          key: group.id
        });
      });
      return groups;
    }
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const group = await defaultGroup();
        if (group) {
          setDefaultSelectedGroupResult(group);
          setSelected(group);
        } else {
          setDefaultSelectedGroupResult([]);
          setSelected([]);
        }
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {getFilteredGroupsList} = useService();

  const onFilterChanged = async (filterText: string): Promise<IPersonaProps[]> => {
    if (filterText.length > 1) {
      const groups = await getFilteredGroupsList(filterText);
      const uniqueGroups = groups.filter(group =>
        selected.every(existingGroup => existingGroup.id !== group.id)
      );
      return uniqueGroups;
    } else {
      return [];
    }
  };

  const onChange = (items?: IPersonaProps[] | undefined) => {
    if (selected.length > (items?.length || 0)) {
      const removedItem = selected.find(selected => !items?.some(item => item.id === selected.id));
      if (removedItem) setRemoved([...removed, removedItem]);
    } else {
      setRemoved(removed.filter(removedItem => !items?.some(item => item.id === removedItem.id)));
    }
    if (onSelectGroup) onSelectGroup(items, itemId);
    setSelected(items || []);
  };

  return {defaultSelectedGroupResult, isLoading, selected, removed, onFilterChanged, onChange};
};
