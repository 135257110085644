/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Save
 *
 * 1 = Publish
 *
 * 2 = PublishWithNotification
 *
 * 3 = PublishAndConfirmProcessValidity
 */
export enum PublishScenario {
  Save = 0,
  Publish = 1,
  PublishWithNotification = 2,
  PublishAndConfirmProcessValidity = 3
}
