import {
  FunctionDto,
  Links,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UnitDto,
  ViewDto,
  BusinessLineDto
} from '../services/generated';

export class ViewModel implements ViewDto {
  public id: number;
  public link: Links[] | null;
  public title: string | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public functions: FunctionDto[] | null;
  public unit: UnitDto | null;
  public includeEmpty: boolean;
  public isFavorite: boolean | null;
  public isDefault: boolean | null;
  public masterViewId: number | null;
  public businessLines: BusinessLineDto[] | null;

  public get excludeItemsApplicableToAll() {
    return !this.includeEmpty;
  }

  public set excludeItemsApplicableToAll(value: boolean) {
    this.includeEmpty = !value;
  }

  public get isGlobal() {
    return !this.unit;
  }

  public get unitId() {
    return this.unit?.id;
  }

  constructor(view: ViewDto) {
    this.id = view.id;
    this.link = view.link;
    this.title = view.title;
    this.projectSizeCategories = view.projectSizeCategories;
    this.industries = view.industries;
    this.specialities = view.specialities;
    this.functions = view.functions;
    this.unit = view.unit;
    this.includeEmpty = view.includeEmpty;
    this.isFavorite = view.isFavorite;
    this.isDefault = view.isDefault;
    this.masterViewId = view.masterViewId;
    this.businessLines = view.businessLines;
  }
}
