import {MessageBar, MessageBarType, Shimmer, Spinner} from '@fluentui/react';
import {PositionEnum, ProcessEnum} from '../../../enums/ProcessEnum';
import {DocumentRecent, UnitProcessNavigator} from '../../../models';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {DecoratorCircle} from '../../common/ProcessDecorators';
import {ProcessResult} from '../../common/ProcessResult';
import {ProcessTiles} from '../../common/ProcessTiles';
import {RecentlyAddedDocument} from '../../common/RecentlyAddedDocuments';
import {TriangleBreadcrumbsLayout} from '../../common/TriangleBreadcrumbsLayout';
import {TriangleSingleItemLayout} from '../../common/TriangleSingleItemLayout';
import {MiddleTopBarUnit} from './MiddleTopBarUnit';
import styles from './Unit.module.scss';
import {useUnit} from './useUnit';
import {DictionariesContext} from '../../../providers';
import {useContext, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import * as models from '../../../models';

export const Unit = () => {
  const {globalFiltersFunction, hasFilters} = useContext(DictionariesContext);
  const {
    unitId,
    processNavigatorData,
    apiMsg,
    recentlyAddedDocument,
    unit,
    isLoading,
    publishedProcessesForUnit,
    unitPermissions
  } = useUnit();

  const [itemsFiltered, setItemsFiltered] = useState<boolean>(false);

  const filteredDocuments = useMemo(() => {
    var result = recentlyAddedDocument ? recentlyAddedDocument.filter(globalFiltersFunction) : [];
    if (
      recentlyAddedDocument &&
      recentlyAddedDocument.length > 0 &&
      result.length !== recentlyAddedDocument.length
    ) {
      setItemsFiltered(true);
    }
    else{
      setItemsFiltered(false);
    }

    return result;
  }, [recentlyAddedDocument, globalFiltersFunction]);

  const filteredDiagram = useMemo(
    () => processNavigatorData?.filter(globalFiltersFunction),
    [processNavigatorData, globalFiltersFunction]
  );

  const getDataFiltred = (type: string, position?: string) => {
    return processNavigatorData
      ?.filter(globalFiltersFunction)
      .filter(item =>
        position
          ? item.group === type && item.position !== null && item.position === position
          : item.group === type
      );
  };

  const dataManagement = processNavigatorData && getDataFiltred(ProcessEnum.Management);
  const dataSupport = processNavigatorData && getDataFiltred(ProcessEnum.Support);
  const dataResults = processNavigatorData && getDataFiltred(ProcessEnum.Result);
  const dataExecutionTop =
    processNavigatorData && getDataFiltred(ProcessEnum.Execution, PositionEnum.Top);
  const dataExecutionMiddle = getDataFiltred(ProcessEnum.Execution, PositionEnum.Middle);
  const dataExecutionBottom = getDataFiltred(ProcessEnum.Execution, PositionEnum.Bottom);

  const prepareUnitTitle = (unit: models.Unit) => {
    return unit.parentTitle && unit.unitTypeId === 2
      ? `${unit.parentTitle} ${unit.title}`
      : unit.title;
  };

  const noResultMessage = useMemo(() => {
    return hasFilters
      ? 'Sorry, no data to show. Please adjust your filters.'
      : 'Sorry, no data to show.';
  }, [hasFilters]);

  return (
    <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
      {isLoading ? (
        <Spinner />
      ) : (
        !apiMsg && (
          <>
            {publishedProcessesForUnit ? (
              <MiddleTopBarUnit
                unitId={unitId}
                publishedData={publishedProcessesForUnit?.result}
                unitPermissions={unitPermissions}
                showManage
              />
            ) : (
              <Shimmer />
            )}
            {unit && (
              <Helmet>
                <title>IMS {prepareUnitTitle(unit)} Landing Page</title>
              </Helmet>
            )}
            {unit && <HeadingWithDecoration text={unit.title || ''} />}
            {itemsFiltered || filteredDocuments.length || filteredDiagram?.length ? (
              <OpexDiagram
                unitId={Number(unitId)}
                dataManagement={dataManagement}
                dataResult={dataResults ? dataResults[0] : undefined}
                dataExecutionTop={dataExecutionTop}
                dataExecutionMiddle={dataExecutionMiddle}
                dataExecutionBottom={dataExecutionBottom}
                dataSupport={dataSupport}
                itemsFiltered={itemsFiltered}
                recentlyAddedDocument={filteredDocuments}
              />
            ) : (
              <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                {noResultMessage}
              </MessageBar>
            )}
          </>
        )
      )}
    </main>
  );
};

type IOpexDiagram = {
  unitId: number;
  dataManagement: UnitProcessNavigator[] | undefined;
  dataResult: UnitProcessNavigator | undefined;
  dataExecutionTop: UnitProcessNavigator[] | undefined;
  dataExecutionMiddle: UnitProcessNavigator[] | undefined;
  dataExecutionBottom: UnitProcessNavigator[] | undefined;
  dataSupport: UnitProcessNavigator[] | undefined;
  recentlyAddedDocument: DocumentRecent[];
  itemsFiltered: boolean;
};

const OpexDiagram = ({
  dataManagement,
  dataResult,
  dataExecutionTop,
  dataExecutionMiddle,
  dataExecutionBottom,
  dataSupport,
  recentlyAddedDocument,
  unitId,
  itemsFiltered
}: IOpexDiagram) => {
  const isTop = dataExecutionTop && dataExecutionTop.length > 0;
  const isBottom = dataExecutionBottom && dataExecutionBottom.length > 0;
  const isMiddle = dataExecutionMiddle && dataExecutionMiddle.length > 0;
  const isLink = dataResult?.customLink && dataResult.customLink.length > 0;
  return (
    <>
      {dataManagement && dataManagement.length > 0 && (
        <ProcessTiles title={ProcessEnum.Management} data={dataManagement} unitId={unitId} />
      )}

      {(isTop || isBottom || isMiddle || isLink) && (
        <div className={styles.processBox}>
          {/* <div className={styles.processHeader}>
          <h4>{ProcessEnum.Execution}</h4>
        </div> */}
          <div className={styles.processBoxInner}>
            <div className={styles.processIndustryNarrow}>
              <DecoratorCircle image="people" />
            </div>
            <div className={styles.processIndustryWide}>
              {isTop && <TriangleSingleItemLayout data={dataExecutionTop} unitId={unitId} />}
              {isMiddle && <TriangleBreadcrumbsLayout data={dataExecutionMiddle} unitId={unitId} />}
              {isBottom && <TriangleSingleItemLayout data={dataExecutionBottom} unitId={unitId} />}
            </div>
            {dataResult?.customLink ? (
              <div className={styles.processIndustryNarrow}>
                <ProcessResult url={dataResult.customLink} />
              </div>
            ) : null}
          </div>
        </div>
      )}
      {dataSupport && dataSupport.length > 0 && (
        <ProcessTiles title={ProcessEnum.Support} data={dataSupport} unitId={unitId} />
      )}
      <RecentlyAddedDocument data={recentlyAddedDocument} itemsFiltered={itemsFiltered} />
    </>
  );
};
