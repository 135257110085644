import {Accordion} from '../../../common/Accordion';
import {Diagram} from '../../../common/Diagram';
import {useCallback, useState} from 'react';
import {DiagramType} from '../../../../services/generated';

interface IProcessDiagram {
  processId: number;
  processTitle: string;
  unitId: number;
  canEdit: boolean;
}

export const ProcessDiagram = ({processId, processTitle, unitId, canEdit}: IProcessDiagram) => {
  const [hidden, setHidden] = useState(false);
  const returnType = useCallback(
    (type: DiagramType) => {
      const shouldBeHidden = type === DiagramType.None;
      if (shouldBeHidden && !hidden) setHidden(true);
    },
    [hidden]
  );
  return !canEdit && hidden ? (
    <></>
  ) : (
    <Accordion header="Diagram" isOpen>
      <Diagram
        processId={processId}
        processTitle={processTitle}
        unitId={unitId}
        canEdit={canEdit}
        returnType={returnType}
      />
    </Accordion>
  );
};
