import {DescriptionVersionDto} from '../services/generated';
import {ProcessVersionFlatStructureDto} from './ProcessVersionFlatStructureDto';
import {ProcessVersionFlatStructureBase} from './ProcessVersionFlatStructureBase';

export class ProcessVersionFlatStructure
  extends ProcessVersionFlatStructureBase
  implements ProcessVersionFlatStructureDto
{
  public descriptions: DescriptionVersionDto[] | null;

  constructor(processVersion: ProcessVersionFlatStructureDto) {
    super(processVersion);
    this.descriptions = processVersion.descriptions;
  }
}
