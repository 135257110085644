import styles from './DefaultPortalSelectionForm.module.scss';
import {ApiResponse} from '../../../services/ApiResponseType';
import {
  Checkbox,
  Dropdown,
  IComboBoxOption,
  Icon,
  IDropdownStyles,
  Label,
  MessageBarType,
  Spinner
} from '@fluentui/react';
import {LoggerContext, PortalDropdownsContext, ProcessContext} from '../../../providers';
import {PortalStructure} from '../../../enums/PortalStructureEnum';
import {Process} from '../../../models';
import {ProcessType} from '../../../enums/ProcessEnum';
import {TheButton} from '../TheButton';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useProcess} from '../../../hooks/services/useProcess';
import {InfoTooltip} from '../InfoTooltip';

export interface ILocalStorage {
  redirectUrl: string;
  divValue: IComboBoxOption | undefined;
  countryValue: IComboBoxOption | undefined;
}

const divString = PortalStructure.Div;
const countryString = PortalStructure.Country;

export const DefaultPortalSelectionForm = (props: {redirectOnEnter?: boolean}) => {
  const {portalStructureData, isLoading, setFavUnit, favUnit} = useContext(PortalDropdownsContext);
  const {setProcess, setIsLoading} = useContext(ProcessContext);
  const {addLog} = useContext(LoggerContext);
  const {redirectOnEnter} = props;
  const navigate = useNavigate();
  const {processId, unitId} = useParams();
  const initializedUnitId = useRef('');
  const {getProcess: getProcessService, getProcessForUnit} = useProcess();

  //const localStorageValueTemp = localStorage.getItem('currentSettings');
  const localStorageValuePersonal = localStorage.getItem('personalSettings');

  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true);
  const [divValue, setDivValue] = useState<IComboBoxOption>();

  const [divValues, setDivValues] = useState<IComboBoxOption[]>([]);
  const [countryValue, setCountryValue] = useState<IComboBoxOption>();

  const [countryValues, setCountryValues] = useState<IComboBoxOption[]>([]);

  // const [detailsDataPersonal, setDetailsDataPersonal] = useState<ILocalStorage>();
  const [saveAsPersonal, setSaveAsPersonal] = useState<boolean>(false);

  const emptyObjRow = {key: '-1', text: ''};

  const buildDataStructure = (parentId: number | string | null, level: number) => {
    const filteredData = portalStructureData.filter(
      item => item.parentId === parentId && item.unitTypeId === level
    );
    const dropdownData: IComboBoxOption[] = filteredData.map(item => ({
      key: item.id,
      text: item.title || ''
    }));
    if (level === countryString) {
      setCountryValues([emptyObjRow, ...dropdownData]);
    }
    return filteredData;
  };

  const buildDivStructure = (level: number) => {
    const filteredData = portalStructureData.filter(item => item.unitTypeId === level);
    const dropdownData: IComboBoxOption[] = filteredData.map(item => ({
      key: item.id,
      text: item.title || ''
    }));
    if (level === divString) {
      setDivValues([emptyObjRow, ...dropdownData]);
    }
    return filteredData;
  };

  const onChangeSaveAsDefault = (isChecked: boolean | undefined) => {
    const status: boolean = isChecked === undefined ? false : isChecked;
    setSaveAsPersonal(status);
  };

  const checkIfDropValueShouldBeUsed = (value: IComboBoxOption | undefined) => {
    if (value && (value.key as number) > 0) {
      return value.key;
    }
    return undefined;
  };

  const onClickGoToUrl = useCallback(async () => {
    const countryVal = checkIfDropValueShouldBeUsed(countryValue);
    const divVal = checkIfDropValueShouldBeUsed(divValue);
    const urlId = [countryVal, divVal, '1'].find(el => el !== undefined);
    let process: ApiResponse<Process> | undefined = undefined;
    let newProcess: ApiResponse<Process> | undefined = undefined;
    if (processId) {
      setIsLoading(true);
      process = await getProcessService(Number(processId));
      const masterProcessId = process.result?.masterProcessId;
      if (urlId && masterProcessId) {
        newProcess = await getProcessForUnit(Number(urlId), masterProcessId);
      }
      setIsLoading(false);
    }

    const fullUrl = `/unit/${urlId}${
      newProcess?.result?.type === ProcessType.Process && newProcess?.result?.id
        ? `/process/${newProcess?.result?.id}`
        : ''
    }`;
    const fullSettings = {
      redirectUrl: fullUrl,
      divValue,
      countryValue
    };
    //localStorage.setItem('currentSettings', JSON.stringify(fullSettings));
    if (saveAsPersonal) {
      localStorage.setItem(
        'personalSettings',
        JSON.stringify({...fullSettings, redirectUrl: `/unit/${urlId}`})
      );
      setFavUnit(urlId ? String(urlId) : undefined);
      setSaveAsPersonal(false);
    }
    navigate(fullUrl /*, {replace: true}*/);
    if (process?.result) {
      if (!newProcess?.result) {
        addLog(
          MessageBarType.error,
          `Process ${process.result.title} doesn't exist in current unit`
        );
      } else if (newProcess?.result.type === ProcessType.Process) {
        setProcess(newProcess.result);
      } else {
        addLog(MessageBarType.error, `${newProcess.result.title} is non-process in current unit`);
      }
    }
  }, [
    addLog,
    countryValue,
    divValue,
    getProcessForUnit,
    getProcessService,
    navigate,
    processId,
    saveAsPersonal,
    setFavUnit,
    setIsLoading,
    setProcess
  ]);

  const isDisabled = (value: IComboBoxOption | undefined) => {
    return value !== undefined ? !Boolean(value.text) : true;
  };

  const buttonText = () => {
    return saveAsPersonal ? 'Save and GO' : 'GO to site';
  };

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {width: 300}
  };

  const defaultSelectedKey = useCallback((value: IComboBoxOption | undefined) => {
    return value ? value.key : undefined;
  }, []);

  const goToPreferences = () => {
    if (favUnit) {
      // localStorage.setItem('currentSettings', JSON.stringify(detailsDataPersonal));
      navigate(`/unit/${favUnit}`, {replace: true});
    }
  };

  useEffect(() => {
    if (portalStructureData) {
      // if (localStorageValueTemp) {
      //   const detailsData: ILocalStorage = JSON.parse(localStorageValueTemp);
      //   setDivValue(detailsData.divValue);
      //   setCountryValue(detailsData.countryValue);
      // }
      if (localStorageValuePersonal) {
        const detailsData: ILocalStorage = JSON.parse(localStorageValuePersonal);
        // setDetailsDataPersonal(detailsData);
        if (redirectOnEnter && detailsData) {
          navigate(detailsData.redirectUrl, {replace: true});
        }
      }
      buildDivStructure(divString);
      setIsLocalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalStructureData]);

  useEffect(() => {
    if (
      (unitId && unitId !== initializedUnitId.current) ||
      (!countryValue && unitId && portalStructureData.length && divValues.length)
    ) {
      const unit = portalStructureData.find(unit => unit.id === Number.parseInt(unitId));
      if (unit) {
        initializedUnitId.current = unitId;
        if (unit.unitTypeId === 1) {
          // eslint-disable-next-line
          const divVal = divValues.find(val => val.key == unit.id);
          if (divValue?.key !== divVal?.key) setDivValue(divVal);
        } else {
          // eslint-disable-next-line
          const divVal = divValues.find(val => val.key == unit.parentId);
          // eslint-disable-next-line
          const countryVal = countryValues.find(val => val.key == unit.id);
          if (divValue?.key !== divVal?.key) setDivValue(divVal);

          setCountryValue(countryVal);
        }
      }
    }
  }, [portalStructureData, divValues, countryValues, unitId, divValue, countryValue]);

  useEffect(() => {
    if (divValue && (divValue.key as number) > 0) {
      buildDataStructure(divValue.key, countryString);
    } else if (divValue && divValue.key === '-1') {
      setCountryValue(emptyObjRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divValue]);

  const onChangeDropdown = (location: string, value: IComboBoxOption | undefined) => {
    onChangeSaveAsDefault(false);
    if (location === 'div') {
      setDivValue(value ? value : undefined);
      setCountryValue(emptyObjRow);
    }
    if (location === 'country') {
      setCountryValue(value ? value : undefined);
    }
  };

  return (
    <>
      {isLoading || isLocalLoading ? (
        <Spinner />
      ) : !isLoading && !isLocalLoading && portalStructureData.length > 0 ? (
        <div className={styles.box}>
          <div className={styles.boxTop}>
            {!redirectOnEnter && favUnit && (
              <Icon iconName="Home" onClick={goToPreferences} className={styles.boxIcon} />
            )}
            <div>
              <div className={styles.tooltipWrapper}>
                <Label>Division/Hub/BL</Label>
                <InfoTooltip content="To access Division/HUB/BL portal, do not select any country" />
              </div>
              <Dropdown
                options={divValues}
                key={'div'}
                selectedKey={defaultSelectedKey(divValue)}
                styles={dropdownStyles}
                onChange={(e, value?: IComboBoxOption) => {
                  onChangeDropdown('div', value);
                }}
              />
            </div>
            <Dropdown
              label="Country"
              options={countryValues}
              key={'country'}
              styles={dropdownStyles}
              disabled={isDisabled(divValue)}
              selectedKey={defaultSelectedKey(countryValue)}
              onChange={(e, value?: IComboBoxOption) => {
                onChangeDropdown('country', value);
              }}
            />
          </div>
          <div className={styles.boxBottom}>
            <Checkbox
              label="Save as personal value"
              checked={saveAsPersonal}
              onChange={(ev, checked) => {
                onChangeSaveAsDefault(checked);
              }}
            />
            <TheButton onClick={onClickGoToUrl} disabled={isDisabled(divValue)} primary>
              {buttonText()}
            </TheButton>
          </div>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};
