import styles from './DropdownMenu.module.scss';
import {HoverableLi} from './HoverableLi';
import {Icon} from '@fluentui/react';
import {MenuStructure} from './MenuStructure';
import {ProcessTree} from '../../../models';

export interface IDropdownMenu {
  header: string;
  menuItems: ProcessTree[];
  unitId: number | undefined;
}

export const DropdownMenu = ({header, menuItems, unitId}: IDropdownMenu) => (
  <div>
    <ul className={styles.wrapper}>
      <HoverableLi className={styles.hoverableListItem}>
        <>
          <span className={styles.title}>
            {header}
            <Icon iconName={'ChevronDown'} className={styles.icon} />
          </span>
          <ul className={`${styles.childrenWrapper} ${styles.initialSubdropdown}`}>
            <MenuStructure menu={menuItems} unitId={unitId as number} />
          </ul>
        </>
      </HoverableLi>
    </ul>
  </div>
);
