import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {sortByOrderPrefix} from '../../../helpers/arrayMehods';
import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {DictionariesContext} from '../../../providers';
import {ITreeColumn, ITreeColumnBulk} from '../../common/TreeBuilder/TreeColumn';
import {MasterProcessItemModel} from './common/MasterProcessItemModel';
import {
  MasterProcessInputAddChild,
  MasterProcessInputFunction,
  MasterProcessInputOrder,
  MasterProcessInputProjectSizeCategory,
  MasterProcessInputIndustry,
  MasterProcessInputSpeciality,
  MasterProcessInputTitle,
  MasterProcessInputBusinessLine
} from './input';

export const useMasterProcessListPage = () => {
  const {getMasterProcessManage, updateMasterProcess} = useMasterProcess();
  const navigate = useNavigate();
  const {
    optionsFunction,
    optionsProjectSizeCategory,
    optionsIndustry,
    optionsSpeciality,
    optionsBusinessLine
  } = useContext(DictionariesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [masterProcesses, setMasterProcesses] = useState<MasterProcessItemModel[]>([]);
  const [isNewOpen, setNewOpen] = useState(false);
  const [newParentId, setNewParentId] = useState<number | null>(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [orders, setOrders] = useState<{[id: number]: number}>({});

  const getBulkModel = useCallback(() => {
    const model = new MasterProcessItemModel();
    model.function = undefined as any;
    return model;
  }, []);

  const visibleMasterProcesses = useMemo(
    () => (showDeleted ? masterProcesses : masterProcesses.filter(item => !item.initialDeleted)),
    [masterProcesses, showDeleted]
  );

  const onNewOpen = useCallback(() => setNewOpen(true), []);
  const onNewClose = useCallback(() => {
    setNewParentId(null);
    setNewOpen(false);
  }, []);

  const onDeleteOpen = useCallback(() => setDeleteOpen(true), []);
  const onDeleteClose = useCallback(() => setDeleteOpen(false), []);

  const onAddChild = useCallback(
    (parentId: number) => {
      setNewParentId(parentId);
      onNewOpen();
    },
    [onNewOpen]
  );

  const updateOrder = useCallback(
    (model: MasterProcessItemModel, newOrder: number) => {
      model.order = newOrder;
      orders[model.id] = newOrder;
    },
    [orders]
  );

  const reorder = useCallback(
    (
      model: MasterProcessItemModel,
      newOrder: number,
      masterProcessesList: MasterProcessItemModel[] = masterProcesses
    ) => {
      const isIncreased = newOrder > model.order;
      updateOrder(model, newOrder);
      const currentLevelItems = masterProcessesList.filter(
        item => item.parentId === model.parentId && !item.deleted
      );
      let lastChangedItem: MasterProcessItemModel | undefined = model;
      while (
        (lastChangedItem = currentLevelItems.find(
          // eslint-disable-next-line no-loop-func
          item => item !== lastChangedItem && item.order === lastChangedItem?.order
        )) !== undefined
      ) {
        const newOrder = lastChangedItem.order + (isIncreased ? -1 : 1);
        updateOrder(lastChangedItem, newOrder);
      }
      setOrders({...orders});
    },
    [orders, updateOrder, masterProcesses]
  );

  const columns = useMemo(() => {
    const cols: ITreeColumn<MasterProcessItemModel>[] = [
      {
        key: 0,
        title: 'Title',
        type: 'title',
        showCheckbox: true,
        getTitle: model => model.prefixedTitle,
        minCSSWidth: '500px',
        property: 'title'
      },
      {
        key: 11,
        title: 'Add',
        type: 'simple',
        minCSSWidth: '50px',
        maxCSSWidth: '50px',
        onRenderColumn: model => (
          <MasterProcessInputAddChild model={model} onAddChild={onAddChild} />
        ),
        property: ''
      },
      {
        key: 10,
        title: 'Prefix',
        type: 'simple',
        onRenderColumn: model => model.prefix,
        property: 'prefix',
        exportToExcel: true,
        hidden: true
      },
      {
        key: 10,
        title: 'Title',
        type: 'simple',
        onRenderColumn: model => <MasterProcessInputTitle model={model} required />,
        property: 'title',
        exportToExcel: true
      },
      {
        key: 2,
        title: 'Order',
        type: 'simple',
        onRenderColumn: (model: MasterProcessItemModel) => (
          <MasterProcessInputOrder model={model} order={orders[model.id]} reorder={reorder} />
        ),
        exportToExcel: true,
        property: 'order'
      },
      {
        key: 1,
        title: 'Function',
        onRenderColumn: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          disabled?: boolean
        ) => (
          <MasterProcessInputFunction model={model} options={optionsFunction} disabled={disabled} />
        ),
        onRenderBulk: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          idsForEdit: number[],
          disabled?: boolean
        ) => (
          <MasterProcessInputFunction
            model={model}
            options={optionsFunction}
            disabled={disabled}
            disableErrorMessage
          />
        ),
        type: 'bulk',
        property: 'function',
        required: true,
        exportToExcel: true,
        getExportToExcelValue: model => model.function?.value
      },
      {
        key: 7,
        title: 'Business Lines',
        onRenderColumn: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          disabled?: boolean
        ) => (
          <MasterProcessInputBusinessLine
            model={model}
            options={optionsBusinessLine}
            disabled={disabled}
          />
        ),
        type: 'bulk',
        property: 'businessLines',
        exportToExcel: true,
        getExportToExcelValue: model => model.businessLines?.map(item => item.value).join(', ')
      },
      {
        key: 5,
        title: 'Industries',
        onRenderColumn: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          disabled?: boolean
        ) => (
          <MasterProcessInputIndustry model={model} options={optionsIndustry} disabled={disabled} />
        ),
        type: 'bulk',
        property: 'industries',
        exportToExcel: true,
        getExportToExcelValue: model => model.industries?.map(item => item.value).join(', ')
      },
      {
        key: 6,
        title: 'Specialities',
        onRenderColumn: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          disabled?: boolean
        ) => (
          <MasterProcessInputSpeciality
            model={model}
            options={optionsSpeciality}
            disabled={disabled}
          />
        ),
        type: 'bulk',
        property: 'specialities',
        exportToExcel: true,
        getExportToExcelValue: model => model.specialities?.map(item => item.value).join(', ')
      },
      {
        key: 4,
        title: 'Project size categories',
        onRenderColumn: (
          model: MasterProcessItemModel,
          column: ITreeColumnBulk<MasterProcessItemModel>,
          disabled?: boolean
        ) => (
          <MasterProcessInputProjectSizeCategory
            model={model}
            options={optionsProjectSizeCategory}
            disabled={disabled}
          />
        ),
        type: 'bulk',
        property: 'projectSizeCategories',
        exportToExcel: true,
        getExportToExcelValue: model =>
          model.projectSizeCategories?.map(item => item.value).join(', ')
      }
    ];
    return cols;
  }, [
    onAddChild,
    orders,
    reorder,
    optionsFunction,
    optionsProjectSizeCategory,
    optionsBusinessLine,
    optionsIndustry,
    optionsSpeciality
  ]);

  const getData = useCallback(async () => {
    const response = await getMasterProcessManage();
    if (response.result && !response.apiCode) {
      const mappedProcesses = response.result
        .map(item => new MasterProcessItemModel(item))
        .sort(sortByOrderPrefix);
      const newOrders: {[id: number]: number} = {};
      for (const mappedProcess of mappedProcesses) {
        newOrders[mappedProcess.id] = mappedProcess.order;
        const parent = mappedProcesses.find(item => item.id === mappedProcess.parentId);
        if (parent) {
          mappedProcess.setParent(parent);
        }
      }
      setMasterProcesses(mappedProcesses);
      setOrders(newOrders);
    }
  }, [getMasterProcessManage]);

  const onSave = useCallback(async () => {
    const modifiedItems = masterProcesses.filter(item => item.isModified);
    await updateMasterProcess(modifiedItems.map(item => item.rowValue));
    await getData();
  }, [updateMasterProcess, getData, masterProcesses]);

  const onShowDeletedToggle = useCallback(() => setShowDeleted(!showDeleted), [showDeleted]);
  const onAfterNewCreated = useCallback(async () => {
    const response = await getMasterProcessManage();
    if (!response.result) return;
    const allMasterProcesses = response.result;
    const newItems = allMasterProcesses
      .filter(newItem => masterProcesses.every(masterProcess => masterProcess.id !== newItem.id))
      .map(newItem => new MasterProcessItemModel(newItem));
    newItems.forEach(newItem => {
      const parent = allMasterProcesses.find(item => item.id === newItem.parentId);
      if (parent) {
        newItem.setParent(new MasterProcessItemModel(parent));
      }
    });
    const newMasterProcesses = [...masterProcesses, ...newItems];
    newItems.forEach(masterProcess =>
      reorder(masterProcess, masterProcess.order, newMasterProcesses)
    );
    setMasterProcesses(newMasterProcesses.sort(sortByOrderPrefix));
  }, [getMasterProcessManage, masterProcesses, reorder]);

  const onCancel = useCallback(() => {
    navigate(`/admin`);
  }, [navigate]);

  const onSubmit = useCallback(async () => {
    if (masterProcesses.some(process => process.isInvalid)) return;
    setIsLoading(true);
    const isDeleted = masterProcesses.some(item => item.isModified && item.deleted);
    if (isDeleted) {
      onDeleteOpen();
    } else {
      await onSave();
    }
    setIsLoading(false);
  }, [onSave, masterProcesses, onDeleteOpen]);

  const onDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    onDeleteClose();
    await onSave();
    setIsLoading(false);
  }, [onDeleteClose, onSave]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    })();
  }, [getData]);

  return useMemo(
    () => ({
      isLoading,
      masterProcesses,
      columns,
      onCancel,
      onSubmit,
      isNewOpen,
      onNewOpen,
      onNewClose,
      onAfterNewCreated,
      optionsFunction,
      optionsProjectSizeCategory,
      optionsIndustry,
      optionsSpeciality,
      optionsBusinessLine,
      showDeleted,
      onShowDeletedToggle,
      isDeleteOpen,
      onDeleteClose,
      onDeleteConfirm,
      newParentId,
      getBulkModel,
      visibleMasterProcesses
    }),
    [
      isLoading,
      masterProcesses,
      columns,
      onCancel,
      onSubmit,
      isNewOpen,
      onNewOpen,
      onNewClose,
      onAfterNewCreated,
      optionsFunction,
      optionsProjectSizeCategory,
      optionsIndustry,
      optionsBusinessLine,
      optionsSpeciality,
      showDeleted,
      onShowDeletedToggle,
      isDeleteOpen,
      onDeleteClose,
      onDeleteConfirm,
      newParentId,
      getBulkModel,
      visibleMasterProcesses
    ]
  );
};
