import './App.css';

import {PartialTheme, ThemeProvider} from '@fluentui/react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {ABBTheme, FontStyle} from './assets/styles/theme';
import {HomeLayout, Layout} from './components/Layouts';
import {Admin} from './components/Pages/Admin';
import {AccessDenied, PageNotFound, ProcessNotFound} from './components/Pages/ErrorPages';
import {Help} from './components/Pages/Help';
import {Home} from './components/Pages/Home';
import {EditProcess, Process} from './components/Pages/Process';
import {ProcessVersion} from './components/Pages/Process/Version/ProcessVersion';
import {ProcessList} from './components/Pages/ProcessList';
import {ProcessMap} from './components/Pages/ProcessMap';
import {SearchResults} from './components/Pages/SearchResults';
import {Unit} from './components/Pages/Unit';
import {UnitFiles} from './components/Pages/UnitFiles';
import {UnitManagementRouting} from './components/Pages/UnitManagement';
import {
  DictionariesProvider,
  LoggerProvider,
  PortalDropdownsProvider,
  PreviousUnitPageProvider,
  ProcessProvider,
  UserProvider
} from './providers';
import {AuthenticationProvider} from './providers/AuthenticationProvider';
import {MainStoreProvider} from './providers/MainStoreProvider';
import {GlobalSettingsProvider} from './providers/GlobalSettingsProvider';
import {MyProcessList} from './components/Pages/MyProcessList';
import {Acknowledgement} from './components/Pages/Acknowledgement';

const theme = ABBTheme;
const fonts = FontStyle;

const appTheme: PartialTheme = {
  palette: {
    ...theme
  },
  defaultFontStyle: {
    ...fonts
  }
};

export const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <MainStoreProvider>
          <AuthenticationProvider>
            <LoggerProvider>
              <DictionariesProvider>
                <UserProvider>
                  <PortalDropdownsProvider>
                    <ProcessProvider>
                      <PreviousUnitPageProvider>
                        <GlobalSettingsProvider>
                          <Routes>
                            <Route element={<HomeLayout />}>
                              <Route index path="/" element={<Home />} />
                            </Route>
                            <Route element={<Layout />}>
                              <Route path="*" element={<PageNotFound />} />
                              <Route path="accessDenied" element={<AccessDenied />} />
                              <Route path="unit/:unitId" element={<Unit />} />
                              <Route
                                path="unit/:unitId/process/accessDenied"
                                element={<ProcessNotFound />}
                              />
                              <Route path="unit/:unitId/process/:processId" element={<Process />} />
                              <Route
                                path="unit/:unitId/process/:processId/version/:version"
                                element={<ProcessVersion />}
                              />
                              <Route
                                path="unit/:unitId/process/:processId/edit"
                                element={<EditProcess />}
                              />
                              <Route path="unit/:unitId/documentsList" element={<UnitFiles />} />
                              <Route
                                path="unit/:unitId/manage/*"
                                element={<UnitManagementRouting />}
                              />
                              <Route path="unit/:unitId/processlist" element={<ProcessList />} />
                              <Route
                                path="unit/:unitId/myprocesslist"
                                element={<MyProcessList />}
                              />
                              <Route path="unit/:unitId/processMap" element={<ProcessMap />} />
                              <Route
                                path="acknowledgement/:packageId"
                                element={<Acknowledgement />}
                              />
                              <Route path="admin/*" element={<Admin />} />
                              <Route path="help" element={<Help />} />
                              <Route path="searchResults" element={<SearchResults />} />
                            </Route>
                          </Routes>
                        </GlobalSettingsProvider>
                      </PreviousUnitPageProvider>
                    </ProcessProvider>
                  </PortalDropdownsProvider>
                </UserProvider>
              </DictionariesProvider>
            </LoggerProvider>
          </AuthenticationProvider>
        </MainStoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
