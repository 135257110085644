import {Spinner} from '@fluentui/react';
import {FC, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../../../../helpers/arrayMehods';
import {Activity} from '../../../../../../models';
import {EnhancedDetailsList, OtherColumn} from '../../../../../common/EnhancedDetailsList';
import {EnhancedAddButton} from '../../../../../common/EnhancedDetailsList/EnhancedAddButton';
import {
  IEnhancedColumn,
  SpecialColumnKey
} from '../../../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {getColumnsAdd} from '../../../../../common/ProcessSections/activitiesListColumns';
import {IProcessRowActivitiesProps} from './IProcessRowActivitiesProps';
import {useActivities} from '../../../../../../hooks/services/useActivities';

function mapChangedToRegular(changedItem: Activity): Activity {
  return changedItem;
}

export const ProcessRowActivities: FC<IProcessRowActivitiesProps> = ({
  model,
  disabled,
  isInput,
  unitId
}) => {
  const {getActivityInputs, getActivityOutputs} = useActivities();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await (isInput ? getActivityInputs(unitId) : getActivityOutputs(unitId));
      if (!response.result) return;
      setExternalLinks(response.result.sort(sortByTitle));
      setIsLoading(false);
    })();
  }, [isInput, getActivityInputs, getActivityOutputs, unitId]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalLinks, setExternalLinks] = useState<Activity[]>([]);
  const [newItemsIds, setNewItemsIds] = useState<number[]>([]);
  const columnsAdd = useMemo(() => {
    const cols: IEnhancedColumn<Activity>[] = [
      {
        key: SpecialColumnKey.Add,
        name: '',
        isResizable: false,
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: Activity) => (
          <EnhancedAddButton
            item={item}
            addedIds={newItemsIds}
            addDocument={newItem => {
              const mappedItem = newItem;
              mappedItem.active = true;
              if (isInput) {
                model.changedActivityInputs.push(newItem);
              } else {
                model.changedActivityOutputs.push(newItem);
              }
              setNewItemsIds([...newItemsIds, newItem.id]);
            }}
            disabled={disabled}
          />
        ),
        data: OtherColumn
      },
      ...getColumnsAdd(isInput ? model.changedActivityInputs as any : model.changedActivityOutputs as any)
    ];
    return cols;
  }, [isInput, newItemsIds, setNewItemsIds, disabled, model]);
  return isLoading ? (
    <Spinner />
  ) : (
    <EnhancedDetailsList<Activity, Activity, Activity>
      items={externalLinks}
      allowAdd
      isAddModal
      columns={columnsAdd}
      changedItems={isInput ? model.changedActivityInputs : model.changedActivityOutputs}
      mapChangedItemToItem={mapChangedToRegular}
      newItemsIds={newItemsIds}
      pageSize={5}
    />
  );
};
