import styles from './MasterProcessInput.module.scss';
import {FC, useCallback, useState} from 'react';
import {InputValidationError} from '../../../common/Input/ValidationError';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {TextField} from '@fluentui/react';

export const MasterProcessInputTitle: FC<{model: MasterProcessItemModel; required?: boolean}> = ({
  model,
  required
}) => {
  const [value, setValue] = useState<string | null>(model.title);
  const onChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
      model.title = value || null;
      setValue(value || null);
    },
    [model]
  );
  return (
    <div>
      <TextField
        className={styles.input}
        defaultValue={model.title || ''}
        onChange={onChange}
        disabled={!model.isChecked}
        required={required}
      />
      {!value && required ? (
        <InputValidationError message={'This value could not be empty. Add title.'} />
      ) : null}
    </div>
  );
};
