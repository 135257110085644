import {useContext, useEffect, useState} from 'react';

import {useService} from '../../../hooks';
import {ProcessMinimal} from '../../../models';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';

export const useBreadcrumbs = (processId: number) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getBreadcrumbs} = useService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [breadcrumbs, setBreadcrumbs] = useState<ProcessMinimal[]>([]);
  const [apiCode, setApiCode] = useState<string | null>(null);

  const getBreadcrumbsApi = async () => {
    const bread = getBreadcrumbs(processId);
    return bread;
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const data = await getBreadcrumbsApi();
        setApiCode(data.apiCode);
        setBreadcrumbs(data.result || []);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  return {breadcrumbs, isLoading, apiCode};
};
