import {Spinner} from '@fluentui/react';
import {FC, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../../../../helpers/arrayMehods';
import {useService} from '../../../../../../hooks';
import {ExternalLink} from '../../../../../../models';
import {EnhancedDetailsList, OtherColumn} from '../../../../../common/EnhancedDetailsList';
import {EnhancedAddButton} from '../../../../../common/EnhancedDetailsList/EnhancedAddButton';
import {
  IEnhancedColumn,
  SpecialColumnKey
} from '../../../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {getColumnsAdd} from '../../../../../common/ProcessSections/linksListColumns';
import {IProcessRowExternalLinks} from './IProcessRowExternalLinks';

function mapChangedToRegular(changedItem: ExternalLink): ExternalLink {
  return changedItem;
}

export const ProcessRowExternalLinks: FC<IProcessRowExternalLinks> = ({model, disabled}) => {
  const {getExternalLinks} = useService();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getExternalLinks();
      if (!response.result) return;
      setExternalLinks(response.result.sort(sortByTitle));
      setIsLoading(false);
    })();
  }, [getExternalLinks]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalLinks, setExternalLinks] = useState<ExternalLink[]>([]);
  const [newItemsIds, setNewItemsIds] = useState<number[]>([]);
  const columnsAdd = useMemo(() => {
    const cols: IEnhancedColumn<ExternalLink>[] = [
      {
        key: SpecialColumnKey.Add,
        name: '',
        isResizable: false,
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: ExternalLink) => (
          <EnhancedAddButton
            item={item}
            addedIds={newItemsIds}
            addDocument={newItem => {
              const mappedItem = newItem;
              mappedItem.active = true;
              model.changedExternalLinks.push(newItem);
              setNewItemsIds([...newItemsIds, newItem.id]);
            }}
            disabled={disabled}
          />
        ),
        data: OtherColumn
      },
      ...getColumnsAdd(model.changedExternalLinks as any)
    ];
    return cols;
  }, [newItemsIds, setNewItemsIds, disabled, model]);
  return isLoading ? (
    <Spinner />
  ) : (
    <EnhancedDetailsList<ExternalLink, ExternalLink, ExternalLink>
      items={externalLinks}
      allowAdd
      isAddModal
      columns={columnsAdd}
      changedItems={model.changedExternalLinks}
      mapChangedItemToItem={mapChangedToRegular}
      newItemsIds={newItemsIds}
      pageSize={5}
    />
  );
};
