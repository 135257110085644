import {FC, PropsWithChildren, useMemo, useRef} from 'react';

import {MainStoreContext} from './MainStoreContext';

import {Users} from '../store/Users';

export const MainStoreProvider: FC<PropsWithChildren> = ({children}) => {
  const users = useMemo(() => new Users(), []);

  const rolePermissions = useRef([]);

  return (
    <MainStoreContext.Provider value={{users, rolePermissions: rolePermissions.current}}>
      {children}
    </MainStoreContext.Provider>
  );
};
