import { FC } from 'react';
import { ModalWithHeader } from '../ModalWithHeader';
import {DocumentListToUpdate} from '../DocumentAdditionalAttributes/DocumentListToUpdate';
import { DocumentSystem } from '../../../models/DocumentSystem';
import { Document } from '../../../models/Document';
interface IDocumentListUpdateModalProps {
  isVisible: boolean;
  editItem: DocumentSystem | null;
  onClose: () => void;
  onUpdated: (modifiedDoc: Document) => void;
  parents: DocumentSystem[] | null
}
export const DocumentListUpdateModal: FC<IDocumentListUpdateModalProps> = ({
  isVisible,
  editItem,
  onClose,
  onUpdated,
  parents
}) => (
  <ModalWithHeader
    header='Update Additional Atrributes'
    isVisible={isVisible}
    dismiss={onClose}>
    <DocumentListToUpdate
      onCancel={onClose}
      editItem={editItem}
      onUpdated={onUpdated}
      parents={parents}
    />
  </ModalWithHeader>
);