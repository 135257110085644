import {Shimmer} from '@fluentui/react';
import {ProcessType} from '../../../enums/ProcessEnum';
import styles from './Breadcrumbs.module.scss';
import {useBreadcrumbs} from './useBreadcrumbs';
import {NavLink} from 'react-router-dom';

type IBreadcrumbs = {
  processId: number;
  unitId: number;
};

export const Breadcrumbs = ({processId, unitId}: IBreadcrumbs) => {
  const {breadcrumbs, isLoading, apiCode} = useBreadcrumbs(processId);

  return (
    <>
      {isLoading ? (
        <Shimmer className={styles.shimmer} />
      ) : (
        !apiCode && (
          <div className={styles.breadcrumbsWrapper}>
            {breadcrumbs.length > 0 && (
              <ul className={styles.breadcrumbs}>
                {breadcrumbs.map(bread => (
                  <li key={bread.title}>
                    {bread.type === ProcessType.Process ? (
                      <NavLink to={`/unit/${unitId}/process/${bread.id}`}>{bread.title}</NavLink>
                    ) : (
                      bread.title
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )
      )}
    </>
  );
};
