import {FC, PropsWithChildren, useContext} from 'react';
import {Link} from 'react-router-dom';
import {LoggerContext} from '../../../../providers';

import styles from './ApiErrorStatus.module.scss';

export const ApiErrorStatus: FC<PropsWithChildren> = ({children}) => {
  const {pageException} = useContext(LoggerContext);
  if (!pageException) return <>{children}</>;
  return (
    <div className={styles.wrapper}>
      <div className={styles.notFoundHeader}>{pageException}</div>
      <div className={styles.notFoundText}>Sorry for that!</div>
      <div className={styles.notFoundBtn}>
        <Link to="/">Take me home</Link>
      </div>
    </div>
  );
};
