import {MasterProcessDto, ProcessDto, SaveDocumentDto} from '../services/generated';
import {Activity} from './Activity';
import {ProcessBaseView} from './ProcessBaseView';

export class ProcessSave extends ProcessBaseView implements ProcessDto {
  public documents: Array<SaveDocumentDto> | null;
  public masterProcess: MasterProcessDto | null;
  public publicationComment: string | null;
  public activityInputs: Activity[] | null;
  public activityOutputs: Activity[] | null;
  public nextUpdateDateBlock: boolean;
  public isMirrorCopy: boolean;
  constructor(process: ProcessDto) {
    super(process);
    this.documents = process.documents;
    this.masterProcess = process.masterProcess;
    this.publicationComment = process.publicationComment;
    this.activityInputs = process.activityInputs?.map(item => new Activity(item)) || null;
    this.activityOutputs = process.activityOutputs?.map(item => new Activity(item)) || null;
    this.nextUpdateDateBlock = process.nextUpdateDateBlock;
    this.isMirrorCopy = process.isMirrorCopy;
  }
}
