import {ProcessType} from '../enums';
import {ProcessBase} from './ProcessBase';

export class ProcessStructure extends ProcessBase {
  public deleted: boolean;
  public masterProcessId: number;
  public processType: ProcessType;
  public unitId: number;
  public unitTitle: string | null;

  constructor(process: ProcessStructure) {
    super(process);
    this.deleted = process.deleted;
    this.masterProcessId = process.masterProcessId;
    this.processType = process.processType;
    this.unitId = process.unitId;
    this.unitTitle = process.unitTitle;
  }
}
