import styles from './TheButton.module.scss';
import {DefaultButton, IButtonProps, IconButton, PrimaryButton} from '@fluentui/react';
import {FC, PropsWithChildren} from 'react';
import {ITheButtonProps} from './ITheButtonProps';

export const TheButton: FC<PropsWithChildren<ITheButtonProps>> = ({
  primary,
  isIcon,
  isCustom,
  split,
  disabled,
  iconProps,
  menuProps,
  className,
  classNameButton,
  style,
  children,
  href,
  target,
  onClick,
  ariaLabel
}) => {
  const props: IButtonProps = {
    href,
    target,
    onClick,
    iconProps,
    className: `${styles.button}${classNameButton ? ' ' + classNameButton : ''}`,
    split,
    menuProps,
    style,
    disabled,
    ariaLabel
  };
  if (isCustom) {
    return (
      <div className={`${styles.wrapperMin}${className ? ' ' + className : ''}`}>
        <DefaultButton {...props} className={classNameButton}>
          {children}
        </DefaultButton>
      </div>
    );
  }
  if (isIcon)
    return (
      <div className={`${styles.wrapper}${className ? ' ' + className : ''}`}>
        <IconButton {...props} />
      </div>
    );
  return (
    <div className={`${styles.wrapper}${className ? ' ' + className : ''}`}>
      {primary ? (
        <PrimaryButton {...props}>{children}</PrimaryButton>
      ) : (
        <DefaultButton {...props}>{children}</DefaultButton>
      )}
    </div>
  );
};
