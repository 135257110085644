import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {actions, PermissionToCheck} from '../../../../enums';
import {sortByTitle} from '../../../../helpers/arrayMehods';
import {checkPermissions} from '../../../../helpers/permissionsHtml';
import {useService} from '../../../../hooks';
import {useProcess} from '../../../../hooks/services/useProcess';
import {
  Process,
  ProcessTree,
  ProcessVersion as ProcessVersionModel,
  RolePermission
} from '../../../../models';
import {ApiResponse} from '../../../../services/ApiResponseType';
import {HeadingWithDecoration} from '../../../common/HeadingWithDecoration';
import {Subscription} from '../../../common/Subscription';
import {ProcessDescriptions} from '../components/ProcessDescriptions';
import {ProcessDocuments} from '../components/ProcessDocuments';
import {ProcessExternalLinks} from '../components/ProcessExternalLinks';
import {ProcessOwner} from '../components/ProcessOwner';
import {ProcessWrapper} from '../components/Wrapper';

import styles from '../Process.module.scss';

export const ProcessVersion = () => {
  const {processId: processIdStr, version: versionString} = useParams();
  const processId = Number(processIdStr);
  const version = Number(versionString);
  const {getProcess /*getProcessVersions*/} = useProcess();
  const {getUserPermissionsPage, getMenuProcessesPublishedForUnit} = useService();
  const [process, setProcess] = useState<ApiResponse<Process>>();
  const [processVersions, setProcessVersions] = useState<ApiResponse<ProcessVersionModel[]>>();
  const [processVersion, setProcessVersion] = useState<ProcessVersionModel>();
  const [processPermissions, setProcessPermissions] = useState<
    ApiResponse<RolePermission[]> | undefined
  >();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] = useState<
    ApiResponse<ProcessTree[]> | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const process = await getProcess(processId);
      setProcess(process);
      if (process.result && process.result.unit) {
        const permissions = await getUserPermissionsPage(process.result.unit.id, process.result.id);
        setProcessPermissions(permissions);
        const processList = await getMenuProcessesPublishedForUnit(process.result.unit.id);
        setPublishedProcessesForUnit(processList);
      }
      // const processVersions = await getProcessVersions(processId);
      // setProcessVersions(processVersions);
      setIsLoading(false);
    })();
  }, [
    processId,
    getProcess,
    setIsLoading,
    setProcessVersions,
    getUserPermissionsPage,
    setProcessPermissions,
    getMenuProcessesPublishedForUnit,
    setPublishedProcessesForUnit
  ]);

  useEffect(() => {
    const newProcessVersion = processVersions?.result?.find(
      processVersion => processVersion.version === version
    );
    setProcessVersion(newProcessVersion);
  }, [version, processVersions, setProcessVersion]);

  const canEdit = useMemo(
    () =>
      checkPermissions(PermissionToCheck.ProcessForm, actions.UPDATE, processPermissions?.result),
    [processPermissions]
  );

  const externalLinks = useMemo(
    () => processVersion?.externalLinks?.sort(sortByTitle) || null,
    [processVersion]
  );

  return (
    <ProcessWrapper
      apiMsg={undefined}
      isLoading={isLoading}
      process={process?.result || null}
      canEdit={canEdit}
      publishedProcessesForUnit={publishedProcessesForUnit}>
      {process?.result && processVersion ? (
        <>
          <div className={styles.processMeta}>
            <div className={styles.left}>
              <HeadingWithDecoration text={process.result.title || ''} />
              <ProcessDescriptions
                process={process.result}
                processUnitTitle={process.result.unit?.title || ''}
              />
            </div>
            <div>
              {processVersion.processOwner ? (
                <ProcessOwner processOwner={processVersion.processOwner} />
              ) : null}
              <div className={styles.processSubWrapper}>
                <Subscription processId={process.result.id} />
              </div>
            </div>
          </div>
          <ProcessDocuments documents={processVersion.documents || null} />
          <ProcessExternalLinks externalLinks={externalLinks} />
        </>
      ) : null}
      {process?.result && !processVersion ? 'Process version not found' : null}
    </ProcessWrapper>
  );
};
