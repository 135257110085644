import {MessageBarType} from '@fluentui/react';
import {createContext} from 'react';

export interface ILog {
  key: string;
  message: string;
  messageType: MessageBarType;
  date: Date;
}

interface ILoggerContext {
  pageException: string | null;
  setPageException: (message: string | null) => void;
  logs: ILog[];
  addLog: (logType: MessageBarType, logMessage?: string | null, error?: unknown) => void;
  deleteLog: (logKey: string) => void;
}

export const LoggerContext = createContext<ILoggerContext>({
  pageException: null,
  setPageException: console.log,
  logs: [],
  addLog: console.log,
  deleteLog: console.log
});
