import {FC, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react';

import {useService} from '../hooks';
import {Unit} from '../models';
import {AuthenticationContext} from './AuthenticationContext';
import {PortalDropdownsContext} from './PortalDropdownsContext';
import {ILocalStorage} from '../components/common/PreferencesForm';

export const PortalDropdownsProvider: FC<PropsWithChildren> = ({children}) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const [portalData, setPortalData] = useState<Unit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [favUnit, setFavUnit] = useState<string | undefined>(undefined);

  const portalStructureData = useMemo(() => {
    return portalData;
  }, [portalData]);

  const {getUnits} = useService();

  const getData = async () => {
    const data = await getUnits();
    return data;
  };

  useEffect(() => {
    const localStorageValuePersonal = localStorage.getItem('personalSettings');
    if (localStorageValuePersonal) {
      const detailsData: ILocalStorage = JSON.parse(localStorageValuePersonal);
      const countryKey = detailsData?.countryValue?.key;
      const savedFavUnitId =
        countryKey && countryKey !== '-1'
          ? detailsData?.countryValue?.key
          : detailsData?.divValue?.key;
      setFavUnit(savedFavUnitId ? String(savedFavUnitId) : undefined);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const data = await getData();
        if (data.result) {
          setPortalData(data.result);
        }
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAzureLoading]);

  return (
    <PortalDropdownsContext.Provider value={{portalStructureData, isLoading, favUnit, setFavUnit}}>
      {children}
    </PortalDropdownsContext.Provider>
  );
};
