/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {ProcessCommentDto} from '../models/ProcessCommentDto';

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import {ElevateProcessCommentDto} from '../models/ElevateProcessCommentDto';
import { OpexDto } from '../models/OpexDto';

export class CommentService {
  /**
   * @param processId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiComment(processId: number, requestBody?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Comment/{processId}',
      path: {
        processId: processId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

    /**
   * @param commentId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
    public static postRaisedToOpexComment(commentId: number, requestBody: OpexDto): CancelablePromise<any> {
      return __request(OpenAPI, {
        method: 'POST',
        url: '/api/Comment/raiseToOpex/{commentId}',
        path: {
          commentId: commentId
        },
        body: requestBody,
        mediaType: 'application/json'
      });
    }

  /**
   * @param processId
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static postApiReplyToComment(
    processId: number,
    replyToId: number,
    requestBody?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Comment/{processId}/replyTo/{replyToId}',
      path: {
        processId: processId,
        replyToId: replyToId
      },
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param processId
   * @returns ProcessCommentDto Success
   * @throws ApiError
   */
  public static getApiComment(processId: number): CancelablePromise<Array<ProcessCommentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Comment/{processId}',
      path: {
        processId: processId
      }
    });
  }

  /**
   * @param commentId
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiComment(commentId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Comment/{commentId}',
      path: {
        commentId: commentId
      }
    });
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiCommentDeleteMany(requestBody?: Array<number>): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Comment/deleteMany',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param commentId
   * @returns any Success
   * @throws ApiError
   */
  public static postApiCommentThumbUp(commentId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Comment/{commentId}/thumbUp',
      path: {
        commentId: commentId
      }
    });
  }

  /**
   * @param commentId
   * @returns any Success
   * @throws ApiError
   */
  public static postApiCommentThumbDown(commentId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Comment/{commentId}/thumbDown',
      path: {
        commentId: commentId
      }
    });
  }

  /**
   * @param requestBody
   * @returns ElevateProcessCommentDto Success
   * @throws ApiError
   */
  public static putApiElevateToProcessComment(
    requestBody?: ElevateProcessCommentDto
  ): CancelablePromise<ElevateProcessCommentDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Comment',
      body: requestBody,
      mediaType: 'application/json'
    });
  }

  /**
   * @param commentId
   * @returns any Success
   * @throws ApiError
   */
  public static putApiCommentResolve(commentId: number): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Comment/{commentId}',
      path: {
        commentId: commentId
      }
    });
  }
}
