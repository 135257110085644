import {ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType} from '@fluentui/react';
import {FC, useMemo, useCallback, useState} from 'react';
import {ISelectPickerMultiProps, ISelectPickerSingleProps} from './ISelectPickerProps';

export const SelectPicker: FC<ISelectPickerSingleProps | ISelectPickerMultiProps> = ({
  multiSelect,
  options,
  label,
  onChange,
  defaultValue,
  skipSort
}) => {
  const initialOptions: IComboBoxOption[] = useMemo(() => {
    const sortedOptions = skipSort ? options : options.sort();
    const initial: IComboBoxOption[] = [
      {key: 'Header1', text: 'Options', itemType: SelectableOptionMenuItemType.Header},
      ...sortedOptions.map(option => ({key: option, text: option})),
      {key: 'divider2', text: '-', itemType: SelectableOptionMenuItemType.Divider},
      {key: 'Header3', text: 'Free text', itemType: SelectableOptionMenuItemType.Header}
    ];
    if (defaultValue) {
      if (multiSelect) {
        defaultValue.forEach(value => {
          if (!initial.find(option => option.key === value)) {
            initial.push({
              key: value,
              text: value,
              data: {freeText: true}
            });
          }
        });
      } else if (!multiSelect && !initial.find(option => option.key === defaultValue)) {
        initial.push({
          key: defaultValue,
          text: defaultValue,
          data: {freeText: true}
        });
      }
    }
    return initial;
  }, [multiSelect, defaultValue, options, skipSort]);
  const [selectedKey, setSelectedKey] = useState(multiSelect ? undefined : defaultValue);
  const [selectedKeys, setSelectedKeys] = useState(multiSelect ? defaultValue : undefined);
  const [pickerOptions, setPickerOptions] = useState(initialOptions);
  const onComboChange = useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      const key = (option?.key as string) || value || '';
      let newPickerOptions = pickerOptions;
      if (!option && value) {
        const newOption: IComboBoxOption = {key, text: value, data: {freeText: true}};
        newPickerOptions = [...pickerOptions, newOption];
        setPickerOptions(newPickerOptions);
      }
      if (multiSelect) {
        const newKeys =
          option?.selected || (!option && value)
            ? [...(selectedKeys || []), key]
            : (selectedKeys || []).filter(selectedKey => selectedKey !== key);
        onChange(
          newKeys,
          newPickerOptions.filter(option => newKeys.some(key => key === option.key))
        );
        setSelectedKeys(newKeys);
      } else {
        onChange(
          key,
          newPickerOptions.find(option => option.key === key)
        );
        setSelectedKey(key);
      }
    },
    [multiSelect, onChange, selectedKeys, pickerOptions]
  );
  return (
    <>
      <ComboBox
        label={label}
        allowFreeform={true}
        autoComplete={'on'}
        options={pickerOptions}
        onChange={onComboChange}
        selectedKey={multiSelect ? selectedKeys : selectedKey}
        multiSelect={multiSelect}
      />
    </>
  );
};
