/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = IMSProcess
 *
 * 2 = Site
 *
 * 3 = SoftwareApplication
 *
 * 4 = Examples
 * 
 * 5 = ELearning
 */
export enum ExternalLinkType {
  IMSProcess = 1,
  Site = 2,
  SoftwareApplication = 3,
  Examples = 4,
  ELearning = 5
}
