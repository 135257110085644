import {XLSXValue} from '../../../../helpers/excel';
import {ColumnType} from '../IEnhancedDetailsList';
import {IColumn, IComboBoxOption, TextField, TooltipHost} from '@fluentui/react';

export abstract class EnhancedColumn {
  public static type: ColumnType;
  public static canFilter = true;
  public static canSort = true;
  public static minWidth: number = 75;
  public static maxWidth?: number;
  public static isResizable: boolean = true;

  public static renderer(value: any, column: IColumn, item: any | undefined): JSX.Element {
    const textValue = value && typeof value === 'object' ? value.value : value;
    return <TooltipHost content={textValue}>{textValue}</TooltipHost>;
  }

  public static getColumnValueForSort(value: any): string {
    return value ? `${value}` : '0';
  }

  public static hasActiveFilter(value: any): boolean {
    return !!value;
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions?: any[]
  ): JSX.Element {
    return (
      <TextField
        label="Filter"
        defaultValue={defaultValue}
        onChange={(e, newValue) => onChange(newValue)}
      />
    );
  }

  public static filterFunction(itemValue: any, filterValue: any): boolean {
    const lowerCaseFilterValue = ((filterValue as string) || '').toLowerCase();
    if (typeof itemValue === 'number' || typeof itemValue === 'string')
      return `${itemValue}`.toLowerCase().indexOf(lowerCaseFilterValue) !== -1;
    return false;
  }

  public static searchFunction(itemValue: any, lowerCaseSearch: string): boolean {
    return false;
  }

  public static getColumnValueForExcel(itemValue: any): XLSXValue {
    return typeof itemValue === 'undefined' || itemValue === null ? '' : String(itemValue);
  }
}
