import {FC, useCallback, useContext, useEffect, useState} from 'react';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {ViewModel} from '../../../models';
import {Label, Spinner, TextField, Toggle} from '@fluentui/react';
import {TheButton} from '../../common/TheButton';
import {useViews} from '../../../hooks/services/useViews';
import styles from './ViewsCenter.module.scss';
import {ApiResponse} from '../../../services/ApiResponseType';
import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  UnitDto,
  ViewDto,
  BusinessLineDto
} from '../../../services/generated';
import {DictionariesContext} from '../../../providers';
import {DictionaryMultiselectDropdown} from '../../common/DictionaryDropdown/DictionaryMultiselectDropdown';

interface IViewsCenterAddModalProps {
  unitId: number | undefined;
  isVisible: boolean;
  allowAdd?: boolean;
  editItem?: ViewModel;
  onClose: () => void;
  onCreated: (addedView: ViewModel) => void;
}

export const ViewsCenterAddModal: FC<IViewsCenterAddModalProps> = ({
  unitId,
  isVisible,
  editItem,
  onClose,
  onCreated
}) => {
  const {
    optionsFunction,
    optionsProjectSizeCategory,
    optionsSpeciality,
    optionsIndustry,
    optionsBusinessLine
  } = useContext(DictionariesContext);
  const {createView, updateView, createUnitView, updateUnitView} = useViews();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(editItem?.title || '');
  const [functions, setFunctions] = useState<FunctionDto[] | undefined>(editItem?.functions || []);
  const [projectSizeCategories, setProjectSizeCategories] = useState<
    ProjectSizeCategoryDto[] | undefined
  >(editItem?.projectSizeCategories || []);
  const [industries, setIndustries] = useState<IndustryDto[] | undefined>(
    editItem?.industries || []
  );
  const [businessLines, setBL] = useState<BusinessLineDto[] | undefined>(
    editItem?.businessLines || []
  );
  const [specialities, setSpecialities] = useState<SpecialityDto[] | undefined>(
    editItem?.specialities || []
  );
  const [excludeItemsApplicableToAll, setExcludeItemsApplicableToAll] = useState<boolean>(
    editItem?.excludeItemsApplicableToAll || false
  );
  const onExcludeEmptyChange = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) =>
      setExcludeItemsApplicableToAll(!!checked),
    []
  );

  const isValid = !!title;

  const onSubmit = useCallback(async () => {
    if (!isValid) return;
    setIsLoading(true);
    const newView: ViewDto = {
      id: editItem?.id || 0,
      unit: unitId
        ? ({
            id: unitId,
            link: [],
            title: ''
          } as unknown as UnitDto)
        : null,
      link: [],
      title,
      functions: functions || null,
      projectSizeCategories: projectSizeCategories || null,
      industries: industries || null,
      specialities: specialities || null,
      includeEmpty: !excludeItemsApplicableToAll,
      isFavorite: editItem?.isFavorite || null,
      isDefault: editItem?.isDefault || null,
      masterViewId: editItem?.masterViewId || null,
      businessLines: businessLines || null
    };
    let response: ApiResponse<ViewModel>;
    if (editItem) {
      response = await (unitId
        ? updateUnitView(unitId, editItem.id, newView)
        : updateView(editItem.id, newView));
    } else {
      response = await (unitId ? createUnitView(unitId, newView) : createView(newView));
    }
    if (response.result) {
      onCreated(response.result);
    }
    setIsLoading(false);
  }, [
    isValid,
    editItem,
    unitId,
    title,
    functions,
    projectSizeCategories,
    industries,
    specialities,
    excludeItemsApplicableToAll,
    businessLines,
    updateUnitView,
    updateView,
    createUnitView,
    createView,
    onCreated
  ]);

  const clearValues = useCallback(() => {
    setTitle('');
    setFunctions([]);
    setProjectSizeCategories([]);
    setIndustries([]);
    setBL([]);
    setSpecialities([]);
    setExcludeItemsApplicableToAll(false);
  }, []);

  useEffect(() => {
    if (isVisible) return;
    clearValues();
  }, [isVisible, clearValues]);

  useEffect(() => {
    if (!editItem) return clearValues();
    setTitle(editItem.title || '');
    setFunctions(editItem.functions || []);
    setProjectSizeCategories(editItem.projectSizeCategories || []);
    setIndustries(editItem.industries || []);
    setBL(editItem.businessLines || []);
    setSpecialities(editItem.specialities || []);
    setExcludeItemsApplicableToAll(editItem.excludeItemsApplicableToAll || false);
  }, [editItem, clearValues]);

  return (
    <ModalWithHeader
      header={`${editItem ? 'Update' : 'Add new'} view`}
      isVisible={isVisible}
      dismiss={onClose}>
      <TextField
        label="Title"
        defaultValue={editItem?.title || undefined}
        onChange={(e, value) => setTitle(value || '')}
        required
      />
      <Label>Function</Label>
      <DictionaryMultiselectDropdown
        onChange={setFunctions}
        options={optionsFunction}
        defaultValues={functions}
      />
      <Label>Project Size Category</Label>
      <DictionaryMultiselectDropdown
        onChange={setProjectSizeCategories}
        options={optionsProjectSizeCategory}
        defaultValues={projectSizeCategories}
      />
      <Label>Business Line</Label>
      <DictionaryMultiselectDropdown
        onChange={setBL}
        options={optionsBusinessLine}
        defaultValues={businessLines}
      />
      <Label>Industry</Label>
      <DictionaryMultiselectDropdown
        onChange={setIndustries}
        options={optionsIndustry}
        defaultValues={industries}
      />
      <Label>Speciality</Label>
      <DictionaryMultiselectDropdown
        onChange={setSpecialities}
        options={optionsSpeciality}
        defaultValues={specialities}
      />
      <Label>Exclude items applicable to all</Label>
      <Toggle defaultChecked={excludeItemsApplicableToAll} onChange={onExcludeEmptyChange} />

      <div className={styles.buttons}>
        <TheButton primary disabled={!isValid} onClick={onSubmit}>
          {editItem ? 'Update' : 'Create'}
        </TheButton>
      </div>
      {isLoading ? <Spinner /> : null}
    </ModalWithHeader>
  );
};
