import styles from './EnhancedDetailsList.module.scss';
import {BaseItem, ColumnKey, IEnhancedDetailsListProps} from './IEnhancedDetailsList';
import {
  ConstrainMode,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  SearchBox,
  SelectionMode
} from '@fluentui/react';
import {EnhancedColumn} from './ColumnTypes/EnhancedColumn';
import {EnhancedDetailsListFooter} from './Footer';
import {EnhancedFilterButton} from './EnhancedFilterButton';
import {ModalWithHeader} from '../ModalWithHeader';
import {Pagination} from '../Pagination';
import {TheButton} from '../TheButton';
import {useEnhandedDetailsList} from './useEnhandedDetailsList';

export const EnhancedDetailsList = <Item, ChangedItem, ItemToAdd>({
  allowAdd,
  addLabel,
  columns,
  columnsForAdd,
  hideSearch,
  frozen,
  frozenClassName,
  isAddModal,
  items,
  itemsToAdd,
  changedItems,
  mapChangedItemToItem,
  mapNewItemToChangedItem,
  onCreateItemClick,
  addRowClass,
  className,
  newItemsIds: initialNewItemsIds = [],
  ownerProcessId,
  pageSize = 10,
  excelExport,
  itemsFiltered,
  manageAuditable,
  onManageAuditable,
  excelFileName
}: IEnhancedDetailsListProps<Item & BaseItem, ChangedItem & BaseItem, ItemToAdd & BaseItem>) => {
  const {
    columnsForAddList,
    columnsList,
    currentItemsLength,
    currentPage,
    currentTableData,
    filter,
    getKey,
    isModalVisible,
    onFilterChange,
    onSearchHandler,
    newItemsIds,
    setCurrentPage,
    setIsModalVisible,
    onExportExcel,
    exportMenuProps
  } = useEnhandedDetailsList({
    allowAdd,
    columns,
    columnsForAdd,
    isAddModal,
    items,
    changedItems,
    frozen,
    frozenClassName,
    mapChangedItemToItem,
    mapNewItemToChangedItem,
    ownerProcessId,
    pageSize,
    excelFileName
  });

  const frozenGet = (frozen: boolean, frozenClassName?: string) => {
    let result;
    if (frozen) {
      if (frozenClassName) {
        result = styles[frozenClassName];
      } else {
        result = styles.frozen;
      }
    } else {
      result = '';
    }
    return result;
  };

  return (
    <div className={className}>
      {!hideSearch ? <SearchBox placeholder="Search" onChange={onSearchHandler} /> : null}
      {(allowAdd && itemsToAdd) || excelExport || manageAuditable ? (
        <div className={styles.addButton}>
          {allowAdd && itemsToAdd ? (
            <>
              <TheButton
                primary
                iconProps={{iconName: 'Add'}}
                onClick={() => setIsModalVisible(true)}>
                {addLabel || 'Add new'}
              </TheButton>
              <ModalWithHeader
                header={addLabel}
                isVisible={isModalVisible}
                dismiss={() => setIsModalVisible(false)}>
                <>
                  {onCreateItemClick ? (
                    <TheButton
                      primary
                      iconProps={{iconName: 'add'}}
                      onClick={onCreateItemClick}
                      className={styles.addNewButton}>
                      Create new
                    </TheButton>
                  ) : null}
                  <div>
                    <EnhancedDetailsList<ItemToAdd & BaseItem, ChangedItem & BaseItem, unknown>
                      items={itemsToAdd}
                      isAddModal
                      columns={columnsForAddList}
                      changedItems={changedItems}
                      mapChangedItemToItem={mapNewItemToChangedItem as any}
                      newItemsIds={newItemsIds}
                      frozen={frozen}
                    />
                  </div>
                </>
              </ModalWithHeader>
            </>
          ) : null}
          {excelExport ? (
            <TheButton onClick={onExportExcel} primary split menuProps={exportMenuProps}>
              Export to Excel
            </TheButton>
          ) : null}
          {manageAuditable ? (
            <TheButton onClick={onManageAuditable} primary>
              Manage Auditable
            </TheButton>
          ) : null}
        </div>
      ) : null}
      <div className={styles.detailsList}>
        <DetailsList
          //className={frozen ? styles.frozenUnitsList : ''}
          className={frozenGet(!!frozen, frozenClassName)}
          items={currentTableData}
          compact={true}
          columns={columnsList}
          selectionMode={SelectionMode.none}
          getKey={getKey}
          constrainMode={ConstrainMode.unconstrained}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          onRenderRow={
            !addRowClass
              ? undefined
              : (props, defaultRender) =>
                  defaultRender
                    ? defaultRender(
                        props ? {...props, className: addRowClass(props.item)} : undefined
                      )
                    : null
          }
          onRenderDetailsHeader={(detailsHeaderProps?: IDetailsHeaderProps) => {
            if (!detailsHeaderProps) return null;
            return (
              <DetailsHeader
                {...detailsHeaderProps}
                onRenderColumnHeaderTooltip={(
                  tooltipHostProps?: IDetailsColumnRenderTooltipProps
                ) => {
                  const type = tooltipHostProps?.column?.data as typeof EnhancedColumn;
                  if (!type.canFilter) return <>{tooltipHostProps?.children}</>;
                  const key = tooltipHostProps?.column?.key as ColumnKey;
                  return (
                    <div
                      className={[tooltipHostProps?.hostClassName, styles.columnHeader].join(' ')}>
                      {tooltipHostProps?.children}
                      <EnhancedFilterButton
                        columnKey={key}
                        type={type}
                        filter={filter}
                        onFilterChange={onFilterChange}
                        items={items}
                      />
                    </div>
                  );
                }}
              />
            );
          }}
          onRenderDetailsFooter={
            () => <EnhancedDetailsListFooter
            itemsFiltered={itemsFiltered ? itemsFiltered : false}
            itemsLength={items.length}
            newItemsLength={newItemsIds.length}
             />
          }
        />
      </div>
      {pageSize ? (
        <Pagination
          currentPage={currentPage}
          total={currentItemsLength}
          limit={pageSize}
          onPageChange={setCurrentPage}
        />
      ) : null}
    </div>
  );
};
