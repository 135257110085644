import {
  FunctionDto,
  ManageMasterProcessDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../../../../services/generated';
import {MasterProcessManage} from '../../../../models';

export class MasterProcessItemModel extends MasterProcessManage {
  public get isChecked() {
    return !this.deleted;
  }
  public set isChecked(isChecked: boolean) {
    this.deleted = !isChecked;
  }

  public get value() {
    return this.prefixedTitle;
  }

  public get snowflakeId() {
    return `${this.id}`;
  }

  public isEdit = false;

  public initialDeleted: boolean = this.deleted;
  public initialTitle: string | null = this.title;
  private initialOrder: number = this.order;
  private initalBusinessLines: BusinessLineDto[] | null = this.businessLines;
  private initialFunction: FunctionDto | null = this.function;
  private initialProjectSizeCategories: ProjectSizeCategoryDto[] | null =
    this.projectSizeCategories;
  private initialSpecialities: SpecialityDto[] | null = this.specialities;
  private initialIndustries: IndustryDto[] | null = this.industries;

  public get isModified() {
    return (
      this.deleted !== this.initialDeleted ||
      (!this.deleted &&
        (this.title !== this.initialTitle ||
          this.order !== this.initialOrder ||
          this.function?.id !== this.initialFunction?.id ||
          this.isMultiValuedPropertyModified(
            this.projectSizeCategories,
            this.initialProjectSizeCategories
          ) ||
          this.isMultiValuedPropertyModified(this.specialities, this.initialSpecialities) ||
          this.isMultiValuedPropertyModified(this.industries, this.initialIndustries) ||
          this.isMultiValuedPropertyModified(this.businessLines, this.initalBusinessLines)))
    );
  }

  public get rowValue(): ManageMasterProcessDto {
    return {
      id: this.id,
      link: this.link,
      parentId: this.parentId,
      title: this.title,
      order: this.order,
      deleted: this.deleted,
      function: this.function,
      projectSizeCategories: this.projectSizeCategories,
      specialities: this.specialities,
      industries: this.industries,
      prefix: null,
      businessLines: this.businessLines
    };
  }

  public get isInvalid() {
    return !this.function?.id || !this.title;
  }

  public get orderPrefix(): string {
    return `${this.parent?.orderPrefix || ''}${this.order >= 10 ? this.order : `0${this.order}`}.`;
  }

  public get prefixedTitle(): string {
    return `${this.prefix ? `${this.prefix} ` : ''}${this.initialTitle}`;
  }

  private parent: MasterProcessItemModel | null = null;

  constructor(
    masterProcess: ManageMasterProcessDto = {
      id: 0,
      parentId: null,
      deleted: false,
      title: '',
      order: 1,
      link: [],
      function: {} as FunctionDto,
      projectSizeCategories: null,
      specialities: null,
      industries: null,
      prefix: null,
      businessLines: null
    }
  ) {
    super(masterProcess);
  }

  public setParent(model: MasterProcessItemModel) {
    this.parent = model;
  }

  private isMultiValuedPropertyModified(
    propertyValue: Array<any> | null,
    initialPropertyValue: Array<any> | null
  ): boolean {
    return (
      propertyValue?.length !== initialPropertyValue?.length ||
      propertyValue
        ?.map(s => s.id)
        .sort((a, b) => a - b)
        .toString() !==
        initialPropertyValue
          ?.map(s => s.id)
          .sort((a, b) => a - b)
          .toString()
    );
  }
}
