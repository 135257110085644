import {FC} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {MasterProcessConfirmForm} from './MasterProcessConfirmForm';
import {MasterProcessNewForm} from './MasterProcessNewForm';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {TheButton} from '../../common/TheButton';
import {Toggle} from '@fluentui/react';
import {TreeBuilderFull} from '../../common/TreeBuilder';
import {useMasterProcessListPage} from './useMasterProcessListPage';
import {Helmet} from "react-helmet";

export const MasterProcessListPage: FC = () => {
  const {
    isLoading,
    masterProcesses,
    columns,
    onCancel,
    onSubmit,
    isNewOpen,
    onNewOpen,
    onNewClose,
    onAfterNewCreated,
    optionsFunction,
    optionsProjectSizeCategory,
    optionsIndustry,
    optionsSpeciality,
    optionsBusinessLine,
    showDeleted,
    onShowDeletedToggle,
    isDeleteOpen,
    onDeleteClose,
    onDeleteConfirm,
    newParentId,
    getBulkModel,
    visibleMasterProcesses
  } = useMasterProcessListPage();

  return (
    <>
      <Helmet>
        <title>IMS Master Process List</title>
      </Helmet>
      <HeadingWithDecoration text="Master Process List" />
      <ModalWithHeader
        dismiss={onDeleteClose}
        isVisible={isDeleteOpen}
        header="This will delete following master processes. Are you sure?">
        <MasterProcessConfirmForm
          masterProcesses={masterProcesses}
          onDeleteClose={onDeleteClose}
          onDeleteConfirm={onDeleteConfirm}
        />
      </ModalWithHeader>
      <ModalWithHeader dismiss={onNewClose} isVisible={isNewOpen} header="Add new Master Process">
        <MasterProcessNewForm
          masterProcesses={masterProcesses}
          onNewClose={onNewClose}
          onAfterNewCreated={onAfterNewCreated}
          optionsFunction={optionsFunction}
          optionsProjectSizeCategory={optionsProjectSizeCategory}
          optionsBusinessLine={optionsBusinessLine}
          optionsIndustry={optionsIndustry}
          optionsSpeciality={optionsSpeciality}
          defaultParentId={newParentId}
        />
      </ModalWithHeader>
      <TreeBuilderFull
        bulkHeader="Bulk edit Master Process"
        getBulkModel={getBulkModel}
        turnOffEditNonProcess={false}
        commands={bulkButton => (
          <>
            <TheButton disabled={isLoading} onClick={onNewOpen} primary>
              Add new
            </TheButton>
            {bulkButton}
            <Toggle
              label="Show deleted elements"
              checked={showDeleted}
              onChange={onShowDeletedToggle}
            />
          </>
        )}
        isLoading={isLoading}
        itemsFlat={visibleMasterProcesses}
        editColumnPosition={2}
        columns={columns}
        keepInView
        exportToExcel
        excelFileName={'Exported Master Process'}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};
