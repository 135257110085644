import {MessageBar, MessageBarType, TooltipHost} from '@fluentui/react';
import {FC, useMemo} from 'react';
import {HeadingWithDecoration} from '../../../common/HeadingWithDecoration';
import {MiddleTopBarUnitManage} from '../../../common/MiddleTopBarUnitManage';
import {ModalWithDividedRequests} from '../../../common/ModalWithDividedRequests';
import {TheButton} from '../../../common/TheButton';
import {TreeBuilderFull} from '../../../common/TreeBuilder';
import styles from './UnitManagementProcess.module.scss';
import {useUnitManagementProcess} from './useUnitManagementProcess';
import {Helmet} from 'react-helmet';

export const UnitManagementProcesses: FC = () => {
  const {
    unitId,
    unitTitle,
    isInvalid,
    itemsFlat,
    isAnythingLoading,
    selectAllProcessesAndSave,
    getBulkModel,
    columns,
    revealedMasterProcessIds,
    onCancel,
    onSubmit,
    onDismiss,
    isModalVisible,
    progressStatus
  } = useUnitManagementProcess();

  const additionalPublishButton = useMemo(() => {
    return (
      <>
        <TooltipHost content={'Publish minor change. No notification will be sent.'}>
          <TheButton
            primary
            className={styles.split}
            iconProps={{
              iconName: 'MailPause'
            }}
            onClick={() => {
              onSubmit(false);
            }}>
            Publish
          </TheButton>
        </TooltipHost>
        <TooltipHost
          content={
            'Publish standard or major change. Next process update will change, notification will be sent and comment can be added.'
          }>
          <TheButton
            primary
            className={styles.split}
            iconProps={{
              iconName: 'MailAttached'
            }}
            onClick={() => {
              onSubmit();
            }}>
            Publish with notification
          </TheButton>
        </TooltipHost>
      </>
    );
  }, [onSubmit]);

  return (
    <main className={'noPaddingTop'}>
      <MiddleTopBarUnitManage notUseHelmet unitId={unitId} active={'processes'} />
      {unitTitle && (
        <Helmet>
          <title>IMS {unitTitle} Process Assignment</title>
        </Helmet>
      )}
      <HeadingWithDecoration text="Process Assignment" />
      {isInvalid && (
        <MessageBar messageBarType={MessageBarType.error}>
          Sorry, but the form was not saved. Check changes and try one more time.
        </MessageBar>
      )}
      <div className={styles.saveAllBtn}>
        <TheButton
          disabled={!itemsFlat.length || isAnythingLoading || isInvalid}
          primary
          onClick={() => selectAllProcessesAndSave(false)}>
          Mark and publish all processes as applicable
        </TheButton>
        <TheButton
          disabled={!itemsFlat.length || isAnythingLoading || isInvalid}
          primary
          onClick={selectAllProcessesAndSave}>
          Mark and publish all processes as applicable (with notification)
        </TheButton>
      </div>
      <TreeBuilderFull
        bulkHeader="Bulk edit Manage Process"
        editColumnPosition={1}
        getBulkModel={getBulkModel}
        columns={columns}
        itemsFlat={itemsFlat}
        isLoading={isAnythingLoading}
        initialOpen={revealedMasterProcessIds}
        turnOffEditNonProcess={false}
        onCancel={onCancel}
        keepInView
        exportToExcel
        excelFileName={'Exported Manage Process'}
        additionalButtons={additionalPublishButton}
      />

      <ModalWithDividedRequests
        header="Information"
        dismiss={onDismiss}
        isVisible={isModalVisible}
        couldBeClosed={false}
        progressStatus={progressStatus}
        body="Due to technological reasons, the query will be sent in packages. Please, be patient."
      />
    </main>
  );
};
