import {XLSXValue} from '../../../../helpers/excel';
import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn} from '@fluentui/react';

export class RichtextColumn extends EnhancedColumn {
  public static type = ColumnType.Richtext;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return (
      <div
        className="sun-editor sun-editor-editable no-border"
        style={{
          maxWidth: '100%',
          whiteSpace: 'break-spaces'
        }}
        dangerouslySetInnerHTML={{
          __html: value
        }}
      />
    );
  }

  public static searchFunction(itemValue: string, lowerCaseSearch: string): boolean {
    return itemValue ? itemValue.toLowerCase().includes(lowerCaseSearch) : false;
  }

  public static getColumnValueForExcel(itemValue: any): XLSXValue {
    if (typeof itemValue === 'undefined' || itemValue === null) {
      return '';
    }

    const regex = /data:image\/[^;]+;base64,([^'"]+)/g;
    let result = itemValue as string;
    const matches = result.match(regex);

    if (matches !== null) {
      for (const match in matches) {
        result = result.replace(match, '');
      }
    }
    return {
      html: result.slice(0,32766)
    };
  }
}
