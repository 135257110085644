import {
  FunctionDto,
  ProcessFlatStructureDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../services/generated';
import {ProcessFlatStructureBase} from './ProcessFlatStructureBase';

export class ProcessFlatStructure
  extends ProcessFlatStructureBase
  implements ProcessFlatStructureDto
{
  public function: FunctionDto | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public rawPrefix: string | null;
  public rawTitle: string | null;
  public businessLines: BusinessLineDto[] | null;
  public isMirrorCopy: boolean = false;

  constructor(process: ProcessFlatStructureDto) {
    super(process);
    this.function = process.function;
    this.industries = process.industries;
    this.specialities = process.specialities;
    this.projectSizeCategories = process.projectSizeCategories;
    this.rawPrefix = process.rawPrefix;
    this.rawTitle = process.rawTitle;
    this.businessLines = process.businessLines;
    this.isMirrorCopy = process.isMirrorCopy;
  }
}
