import {EnhancedDetailsList} from '../EnhancedDetailsList';
import {ExternalLink, ExternalLinkWithProcess} from '../../../models';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../helpers/arrayMehods';
import {useService} from '../../../hooks';
import {getColumnsAdd, getColumnsEditFromProcess, getColumnsView} from './linksListColumns';
import {LinksListAddModal} from './LinksListAddModal';
import {useUser} from '../../../hooks/services/useUser';
import {UnitDto} from '../../../services/generated';

export const LinksList: FC<{
  data: ExternalLink[];
  changedData?: ExternalLink[];
  allowAdd?: boolean;
  ownerProcessId?: number;
  pageSize?: number;
}> = ({data, changedData, allowAdd, ownerProcessId, pageSize}) => {
  const {getExternalLinks} = useService();
  const {getUserIsPortalOwner} = useUser();
  const [links, setLinks] = useState<ExternalLinkWithProcess[] | undefined>();
  const [linkCreatedCount, setLinkCreatedCount] = useState(0);
  const [editItem, setEditItem] = useState<ExternalLink>();
  const refreshLinks = useMemo(
    () => async () => {
      const linksResponse = await getExternalLinks();
      if (!linksResponse.result) return;
      setLinks(linksResponse.result.sort(sortByTitle));
    },
    [getExternalLinks, setLinks]
  );
  const [isAddModal, setIsAddModal] = useState(false);
  const [unitPortalOwner, setUnitPortalOwner] = useState<UnitDto[]>([]);

  useEffect(() => {
    if (!allowAdd) return;
    refreshLinks();
  }, [refreshLinks, allowAdd]);

  useEffect(() => {
    (async () => {
      const units = await getUserIsPortalOwner();
      if (units.result) setUnitPortalOwner(units.result);
    })();
  }, [getUserIsPortalOwner]);

  const onOpen = useCallback(() => setIsAddModal(true), []);
  const onClose = useCallback(() => {
    setEditItem(undefined);
    setIsAddModal(false);
  }, []);
  const onEditClick = useCallback(
    (item: ExternalLink) => {
      setEditItem(item);
      onOpen();
    },
    [onOpen]
  );

  const columnsView = useMemo(
    () => getColumnsView(changedData, ownerProcessId),
    [changedData, ownerProcessId]
  );
  const columnsEdit = useMemo(
    () => getColumnsEditFromProcess(changedData, ownerProcessId, unitPortalOwner, onEditClick),
    [changedData, ownerProcessId, unitPortalOwner, onEditClick]
  );
  const columnsAdd = useMemo(
    () => getColumnsAdd(changedData, ownerProcessId),
    [changedData, ownerProcessId]
  );

  const updateExternalLinks = useCallback(
    (modifiedLink: ExternalLink, existingLinks: ExternalLink[] | undefined) => {
      const modifiedLinks = existingLinks?.filter(item => item.id === modifiedLink?.id);
      modifiedLinks?.forEach(link => {
        link.title = modifiedLink.title;
        link.url = modifiedLink.url;
        link.type = modifiedLink.type;
        link.isExternal = modifiedLink.isExternal;
      });
      return !!modifiedLinks?.length;
    },
    []
  );

  const onCreated = useCallback(
    (addedLink: ExternalLink | undefined, modifiedLink?: ExternalLink) => {
      if (addedLink) {
        if (ownerProcessId) {
          addedLink.ownerProcessId = ownerProcessId;
        }
        if (changedData) {
          changedData.push(addedLink);
          setLinkCreatedCount(linkCreatedCount + 1);
        }
      }
      if (modifiedLink) {
        const modified = updateExternalLinks(modifiedLink, data);
        const changed = updateExternalLinks(modifiedLink, changedData);
        if (modified || changed) setLinkCreatedCount(linkCreatedCount + 1);
      }
      refreshLinks();
      setEditItem(undefined);
      setIsAddModal(false);
    },
    [changedData, data, linkCreatedCount, ownerProcessId, updateExternalLinks, refreshLinks]
  );
  return (
    <>
      <EnhancedDetailsList<ExternalLink, ExternalLink, ExternalLink>
        key={linkCreatedCount}
        onCreateItemClick={() => setIsAddModal(true)}
        columns={allowAdd ? columnsEdit : columnsView}
        items={data}
        allowAdd={allowAdd}
        addLabel="Add links"
        columnsForAdd={columnsAdd}
        itemsToAdd={links}
        changedItems={changedData}
        mapChangedItemToItem={link => link}
        mapNewItemToChangedItem={link => {
          return new ExternalLinkWithProcess(link as any);
        }}
        ownerProcessId={ownerProcessId}
        pageSize={pageSize}
      />

      <LinksListAddModal
        isVisible={isAddModal}
        onClose={onClose}
        onCreated={onCreated}
        editItem={editItem}
        allowAdd={!editItem}
        createdByProcessId={ownerProcessId}
      />
    </>
  );
};
