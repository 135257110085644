import { IDropdownOption } from "@fluentui/react";

export enum InquiryCategory {
  Issue = 0,
  Improvement = 1
}

export const inquiryCategoryOptions: IDropdownOption[] = [
  {key: 0, text: InquiryCategory[0]},
  {key: 1, text: InquiryCategory[1]}
];