import {TooltipHost} from '@fluentui/react';
import {ExternalLink, ExternalLinkVersion, ExternalLinkWithProcess} from '../../../models';
import {
  BooleanColumn,
  OpenColumn,
  OtherColumn,
  ProcessesColumn,
  SelectColumn,
  ToggleColumn,
  UserDtoColumn
} from '../EnhancedDetailsList';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../EnhancedDetailsList/IEnhancedDetailsList';
import {OrderInput} from '../OrderInput';
import {CustomToggle} from './CustomToggle';
import {TheButton} from '../TheButton';
import {LinksListColumn} from '../EnhancedDetailsList/ColumnTypes/LinksListColumn';
import {ExternalLinkDto, UnitDto} from '../../../services/generated';
import {Person, PersonViewType} from '@microsoft/mgt-react';
import {NavLink} from 'react-router-dom';
import styles from './LinksListCreate.module.scss';

const isSmart = typeof new URLSearchParams(document.location.search).get('smart') === 'string';

function columnSmartFilter<TYPE extends ExternalLink>(column: IEnhancedColumn<TYPE> | undefined) {
  return !!column && (isSmart || ['ownerProcessId', 'id'].indexOf(column.key) === -1);
}

function mapToClass(item: {[key: string]: any}) {
  return new ExternalLink(item as ExternalLink);
}

const columnsViewList: ColumnKey[] = [
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.RawUrl,
  ColumnKey.OwnerProcessId,
  ColumnKey.TypeString,
  ColumnKey.IsExternal
];
const columnsCenterList: ColumnKey[] = [
  ...columnsViewList,
  ColumnKey.CreatedByProcess,
  ColumnKey.CreatedByProcessOwner,
  ColumnKey.Edit,
  ColumnKey.Delete
];
const columnsEditList: ColumnKey[] = [
  ColumnKey.Removed,
  ColumnKey.Active,
  ColumnKey.Order,
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  ColumnKey.TypeString,
  ColumnKey.IsExternal,
  ColumnKey.CreatedByProcess,
  ColumnKey.CreatedByProcessOwner,
  ColumnKey.Edit
];
const columnsAddList: ColumnKey[] = [
  ColumnKey.Url,
  ColumnKey.Title,
  ColumnKey.Id,
  ColumnKey.TypeString,
  ColumnKey.IsExternal,
  ColumnKey.CreatedByProcess,
  ColumnKey.CreatedByProcessOwner
];

function getColumns<TYPE extends ExternalLink>(
  changedData?: ExternalLink[],
  ownerProcessId?: number,
  onEditClick?: (item: ExternalLink) => void,
  onDeleteClick?: (item: ExternalLink) => void,
  isOnProcess?: boolean,
  unitPortalOwner?: UnitDto[]
): IEnhancedColumn<TYPE>[] {
  return [
    {
      key: ColumnKey.Removed,
      name: '🗑️',
      onRender: (item: TYPE) =>
        item.ownerProcessId === ownerProcessId ? (
          <CustomToggle<ExternalLink>
            property={'removed'}
            changedItems={changedData}
            item={item}
            mapToClass={mapToClass}
          />
        ) : (
          <></>
        ),
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle links to be removed'}>{defaultRender(props)}</TooltipHost>
        ) : (
          <></>
        ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Active,
      name: '👁',
      onRender: (item: TYPE) => (
        <CustomToggle<ExternalLink>
          property={'active'}
          changedItems={changedData}
          item={item}
          mapToClass={mapToClass}
        />
      ),
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle links to be active (visible)'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Order,
      name: 'Order',
      isResizable: false,
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: TYPE) =>
        changedData ? (
          <OrderInput
            defaultValue={item.order}
            onChange={value => {
              const changedDocument = changedData.filter(
                link => link.id === item.id && link.ownerProcessId === item.ownerProcessId
              )[0];
              if (changedDocument) {
                changedDocument.order = value;
              } else {
                changedData.push(new ExternalLink({...item, order: value}));
              }
            }}
          />
        ) : (
          <></>
        ),
      data: OtherColumn
    },

    {
      key: ColumnKey.Url,
      name: '',
      data: OpenColumn
    },
    {
      key: ColumnKey.Title,
      exportToExcel: true,
      name: 'Title',
      minWidth: 290,
      flexGrow: 1
    },

    {
      key: ColumnKey.TypeString,
      exportToExcel: true,
      name: 'Type',
      minWidth: 130,
      data: SelectColumn
    },
    {
      key: ColumnKey.IsExternal,
      exportToExcel: true,
      name: 'Is External',
      tooltip: 'Page or tool outside of ABB',
      minWidth: 140,
      data: BooleanColumn
    },
    {
      key: ColumnKey.CreatedByProcessOwner,
      name: 'Created By Process Owner',
      minWidth: 220,
      data: UserDtoColumn,
      onRender: (model: ExternalLinkDto) => {
        return model.createdByProcessOwner?.azureId ? (
          <Person
            userId={model.createdByProcessOwner.azureId}
            view={PersonViewType.oneline}
            avatarSize={'small'}
            key={model.modified}
          />
        ) : (
          <p className={styles.simpleText}>IMS Administrator</p>
        );
      }
    },
    {
      key: ColumnKey.CreatedByProcess,
      name: 'Created By',
      minWidth: 180,
      data: ProcessesColumn,
      onRender: (model: ExternalLinkDto) => {
        const unitId = model.createdByProcess?.processUnitId;
        const title = model.createdByProcess?.title;
        const processId = model.createdByProcess?.id;
        return model.createdByProcess ? (
          <NavLink
            key={processId}
            className={styles.link}
            to={`/unit/${unitId}/process/${processId}`}>
            {title}
          </NavLink>
        ) : (
          <></>
        );
      }
    },
    {
      key: ColumnKey.Id,
      name: 'Link ID',
      minWidth: 130
    },
    {
      key: ColumnKey.OwnerProcessId,
      name: 'Process ID',
      minWidth: 130
    },
    {
      key: SpecialColumnKey.Edit,
      name: '',
      onRender: (item: TYPE) => {
        let disabled = !!isOnProcess;
        if (
          isOnProcess &&
          (item instanceof ExternalLinkWithProcess || item instanceof ExternalLinkVersion)
        ) {
          disabled =
            (item.createdByProcess?.id !== ownerProcessId &&
              !unitPortalOwner?.some(unit => unit.id === item.createdByProcess?.processUnitId)) ||
            false;
        }
        return (
          <TheButton
            iconProps={{
              iconName: 'Edit'
            }}
            onClick={() => (onEditClick ? onEditClick(item) : undefined)}
            disabled={disabled}
            primary>
            Edit
          </TheButton>
        );
      },
      data: OtherColumn,
      minWidth: 100
    },
    {
      key: SpecialColumnKey.Delete,
      name: '',
      onRender: (item: TYPE) => (
        <TheButton
          iconProps={{
            iconName: 'Delete'
          }}
          onClick={() => (onDeleteClick ? onDeleteClick(item) : undefined)}
          primary>
          Delete
        </TheButton>
      ),
      data: OtherColumn,
      minWidth: 100
    }
  ];
}

export function getColumnsLinksCenter(
  onEditClick: (item: ExternalLink) => void,
  onDeleteClick: (item: ExternalLink) => void
): IEnhancedColumn<ExternalLinkWithProcess>[] {
  const columns = columnsCenterList
    .map(columnKey =>
      getColumns<ExternalLinkWithProcess>(undefined, undefined, onEditClick, onDeleteClick).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<ExternalLinkWithProcess>[];
  columns.splice(
    columns.length - 4,
    0,
    {
      key: ColumnKey.RawUrl,
      exportToExcel: true,
      name: 'Url',
      minWidth: 205
    },
    {
      key: 'processes',
      exportToExcel: true,
      name: 'Process(es)',
      minWidth: 300,
      data: LinksListColumn
    }
  );
  return columns;
}

export function getColumnsEditFromProcess(
  changed?: ExternalLink[],
  ownerProcessId?: number,
  unitPortalOwner?: UnitDto[],
  onEditClick?: (item: ExternalLink) => void
) {
  return columnsEditList
    .map(columnKey =>
      getColumns(changed, ownerProcessId, onEditClick, undefined, true, unitPortalOwner).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<ExternalLink>[];
}

export function getColumnsView(changed?: ExternalLink[], ownerProcessId?: number) {
  return columnsViewList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as IEnhancedColumn<ExternalLink>[];
}
export function getColumnsEdit(changed?: ExternalLink[], ownerProcessId?: number) {
  return columnsEditList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as IEnhancedColumn<ExternalLink>[];
}
export function getColumnsAdd(changed?: ExternalLink[], ownerProcessId?: number) {
  return columnsAddList
    .map(columnKey => getColumns(changed, ownerProcessId).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as any as IEnhancedColumn<ExternalLink>[];
}
