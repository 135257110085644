import {useMemo} from 'react';

import {IPortalOwner} from '../../interfaces';
import {
  DocumentInUnit,
  DocumentRecent,
  ParentProcess,
  ProcessFlatStructure,
  ProcessSlim,
  ProcessTree,
  Unit,
  UnitProcessNavigator,
  UnitProcessNavigatorSave,
  UnitsProcess,
  UnitsList
} from '../../models';
import {AvailableProcess} from '../../models/AvailableProcess';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {
  ManageProcessDto,
  UnitDto,
  UnitProcessNavigatorDto,
  UnitService,
  UserDto
} from '../../services/generated';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {ProcessSubscriptionDto} from '../../services/generated/models/ProcessSubscriptionDto';
import {ProcessSubscription} from '../../models/ProcessSubscription';
import {ManageProcessDetails} from '../../models/ManageProcessDetails';
import {UpdateDocumentAuditableDto} from '../../services/generated/models/UpdateDocumentAuditableDto';

export const useUnit = () => {
  const requestWrapper = useApiService();

  /** Returns a list of all units */
  const getUnits = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<Unit[]> => {
    return await requestWrapper(
      UnitService.getApiUnit(),
      result => result.map(item => new Unit(item)),
      log
    );
  };

  /** Get single unit */
  const getUnit = async (
    id: number,
    log: LogMessageType = {
      error: false,
      pageException: true,
      pageExceptionMessage: `Unit (${id}) Not Found`
    }
  ): ApiResponsePending<Unit> => {
    return await requestWrapper(UnitService.getApiUnit1(id), result => new Unit(result), log);
  };

  /** Get unit full path */
  const getUnitFullPath = async (
    id: number,
    log: LogMessageType = {
      error: false,
      pageException: true,
      pageExceptionMessage: `Unit (${id}) Not Found`
    }
  ): ApiResponsePending<string> => {
    return await requestWrapper(UnitService.getApiFullUnitPath(id), result => result, log);
  };

  /** unit manage. Returns 401 if not owner - cannot edit */
  const getUnitManage = async (
    id: number,
    log: LogMessageType = {
      error: false,
      pageException: true,
      pageExceptionMessage: `Not Enough Permissions To Manage unit (${id})`
    }
  ): ApiResponsePending<UnitsList> => {
    return await requestWrapper(
      UnitService.getApiUnitManage(id),
      result => new UnitsList(result),
      log
    );
  };

  const getUnitProcessNavigator = async (
    id: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UnitProcessNavigator[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcessNavigator(id),
      result => result.map(item => new UnitProcessNavigator(item)),
      log
    );
  };

  const getUnitManageProcessNavigator = async (
    id: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UnitProcessNavigator[]> => {
    return await requestWrapper(
      UnitService.getApiUnitManageProcessNavigator(id),
      result => result.map(item => new UnitProcessNavigator(item)),
      log
    );
  };

  /** TODO strange response */
  const updateUnitProcessNavigators = async (
    id: number,
    unitProcessNavigator: Partial<UnitProcessNavigatorSave>[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Unit process navigator updated successfully`
    }
  ): ApiResponsePending<UnitProcessNavigator[]> => {
    return await requestWrapper(
      UnitService.putApiUnitManageProcessNavigator(
        id,
        unitProcessNavigator as UnitProcessNavigatorDto[]
      ),
      result => [],
      log
    );
  };

  /** Modify single unit */
  const updateSingleUnit = async (
    unitId: number,
    data: UnitDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Unit updated successfully`
    }
  ): ApiResponsePending<Unit> => {
    return await requestWrapper(
      UnitService.putApiUnit(unitId, data),
      result => new Unit(result),
      log
    );
  };

  /** TODO Delete unit */
  const deleteUnit = async (
    unitId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Unit deleted successfully`
    }
  ): ApiResponsePending<boolean> => {
    throw new Error('TODO'); // TODO
  };

  /** Returns a list of published processes for a specific unit */
  const getProcessesPublishedForUnit = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UnitsProcess[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcess(unitId),
      result => result.map(item => new UnitsProcess(item, unitId)),
      log
    );
  };

  /** Returns a list of published processes for a specific unit */
  const getProcessesPublishedForUnitFlat = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessFlatStructure[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcessFlatStructure(unitId),
      result => result.map(item => new ProcessFlatStructure(item)),
      log
    );
  };

  const getProcessesPublishedForUnitFlatForManage = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessFlatStructure[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcessFlat(unitId),
      result => result.map(item => new ProcessFlatStructure(item)),
      log
    );
  };

  /** Returns a list of published processes for a specific unit */
  const getMenuProcessesPublishedForUnit = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessTree[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcessMenu(unitId),
      result => result.map(item => new ProcessTree(item)),
      log
    );
  };

  const getProcessesToManage = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessSlim[]> => {
    return await requestWrapper(
      UnitService.getApiUnitManageProcess(unitId),
      result => result.map(item => new ProcessSlim(item)),
      log
    );
  };

  const getProcessesDraftsToManage = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ManageProcessDetails[]> => {
    return await requestWrapper(
      UnitService.getApiUnitProcessesDetails(unitId),
      result => result.map(item => new ManageProcessDetails(item)),
      log
    );
  };

  /** TODO Add process to unit */
  const addProcessToUnit = async (
    unitId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Process added to unit successfully`
    }
  ): ApiResponsePending<unknown> => {
    throw new Error('TODO'); // TODO
  };

  /**
   * Returns units in which selected process is implemented
   */
  const getUnitsImplementingProcess = async (
    unitId: number,
    masterProcessId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ParentProcess[]> => {
    return await requestWrapper(
      UnitService.getApiUnitMasterProcess(unitId, masterProcessId),
      result => result.map(item => new ParentProcess(item)),
      log
    );
  };

  const getUnitsImplementingProcesses = async (
    unitId: number,
    masterProcessIds: number[],
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<{[masterProcessId: number]: ParentProcess[]}> => {
    return await requestWrapper(
      UnitService.postApiUnitMasterProcess(unitId, masterProcessIds),
      result => {
        const mappedItems: {[masterProcessId: number]: ParentProcess[]} = {};
        for (const key in result) {
          const mappedRow = result[key].map(item => new ParentProcess(item));
          mappedItems[+key] = mappedRow;
        }
        return mappedItems;
      },
      log
    );
  };

  const getDocumentsRecentlyAdded = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<DocumentRecent[]> => {
    return await requestWrapper(
      UnitService.getApiUnitRecentlyAddedDocuments(unitId),
      result => result.map(item => new DocumentRecent(item)),
      log
    );
  };

  const getPortalOwners = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<IPortalOwner[]> => {
    return await requestWrapper(
      UnitService.getApiUnitOwner(unitId),
      result =>
        result.map(item => ({
          id: item.id,
          azureId: item.azureId,
          userPrincipalName: item.userPrincipalName || ''
        })),
      log
    );
  };

  const updatePortalOwners = async (
    unitId: number,
    data: string[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'Portal owners updated successfully'
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      UnitService.putApiUnitOwner(unitId, data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const updateNavigatorControl = async (
    unitId: number,
    data: Partial<UnitProcessNavigatorDto>[],
    log: LogMessageType = {
      error: true,
      success: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      UnitService.putApiUnitManageProcessNavigator(unitId, data as UnitProcessNavigatorDto[]),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const updateProcessToManage = async (
    unitId: number,
    data: Partial<ManageProcessDto>[],
    sendNotification: boolean = true,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Process updated successfully`
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      UnitService.putApiUnitManageProcess(unitId, sendNotification, data as ManageProcessDto[]),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const getUnitProcessOwner = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<UserDto> => {
    return await requestWrapper(
      UnitService.getApiUnitDefaultProcessOwner(unitId),
      result => result,
      log
    );
  };

  const updateProcessOwner = async (
    unitId: number,
    data: string,
    log: LogMessageType = {
      error: true,
      success: true
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      UnitService.putApiUnitDefaultProcessOwner(unitId, data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  const getUnitManageAdminProcess = async (
    unitId: string,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<AvailableProcess[]> => {
    return await requestWrapper(
      UnitService.getApiUnitManageAdminProcess(unitId),
      result => result.map(item => new AvailableProcess(item)),
      log
    );
  };

  const getUnitManageProcessSubscription = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessSubscription[]> => {
    return await requestWrapper(
      UnitService.getApiUnitManageSubscriptions(unitId),
      result => result.map(item => new ProcessSubscription(item)),
      log
    );
  };

  const saveUnitManageProcessSubscription = async (
    unitId: number,
    data: ProcessSubscriptionDto[],
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      UnitService.postApiUnitManageSubscriptions(unitId, data),
      result => result,
      log
    );
  };

  const getUnitDocuments = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<DocumentInUnit[]> => {
    return await requestWrapper(
      UnitService.getApiUnitDocument(unitId),
      result => result.map(item => new DocumentInUnit(item)),
      log
    );
  };

  const getUnitCenterDocuments = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<DocumentInUnit[]> => {
    return await requestWrapper(
      UnitService.getApiUnitDocumentCenter(unitId),
      result => result.map(item => new DocumentInUnit(item)),
      log
    );
  };

  const putUnitManageAuditable = async (
    unitId: number,
    data: UpdateDocumentAuditableDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'Auditable has been correctly amended'
    }
  ): ApiResponsePending<boolean> => {
    return await requestWrapper(
      UnitService.putApiUnitManageAuditable(unitId, data),
      (result, apiCode) => !apiCode,
      log
    );
  };

  return useMemo(
    () => ({
      getUnits,
      getUnitFullPath,
      getUnit,
      getUnitManage,
      getUnitProcessNavigator,
      getUnitManageProcessNavigator,
      updateUnitProcessNavigators,
      updateSingleUnit,
      deleteUnit,
      getProcessesPublishedForUnit,
      getProcessesToManage,
      addProcessToUnit,
      getUnitsImplementingProcess,
      getUnitsImplementingProcesses,
      getDocumentsRecentlyAdded,
      getPortalOwners,
      updatePortalOwners,
      updateNavigatorControl,
      updateProcessToManage,
      getMenuProcessesPublishedForUnit,
      getProcessesPublishedForUnitFlat,
      getProcessesPublishedForUnitFlatForManage,
      getUnitProcessOwner,
      updateProcessOwner,
      getProcessesDraftsToManage,
      getUnitManageAdminProcess,
      getUnitManageProcessSubscription,
      saveUnitManageProcessSubscription,
      getUnitDocuments,
      getUnitCenterDocuments,
      putUnitManageAuditable
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
