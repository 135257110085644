import bgPattern from './bgPattern.jpg';
import bgPatternMedium from './bgPatternMedium.png';
import bgPatternSmall from './bgPatternSmall.jpg';
import people from './people.png';

import think from './ABBicon_think.png';

export const Images: {[key: string]: string} = {
  people,
  bgPattern,
  bgPatternSmall,
  bgPatternMedium,
  think
};
