import {useContext, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useService} from '../../../hooks';
import {ProcessTree, RolePermission, Unit, UnitsProcess} from '../../../models';
import {DictionariesContext} from '../../../providers';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';
import {ApiResponse} from '../../../services/ApiResponseType';
import {
  BooleanColumn,
  DateColumn,
  OpenColumn,
  OtherColumn,
  RichtextColumn,
  SelectColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {ColumnKey, IEnhancedColumn} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {MultiselectColumn} from '../../common/EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {Description} from '../Process/components/Description';
import {Descriptions} from '../Process/components/Descriptions';
import {LinksListColumn} from '../../common/EnhancedDetailsList/ColumnTypes/LinksListColumn';
import {ProcessType} from '../../../enums';
import {IColumn} from '@fluentui/react';
import {XLSXValue} from '../../../helpers/excel';
import {UnitsDescriptionDto} from '../../../services/generated';
import {useUnit} from '../../../hooks/services/useUnit';

export const useProcessList = () => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {globalFiltersFunction} = useContext(DictionariesContext);

  const [publishedProcessesForUnit, setPublishedProcessesForUnit] =
    useState<ApiResponse<ProcessTree[]>>();
  const [processList, setProcessList] = useState<ApiResponse<UnitsProcess[]>>();
  const [unitTitle, setUnitTitle] = useState<string>();
  const processes = useMemo(
    () => processList?.result?.filter(process => !process.deleted).filter(globalFiltersFunction),
    [processList, globalFiltersFunction]
  );
  const [unitPermissions, setUnitPermissions] = useState<ApiResponse<RolePermission[]>>();

  const {getMenuProcessesPublishedForUnit, getUserPermissionsPage, getProcessesPublishedForUnit} =
    useService();

  const {getUnit} = useUnit();

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        setIsLoading(true);
        const permissions = await getUserPermissionsPage(unitId);
        setUnitPermissions(permissions);
        const processTree = await getMenuProcessesPublishedForUnit(unitId);
        setPublishedProcessesForUnit(processTree);
        const processList = await getProcessesPublishedForUnit(unitId);
        setProcessList(processList);
        const unit = await getUnit(unitId);
        if (unit.result) {
          setUnitTitle(prepareUnitTitle(unit.result));
        }
        if (permissions.apiCode || processTree.apiCode || processList.apiCode) setIsError(true);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading, unitId]);
  /* eslint-enable*/

  const getInheritedDescriptionsValues = (
    descriptions: UnitsDescriptionDto[] | undefined
  ): XLSXValue => {
    if (descriptions === undefined || descriptions.length === 0) {
      return '';
    }

    const regex = /data:image\/[^;]+;base64,([^'"]+)/g;
    let result = descriptions.map(desc => desc.content).join('\n\n\n');
    const matches = result.match(regex);
    if (matches !== null) {
      for (const match in matches) {
        result = result.replace(match, '');
      }
    }
    return {
      html: result.slice(0, 32766)
    };
  };

  const columns = useMemo(() => {
    const cols: IEnhancedColumn<UnitsProcess>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenColumn,
        onRender: (item, index, column) =>
          item.type === ProcessType.Process ? (
            OpenColumn.renderer(item.url, column as IColumn)
          ) : (
            <></>
          )
      },
      {
        key: ColumnKey.TypeString,
        name: 'Type',
        minWidth: 90,
        data: SelectColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        flexGrow: 1,
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          return {
            text: item.title,
            link: window.location.origin + item.url
          };
        }
      },
      {
        key: ColumnKey.ProcessOwner,
        name: 'Owner',
        minWidth: 180,
        data: UserDtoColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.SecondaryProcessOwner,
        name: 'Secondary Owner',
        minWidth: 180,
        data: UserDtoColumn,
        exportToExcel: true
      },
      {
        key: 'parentUnitTitle',
        name: 'Parent unit',
        minWidth: 120,
        data: SelectColumn,
        exportToExcel: true
      },
      {
        key: 'description',
        name: 'Description',
        minWidth: 300,
        data: OtherColumn,
        onRender: item => {
          return item.description ? (
            <div style={{whiteSpace: 'break-spaces', maxWidth: '100%'}}>
              <Description description={item.description} />
            </div>
          ) : (
            <></>
          );
        },
        exportToExcel: true,
        onExportToExcel: (item, column) =>
          RichtextColumn.getColumnValueForExcel(item.description?.content)
      },
      {
        key: 'descriptions',
        name: 'Descriptions inherited',
        minWidth: 300,
        data: OtherColumn,
        onRender: item => {
          const descriptions = item.descriptions?.filter(
            description => description.ownerProcessId !== item.id
          );
          if (!descriptions?.length) return <></>;
          return (
            <div style={{whiteSpace: 'break-spaces', maxWidth: '100%'}}>
              <Descriptions descriptions={descriptions} isOpen={false} />
            </div>
          );
        },
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          const descriptions = item.descriptions?.filter(desc => desc.ownerProcessId !== item.id);
          return getInheritedDescriptionsValues(descriptions);
        }
      },
      {
        key: 'documents',
        name: 'Documents',
        minWidth: 150,
        data: LinksListColumn,
        exportToExcel: true
      },
      {
        key: 'externalLinks',
        name: 'Links',
        minWidth: 150,
        data: LinksListColumn,
        exportToExcel: true
      },
      {
        key: 'activityInputs',
        name: 'Activity inputs',
        minWidth: 150,
        data: LinksListColumn,
        exportToExcel: true
      },
      {
        key: 'activityOutputs',
        name: 'Activity outputs',
        minWidth: 150,
        data: LinksListColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.FunctionString,
        name: 'Function',
        data: SelectColumn,
        minWidth: 140,
        exportToExcel: true
      },
      {
        key: 'projectSizeCategories',
        name: 'Project size category',
        data: MultiselectColumn,
        minWidth: 140,
        exportToExcel: true
      },
      {
        key: 'industries',
        name: 'Industry',
        data: MultiselectColumn,
        minWidth: 140,
        exportToExcel: true
      },
      {
        key: 'publishedBy',
        name: 'Last published by',
        minWidth: 180,
        data: UserDtoColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.Published,
        name: 'Last published on',
        minWidth: 170,
        data: DateColumn,
        exportToExcel: true
      },
      {
        key: 'draftExists',
        name: 'Draft Exists',
        maxWidth: 130,
        data: BooleanColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.ModifiedBy,
        name: 'Last modified by',
        minWidth: 180,
        data: UserDtoColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.Modified,
        name: 'Last modified on',
        data: DateColumn,
        minWidth: 170,
        exportToExcel: true
      },
      {
        key: ColumnKey.NextUpdate,
        name: 'Next update',
        data: DateColumn,
        minWidth: 130,
        exportToExcel: true
      }
    ];
    return cols;
  }, []);

  return useMemo(
    () => ({
      unitId,
      unitTitle,
      isLoading,
      isError,
      publishedProcessesForUnit,
      unitPermissions,
      processes,
      columns
    }),
    [
      unitId,
      isLoading,
      unitTitle,
      isError,
      publishedProcessesForUnit,
      unitPermissions,
      processes,
      columns
    ]
  );
};
