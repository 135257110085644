import styles from './FormFooter.module.scss';
import {FC, PropsWithChildren} from 'react';
import {IFormFooterProps} from './IFormFooterProps';
import {TheButton} from '../TheButton';
import {TooltipHost} from '@fluentui/react';

export const FormFooter: FC<PropsWithChildren<IFormFooterProps>> = ({
  children,
  saveLabel,
  isDisabled,
  onCancel,
  onSubmit,
  addTooltip
}) => (
  <div className={styles.footer}>
    {onCancel && <TheButton onClick={onCancel}>Cancel</TheButton>}
    <div className={styles.rightButtons}>
      {onSubmit &&
        (addTooltip ? (
          <TooltipHost content={addTooltip}>
            <TheButton primary disabled={isDisabled} onClick={onSubmit}>
              {saveLabel || 'Save'}
            </TheButton>
          </TooltipHost>
        ) : (
          <TheButton primary disabled={isDisabled} onClick={onSubmit}>
            {saveLabel || 'Save'}
          </TheButton>
        ))}
      {children}
    </div>
  </div>
);
