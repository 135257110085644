import {Links, UserDto} from '../services/generated';
import {ProcessStructure} from './ProcessStructure';

interface IProcessVersionFlatStructureBase {
  id: number;
  readonly link: Array<Links> | null;
  deleted: boolean;
  masterProcessId: number;
  processType: number;
  title: string | null;
  parentMasterProcessId: number;
  unitId: number;
  unitTitle: string | null;
  processOwner: UserDto | null;
  secondaryProcessOwner: UserDto | null;
  parentProcessId: number;
  parentUnitId: number;
  parentUnitTitle: string | null;
  order: number;
}

export class ProcessVersionFlatStructureBase extends ProcessStructure {
  public parentMasterProcessId: number;
  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;
  public parentProcessId: number;
  public parentUnitId: number;
  public parentUnitTitle: string | null;
  public order: number;

  private parent: ProcessVersionFlatStructureBase | null = null;

  public get orderPrefix(): string {
    return `${this.parent?.orderPrefix || ''}${this.order >= 10 ? this.order : `0${this.order}`}.`;
  }

  public get fakePrefix() {
    const regex = /^(\d+\.)+\d+/;
    const result = this.title?.match(regex);
    if (!result) return this.order >= 10 ? this.order + '' : `0${this.order}`;
    const prefix = result[0];
    return prefix
      .split('.')
      .map(num => (Number(num) >= 10 ? num : `0${num}`))
      .join('.');
  }

  constructor(process: IProcessVersionFlatStructureBase) {
    super(process);
    this.processOwner = process.processOwner;
    this.secondaryProcessOwner = process.secondaryProcessOwner;
    this.parentMasterProcessId = process.parentMasterProcessId;
    this.parentProcessId = process.parentProcessId;
    this.parentUnitId = process.parentUnitId;
    this.parentUnitTitle = process.parentUnitTitle;
    this.order = process.order;
  }

  public setParent(model: ProcessVersionFlatStructureBase) {
    this.parent = model;
  }
}
