import {FC, useCallback, useRef, useState} from 'react';
import {ProcessComment, ProcessCommentManage} from '../../../../../models';
import {useComments} from '../../../../../hooks/services/useComments';
import {Richtext} from '../../../../common/Richtext';
import styles from './Feedback.module.scss';
import { TheButton } from '../../../../common/TheButton';

interface IFeedbackReplyToProps {
  isOpen: boolean;
  comment: ProcessComment | ProcessCommentManage;
  processId: number;
  level?: number;
  refresh: (skipLoading?: boolean) => void;
}

export const FeedbackReplyTo: FC<IFeedbackReplyToProps> = ({
  isOpen,
  comment,
  processId,
  refresh,
  level,
}) => {
  const stylesReplies: React.CSSProperties = {marginLeft: `calc(5% * ${level} + 20px)`};
  const {replyToComment} = useComments();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [counter, setCounter] = useState(1);
  const onAdd = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await replyToComment(processId, comment.id, content);
      setContent('');
      setCounter(counter + 1);
      await refresh();
    })();
  }, [comment, replyToComment, counter, refresh, content, processId]);
  return (
    <>
    {isOpen &&
      <>
      <div style={{...stylesReplies}} className={styles.richTextContainer}>
        <Richtext key={counter} onChange={setContent} disable={isLoading} />
      </div>
      <div className={styles.footer}>
    <div className={styles.rightButtons}>
          <TheButton primary disabled={isLoading} onClick={onAdd}>
            {"Reply To"}
          </TheButton>
    </div>
  </div>
      </>
}
 </>  
  );
};
