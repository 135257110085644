import {DefaultPortalSelectionForm} from './DefaultPortalSelectionForm';
import styles from './HiddenTopBar.module.scss';

export const HiddenTopBar = () => {
  return (
    <>
      <input type="checkbox" name="toggle" id="toggle" className={styles.touchBtn} />
      <label htmlFor="toggle"></label>
      <div className={styles.slide}>
        <DefaultPortalSelectionForm />
      </div>
    </>
  );
};
