import {IColumn, IComboBoxOption, TooltipHost} from '@fluentui/react';
import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IDictionaryItem} from '../../DictionaryDropdown/IDictionaryDropdownProps';
import {SelectPicker} from '../../SelectPicker';

export class KeywordsColumn extends EnhancedColumn {
  public static type = ColumnType.KeyWords;

  public static renderer(value: string[] | undefined, column: IColumn): JSX.Element {
    const textValue = value ? value.map(val => val).join(', ') : '';
    return <TooltipHost content={textValue}>{textValue}</TooltipHost>;
  }

  public static getColumnValueForSort(value: string[] | undefined): string {
    return value && value[0] ? value[0] : '';
  }

  public static hasActiveFilter(value: any): boolean {
    return value && value.length > 0;
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: string[][]
  ): JSX.Element {
    const options = [
      ' ',
      ...new Set(
        allOptions.reduce<string[]>(
          (acc, current) => [...acc, ...current.map(val => val || '')],
          []
        )
      )
    ];
    return (
      <SelectPicker
        type="combobox"
        multiSelect
        label="Filter"
        defaultValue={defaultValue}
        options={options}
        onChange={(keys, items) => onChange(keys, items)}
      />
    );
  }

  public static filterFunction(
    itemValue: string[] | undefined,
    filterValue: string[]
  ): boolean {
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if (!filterVal) return !itemValue;
      if (filterVal === ' ') return itemValue?.length === 0;
      return itemValue?.some(item => item === filterVal);
    });
  }

  public static searchFunction(
    itemValue: IDictionaryItem[] | undefined,
    lowerCaseSearch: string
  ): boolean {
    return itemValue
      ? itemValue.some(val => val.value?.toLowerCase().includes(lowerCaseSearch))
      : false;
  }

  public static getColumnValueForExcel(itemValue: IDictionaryItem[]): string {
    return itemValue?.map(item => item.value).join(', ') || '';
  }
}
