import {Header} from '../common/Header';
import {Main} from '../common/Main';
import {ReleaseNotes} from '../common/ReleaseNotes';

export const HomeLayout = () => {
  return (
    <>
      <Header />
      <ReleaseNotes />
      <Main />
    </>
  );
};
