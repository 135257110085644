import styles from './MasterProcessInput.module.scss';
import {DictionaryDropdown} from '../../../common/DictionaryDropdown';
import {FC, useCallback} from 'react';
import {IDictionaryItem} from '../../../common/DictionaryDropdown/IDictionaryDropdownProps';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';

interface IMasterProcessInputFunctionProps {
  model: MasterProcessItemModel;
  options: MasterProcessItemModel[] | null;
}

export const MasterProcessInputParent: FC<IMasterProcessInputFunctionProps> = ({
  model,
  options
}) => {
  const onChange = useCallback(
    (value: IDictionaryItem | undefined) => (model.parentId = value?.id || null),
    [model]
  );
  return (
    <DictionaryDropdown
      className={styles.input}
      options={options}
      onChange={onChange}
      defaultValue={model.parentId}
      disabled={!model.isChecked}
    />
  );
};
