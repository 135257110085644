import {MessageBar, MessageBarType} from '@fluentui/react';
import {useRef, useEffect, useState, useMemo, FC} from 'react';


interface IEnhancedDetailsListFooterProps {
  itemsFiltered: boolean;
  itemsLength: number;
  newItemsLength: number;
}

export const EnhancedDetailsListFooter: FC<IEnhancedDetailsListFooterProps> = ({itemsFiltered,itemsLength,newItemsLength}) => {
  const elementRef: React.Ref<HTMLDivElement> = useRef(null);
  const [width, setWitdh] = useState<number>();
  const [showNoResultMessage, setShowNoResultMessage] = useState<boolean>(false);

  useEffect(() => {
    const width =
      elementRef.current?.parentElement?.querySelector('.ms-DetailsHeader')?.clientWidth;
    if (width) {
      setWitdh(width);
    }
  }, []);

  const noResultMessage = useMemo(() => {
    console.log()
    if(itemsFiltered && itemsLength > 0)
    {
      setShowNoResultMessage(true)
      return "The content has been filtered. Please clear filters to see full content"
    }
    if(itemsFiltered && itemsLength === 0)
    {
      setShowNoResultMessage(true)
      return "Sorry, no data to show. Please adjust your filters."
    }
    if(!itemsFiltered && !itemsLength && !newItemsLength)
    {
      setShowNoResultMessage(true)
      return "Sorry, no data to show."
    }

    setShowNoResultMessage(false)
  }, [itemsFiltered, itemsLength, newItemsLength]);

  return (
    <>
     {showNoResultMessage ? 
     (
      <MessageBar
      styles={{
        root: {
          width: `${width}px`
        }
      }}
      ref={elementRef}
      messageBarType={MessageBarType.error}
      isMultiline={false}>
      {noResultMessage}
    </MessageBar>
    ) : (
     undefined
    )}
    </>
   
  );
};
