/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = ThumbUp
 *
 * 2 = ThumbDown
 *
 * 3 = Neutral
 */
export enum UserCommentThumbStatus {
  ThumbUp = 1,
  ThumbDown = 2,
  Neutral = 3,
}
