import {FC} from 'react';
import {ProcessTree} from '../../../models';
import {SingleMenuItem} from './SingleMenuItem';

interface IMenuStructureProps {
  menu: ProcessTree[] | null;
  unitId: number;
}

export const MenuStructure: FC<IMenuStructureProps> = ({menu, unitId}) => {
  if (!menu) return null;
  return (
    <>
      {menu
        .filter(x => x.deleted === false)
        .map((element, index) => (
          <SingleMenuItem
            key={element.id === 0 ? index : element.id + (element.title || '')}
            unitId={unitId}
            process={element}
          />
        ))}
    </>
  );
};
