import {FC, useCallback} from 'react';
import {IOrderInputProps} from './IOrderInputProps';
import {SpinButton} from '@fluentui/react';

export const OrderInput: FC<IOrderInputProps> = ({
  defaultValue,
  onChange,
  className,
  disabled,
  value
}) => {
  const onInputChange = useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
      const value = Number(newValue || 0);
      onChange(value);
    },
    [onChange]
  );
  return (
    <SpinButton
      className={className}
      min={0}
      step={1}
      precision={0}
      defaultValue={typeof defaultValue === 'undefined' ? undefined : `${defaultValue}`}
      value={typeof value === 'undefined' ? undefined : `${value}`}
      onValidate={value => {
        if (value.indexOf('-') !== -1) return '0';
        if (value.indexOf('.') !== -1) return `${Math.floor(Number(value))}`;
        return value;
      }}
      onChange={onInputChange}
      disabled={disabled}
    />
  );
};
