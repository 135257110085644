import {
  ComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  ITag,
  SelectableOptionMenuItemType,
  Spinner
} from '@fluentui/react';
import {Document} from '../../../models';
import {FC, useCallback, useEffect, useState} from 'react';
import {TheButton} from '../TheButton';
import styles from './DocumentAdditionalAttributes.module.scss';
import {useDocument} from '../../../hooks/services/useDocument';
import {DocumentSystem} from '../../../models/DocumentSystem';
import { DocumentKeyWords } from './DocumentKeyWords';
import { DocumentContentType } from '../../../services/generated';
interface DocumentListToUpdateProps {
  editItem: DocumentSystem | null;
  onUpdated: (modifiedDoc: Document) => void;
  onCancel: () => void;
  parents: DocumentSystem[] | null
}
const comboBoxStyles: Partial<IComboBoxStyles> = { callout: { width: 1000 } };

export const DocumentListToUpdate: FC<DocumentListToUpdateProps> = ({editItem, onUpdated, onCancel, parents}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [relevantToId, setRelevantToId] = useState<number | null>(
    editItem ? editItem.relevantToId : null
  );
  const {changeAdditionalAttributes, getDocuments} = useDocument();
  const [documents, setDocuments] = useState<Document[]>();
  const [words, setWords] = useState<ITag[] | undefined>(
    editItem?.keyWords?.map(x => ({ name: x, key: x } as ITag))
  );
  const documentId = editItem?.id;


  const refresh = useCallback(async () => {
    setIsLoading(true);
    const response = await getDocuments();
    if (response.result) {
      setDocuments(response.result);
    }
    setIsLoading(false);
  }, [getDocuments]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  
  const prepareTitle = (item: DocumentSystem): string => {
    return item ? `${item.title} | ${item.documentId} | ${item.languageCode}` : '';
  };
  const prepareCorrectRelevantToIdValue = (option: IComboBoxOption | undefined): number | null => {
    if(option === undefined)
    {
      return null;
    }
    const result = option.key as number > 0 ? option.key as number : null
    return result
  }
  const onSubmit = useCallback(async () => {
    if (!documentId) return;
    setIsLoading(true);
    const keyWords = words ? words.map(x => x.name) : null;
    const changeParent = {
      documentId,
      relevantToId,
      keyWords
    };
    if (editItem) {
      await changeAdditionalAttributes(changeParent);
      const modifiedDoc = documents?.find(x => x.id === documentId);
      const newParent = documents?.find(x => x.id === relevantToId);
      if (modifiedDoc) {
        const result = new Document({
          ...modifiedDoc,
          relevantTo: newParent ? newParent.documentId : null,
          relevantToId: newParent ? newParent.id : null,
          keyWords: keyWords
        });
        onUpdated(result);
      }
      setIsLoading(false);
    }
  }, [documentId, words, editItem, onUpdated, documents, changeAdditionalAttributes, relevantToId]);
  const comboBoxOptions = (editeItemId: number | undefined): IComboBoxOption[] => {
    const filteredDocuments = parents
      ?.filter(x => x.id !== editeItemId)
      .map(
        x =>
          ({
            key: x.id,
            text: prepareTitle(x)
          } as IComboBoxOption)
      );
    return [
      {
        key: 'Header',
        text: 'Title | Document ID | Language Code',
        itemType: SelectableOptionMenuItemType.Header
      },
      {
        key: 'Divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider
      },
      {
        key: 0,
        text: ''
      },
      ...(filteredDocuments ? filteredDocuments.sort((a, b) => (a.text > b.text ? 1 : -1)) : [])
    ];
  };
  return (
    <>
      <div>
        <ComboBox
          selectedKey={relevantToId || null}
          label="Parent"
          options={comboBoxOptions(editItem?.id)}
          styles={comboBoxStyles}
          onChange={(e, value) => setRelevantToId(prepareCorrectRelevantToIdValue(value))}
          allowFreeInput
          autoComplete="on"
          disabled={isLoading}
        />
        {editItem?.contentType !== DocumentContentType.Document && (
          <DocumentKeyWords
          defaultItems={words}
          setValue={setWords}
          showRemoved={true}
          disabled={isLoading}
          
          />
        )}
        <div className={styles.buttonsContainer}>
        <TheButton disabled={isLoading} onClick={() => onCancel()}>
            Cancel
          </TheButton>
          <TheButton primary disabled={isLoading} onClick={() => onSubmit()}>
            Update
          </TheButton>
        </div>
        {isLoading ? <Spinner /> : null}
      </div>
    </>
  );
};

