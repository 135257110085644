import {utils, writeFile} from 'xlsx-js-style';
import {convert} from 'html-to-text';

export type XLSXValue = string | XLSXLink | XLSXRichtext;

export interface XLSXLink {
  text: string | null;
  link: string | null;
}

interface XLSXRichtext {
  html: string;
}

interface SheetJSLink {
  t: 's';
  v: string;
  l?: {Target: string};
  s: {};
}

interface SheetJSItem {
  [key: string | number | symbol]: string | SheetJSLink;
}

export function exportToExcel(
  items: {[key: string | number | symbol]: XLSXValue}[],
  columns: {
    title?: string;
    property: string | number | symbol;
  }[],
  excelFileName?: string,
  addGroupingBy?: string
) {
  const maxLengths: {[key: string | number | symbol]: number} = {};
  const itemsWithLinks: SheetJSItem[] = items.map(item => {
    const newItem: SheetJSItem = {};
    for (const prop in item) {
      const value = item[prop] || '';
      if (prop === 'isElementVisible') {
        continue;
      } else if (typeof value === 'string') {
        newItem[prop] = {
          t: 's',
          v: value,
          s: {alignment: {vertical: 'top', wrapText: true}}
        };
      } else if ((value as XLSXRichtext)?.html) {
        newItem[prop] = {
          t: 's',
          v: convert((value as XLSXRichtext).html || '', {
            wordwrap: false
          }),
          s: {alignment: {vertical: 'top', wrapText: true}}
        };
      } else {
        const linkValue = value as XLSXLink;
        const text = linkValue.text || linkValue.link || '';
        newItem[prop] = linkValue.link
          ? {
              t: 's',
              v: text,
              l: {
                Target: linkValue.link
              },
              s: {
                font: {color: {rgb: '0000EE'}, underline: true},
                alignment: {vertical: 'top', wrapText: true}
              }
            }
          : text;
      }
    }
    return newItem;
  });
  for (const item of itemsWithLinks) {
    for (const column of columns) {
      const prop = column.property;
      const val = item[prop] || '';
      const length = typeof val === 'string' ? val.length : (val.v || '')?.length;
      if (length > (maxLengths[prop] || 0)) {
        maxLengths[prop] = length;
      }
    }
  }
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(itemsWithLinks);
  worksheet['!autofilter'] = {
    ref: `A1:${String.fromCharCode(columns.length + 64)}1`
  };
  if (addGroupingBy) {
    worksheet['!rows'] = [
      {},
      ...items.map(item => {
        let group;
        if (typeof item[addGroupingBy] === 'undefined') {
          group = '';
        } else if (typeof item[addGroupingBy] === 'string') {
          group = item[addGroupingBy] as string;
        } else {
          group = (item[addGroupingBy] as XLSXLink).text;
        }
        const level = group?.split('.').length || 1 - 1;
        return {
          level,
          hidden: !item.isElementVisible
        };
      })
    ];
  }
  worksheet['!cols'] = columns.map(col => {
    const titleLength = (col.title?.length || 0) + 3;
    let maxCellLength = (maxLengths as any)[col.property] || 0;
    if (maxCellLength > 100) {
      maxCellLength = 100;
    }
    return {
      wch: titleLength > maxCellLength ? titleLength : maxCellLength
    };
  });
  utils.sheet_add_aoa(worksheet, [
    columns.map(col => ({
      v: col.title,
      t: 's',
      s: {font: {bold: true, color: {rgb: 'FFFFFF'}}, fill: {fgColor: {rgb: 'ff000f'}}}
    }))
  ]);
  const sheetName = `Exported ${new Date().toDateString()}`;
  const fileName = excelFileName || 'IMS_exported_data';
  workbook.SheetNames.push(sheetName);
  workbook.Sheets[sheetName] = worksheet;
  writeFile(workbook, `${fileName}.xlsx`);
}
