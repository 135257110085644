/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = Function
 *
 * 2 = Industry
 *
 * 3 = ProjectSizeCategory
 *
 * 4 = Speciality
 *
 * 5 = BusinessLine
 */
export enum DictionaryType {
  Function = 1,
  Industry = 2,
  ProjectSizeCategory = 3,
  Speciality = 4,
  BusinessLine = 5,
}
