import {
  FunctionDto,
  ManageMasterProcessDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  BusinessLineDto
} from '../services/generated';
import {MasterProcessBase} from './MasterProcessBase';

export class MasterProcessManage extends MasterProcessBase implements ManageMasterProcessDto {
  public function: FunctionDto | null;
  public parentId: number | null;
  public deleted: boolean;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public industries: IndustryDto[] | null;
  public prefix: string | null;
  public businessLines: BusinessLineDto[] | null;

  public get value() {
    return this.prefixedTitle;
  }

  public get prefixedTitle(): string {
    return `${this.prefix ? `${this.prefix} ` : ''}${this.title}`;
  }

  constructor(masterProcessManage: ManageMasterProcessDto) {
    super(masterProcessManage);
    this.function = masterProcessManage.function;
    this.parentId = masterProcessManage.parentId;
    this.deleted = masterProcessManage.deleted;
    this.projectSizeCategories = masterProcessManage.projectSizeCategories;
    this.specialities = masterProcessManage.specialities;
    this.industries = masterProcessManage.industries;
    this.prefix = masterProcessManage.prefix;
    this.businessLines = masterProcessManage.businessLines;
  }
}
