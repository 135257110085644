import {FC} from 'react';
import {ModalWithHeader} from '../ModalWithHeader';
import {ActivitiesListCreate} from './ActivitiesListCreate';
import {Activity} from '../../../models';

interface IActivitiesListAddModalProps {
  isVisible: boolean;
  allowAdd?: boolean;
  editItem?: Activity;
  onClose: () => void;
  onCreated: (addedLink: Activity | undefined, modifiedLink?: Activity) => void;
  isInput: boolean;
  ownerProcessId?: number;
}

export const ActivitiesListAddModal: FC<IActivitiesListAddModalProps> = ({
  isVisible,
  allowAdd,
  editItem,
  onClose,
  onCreated,
  isInput,
  ownerProcessId
}) => (
  <ModalWithHeader
    header={`${editItem ? 'Update' : 'Add new'} custom activity`}
    isVisible={isVisible}
    dismiss={onClose}>
    <ActivitiesListCreate
      onCreated={onCreated}
      allowAdd={allowAdd}
      editItem={editItem}
      isInput={isInput}
      ownerProcessId={ownerProcessId || null}
    />
  </ModalWithHeader>
);
