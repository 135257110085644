import {FC} from 'react';
import {useParams} from 'react-router';

import {MiddleTopBarUnitManage} from '../../../common/MiddleTopBarUnitManage';
import {FeedbackCenter} from '../../FeedbackCenter';

export const UnitManagementFeedbackCenter: FC = () => {
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);

  return (
    <main className={'noPaddingTop'}>
      <MiddleTopBarUnitManage unitId={unitId} active={'feedbackCenter'} />
      <div>
        <FeedbackCenter unitId={unitId} />
      </div>
    </main>
  );
};
