import {Spinner} from '@fluentui/react';
import {FC, useEffect, useMemo, useState} from 'react';
import {sortByTitle} from '../../../../../../helpers/arrayMehods';
import {useService} from '../../../../../../hooks';
import { ExternalLink} from '../../../../../../models';
import {EnhancedDetailsList, OtherColumn} from '../../../../../common/EnhancedDetailsList';
import {SpecialColumnKey} from '../../../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import { EnhancedRemoveButton } from '../../../../../common/EnhancedDetailsList/EnchancedRemoveButton';
import { IProcessRowExternalLinks } from './IProcessRowExternalLinks';
import { getColumnsAdd } from '../../../../../common/ProcessSections/linksListColumns';

function mapRemovedExternalLinkToExternalLink(changedItem: ExternalLink): ExternalLink {
  return changedItem;
}

export const ProcessRowRemoveExternalLinks: FC<IProcessRowExternalLinks> = ({model, disabled}) => {
  const {getExternalLinks} = useService();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const externalLinksResponse = await getExternalLinks();
      if (!externalLinksResponse.result) return;
      setExternalLinks(externalLinksResponse.result.sort(sortByTitle));
      setIsLoading(false);
    })();
  }, [getExternalLinks]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalLinks, setExternalLinks] = useState<ExternalLink[]>([]);
  const [newItemsIds, setNewItemsIds] = useState<number[]>([]);
  const columnsAdd = useMemo(
    () => [
      {
        key: SpecialColumnKey.Remove,
        name: '',
        isResizable: false,
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: ExternalLink) => (
          <EnhancedRemoveButton
            item={item}
            removedIds={newItemsIds}
            removeElement={newItem => {
              const mappedItem = newItem;
              mappedItem.active = true;
              model.removedExternalLinks.push(newItem);
              setNewItemsIds([...newItemsIds, newItem.id]);
            }}
            disabled={disabled}
          />
        ),
        data: OtherColumn
      },
      ...getColumnsAdd(model.removedExternalLinks as any)
    ],
    [newItemsIds, setNewItemsIds, disabled, model]
  );
  return isLoading ? (
    <Spinner />
  ) : (
    <EnhancedDetailsList<ExternalLink, ExternalLink, ExternalLink>
      items={externalLinks}
      allowAdd
      isAddModal
      columns={columnsAdd}
      changedItems={model.removedExternalLinks}
      mapChangedItemToItem={mapRemovedExternalLinkToExternalLink}
      newItemsIds={newItemsIds}
      pageSize={5}
    />
  );
};
