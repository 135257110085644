import {FC, PropsWithChildren, useState} from 'react';
import {PreviousUnitPageContext} from './PreviousUnitPageContext';

export const PreviousUnitPageProvider: FC<PropsWithChildren> = ({children}) => {
  const [unitId, setUnitId] = useState<number | null>(null);
  return (
    <PreviousUnitPageContext.Provider value={{unitId, setUnitId}}>
      {children}
    </PreviousUnitPageContext.Provider>
  );
};
