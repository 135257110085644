import {IDropdownOption} from '@fluentui/react';
import {useEffect, useMemo, useState} from 'react';
import {MinimalProcessForDiagramDto} from '../../../services/generated';
import {IMatrixValue} from './MatrixDiagram';

export const useMatrixDiagram = (
  matrixValues: IMatrixValue[],
  setMatrixValues: (matrixValues: IMatrixValue[]) => void
) => {
  const [colsCount, setColsCount] = useState<number>(0);
  const [rowsCount, setRowsCount] = useState<number>(0);

  const incrementCols = useMemo(() => () => setColsCount(colsCount + 1), [colsCount, setColsCount]);
  const incrementRows = useMemo(() => () => setRowsCount(rowsCount + 1), [rowsCount, setRowsCount]);

  const setDiagramVariables = useMemo(
    () => () => {
      if (!colsCount) {
        const columns = matrixValues.map(elem => elem.column).sort((a, b) => a - b);
        setColsCount(columns[columns.length - 1] || 0);
      }
      if (!rowsCount) {
        const rows = matrixValues.map(elem => elem.row).sort((a, b) => a - b);
        setRowsCount(rows[rows.length - 1] || 0);
      }
    },
    [colsCount, rowsCount, setColsCount, setRowsCount, matrixValues]
  );

  const onProcessSelect = useMemo(
    () => (rowIndex: number, colIndex: number, option: IDropdownOption, multiSelect?: boolean) => {
      const existingValue = matrixValues.find(
        value => value.row === rowIndex && value.column === colIndex
      );
      const newValue: IMatrixValue = existingValue || {
        row: rowIndex,
        column: colIndex,
        processes: [] as MinimalProcessForDiagramDto[],
        customValue: option.data === true ? (option.key as string) : null
      };
      const processId = option?.key as number;
      if (!existingValue) {
        matrixValues.push(newValue);
      }
      if (!multiSelect) {
        if (option.data === true) {
          newValue.processes = [];
          newValue.customValue = option.key as string;
        } else {
          newValue.processes = processId
            ? [
                {
                  id: processId
                } as MinimalProcessForDiagramDto
              ]
            : [];
        }
      } else {
        if (option?.selected) {
          newValue.processes.push({
            id: processId
          } as MinimalProcessForDiagramDto);
        } else {
          newValue.processes = newValue.processes.filter(process => process.id !== processId);
        }
      }
    },
    [matrixValues]
  );

  const removeRow = useMemo(
    () => (rowIndex: number) => {
      const newValues = matrixValues.filter(value => value.row !== rowIndex);
      newValues.forEach(value => {
        if (value.row < rowIndex) return;
        value.row = value.row - 1;
      });
      setMatrixValues(newValues);
      setRowsCount(rowsCount - 1);
    },
    [matrixValues, setMatrixValues, rowsCount, setRowsCount]
  );
  const removeCol = useMemo(
    () => (colIndex: number) => {
      const newValues = matrixValues.filter(value => value.column !== colIndex);
      newValues.forEach(value => {
        if (value.column < colIndex) return;
        value.column = value.column - 1;
      });
      setMatrixValues(newValues);
      setColsCount(colsCount - 1);
    },
    [matrixValues, setMatrixValues, colsCount, setColsCount]
  );

  useEffect(() => {
    setDiagramVariables();
  }, [setDiagramVariables]);

  return useMemo(
    () => ({
      matrixValues,
      colsCount,
      rowsCount,
      incrementCols,
      incrementRows,
      onProcessSelect,
      removeRow,
      removeCol
    }),
    [
      matrixValues,
      colsCount,
      rowsCount,
      incrementCols,
      incrementRows,
      onProcessSelect,
      removeRow,
      removeCol
    ]
  );
};
