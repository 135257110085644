import {FC, useMemo} from 'react';
import SunEditor from 'suneditor-react';
import SetOptions from 'suneditor-react/dist/types/SetOptions';
import {IRichtextProps} from './IRichtextProps';

export const Richtext: FC<IRichtextProps> = ({
  onChange,
  defaultValue,
  readOnly,
  disable,
  hideToolbar
}) => {
  const suneditorSettings: SetOptions = useMemo(
    () => ({
      mode: 'classic',
      rtl: false,
      katex: 'window.katex',
      imageFileInput: false,
      videoFileInput: false,
      tabDisable: false,
      defaultStyle: `font-size: 16px; font-family: 'ABBvoice';`,
      font: ['ABBvoice'],
      buttonList: [
        [
          'undo',
          'redo',
          'font',
          'fontSize',
          'formatBlock',
          'paragraphStyle',
          'blockquote',
          'bold',
          'underline',
          'italic',
          'strike',
          'subscript',
          'superscript',
          'fontColor',
          'hiliteColor',
          'textStyle',
          'removeFormat',
          'outdent',
          'indent',
          'align',
          'horizontalRule',
          'list',
          'lineHeight',
          'table',
          'link',
          'image',
          'video',
          'audio',
          'math',
          'fullScreen',
          'showBlocks',
          'codeView',
          'preview',
          'print'
        ]
      ],
      'lang(In nodejs)': 'en'
    }),
    []
  );
  return (
    <SunEditor
      height={'100px'}
      defaultValue={defaultValue}
      readOnly={readOnly}
      disable={disable}
      hideToolbar={hideToolbar}
      setOptions={suneditorSettings}
      onChange={onChange}
    />
  );
};
