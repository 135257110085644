/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ViewDto } from '../models/ViewDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ViewService {

  /**
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static getApiView(): CancelablePromise<Array<ViewDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/View',
    });
  }

  /**
   * @param id 
   * @returns ViewDto Success
   * @throws ApiError
   */
  public static getApiView1(
id: number,
): CancelablePromise<ViewDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/View/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static putApiViewDefaultView(
requestBody?: ViewDto,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/View/defaultView',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiViewDefaultView(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/View/defaultView',
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static postApiViewFavorite(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/View/{id}/favorite',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static deleteApiViewFavorite(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/View/{id}/favorite',
      path: {
        'id': id,
      },
    });
  }

}
