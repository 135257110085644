import {
  ITextFieldProps,
  MessageBar,
  MessageBarType,
  Text,
  TextField,
  TooltipHost
} from '@fluentui/react';
import {FC, useCallback, useEffect, useRef, useState} from 'react';

import styles from './MyProcessList.module.scss';
import {useMyProcessList} from './useMyProcessList';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {TheButton} from '../../common/TheButton';
import {TreeBuilderFull} from '../../common/TreeBuilder';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {scrollIntoElement} from '../../../helpers/visual';
import {Helmet} from 'react-helmet';
import {FormFooter} from '../../common/FormFooter';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import React from 'react';
import {ModalWithDividedRequests} from '../../common/ModalWithDividedRequests';

export const MyProcessList: FC = () => {
  const {
    unitId,
    unitTitle,
    columns,
    itemsFlat,
    revealedMasterProcessIds,
    isAnythingLoading,
    isInvalid,
    publishedProcessesForUnit,
    unitPermissions,
    isModalVisible,
    progressStatus,
    onDismiss,
    getBulkModel,
    onCancel,
    onSave,
    onSubmit,
    onSubmitWithNotification
  } = useMyProcessList();

  const errorMsgBar = useRef<HTMLDivElement>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [processPublicationComment, setProcessPublicationComment] = React.useState('');
  const onProcessPublicationCommentChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (!newValue || newValue.length <= 1024) setProcessPublicationComment(newValue || '');
    },
    []
  );
  const onModalWithCommentClose = useCallback(() => setIsCommentModalOpen(false), []);
  const onApproveModal = () => {
    onSubmitWithNotification(processPublicationComment);
    setIsCommentModalOpen(false);
    setProcessPublicationComment('');
  };

  useEffect(() => {
    if (isInvalid) {
      scrollIntoElement(errorMsgBar);
    }
  }, [isInvalid]);

  return (
    <main className={'noPaddingTop'}>
      <MiddleTopBarUnit
        unitId={unitId}
        publishedData={publishedProcessesForUnit?.result}
        unitPermissions={unitPermissions}
        showManage
      />
      {unitTitle && (
        <Helmet>
          <title>IMS {unitTitle} My Processes List</title>
        </Helmet>
      )}
      <HeadingWithDecoration text="My Processes List" />
      {isInvalid && (
        <MessageBar
          className={styles.errorMessage}
          messageBarType={MessageBarType.error}
          ref={errorMsgBar}>
          Sorry, but the form was not saved. Check changes and try one more time.
        </MessageBar>
      )}
      <TreeBuilderFull
        bulkHeader="Bulk edit Manage Process Details"
        editColumnPosition={1}
        getBulkModel={getBulkModel}
        columns={columns}
        itemsFlat={itemsFlat}
        turnOffEditNonProcess={true}
        onCancel={onCancel}
        onSubmit={onSave}
        additionalButtons={
          <>
            <TooltipHost content={'Publish minor change. No notification will be sent.'}>
              <TheButton
                primary
                className={styles.split}
                disabled={isAnythingLoading}
                iconProps={{
                  iconName: 'MailPause'
                }}
                onClick={onSubmit}>
                Publish
              </TheButton>
            </TooltipHost>
            <TooltipHost
              content={
                'Publish standard or major change. Next process update will change, notification will be sent and comment can be added.'
              }>
              <TheButton
                primary
                className={styles.split}
                iconProps={{
                  iconName: 'MailAttached'
                }}
                disabled={isAnythingLoading}
                onClick={() => {
                  setIsCommentModalOpen(true);
                }}>
                Publish and confirm process validity
              </TheButton>
            </TooltipHost>
            <ModalWithHeader
              dismiss={onModalWithCommentClose}
              isVisible={isCommentModalOpen}
              header={'Comment'}>
              <div className={styles.formWrapper}>
                <TextField
                  multiline
                  resizable={false}
                  value={processPublicationComment}
                  onChange={onProcessPublicationCommentChange}
                  description={processPublicationComment.length.toString() + '/1024'}
                  onRenderDescription={(props: ITextFieldProps | undefined) => (
                    <Text variant="small" styles={{root: {float: 'right'}}}>
                      {props?.description}
                    </Text>
                  )}></TextField>
                <div className={styles.disclaimer}>
                  By publishing a new revision of this process I confirm, that this process page and
                  all documentation is up-to-date and does not require any modification.
                </div>
                <p></p>
              </div>
              <FormFooter
                onCancel={onModalWithCommentClose}
                onSubmit={onApproveModal}
                saveLabel="Publish and confirm process validity"
                addTooltip={
                  'Publish standard or major change. Next process update will change, notification will be sent and comment can be added.'
                }
              />
            </ModalWithHeader>
          </>
        }
        isLoading={isAnythingLoading}
        initialOpen={revealedMasterProcessIds}
        keepInView
        exportToExcel
        excelFileName="Exported Manage Process Details"
        filterDeleted
      />
      <ModalWithDividedRequests
        header="Information"
        dismiss={onDismiss}
        isVisible={isModalVisible}
        couldBeClosed={false}
        progressStatus={progressStatus}
        body="Due to technological reasons, the query will be sent in packages. Please, be patient."
      />
    </main>
  );
};
