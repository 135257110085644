import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn, IComboBoxOption} from '@fluentui/react';
import {PeoplePicker} from '../../PeoplePicker';
import {SelectPicker} from '../../SelectPicker';
import {UserDto} from '../../../../services/generated';

export class UserIdColumn extends EnhancedColumn {
  public static type = ColumnType.UserIdColumn;
  public static canFilter = false;
  public static canSort = false;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return value ? (
      <PeoplePicker onSelectPerson={null as any} defaultSelectedPersonId={value} isReadonly />
    ) : (
      <></>
    );
  }

  public static getColumnValueForSort(value: UserDto): string {
    return value?.userPrincipalName || '0';
  }

  public static hasActiveFilter(value: any): boolean {
    return value && value.length > 0;
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: UserDto[]
  ): JSX.Element {
    const options = [...new Set(allOptions.map(option => option?.userPrincipalName || ''))];
    return (
      <SelectPicker
        type="combobox"
        multiSelect
        label="Filter"
        defaultValue={defaultValue}
        options={options}
        onChange={(keys, items) => onChange(keys, items)}
      />
    );
  }

  public static filterFunction(itemValue: UserDto, filterValue: string[]): boolean {
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if (!filterVal) return !itemValue;
      return (itemValue?.userPrincipalName || '').indexOf(filterVal) !== -1;
    });
  }

  public static searchFunction(itemValue: UserDto, lowerCaseSearch: string): boolean {
    const email = itemValue?.userPrincipalName || '';
    return email ? email.includes(lowerCaseSearch) : false;
  }

  public static getColumnValueForExcel(itemValue: UserDto): string {
    return itemValue?.userPrincipalName || '';
  }
}
