import {EnhancedDetailsList} from '../../common/EnhancedDetailsList';
import {FC} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {IProcessListProps} from './IProcessListProps';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {Spinner} from '@fluentui/react';
import {useProcessList} from './useProcessList';
import {Helmet} from "react-helmet";

export const ProcessList: FC<IProcessListProps> = () => {
  const {
    unitId,
    isLoading,
    isError,
    publishedProcessesForUnit,
    unitPermissions,
    processes,
    columns,
    unitTitle
  } = useProcessList();
  return (
    <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
      {isLoading ? (
        <Spinner />
      ) : !isError ? (
        <>
          {unitTitle && (
            <Helmet>
              <title>IMS {unitTitle} Process List</title>
            </Helmet>
          )}
          <MiddleTopBarUnit
            unitId={unitId}
            publishedData={publishedProcessesForUnit?.result}
            unitPermissions={unitPermissions}
            showManage
          />
          <HeadingWithDecoration text="Process List" />
          {processes ? (
            <EnhancedDetailsList
              items={processes}
              columns={columns}
              pageSize={30}
              excelExport
              excelFileName={`Process_List_${unitId}`}
            />
          ) : null}
        </>
      ) : null}
    </main>
  );
};
