/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchDto } from '../models/BatchDto';
import type { BatchElementDto } from '../models/BatchElementDto';
import type { ManageMasterProcessDto } from '../models/ManageMasterProcessDto';
import type { OwningUnitDto } from '../models/OwningUnitDto';
import type { UnitAssignmentMasterProcessDto } from '../models/UnitAssignmentMasterProcessDto';
import type { ViewMasterProcessDto } from '../models/ViewMasterProcessDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MasterProcessService {

  /**
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static postApiMasterProcessBatch(
requestBody?: Array<BatchElementDto>,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/MasterProcess/batch',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id 
   * @returns BatchDto Success
   * @throws ApiError
   */
  public static getApiMasterProcessBatch(
id?: number,
): CancelablePromise<Array<BatchDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/MasterProcess/batch',
      query: {
        'id': id,
      },
    });
  }

  /**
   * @param unitId 
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static postApiMasterProcessCreateIndependentProcess(
unitId: number,
requestBody?: Array<OwningUnitDto>,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/MasterProcess/{unitId}/createIndependentProcess',
      path: {
        'unitId': unitId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ManageMasterProcessDto Success
   * @throws ApiError
   */
  public static getApiMasterProcessManage(): CancelablePromise<Array<ManageMasterProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/MasterProcess/manage',
    });
  }

  /**
   * @param id 
   * @param requestBody 
   * @returns any Success
   * @throws ApiError
   */
  public static putApiMasterProcessManage(
id?: number,
requestBody?: Array<ManageMasterProcessDto>,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/MasterProcess/manage',
      query: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param requestBody 
   * @returns UnitAssignmentMasterProcessDto Success
   * @throws ApiError
   */
  public static postApiMasterProcessUnitAssignment(
requestBody?: Array<number>,
): CancelablePromise<Array<UnitAssignmentMasterProcessDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/MasterProcess/unitAssignment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ViewMasterProcessDto Success
   * @throws ApiError
   */
  public static getApiMasterProcess(): CancelablePromise<Array<ViewMasterProcessDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/MasterProcess',
    });
  }

  /**
   * @param id 
   * @returns any Success
   * @throws ApiError
   */
  public static putApiMasterProcessBatch(
id: number,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/MasterProcess/{id}/batch',
      path: {
        'id': id,
      },
    });
  }

}
