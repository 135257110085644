import styles from './TriangleBreadcrumbsLayout.module.scss';
import {Arrow} from '../Arrow';
import {ProcessType} from '../../../enums';
import {UnitProcessNavigator} from '../../../models';

export const TriangleBreadcrumbsLayout = (props: {
  data: UnitProcessNavigator[];
  unitId: number;
}) => {
  const {data, unitId} = props;

  return (
    <ul className={styles.breadcrumbs}>
      {data.length > 0 &&
        data.map(item => (
          <Arrow
            key={item.processId}
            text={item.processId ? item.processTitle : item.linkTitle}
            url={
              item.processId
                ? item.processType === ProcessType.Process
                  ? `/unit/${unitId}/process/${item.processId}`
                  : undefined
                : item.customLink || ''
            }
            targetBlank={!item.processId}
          />
        ))}
    </ul>
  );
};
