import {MasterProcessBase} from './MasterProcessBase';
import {
  FunctionDto,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  ViewMasterProcessDto,
  BusinessLineDto
} from '../services/generated';

export class MasterProcess extends MasterProcessBase implements ViewMasterProcessDto {
  public description: string | null;
  public function: FunctionDto | null;
  public children: Array<MasterProcess> | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public industries: IndustryDto[] | null;
  public businessLines: BusinessLineDto[] | null;
  constructor(masterProcess: ViewMasterProcessDto) {
    super(masterProcess);
    this.description = masterProcess.description;
    this.function = masterProcess.function;
    this.children = masterProcess.children;
    this.projectSizeCategories = masterProcess.projectSizeCategories;
    this.specialities = masterProcess.specialities;
    this.industries = masterProcess.industries;
    this.businessLines = masterProcess.businessLines;
  }
}
