import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn} from '@fluentui/react';

export class OtherColumn extends EnhancedColumn {
  public static type = ColumnType.Other;
  public static canFilter = false;
  public static canSort = false;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return <>Add custom renderer</>;
  }
}
