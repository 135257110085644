import {useCallback, useContext, useEffect, useState} from 'react';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';
import {useAdmin} from '../../../hooks/services/useAdmin';
import {sortByTitle} from '../../../helpers/arrayMehods';
import {useActivities} from '../../../hooks/services/useActivities';
import {ActivityWithProcess} from '../../../models/ActivityWithProcess';

export const useActivitiesCenter = () => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);

  const {getAdminActivityInput, getAdminActivityOutput} = useAdmin();
  const {deleteActivityActivityOutput, deleteActivityActivityInput} = useActivities();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activityInputs, setActivityInputs] = useState<ActivityWithProcess[] | undefined>([]);
  const [activityOutputs, setActivityOutputs] = useState<ActivityWithProcess[] | undefined>([]);

  const getInputs = useCallback(async () => {
    const result = await getAdminActivityInput();
    return result;
  }, [getAdminActivityInput]);

  const getOutputs = useCallback(async () => {
    const result = await getAdminActivityOutput();
    return result;
  }, [getAdminActivityOutput]);

  const deleteInputs = useCallback(
    async (id: number) => {
      const result = await deleteActivityActivityInput(id);
      return result;
    },
    [deleteActivityActivityInput]
  );

  const deleteOutputs = useCallback(
    async (id: number) => {
      const result = await deleteActivityActivityOutput(id);
      return result;
    },
    [deleteActivityActivityOutput]
  );

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const inputs = await getInputs();
        setActivityInputs(inputs.result?.sort(sortByTitle));
        const outputs = await getOutputs();
        setActivityOutputs(outputs.result?.sort(sortByTitle));
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  return {
    activityInputs,
    activityOutputs,
    isLoading,
    deleteInputs,
    deleteOutputs,
    getOutputs,
    getInputs
  };
};
