import {ITreeColumnEdit} from '../TreeColumn';
import {ITreeItem} from '../ITreeItem';
import {ITreeRowProps} from './ITreeRowProps';
import {useCallback, useMemo, useRef, useState} from 'react';

export const useTreeRow = <ITEM extends ITreeItem>(props: ITreeRowProps<ITEM>) => {
  const {
    item,
    initialOpen,
    checkedIds,
    setCheckedIds,
    itemsFlat,
    editIds,
    setEditIds,
    onOpenChange
  } = props;
  const hasBeenClosed = useRef(false);
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen?.some(id => id === item.id) || false);
  const hasChildren = useMemo(
    () => itemsFlat.some(itemFlat => itemFlat.parentId === item.id),
    [itemsFlat, item]
  );
  const onChevronClick = useCallback(() => {
    setIsOpen(!isOpen);
    if (onOpenChange) {
      onOpenChange(item.id, !isOpen);
    }
    hasBeenClosed.current = true;
  }, [isOpen, item, onOpenChange]);
  const updateCheckboxRecursive = useCallback(
    (isChecked: boolean, item: ITEM, checkedIdsTemp: number[]): number[] => {
      if (item.isChecked === isChecked) return checkedIdsTemp;
      item.isChecked = isChecked;
      let filtered = checkedIdsTemp.filter(id => id !== item.id);
      if (isChecked) {
        filtered.push(item.id);
        const parent = itemsFlat.find(itemFlat => itemFlat.id === item.parentId);
        if (parent) {
          return updateCheckboxRecursive(isChecked, parent, filtered);
        }
      } else {
        const children = itemsFlat.filter(itemFlat => itemFlat.parentId === item.id);
        for (const child of children) {
          filtered = updateCheckboxRecursive(isChecked, child, filtered);
        }
      }
      return filtered;
    },
    [itemsFlat]
  );
  const onCheckboxClick = useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      checked?: boolean | undefined
    ) => {
      const checkedIdsTemp = [...checkedIds];
      const checkedIdsNew = updateCheckboxRecursive(!!checked, item, checkedIdsTemp);
      setCheckedIds(checkedIdsNew);
    },
    [item, checkedIds, setCheckedIds, updateCheckboxRecursive]
  );

  const onEditClick = useCallback(
    (column: ITreeColumnEdit) => {
      const isEdit = editIds.some(id => id === item.id);
      item.isEdit = !isEdit;
      if (isEdit) {
        const newEditIds = editIds.filter(id => id !== item.id);
        setEditIds(newEditIds);
        column.onChange(newEditIds);
      } else {
        const newEditIds = [...editIds, item.id];
        setEditIds(newEditIds);
        column.onChange(newEditIds);
      }
    },
    [item, editIds, setEditIds]
  );
  const updateEdit = useCallback(
    (itemsToChange: ITEM[], isEdit: boolean, column: ITreeColumnEdit) => {
      for (const element of itemsToChange) {
        element.isEdit = isEdit;
      }
      const newEditIds = isEdit
        ? [...editIds, ...itemsToChange.map(itemFlat => itemFlat.id)]
        : editIds.filter(id => !itemsToChange.some(itemFlat => itemFlat.id === id));
      setEditIds(newEditIds);
      column.onChange(newEditIds);
    },
    [editIds, setEditIds]
  );

  const directChildren = useCallback(
    (isEdit: boolean, column: ITreeColumnEdit) => {
      const childItems = itemsFlat.filter(itemFlat => itemFlat.parentId === item.id);
      const allItems = [...childItems, item];
      updateEdit(allItems, isEdit, column);
    },
    [itemsFlat, item, updateEdit]
  );

  const allChildren = useCallback(
    (isEdit: boolean, column: ITreeColumnEdit) => {
      let allItems = [item];
      let parentItems = [item];
      while (parentItems.length) {
        // eslint-disable-next-line no-loop-func
        parentItems = itemsFlat.filter(flatItem =>
          parentItems.some(parentItem => parentItem.id === flatItem.parentId)
        );
        allItems = [...allItems, ...parentItems];
      }
      updateEdit(allItems, isEdit, column);
    },
    [itemsFlat, item, updateEdit]
  );

  const directChildrenSelect = useCallback(
    (column: ITreeColumnEdit) => directChildren(true, column),
    [directChildren]
  );
  const directChildrenUnSelect = useCallback(
    (column: ITreeColumnEdit) => directChildren(false, column),
    [directChildren]
  );
  const allChildrenSelect = useCallback(
    (column: ITreeColumnEdit) => allChildren(true, column),
    [allChildren]
  );
  const allChildrenUnSelect = useCallback(
    (column: ITreeColumnEdit) => allChildren(false, column),
    [allChildren]
  );
  const editSubOptions = useCallback(
    (column: ITreeColumnEdit) => ({
      items: [
        {
          key: 'children',
          text: 'Select with direct children',
          iconProps: {
            iconName: 'ChevronRight'
          },
          onClick: () => directChildrenSelect(column)
        },
        {
          key: 'unchildren',
          text: 'Unselect with direct children',
          iconProps: {
            iconName: 'ChevronRightSmall'
          },
          onClick: () => directChildrenUnSelect(column)
        },
        {
          key: 'childrenAll',
          text: 'Select with all children',
          iconProps: {
            iconName: 'DoubleChevronRight'
          },
          onClick: () => allChildrenSelect(column)
        },
        {
          key: 'unchildrenAll',
          text: 'Unselect with all children',
          iconProps: {
            iconName: 'DoubleChevronRight8'
          },
          onClick: () => allChildrenUnSelect(column)
        }
      ]
    }),
    [directChildrenSelect, directChildrenUnSelect, allChildrenSelect, allChildrenUnSelect]
  );
  return useMemo(
    () => ({
      isOpen,
      onChevronClick,
      onCheckboxClick,
      onEditClick,
      hasChildren,
      editSubOptions
    }),
    [isOpen, onChevronClick, onCheckboxClick, onEditClick, hasChildren, editSubOptions]
  );
};
