import {FC, useCallback, useContext, useEffect, useState} from 'react';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import styles from './ReleaseNotes.module.scss';
import {TheButton} from '../TheButton';

export const ReleaseNotes: FC = () => {
  const {globalSettings} = useContext(GlobalSettingsContext);
  const [isVisible, setIsVisible] = useState(false);
  const onDismiss = useCallback(() => {
    localStorage.setItem('LastReleaseDate', new Date().toISOString());
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const notes = globalSettings.settings.ReleaseNotes;
    if (!notes) return;
    const lastReleaseDate =
      globalSettings.settings.LastReleaseDate?.length > 2 &&
      new Date(globalSettings.settings.LastReleaseDate);
    const lastBannerClose =
      localStorage.getItem('LastReleaseDate') &&
      new Date(localStorage.getItem('LastReleaseDate') as string);

    const div = document.createElement('div');
    div.innerHTML = notes;
    const text = div.innerText.replace(/\s/g, '').replace(/\u200B/g, '');
    const notesExist = !!text.length || notes.toLowerCase().indexOf('img') !== -1;

    if (notesExist && lastReleaseDate && (!lastBannerClose || lastReleaseDate > lastBannerClose)) {
      setIsVisible(true);
    }
  }, [globalSettings]);

  return isVisible ? (
    <div className={styles.sticky}>
      <div
        className={`sun-editor sun-editor-editable no-border`}
        dangerouslySetInnerHTML={{
          __html: globalSettings.settings.ReleaseNotes
        }}
      />
      <TheButton
        isIcon
        iconProps={{iconName: 'Cancel'}}
        className={styles.close}
        onClick={onDismiss}
      />
    </div>
  ) : null;
};
