import {FC} from 'react';
import {IDictionaryPillProps} from './IDictionaryPillProps';

import style from './DictionaryPill.module.scss';
import {TooltipHost} from '@fluentui/react';

export const DictionaryPill: FC<IDictionaryPillProps> = ({label, value}) => {
  if (!value || (Array.isArray(value) ? !value.length : !value.value)) return null;
  const text = Array.isArray(value) ? value.map(val => val.value).join(', ') : value.value;
  const isLong = text && text.length > 30;
  const displayText = isLong ? text.substring(0, 30) + '...' : text;
  return (
    <div className={style.pill}>
      <b>{label}: </b>
      {isLong ? <TooltipHost content={text}>{displayText}</TooltipHost> : displayText}
    </div>
  );
};
