import styles from './HeadingWithDecoration.module.scss';

interface IProps {
  text: string;
}

export const HeadingWithDecoration = ({ text }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingCursor}></div>
      <h3>{text}</h3>
    </div>
  );
};
