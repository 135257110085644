import styles from '../TreeBuilder.module.scss';
import {ITreeColumn} from './ITreeColumn';
import {ITreeItem} from '../ITreeItem';
import {useMemo} from 'react';
import {InfoTooltip} from '../../InfoTooltip';

interface ITreeBuilderColumnsProps<ITEM extends ITreeItem> {
  columns: ITreeColumn<ITEM>[];
  repeatModeTimes: number;
}

export const TreeBuilderColumns = <ITEM extends ITreeItem>({
  columns,
  repeatModeTimes
}: ITreeBuilderColumnsProps<ITEM>) => {
  const repeadtedColumnsJSX = useMemo(() => {
    let repeadtedColumnsJSX: JSX.Element[] = [];
    for (let j = 1; j <= repeatModeTimes; j++) {
      repeadtedColumnsJSX = [
        ...repeadtedColumnsJSX,
        ...columns
          .filter(column => !column.hidden)
          .map((column, i) => (
            <div
              key={`${column.key}_${j}`}
              className={`${styles.displayGridCell} ${styles.isHeader}${
                i === 0 ? ` ${styles.firstTitle}` : ''
              }`}>
              {column.title}
              {column.tooltip ? <InfoTooltip content={column.tooltip} /> : null}
            </div>
          ))
      ];
    }
    return repeadtedColumnsJSX;
  }, [columns, repeatModeTimes]);
  return <>{repeadtedColumnsJSX}</>;
};
