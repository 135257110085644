import styles from './MasterProcessInput.module.scss';
import {BusinessLineDto} from '../../../../services/generated';
import {FC, useCallback} from 'react';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {DictionaryMultiselectDropdown} from '../../../common/DictionaryDropdown/DictionaryMultiselectDropdown';

interface IMasterProcessInputBusinessLineProps {
  model: MasterProcessItemModel;
  options: BusinessLineDto[] | null;
  disabled?: boolean;
}

export const MasterProcessInputBusinessLine: FC<IMasterProcessInputBusinessLineProps> = ({
  model,
  options,
  disabled
}) => {
  const onChange = useCallback(
    (value: BusinessLineDto[] | undefined) => (model.businessLines = value || null),
    [model]
  );
  return (
    <DictionaryMultiselectDropdown
      className={styles.input}
      options={options}
      onChange={onChange}
      defaultValues={model.businessLines}
      disabled={disabled || !model.isChecked}
    />
  );
};
