import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn, IComboBoxOption} from '@fluentui/react';
import styles from './ProcessesColumn.module.scss';
import {SelectPicker} from '../../SelectPicker';
import {sortByTitle} from '../../../../helpers/arrayMehods';
import {XLSXValue} from '../../../../helpers/excel';

interface IValue {
  id: number;
  title: string;
  url?: string;
}

export class LinksListColumn extends EnhancedColumn {
  public static type = ColumnType.LinksList;

  public static renderer(values: IValue[] | undefined, column: IColumn): JSX.Element {
    return (
      <>
        {values?.map(value => {
          return value.url ? (
            <a
              key={value.id}
              href={value.url}
              className={styles.link}
              target="_blank"
              rel="noreferrer">
              {value.title}
            </a>
          ) : (
            <div key={value.id} className={styles.nonlink}>
              {value.title}
            </div>
          );
        })}
      </>
    );
  }

  public static getColumnValueForSort(values?: IValue[]): string {
    return `${values?.length || 0}`;
  }

  public static hasActiveFilter(value?: IValue[]): boolean {
    return Boolean(value?.length);
  }

  public static renderFilter(
    defaultValue: any,
    onChange: (value?: string | string[] | undefined, options?: IComboBoxOption[]) => void,
    allOptions: IValue[][]
  ): JSX.Element {
    const flatOptions = allOptions.flat();
    const existingOptionIds: number[] = [];
    const uniqueOptions = flatOptions.filter(option => {
      if (existingOptionIds.some(id => option.id === id)) return false;
      existingOptionIds.push(option.id);
      return true;
    });
    const options = [' ', ...new Set(uniqueOptions.sort(sortByTitle).map(option => option.title || ''))];
    return (
      <SelectPicker
        type="combobox"
        multiSelect
        label="Filter"
        defaultValue={defaultValue}
        options={options}
        onChange={(keys, items) => onChange(keys, items)}
        skipSort
      />
    );
  }

  public static filterFunction(itemValue: IValue[], filterValue: string[]): boolean {
    if (!Array.isArray(filterValue)) return false;
    return filterValue.some(filterVal => {
      if (!filterVal) return !itemValue;
      if(filterVal === ' ') return itemValue?.length === 0
      return itemValue?.some(val => val.title === filterVal) || false;
    });
  }

  public static searchFunction(itemValue: IValue[], lowerCaseSearch: string): boolean {
    return itemValue
      ? itemValue.some(process => process.title.toLowerCase().includes(lowerCaseSearch))
      : false;
  }
  public static getColumnValueForExcel(itemValue: IValue[]): XLSXValue {
    if (!itemValue || !itemValue.length) return '';
    const text = itemValue.map(item => item.title).join('<br />').slice(0, 32766);
    return itemValue.length > 1
      ? {
          html: text
        }
      : {
          text,
          link: itemValue[0].url || ''
        };
  }
}
