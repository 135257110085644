import {Spinner} from '@fluentui/react';
import {PropsWithChildren, useEffect, useMemo, useRef, useState} from 'react';

import {ITreeBuilderProps} from './ITreeBuilderProps';
import {ITreeItem} from './ITreeItem';
import styles from './TreeBuilder.module.scss';
import {TreeBuilderColumns} from './TreeColumn';
import {TreeRows} from './TreeRows';

export const TreeBuilder = <ITEM extends ITreeItem>({
  children,
  columns,
  isLoading,
  itemsFlat,
  initialOpen,
  hideTitles,
  keepInView,
  wrapper,
  repeatModeTimes = 1,
  onOpenChange,
  maxHeight,
  onMaxHeightCalc,
  customClassName
}: PropsWithChildren<ITreeBuilderProps<ITEM>>) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [editIds, setEditIds] = useState<number[]>([]);
  useEffect(
    () => setCheckedIds(itemsFlat.filter(item => item.isChecked).map(item => item.id)),
    [itemsFlat]
  );
  const treeRef = useRef<HTMLDivElement | null>(null);
  const wrapperStyle = useMemo(() => {
    if (!keepInView) {
      return undefined;
    }
    const barHeight = '48px';
    const pagePaddingBottom = '35px';
    let customMaxHeight = maxHeight || `calc(100vh - ${barHeight} - ${pagePaddingBottom})`;
    if (wrapper && !maxHeight) {
      customMaxHeight = `calc(100vh - ${barHeight} - ${pagePaddingBottom} - ${
        treeRef.current ? wrapper.clientHeight - treeRef.current.clientHeight : 0
      }px)`;
      if (onMaxHeightCalc) {
        onMaxHeightCalc(customMaxHeight);
      }
    }
    return {
      overflow: 'auto',
      maxWidth: '100% ',
      maxHeight: customMaxHeight
    };
  }, [keepInView, wrapper, treeRef, maxHeight, onMaxHeightCalc]);
  const style = useMemo(() => {
    let gridTemplateColumns = columns
      .filter(column => !column.hidden)
      .reduce(
        (acc, curr) =>
          acc + ` minmax(${curr.minCSSWidth || '150px'}, ${curr.maxCSSWidth || '1fr'})`,
        ''
      );
    const basicGridTemplateColumns = gridTemplateColumns;
    for (let i = 1; i < repeatModeTimes; i++) {
      gridTemplateColumns = `${gridTemplateColumns} ${basicGridTemplateColumns}`;
    }
    return {
      gridTemplateColumns: gridTemplateColumns
    };
  }, [columns, repeatModeTimes]);

  return (
    <div className={styles.displayGrid} style={{...style, ...wrapperStyle}} ref={treeRef}>
      {!hideTitles && <TreeBuilderColumns columns={columns} repeatModeTimes={repeatModeTimes} />}
      {isLoading ? (
        <Spinner />
      ) : (
        <TreeRows
          level={0}
          columns={columns}
          itemsFlat={itemsFlat}
          initialOpen={initialOpen}
          onOpenChange={onOpenChange}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          editIds={editIds}
          setEditIds={setEditIds}
          customClassName={customClassName}
        />
      )}
      {isLoading ? <Spinner /> : children}
    </div>
  );
};
