import {
  FunctionDto,
  Links,
  ProjectSizeCategoryDto,
  IndustryDto,
  SpecialityDto,
  ViewUnitProcessNavigatorDto,
  BusinessLineDto
} from '../services/generated';
import {ProcessType} from '../enums/ProcessEnum';

export class UnitProcessNavigator implements ViewUnitProcessNavigatorDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public processId: number | null;
  public processTitle: string | null;
  public processType: ProcessType;
  public group: string | null;
  public customLink: string | null;
  public position: string | null;
  public linkTitle: string | null;
  public function: FunctionDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public industries: IndustryDto[] | null;
  public businessLines: BusinessLineDto[] | null;
  constructor(unitProcessNavigator: ViewUnitProcessNavigatorDto) {
    this.id = unitProcessNavigator.id;
    this.link = unitProcessNavigator.link;
    this.processId = unitProcessNavigator.processId;
    this.processType = unitProcessNavigator.processType;
    this.processTitle = unitProcessNavigator.processTitle;
    this.group = unitProcessNavigator.group;
    this.customLink = unitProcessNavigator.customLink;
    this.position = unitProcessNavigator.position;
    this.linkTitle = unitProcessNavigator.linkTitle;
    this.function = unitProcessNavigator.function;
    this.projectSizeCategories = unitProcessNavigator.projectSizeCategories;
    this.specialities = unitProcessNavigator.specialities;
    this.industries = unitProcessNavigator.industries;
    this.businessLines = unitProcessNavigator.businessLines;
  }
}
