import {useContext} from 'react';

import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {DefaultPortalSelectionForm} from '../../common/PreferencesForm';
import styles from './Home.module.scss';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import {Spinner} from '@fluentui/react';
import {Helmet} from "react-helmet";

export const Home = () => {
  const {globalSettings, isLoading} = useContext(GlobalSettingsContext);


  return (
    <main>
      <Helmet>
        <title>IMS Home Page</title>
      </Helmet>
      <div className={styles.homePage}>
        {isLoading ? (
          <Spinner />
        ) : globalSettings && globalSettings.settings.homePageTitle ? (
          <>
            <div className={styles.redBorderDecorator}></div>
            <div
              dangerouslySetInnerHTML={{
                __html: globalSettings.settings.homePageTitle
              }}></div>
          </>
        ) : (
          <>
            <HeadingWithDecoration text="Welcome to IMS" />
            <p>
              Integrated Management System is a global operating system for PAEN and PAPI enabling
              the standardization and integration of current local Quality Systems into one globally
              shared environment.
            </p>
            <p>
              It enables collaboration across divisions with easy access to guidelines,
              instructions, procedures and templates for all employees, and encourages experience
              sharing and continuous improvement.
            </p>
            <p>Please select your default portal:</p>
          </>
        )}
        <DefaultPortalSelectionForm redirectOnEnter={true} />
      </div>
    </main>
  );
};
