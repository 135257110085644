import {MessageBarType} from '@fluentui/react';
import {useContext} from 'react';
import {LoggerContext} from '../providers';
import {ApiResponsePending} from './ApiResponseType';
import {LogMessageType} from './LogMessagesType';

export async function responseTypesFixer<T>(request: Promise<T>): ApiResponsePending<T> {
  return (await (request as Promise<any>)) as {
    apiCode: null | string;
    message: null | string;
    result: null | T;
    stackTrace: null | string;
  };
}

export function useApiService() {
  const {addLog, setPageException} = useContext(LoggerContext);
  return async <T, Expected>(
    request: Promise<T>,
    mapping: (response: T, apiCode: string | null) => Expected,
    log: LogMessageType = {error: true}
  ) => {
    try {
      const resp = await responseTypesFixer(request);
      if (resp.apiCode && log?.pageExceptionMessage) {
        setPageException(log?.pageExceptionMessage);
      }
      if (resp.apiCode && log?.error) {
        addLog(MessageBarType.error, resp.message, resp.stackTrace);
      }
      if (!resp.apiCode && log?.success) {
        addLog(MessageBarType.success, log?.successMessage || 'Succeeded', resp.stackTrace);
      }
      const result = resp.result ? mapping(resp.result, resp.apiCode) : null;
      return {...resp, result};
    } catch (error) {
      if (log?.pageExceptionMessage) {
        setPageException(log?.pageExceptionMessage);
      }
      if (log?.error) {
        addLog(
          MessageBarType.error,
          (
            error as {
              body: {
                apiCode: null | string;
                message: null | string;
                result: null | T;
                stackTrace: null | string;
              };
            }
          )?.body?.message || (error as Error)?.message,
          error
        );
      }
      return {
        apiCode: (error as Error)?.name || null,
        message: (error as Error)?.message || null,
        result: null,
        stackTrace: (error as Error)?.stack || null
      };
    }
  };
}
