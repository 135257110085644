import styles from './MasterProcessInput.module.scss';
import {FC, useCallback} from 'react';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {OrderInput} from '../../../common/OrderInput';

export const MasterProcessInputOrder: FC<{
  model: MasterProcessItemModel;
  order: number;
  reorder: (model: MasterProcessItemModel, newOrder: number) => void;
}> = ({model, order, reorder}) => {
  const onChange = useCallback((value: number) => reorder(model, value), [model, reorder]);
  return (
    <OrderInput
      className={styles.input}
      value={order}
      onChange={onChange}
      disabled={!model.isChecked}
    />
  );
};
