import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import {IColumn} from '@fluentui/react';

export class ToggleColumn extends EnhancedColumn {
  public static type = ColumnType.Toggle;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 40;
  public static maxWidth = 40;

  public static renderer(value: string, column: IColumn): JSX.Element {
    return <>Add custom renderer</>;
  }

  public static searchFunction(itemValue: boolean, lowerCaseSearch: string): boolean {
    return `${itemValue || false}`.includes(lowerCaseSearch);
  }
}
