import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {LinksListAddModal} from '../../common/ProcessSections/LinksListAddModal';
import {TheButton} from '../../common/TheButton';
import {EnhancedDetailsList} from '../../common/EnhancedDetailsList';
import {ExternalLink, ExternalLinkWithProcess} from '../../../models';
import {getColumnsLinksCenter} from '../../common/ProcessSections/linksListColumns';
import {useExternalLinks} from '../../../hooks/services/useExternalLinks';
import {Spinner} from '@fluentui/react';
import styles from './LinksCenter.module.scss';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import { useAdmin } from '../../../hooks/services/useAdmin';
import {Helmet} from "react-helmet";

export const LinksCenter: FC = () => {
  const {deleteExternalLink} = useExternalLinks();
  const {getAdminExternalLink} = useAdmin();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [links, setLinks] = useState<ExternalLinkWithProcess[]>([]);
  const [editItem, setEditItem] = useState<ExternalLink>();

  const [isAddModal, setIsAddModal] = useState(false);
  const onOpen = useCallback(() => setIsAddModal(true), []);
  const onClose = useCallback(() => {
    setEditItem(undefined);
    setIsAddModal(false);
  }, []);
  const onEditClick = useCallback(
    (item: ExternalLink) => {
      setEditItem(item);
      onOpen();
    },
    [onOpen]
  );
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const onOpenDelete = useCallback(() => setIsDeleteModal(true), []);
  const onCloseDelete = useCallback(() => {
    setEditItem(undefined);
    setIsDeleteModal(false);
  }, []);
  const onDeleteClick = useCallback(
    (item: ExternalLink) => {
      setEditItem(item);
      onOpenDelete();
    },
    [onOpenDelete]
  );

  const columnsView = useMemo(
    () => getColumnsLinksCenter(onEditClick, onDeleteClick),
    [onEditClick, onDeleteClick]
  );

  const refresh = useCallback(async () => {
    setIsLoading(true);
    const response = await getAdminExternalLink();
    if (response.result) {
      setLinks(response.result);
    }
    setIsLoading(false);
  }, [getAdminExternalLink]);

  const onCreated = useCallback(() => {
    onClose();
    refresh();
  }, [onClose, refresh]);

  const onDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    if (editItem) {
      const result = await deleteExternalLink(editItem.id);
      if (!result.apiCode) {
        onCloseDelete();
      }
    }
    refresh();
  }, [editItem, deleteExternalLink, onCloseDelete, refresh]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <Helmet>
        <title>IMS Links Center</title>
      </Helmet>
      <HeadingWithDecoration text="Links Center" />
      <div className={styles.buttons}>
        <TheButton primary iconProps={{iconName: 'add'}} onClick={onOpen}>
          Create new
        </TheButton>
      </div>
      <LinksListAddModal
        isVisible={isAddModal}
        onClose={onClose}
        onCreated={onCreated}
        editItem={editItem}
      />
      <ModalWithHeader
        header={'Delete custom link'}
        isVisible={isDeleteModal}
        dismiss={onCloseDelete}>
        <p>
          Link <b>{editItem?.title}</b> (
          <a href={editItem?.url || ''} target="_blank" rel="noreferrer">
            {editItem?.url}
          </a>
          ) will be permanently deleted from all processes. Are you sure?
        </p>
        <div className={styles.buttons}>
          <TheButton onClick={onCloseDelete} disabled={isLoading}>
            Cancel
          </TheButton>
          <TheButton
            primary
            iconProps={{iconName: 'delete'}}
            onClick={onDeleteConfirm}
            disabled={isLoading}>
            Confirm {isLoading ? <Spinner /> : null}
          </TheButton>
        </div>
      </ModalWithHeader>
      {isLoading ? <Spinner /> : null}
      {!isLoading ? (
        <EnhancedDetailsList<
          ExternalLinkWithProcess,
          ExternalLinkWithProcess,
          ExternalLinkWithProcess
        >
          onCreateItemClick={onOpen}
          columns={columnsView}
          items={links}
          excelExport
          excelFileName="Exported Links Center List"
        />
      ) : null}
    </>
  );
};
