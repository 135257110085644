import {FC} from 'react';
import {Dropdown, Spinner} from '@fluentui/react';
import styles from '../../UnitManagementProcess.module.scss';
import {IUnitDropdownBulkProps} from './IUnitDropdownBulkProps';
import {useUnitDropdownBulk} from './useUnitDropdownBulk';

export const UnitDropdownBulk: FC<IUnitDropdownBulkProps> = props => {
  const {isLoading, onChange, options} = useUnitDropdownBulk(props);
  return isLoading ? (
    <Spinner />
  ) : (
    <Dropdown
      className={styles.regular}
      onChange={onChange}
      placeholder="Select an option"
      options={options}
      disabled={props.disabled}
    />
  );
};
