import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IRenderFunction,
  ISelectableDroppableTextProps,
  SearchBox,
  SelectableOptionMenuItemType
} from '@fluentui/react';
import {FC, useCallback, useMemo, useState} from 'react';
import {IProcessOption} from './useDiagram';
import styles from './MatrixComboBox.module.scss';

interface IMatrixComboBoxProps {
  processesOptions: IProcessOption[];
  defaultSelectedKey?: string | number;
  onChanged: (option: IComboBoxOption) => void;
}

export const MatrixComboBox: FC<IMatrixComboBoxProps> = ({
  processesOptions,
  defaultSelectedKey,
  onChanged
}) => {
  const initialOptions: IComboBoxOption[] = useMemo(() => {
    const options = [
      {key: 'Header1', text: 'Matrix Function', itemType: SelectableOptionMenuItemType.Header},
      {key: 'Consulting services', text: 'Consulting services', data: true},
      {key: 'Controlling', text: 'Controlling', data: true},
      {key: 'Engineering - Automation', text: 'Engineering - Automation', data: true},
      {key: 'Engineering - Electrical', text: 'Engineering - Electrical', data: true},
      {key: 'Engineering - Overall/General', text: 'Engineering - Overall/General', data: true},
      {key: 'HBU Manager', text: 'HBU Manager', data: true},
      {key: 'HR', text: 'HR', data: true},
      {key: 'HSE', text: 'HSE', data: true},
      {key: 'Inspection', text: 'Inspection', data: true},
      {key: 'LBU Manager', text: 'LBU Manager', data: true},
      {key: 'Managing Director', text: 'Managing Director', data: true},
      {key: 'Manufacturing', text: 'Manufacturing', data: true},
      {key: 'Marketing', text: 'Marketing', data: true},
      {key: 'Master Data Responsible', text: 'Master Data Responsible', data: true},
      {key: 'Operations', text: 'Operations', data: true},
      {key: 'Planning and Fulfillment', text: 'Planning and Fulfillment', data: true},
      {key: 'Procurement and Logistics', text: 'Procurement and Logistics', data: true},
      {key: 'Product Development', text: 'Product Development', data: true},
      {key: 'Project Execution', text: 'Project Execution', data: true},
      {key: 'Project Management', text: 'Project Management', data: true},
      {key: 'Purchasing and Logistics', text: 'Purchasing and Logistics', data: true},
      {key: 'Quality / CI', text: 'Quality / CI', data: true},
      {key: 'Resource Management', text: 'Resource Management', data: true},
      {key: 'Sales', text: 'Sales', data: true},
      {key: 'Service Execution', text: 'Service Execution', data: true},
      {key: 'Site Management', text: 'Site Management', data: true},
      {key: 'Studies', text: 'Studies', data: true},
      {key: 'Technology', text: 'Technology', data: true},
      {key: 'Tendering', text: 'Tendering', data: true},
      {key: 'divider1', text: '-', itemType: SelectableOptionMenuItemType.Divider},
      {key: 'Header2', text: 'Process', itemType: SelectableOptionMenuItemType.Header},
      ...processesOptions,
      {key: 'divider2', text: '-', itemType: SelectableOptionMenuItemType.Divider},
      {key: 'Header3', text: 'Free text', itemType: SelectableOptionMenuItemType.Header}
    ];
    if (defaultSelectedKey && !options.find(option => option.key === defaultSelectedKey)) {
      options.push({
        key: defaultSelectedKey as string,
        text: defaultSelectedKey as string,
        data: true
      });
    }
    return options;
  }, [defaultSelectedKey, processesOptions]);
  const [selectedKey, setSelectedKey] = useState<string | number | undefined>(defaultSelectedKey);
  const [options, setOptions] = useState(initialOptions);
  const onChange = useCallback(
    (
      event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      index?: number,
      value?: string
    ): void => {
      let key = option?.key || '';
      if (!option && value) {
        key = `${value}`;
        const newOption: IComboBoxOption = {key, text: value, data: true};
        setOptions(prevOptions => [...prevOptions, newOption]);
        onChanged(newOption);
      } else if (option) {
        onChanged(option);
      }
      setSelectedKey(key);
    },
    [onChanged]
  );

  const [query, setQuery] = useState('');
  const onSearchChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
      setQuery(newValue || '');
    },
    []
  );

  const onRenderList: IRenderFunction<ISelectableDroppableTextProps<IComboBox, IComboBox>> =
    useCallback(
      (props, defaultRender) =>
        defaultRender && props ? (
          <>
            <SearchBox
              placeholder="Filter options"
              onChange={onSearchChange}
              className={styles.search}
            />
            {defaultRender({
              ...props,
              options: (props.options as IComboBoxOption[]).filter(
                item =>
                  !query ||
                  item.itemType === SelectableOptionMenuItemType.Header ||
                  !item.text ||
                  item.text?.indexOf(query) !== -1
              )
            })}
          </>
        ) : null,
      [onSearchChange, query]
    );

  return (
    <>
      <ComboBox
        allowFreeform={true}
        autoComplete={'on'}
        options={options}
        onChange={onChange}
        selectedKey={selectedKey}
        onRenderList={false ? onRenderList : undefined}
        // shouldRestoreFocus={false}
        // onFocus={() => {}}
        // onFocusCapture={() => {}}
        // calloutProps={{
        //   setInitialFocus: true,
        //   layerProps: {},
        //   popupProps: {}
        // }}
        // onRenderContainer={(props, defaultRender) => (
        //   <div>
        //     ELO
        //     <input />
        //     {defaultRender
        //       ? defaultRender({
        //           ...props,
        //           onFocusCapture: () => {}
        //           // onRenderContainer: (props, defaultRender) => (
        //           //   <div>
        //           //     ELO2
        //           //     <input />
        //           //     {defaultRender ? defaultRender(props) : null}
        //           //   </div>
        //           // )
        //         })
        //       : null}
        //   </div>
        // )}
      />
    </>
  );
};
