import {Configuration, RedirectRequest} from '@azure/msal-browser';

export const scope = `api://${process.env.REACT_APP_API_CLIENT_ID}/user_impersonation`;
export const graphUrl = 'https://graph.microsoft.com/beta';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read', 'User.Read.All'],
  prompt: 'loginPopup',
  redirectUri: `${process.env.REACT_APP_API_URL}`,
  redirectStartPage: '/'
};
