import styles from './MasterProcessInput.module.scss';
import { DictionaryMultiselectDropdown } from '../../../common/DictionaryDropdown/DictionaryMultiselectDropdown';
import {FC, useCallback} from 'react';
import {MasterProcessItemModel} from '../common/MasterProcessItemModel';
import {ProjectSizeCategoryDto} from '../../../../services/generated';

interface IMasterProcessInputProjectSizeCategoryProps {
  model: MasterProcessItemModel;
  options: ProjectSizeCategoryDto[] | null;
  disabled?: boolean;
}

export const MasterProcessInputProjectSizeCategory: FC<
  IMasterProcessInputProjectSizeCategoryProps
> = ({model, options, disabled}) => {
  const onChange = useCallback(
    (value:  ProjectSizeCategoryDto[] | undefined) => {
      model.projectSizeCategories = value ?? null; 
    },
    [model]
  );
  return (
    <DictionaryMultiselectDropdown
      className={styles.input}
      options={options}
      onChange={onChange}
      defaultValues={model.projectSizeCategories}
      disabled={disabled || !model.isChecked}
    />
  );
};
