/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = String
 *
 * 2 = User
 *
 * 3 = Richtext
 *
 * 4 = Comment
 */
export enum ChangeElementType {
  String = 1,
  User = 2,
  Richtext = 3,
  Comment = 4
}
