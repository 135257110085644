import {IDropdownOption} from '@fluentui/react';

export enum ProcessEnum {
  Management = 'Management Processes',
  Execution = 'Execution Processes',
  Support = 'Support Processes',
  Result = 'Result'
}

export enum PositionEnum {
  Top = 'Top',
  Middle = 'Middle',
  Bottom = 'Bottom'
}

export enum StatusColor {
  'DRA' = 'orange',
  'NOM' = 'red',
  'BCE' = 'blue',
  'PYDS' = 'yellow',
  'SRI' = 'green'
}

export enum ProcessType {
  NonProcess,
  Process
}

export enum PublishScenario {
  Save = 0,
  Publish = 1,
  PublishWithNotification = 2,
  PublishAndConfirmProcessValidity = 3,
}

export const dropdownOptionsTypes: IDropdownOption[] = [
  {key: 0, text: ProcessType[0]},
  {key: 1, text: ProcessType[1]}
];
