import styles from './MasterProcessNewForm.module.scss';
import {FC} from 'react';
import {FormFooter} from '../../../common/FormFooter';
import {IMasterProcessNewFormProps} from './IMasterProcessNewFormProps';
import {InputValidationError} from '../../../common/Input/ValidationError';
import {Label} from '@fluentui/react';
import {
  MasterProcessInputFunction,
  MasterProcessInputOrder,
  MasterProcessInputParent,
  MasterProcessInputProjectSizeCategory,
  MasterProcessInputIndustry,
  MasterProcessInputSpeciality,
  MasterProcessInputTitle,
  MasterProcessInputBusinessLine
} from '../input';
import {useMasterProcessNewForm} from './useMasterProcessNewForm';

export const MasterProcessNewForm: FC<IMasterProcessNewFormProps> = ({
  onNewClose,
  onAfterNewCreated,
  masterProcesses,
  optionsFunction,
  optionsProjectSizeCategory,
  optionsIndustry,
  optionsSpeciality,
  optionsBusinessLine,
  defaultParentId
}) => {
  const {
    isLoading,
    onSubmitNew,
    model,
    isErrorFunction,
    isErrorTitle,
    parentOptions,
    order,
    reorder
  } = useMasterProcessNewForm(masterProcesses, onNewClose, onAfterNewCreated, defaultParentId);

  return (
    <>
      <Label className={styles.formInput}>Parent Process</Label>
      <MasterProcessInputParent model={model} options={parentOptions} />
      <Label className={styles.formInput} required>
        Title
      </Label>
      <MasterProcessInputTitle model={model} />
      {isErrorTitle ? (
        <InputValidationError message={'This value could not be empty. Add title.'} />
      ) : null}
      <Label className={styles.formInput} required>
        Function
      </Label>
      <MasterProcessInputFunction model={model} options={optionsFunction} disableErrorMessage />
      {isErrorFunction ? (
        <InputValidationError message={'This value could not be empty. Choose Function.'} />
      ) : null}
      <Label className={styles.formInput}>Order</Label>
      <MasterProcessInputOrder model={model} order={order} reorder={reorder} />
      <Label className={styles.formInput}>Project size category</Label>
      <MasterProcessInputProjectSizeCategory model={model} options={optionsProjectSizeCategory} />
      <Label className={styles.formInput}>Business line</Label>
      <MasterProcessInputBusinessLine model={model} options={optionsBusinessLine} />
      <Label className={styles.formInput}>Industry</Label>
      <MasterProcessInputIndustry model={model} options={optionsIndustry} />
      <Label className={styles.formInput}>Specialty</Label>
      <MasterProcessInputSpeciality model={model} options={optionsSpeciality} />
      <FormFooter isDisabled={isLoading} onCancel={onNewClose} onSubmit={onSubmitNew} />
    </>
  );
};
