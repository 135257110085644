import {useContext, useEffect, useState} from 'react';

import {useProcess} from '../../../hooks/services/useProcess';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';

export const useSubscription = (processId: number) => {
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {
    getSubscription,
    putSubscription,
    putSubscriptionBranch,
    deleteSubscription,
    deleteSubscriptionBranch
  } = useProcess();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<boolean>(false);
  const [apiCode, setApiCode] = useState<string | null>(null);

  const subscribe = () => {
    setSubscription(true);
    putSubscription(processId);
  };
  const unsubscribe = () => {
    setSubscription(false);
    deleteSubscription(processId);
  };

  const subscribeBranch = () => {
    setSubscription(true);
    putSubscriptionBranch(processId);
  };
  const unsubscribeBranch = () => {
    setSubscription(false);
    deleteSubscriptionBranch(processId);
  };

  const getSubscriptionApi = async () => {
    const sub = getSubscription(processId);
    return sub;
  };

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        const data = await getSubscriptionApi();
        setApiCode(data.apiCode);
        setSubscription(data.result || false);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  return {
    subscription,
    subscribe,
    unsubscribe,
    subscribeBranch,
    unsubscribeBranch,
    isLoading,
    apiCode
  };
};
