import {
  Dropdown,
  IDropdownOption,
  Label,
  SpinButton,
  Spinner,
  TextField,
  Toggle
} from '@fluentui/react';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {OrderInput} from '../OrderInput';
import {useProcess} from '../../../hooks/services/useProcess';

interface IPDFDiagramProps {
  isEdit: boolean;
  diagramId?: number | null;
  imageUrl: string;
  setImageUrl: (url: string) => void;
  height: number | null;
  setHeight: (height: number) => void;
  page: number | null;
  setPage: (page: number) => void;
  toolbar: 0 | 1 | null;
  setToolbar: (toolbar: 0 | 1 | null) => void;
  view: 'FitV' | 'FitH' | 'Fit' | null;
  setView: (view: 'FitV' | 'FitH' | 'Fit' | null) => void;
  zoom: number | null;
  setZoom: (zoom: number) => void;
}

export const PDFDiagram: FC<IPDFDiagramProps> = ({
  isEdit,
  diagramId,
  imageUrl,
  setImageUrl,
  height,
  setHeight,
  page,
  setPage,
  toolbar,
  setToolbar,
  view,
  setView,
  zoom,
  setZoom
}) => {
  const [isInitialDiagramBlobLoaded, setIsInitialDiagramBlobLoaded] = useState(false);
  const [initialdiagramUrlObject, setInitialDiagramUrlObject] = useState<string | null>(null);
  const [isDiagramBlobLoaded, setIsDiagramBlobLoaded] = useState(false);
  const [diagramUrlObject, setDiagramUrlObject] = useState<string | null>(null);
  const {getProcessDiagramBlob, getProcessDiagramPreviewBlob} = useProcess();
  const getInitialBlob = useCallback(async () => {
    if (imageUrl && isEdit) {
      setIsInitialDiagramBlobLoaded(false);
      let blob: Blob | undefined = undefined;
      try {
        blob = await getProcessDiagramPreviewBlob(imageUrl);
      } catch (e) {}
      if (blob) {
        const urlObject = URL.createObjectURL(blob);
        setInitialDiagramUrlObject(urlObject);
      } else {
        setInitialDiagramUrlObject(null);
      }
    }
    setIsInitialDiagramBlobLoaded(true);
  }, [imageUrl, isEdit, getProcessDiagramPreviewBlob]);
  const getBlob = useCallback(async () => {
    if (diagramId) {
      let blob: Blob | undefined = undefined;
      setIsDiagramBlobLoaded(false);
      try {
        blob = await getProcessDiagramBlob(diagramId);
      } catch (e) {}
      if (blob) {
        const urlObject = URL.createObjectURL(blob);
        setDiagramUrlObject(urlObject);
      } else {
        setDiagramUrlObject(null);
      }
    }
    setIsDiagramBlobLoaded(true);
  }, [diagramId, getProcessDiagramBlob]);
  useEffect(() => {
    getBlob();
    getInitialBlob();
  }, [getBlob, getInitialBlob]);
  const fitOptions: IDropdownOption[] = useMemo(
    () => [
      {
        key: '',
        text: 'None'
      },
      {
        key: 'FitV',
        text: 'Fit height of page to frame'
      },
      {
        key: 'FitH',
        text: 'Fit width of page to frame'
      },
      {
        key: 'Fit',
        text: 'Fit whole page to frame'
      }
    ],
    []
  );
  const urlHash = `#${typeof toolbar === 'number' ? `toolbar=${toolbar}` : ''}${
    view ? `&view=${view}` : ''
  }${zoom ? `&zoom=${zoom}` : ''}${page ? `&page=${page}` : ''}`;
  return (
    <>
      {isEdit ? (
        <div>
          <TextField
            label="PDF Url"
            value={imageUrl}
            onChange={(e, value) => setImageUrl(value || '')}
          />
          <Label>Show controls</Label>
          <Toggle
            defaultChecked={typeof toolbar === 'number' ? Boolean(toolbar) : true}
            onChange={(e, checked) => setToolbar(checked ? 1 : 0)}
          />
          <Label>Initial page</Label>
          <OrderInput value={page || 1} onChange={setPage} />
          <Label>Height (as % of screen height)</Label>
          <OrderInput value={height || 100} onChange={setHeight} />
          <Label>Page fit</Label>
          <Dropdown
            options={fitOptions}
            defaultSelectedKey={view || ''}
            onChange={(e, option) => setView((option?.key as 'FitV' | 'FitH' | 'Fit' | '') || null)}
          />
          {!view && (
            <>
              <Label>Zoom</Label>
              <SpinButton
                value={`${zoom || 100}`}
                step={10}
                precision={0}
                min={10}
                max={500}
                onValidate={value => {
                  if (value.indexOf('-') !== -1) return '0';
                  if (value.indexOf('.') !== -1) return `${Math.floor(Number(value))}`;
                  return value;
                }}
                onChange={(e, value) => setZoom(Number(value))}
              />
            </>
          )}
        </div>
      ) : null}{' '}
      {isEdit && imageUrl && (
        <>
          {isInitialDiagramBlobLoaded ? (
            initialdiagramUrlObject ? (
              <iframe
                key={`${imageUrl}${urlHash}`}
                style={{width: '100%', height: `${height || 100}vh`}}
                title="Diagram"
                src={`${initialdiagramUrlObject}${urlHash}`}></iframe>
            ) : null
          ) : (
            <Spinner />
          )}
        </>
      )}
      {diagramId && (
        <>
          {isDiagramBlobLoaded && diagramUrlObject ? (
            diagramUrlObject ? (
              <iframe
                style={{width: '100%', height: `${height || 100}vh`}}
                title="Diagram"
                src={`${diagramUrlObject}${urlHash}`}></iframe>
            ) : null
          ) : (
            <Spinner />
          )}
        </>
      )}
    </>
  );
};
