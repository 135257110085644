import {FC} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Pivot, PivotItem, Spinner} from '@fluentui/react';
import {ActivitiesInner} from './ActivitiesInner';
import {useActivitiesCenter} from './useActivitiesCenter';
import styles from './ActivitiesCenter.module.scss';
import {Helmet} from "react-helmet";

export const ActivitiesCenter: FC = () => {
  const {isLoading} = useActivitiesCenter();

  return (
    <>
      <Helmet>
        <title>IMS Activities Center</title>
      </Helmet>
      <HeadingWithDecoration text="Activities Center" />
      {isLoading ? <Spinner /> : null}
      <Pivot>
        <PivotItem headerText="Activities Input" className={styles.tab}>
          <ActivitiesInner isInput={true} allowAdd />
        </PivotItem>
        <PivotItem headerText="Activities Output" className={styles.tab}>
          <ActivitiesInner isInput={false} allowAdd />
        </PivotItem>
      </Pivot>
    </>
  );
};
