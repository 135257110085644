import {
  ActivityDto,
  FunctionDto,
  Links,
  ProjectSizeCategoryDto,
  SpecialityDto,
  UserDto
} from '../services/generated';

export class Activity implements ActivityDto {
  public id: number;
  public link: Links[] | null;
  public created: string | null;
  public createdBy: UserDto | null;
  public deleted: boolean;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public version: number;
  public active: boolean;
  public url: string | null;
  public title: string | null;
  public ownerProcessId: number;
  public order: number;
  public removed: boolean;
  public function: FunctionDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;

  public specialities: SpecialityDto[] | null;

  public functionString: string = '';

  constructor(activity: ActivityDto) {
    this.id = activity.id;
    this.link = activity.link;
    this.created = activity.created;
    this.createdBy = activity.createdBy;
    this.deleted = activity.deleted;
    this.modified = activity.modified;
    this.modifiedBy = activity.modifiedBy;
    this.version = activity.version;
    this.active = activity.active;
    this.url = activity.url;
    this.title = activity.title;
    this.ownerProcessId = activity.ownerProcessId;
    this.order = activity.order;
    this.removed = activity.removed;
    this.function = activity.function;
    this.projectSizeCategories = activity.projectSizeCategories;
    this.specialities = activity.specialities;

    Object.defineProperty(this, 'functionString', {
      get() {
        return this.function?.value || '';
      }
    });
  }
}
