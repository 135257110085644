import {FC} from 'react';
import {ModalWithHeader} from '../ModalWithHeader';
import {LinksListCreate} from './LinksListCreate';
import {ExternalLink} from '../../../models';

interface ILinksListAddModalProps {
  isVisible: boolean;
  allowAdd?: boolean;
  editItem?: ExternalLink;
  onClose: () => void;
  onCreated: (addedLink: ExternalLink | undefined, modifiedLink?: ExternalLink) => void;
  createdByProcessId?: number;
}

export const LinksListAddModal: FC<ILinksListAddModalProps> = ({
  isVisible,
  allowAdd,
  editItem,
  onClose,
  onCreated,
  createdByProcessId
}) => (
  <ModalWithHeader
    header={`${editItem ? 'Update' : 'Add new'} custom link`}
    isVisible={isVisible}
    dismiss={onClose}>
    <LinksListCreate
      onCreated={onCreated}
      allowAdd={allowAdd}
      editItem={editItem}
      createdByProcessId={createdByProcessId}
    />
  </ModalWithHeader>
);
