import {IComboBoxOption} from '@fluentui/react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {PortalStructure} from '../../../enums';
import {useChunkedRequest} from '../../../hooks/services/useChunkedRequest';
import {useMasterProcess} from '../../../hooks/services/useMasterProcess';
import {useUnit} from '../../../hooks/services/useUnit';
import {useService} from '../../../hooks/useService';
import {Unit} from '../../../models';
import {AvailableProcess} from '../../../models/AvailableProcess';
import {PortalDropdownsContext} from '../../../providers';
import {ITreeColumn} from '../../common/TreeBuilder/TreeColumn';
import {ProcessRowOwner, ProcessRowType} from '../UnitManagement/UnitManagementProcesses/inputs';

const divString = PortalStructure.Div;
const countryString = PortalStructure.Country;
const maxChunkSize = 1;

export const useAllExistingProcesses = () => {
  const emptyObjRow = useMemo(() => ({key: '-1', text: ''}), []);
  const {portalStructureData, isLoading} = useContext(PortalDropdownsContext);
  const navigate = useNavigate();

  const {getUnitManageAdminProcess} = useUnit();
  const {getProcessesPublishedForUnitFlatForManage} = useService();
  const {createIndependentProcessInUnit} = useMasterProcess();

  const [divValues, setDivValues] = useState<IComboBoxOption[]>([]);
  const [countryValues, setCountryValues] = useState<IComboBoxOption[]>([]);
  const [divValue, setDivValue] = useState<IComboBoxOption>();
  const [countryValue, setCountryValue] = useState<IComboBoxOption>();
  const [unitId, setUnitId] = useState<string>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [itemsFlat, setItemsFlat] = useState<AvailableProcess[]>([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isDisabled = useCallback((value: IComboBoxOption | undefined) => {
    return value !== undefined ? !Boolean(value.text) : true;
  }, []);

  const buildData = useCallback((filteredData: Unit[]) => {
    const dropdownData: IComboBoxOption[] = filteredData.map(item => ({
      key: item.id,
      text: item.title || ''
    }));
    return dropdownData;
  }, []);

  const buildDataStructure = useCallback(
    (parentId: number | string | null, level: number) => {
      const filteredData = portalStructureData.filter(
        item => item.parentId === parentId && item.unitTypeId === level
      );
      const dropdownData = buildData(filteredData);
      if (level === countryString) {
        setCountryValues([emptyObjRow, ...dropdownData]);
      }
      return filteredData;
    },
    [buildData, emptyObjRow, portalStructureData]
  );

  const buildDivStructure = useCallback(
    (level: number) => {
      const filteredData = portalStructureData.filter(item => item.unitTypeId === level);
      const dropdownData = buildData(filteredData);
      if (level === divString) {
        setDivValues([emptyObjRow, ...dropdownData]);
      }
      return filteredData;
    },
    [buildData, emptyObjRow, portalStructureData]
  );

  const showProcesses = useCallback(() => {
    const whereToPing =
      countryValue && countryValue.key > '-1'
        ? countryValue.key
        : divValue && divValue.key
        ? divValue.key
        : '1';
    setUnitId(whereToPing.toString());
  }, [countryValue, divValue]);

  const onCancel = useCallback(() => {
    const fullUrl = `/admin/masterProcessList`;
    navigate(fullUrl, {replace: true});
  }, [navigate]);

  const onDismiss = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const refreshData = useCallback(async () => {
    if (unitId) {
      const processesToManageResult = await getUnitManageAdminProcess(unitId);
      const processesPublishedForUnitResult = await getProcessesPublishedForUnitFlatForManage(
        Number(unitId)
      );

      const processesPublishedForUnit = processesPublishedForUnitResult.result;
      if (!processesToManageResult.result || !processesPublishedForUnit) return;
      processesPublishedForUnit.forEach(processPublished => {
        const parent = processesPublishedForUnit.find(
          item => item.masterProcessId === processPublished.parentMasterProcessId
        );
        if (parent) {
          processPublished.setParent(parent);
        }
      });
      const itemsFlat = processesToManageResult.result
        .map(
          process =>
            new AvailableProcess(
              process,
              processesPublishedForUnit?.find(
                item => item.masterProcessId === process.masterProcess?.id
              )
            )
        )
        .filter(item => item.isVisible);
      setItemsFlat(itemsFlat);
    }
  }, [getProcessesPublishedForUnitFlatForManage, getUnitManageAdminProcess, unitId]);

  const {sendRequests, isRequestInProgress, moreThanOneChunk, progressStatus} = useChunkedRequest<
    AvailableProcess,
    void
  >(async (data: AvailableProcess[]) => {
    const mapBeforeSave = data.map(item => ({
      masterProcessId: item.masterProcess?.id || 0,
      azureId: item.processOwnerAzureId || '',
      processType: item.type
    }));
    await createIndependentProcessInUnit(Number(unitId), mapBeforeSave);
  }, maxChunkSize);

  const submitProcesses = useCallback(async () => {
    setIsDataLoading(true);
    const modifiedItems = itemsFlat.filter(item => item.isModified);
    const isValid = modifiedItems.every(item => item.isValid);
    setIsInvalid(!isValid);
    if (isValid && unitId) {
      await sendRequests(modifiedItems);
      await refreshData();
    }
    setIsDataLoading(false);
  }, [itemsFlat, unitId, sendRequests, refreshData]);

  const isAnythingLoading = useMemo(
    () => isDataLoading || isRequestInProgress,
    [isDataLoading, isRequestInProgress]
  );

  const columns = useMemo(() => {
    const cols: ITreeColumn<AvailableProcess>[] = [
      {
        key: 1,
        title: 'Title',
        type: 'title',
        showCheckbox: true,
        getTitle: (model: AvailableProcess) => model.title || '',
        minCSSWidth: '500px',
        property: 'title'
      },
      {
        key: 2,
        title: 'Type',
        type: 'simple',
        onRenderColumn: (
          model: AvailableProcess,
          column: ITreeColumn<AvailableProcess>,
          disabled?: boolean
        ) => <ProcessRowType model={model} disabled={model.disabled || disabled} />,
        property: 'type'
      },
      {
        key: 3,
        title: 'Process Owner',
        type: 'simple',
        onRenderColumn: (
          model: AvailableProcess,
          column: ITreeColumn<AvailableProcess>,
          disabled?: boolean
        ) => <ProcessRowOwner model={model} disabled={model.disabled || disabled} />,
        property: 'processOwnerAzureId'
      }
    ];
    return cols;
  }, []);

  const onCountryChange = useCallback((value: IComboBoxOption | undefined) => {
    setCountryValue(value);
  }, []);

  const onDivChange = useCallback(
    (value: IComboBoxOption | undefined) => {
      setDivValue(value);
      setCountryValue(emptyObjRow);
    },
    [emptyObjRow]
  );

  useEffect(() => {
    if (portalStructureData) {
      buildDivStructure(divString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalStructureData]);

  useEffect(() => {
    if (divValue && divValue.key) {
      buildDataStructure(divValue.key, countryString);
    } else if (divValue && divValue.key === '-1') {
      setCountryValue(emptyObjRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divValue]);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      await refreshData();
      setIsDataLoading(false);
    })();
  }, [refreshData]);

  useEffect(() => {
    setIsModalVisible(moreThanOneChunk);
  }, [moreThanOneChunk]);

  return useMemo(
    () => ({
      showProcesses,
      divValues,
      countryValues,
      isLoading,
      columns,
      isDisabled,
      onDismiss,
      onCancel,
      isAnythingLoading,
      submitProcesses,
      isModalVisible,
      isInvalid,
      itemsFlat,
      divValue,
      onDivChange,
      onCountryChange,
      progressStatus,
      countryValue
    }),
    [
      showProcesses,
      divValues,
      countryValues,
      isLoading,
      columns,
      isDisabled,
      onDismiss,
      onCancel,
      isAnythingLoading,
      submitProcesses,
      isModalVisible,
      isInvalid,
      itemsFlat,
      divValue,
      onDivChange,
      onCountryChange,
      progressStatus,
      countryValue
    ]
  );
};
