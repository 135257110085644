import {useMemo} from 'react';
import {useApiService} from '../../services/helpers';
import {LogMessageType} from '../../services/LogMessagesType';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {AdminService, CommentService, UnitService} from '../../services/generated';
import {ProcessComment, ProcessCommentManage} from '../../models';
import {ElevateProcessCommentDto} from '../../services/generated/models/ElevateProcessCommentDto';
import { OpexDto } from '../../services/generated/models/OpexDto';

export const useComments = () => {
  const requestWrapper = useApiService();

  const getComments = async (
    processId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessComment[]> => {
    return await requestWrapper(
      CommentService.getApiComment(processId),
      result => result.map(item => new ProcessComment(item)),
      log
    );
  };

  const getCommentsUnit = async (
    unitId: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessCommentManage[]> => {
    return await requestWrapper(
      UnitService.getApiUnitComments(unitId),
      result => result.map(item => new ProcessCommentManage(item)),
      log
    );
  };

  const getCommentsAdmin = async (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<ProcessCommentManage[]> => {
    return await requestWrapper(
      AdminService.getApiAdminComments(),
      result => result.map(item => new ProcessCommentManage(item)),
      log
    );
  };

  const createComment = async (
    processId: number,
    comment: string,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Comment added successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.postApiComment(processId, comment),
      result => {},
      log
    );
  };

  const raiseComment = async (
    commentId: number,
    opex: OpexDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Comment raised to opex successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.postRaisedToOpexComment(commentId, opex),
      result => {},
      log
    );
  };

  const replyToComment = async (
    processId: number,
    replyToId: number,
    comment: string,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Reply to comment added successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.postApiReplyToComment(processId, replyToId, comment),
      result => {},
      log
    );
  };

  const deleteComment = async (
    commentId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Commmnt deleted successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(CommentService.deleteApiComment(commentId), result => {}, log);
  };

  const deleteComments = async (
    commentId: number[],
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: `Commments deleted successfully`
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.deleteApiCommentDeleteMany(commentId),
      result => {},
      log
    );
  };

  const toggleLike = async (
    commentId: number,
    log: LogMessageType = {
      error: true,
      success: true
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(CommentService.postApiCommentThumbUp(commentId), result => {}, log);
  };

  const toggleDislike = async (
    commentId: number,
    log: LogMessageType = {
      error: true,
      success: true
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.postApiCommentThumbDown(commentId),
      result => {},
      log
    );
  };

  const elevateToProcessComment = async (
    comment: ElevateProcessCommentDto,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'Comment was elevated up'
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(
      CommentService.putApiElevateToProcessComment(comment),
      result => {},
      log
    );
  };

  const resolveComment = async (
    commentId: number,
    log: LogMessageType = {
      error: true,
      success: true,
      successMessage: 'Comment was resolved'
    }
  ): ApiResponsePending<void> => {
    return await requestWrapper(CommentService.putApiCommentResolve(commentId), result => {}, log);
  };

  return useMemo(
    () => ({
      getComments,
      getCommentsUnit,
      getCommentsAdmin,
      createComment,
      deleteComment,
      deleteComments,
      toggleLike,
      replyToComment,
      toggleDislike,
      elevateToProcessComment,
      resolveComment,
      raiseComment
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
