import {useMemo} from 'react';

import {ITreeCommonProps} from '../ITreeCommonProps';
import {ITreeItem} from '../ITreeItem';
import {TreeRow} from './TreeRow';

interface ITreeRowProps<ITEM extends ITreeItem> extends ITreeCommonProps<ITEM> {
  level: number;
  parentId?: number;
  checkedIds: number[];
  setCheckedIds: (ids: number[]) => void;
  editIds: number[];
  setEditIds: (ids: number[]) => void;
  customClassName?: string;
}

export const TreeRows = <ITEM extends ITreeItem>({
  level,
  columns,
  itemsFlat,
  parentId,
  initialOpen,
  checkedIds,
  setCheckedIds,
  editIds,
  setEditIds,
  onOpenChange,
  customClassName
}: ITreeRowProps<ITEM>) => {
  const currentItems = useMemo(
    () => itemsFlat.filter(item => (item.parentId || 0) === (parentId || 0)),
    [itemsFlat, parentId]
  );

  return (
    <>
      {currentItems.map(item => (
        <TreeRow
          key={item.id}
          level={level}
          columns={columns}
          item={item}
          itemsFlat={itemsFlat}
          initialOpen={initialOpen}
          onOpenChange={onOpenChange}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          editIds={editIds}
          setEditIds={setEditIds}
          customClassName={customClassName}
        />
      ))}
    </>
  );
};
