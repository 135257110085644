import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Accordion} from '../../../common/Accordion';
import styles from '../Process.module.scss';
import {Spinner} from '@fluentui/react';
import {useProcess} from '../../../../hooks/services/useProcess';
import {ProcessChange} from '../../../../models';
import {DateTimeColumn, EnhancedDetailsList} from '../../../common/EnhancedDetailsList';
import {IEnhancedColumn} from '../../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {UserIdColumn} from '../../../common/EnhancedDetailsList/ColumnTypes/UserIdColumn';
import {ChangeElementType} from '../../../../services/generated';
import {PeoplePicker} from '../../../common/PeoplePicker';
import {Description} from './Description';
import { PublicationComment } from './PublicationComment';

interface IProcessVersionHistoryProps {
  processId: number;
}

interface IChangeFlat {
  id: number;
  versions: string | null;
  published: string | null;
  publishedBy: string | null;
  field: string | null;
  fieldType: ChangeElementType;
  oldValue: string | null;
  newValue: string | null;
}

export const ProcessVersionHistory: FC<IProcessVersionHistoryProps> = ({processId}) => {
  const {getProcessChanges} = useProcess();
  const wrapper = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(window.location.hash === '#VersionHistory');
  const [isLoaded, setIsLoaded] = useState(false);
  const [changes, setChanges] = useState<ProcessChange[] | null>(null);

  const changesFlat = useMemo(() => {
    const changesFlat: IChangeFlat[] = [];
    changes?.forEach(change => {
      change.changes?.forEach((fieldChange, index) => {
        changesFlat.push({
          id: change.id + index * 1000000,
          versions: change.versions,
          published: change.published,
          publishedBy: change.publishedBy,
          field: fieldChange.field,
          fieldType: fieldChange.fieldType,
          oldValue: fieldChange.oldValue,
          newValue: fieldChange.newValue
        });
      });
    });
    return changesFlat;
  }, [changes]);

  const onToggle = useCallback((isOpen: boolean) => {
    setIsOpen(isOpen);
  }, []);

  const columns = useMemo(() => {
    const stylesHeader: React.CSSProperties = {fontSize: '13px', padding: 0};
    const stylesDescription = {padding: 0, margin: 0};
    const stylesComment = {padding: 0, margin: 0};
    const columns: IEnhancedColumn<IChangeFlat>[] = [
      {
        key: 'versions',
        name: 'Versions',
        minWidth: 110
      },
      {
        key: 'published',
        name: 'Published date',
        minWidth: 165,
        data: DateTimeColumn
      },
      {
        key: 'publishedBy',
        name: 'Published by',
        minWidth: 180,
        data: UserIdColumn,
        exportToExcel: true
      },
      {
        key: 'field',
        name: 'Field',
        minWidth: 120
      },
      {
        key: 'oldValue',
        name: 'Old value',
        minWidth: 290,
        onRender(item, index, column) {
          if (!item.oldValue) return <div className={styles.changes}></div>;
          if (item.fieldType === ChangeElementType.Richtext) {
            return (
              <Description
                unitTitle={'Description'}
                description={{content: item.oldValue}}
                className={styles.changes}
                isOpen={false}
                customStyles={{stylesHeader, stylesDescription}}
              />
            );
          }
          if (item.fieldType === ChangeElementType.Comment) {
            return (
              <PublicationComment
                comment={item.oldValue}
                className={styles.changes}
                isOpen={false}
                customStyles={{stylesHeader, stylesComment}}
              />
            );
          }
          if (item.fieldType === ChangeElementType.String) {
            return <div className={styles.changes}>{item.oldValue}</div>;
          }
          return <PeoplePicker isReadonly defaultSelectedPersonId={item.oldValue} />;
        }
      },
      {
        key: 'newValue',
        name: 'New value',
        minWidth: 290,
        onRender(item, index, column) {
          if (!item.newValue) return <div className={styles.changes}></div>;
          if (item.fieldType === ChangeElementType.Richtext) {
            return (
              <Description
                unitTitle={'Description'}
                description={{content: item.newValue}}
                className={styles.changes}
                isOpen={false}
                customStyles={{stylesHeader, stylesDescription}}
              />
            );
          }
          if (item.fieldType === ChangeElementType.Comment) {
            return (
              <PublicationComment
                comment={item.newValue}
                className={styles.changes}
                isOpen={false}
                customStyles={{stylesHeader, stylesComment}}
              />
            );
          }
          if (item.fieldType === ChangeElementType.String) {
            return <div className={styles.changes}>{item.newValue}</div>;
          }
          return <PeoplePicker isReadonly defaultSelectedPersonId={item.newValue} />;
        }
      }
    ];
    return columns;
  }, []);

  useEffect(() => {
    (async () => {
      if (!isOpen || isLoaded) return;
      const response = await getProcessChanges(processId);
      setChanges(response.result);
      setIsLoaded(true);
    })();
  }, [isOpen, isLoaded, processId, getProcessChanges]);

  useEffect(() => {
    if (!isLoaded) return;
    wrapper.current?.scrollIntoView({
      behavior: 'smooth'
    });
  }, [isLoaded]);

  return (
    <div ref={wrapper} className={styles.processLinksWrapper}>
      <Accordion isOpen={isOpen} header={`Version History`} onToggle={onToggle}>
        {isLoaded ? <EnhancedDetailsList items={changesFlat} columns={columns} /> : <Spinner />}
      </Accordion>
    </div>
  );
};
