import {Label} from '@fluentui/react/lib/Label';
import {PeoplePicker} from '../../../common/PeoplePicker';
import {EnhancedDetailsList} from '../../../common/EnhancedDetailsList';
import {FC, useCallback, useMemo, useState} from 'react';
import {DocumentSystem} from '../../../../models/DocumentSystem';
import {getColumnsAcknowledgement} from '../../../common/ProcessSections/documentAndFileColumns';
import {TheButton} from '../../../common/TheButton';
import {Richtext} from '../../../common/Richtext';
import styles from '../../Process/Process.module.scss';
import {
  DatePicker,
  DayOfWeek,
  IPersonaProps,
  MessageBar,
  MessageBarType,
  Spinner
} from '@fluentui/react';
import {GroupsPicker} from '../../../common/PeoplePicker/GroupPicker';
import {UserDto, GroupDto} from '../../../../services/generated';
import {BatchAcknowledgementSaveDto} from '../../../../services/generated/models/BatchAcknowledgementSaveDto';
import {useAcknowledgementBatch} from '../../../../hooks/services/useAcknowledgementBatch';
import {useNavigate} from 'react-router-dom';
import {dateTodayMidnight} from '../../../../helpers/date';

function mapChangedDocumentToDocument(changedItem: DocumentSystem): DocumentSystem {
  return changedItem;
}

export const Acknowledgement: FC<{
  data: DocumentSystem[] | null;
  ownerProcessId?: number;
  changedDocuments?: DocumentSystem[];
  processId: number;
  unitId?: string;
}> = ({data, ownerProcessId, unitId, processId}) => {
  const navigate = useNavigate();
  const today = dateTodayMidnight;
  const [changedItems, setChangedItems] = useState<DocumentSystem[] | []>([]);
  const {postAcknowledgementForm} = useAcknowledgementBatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedPersons, setSelectedPersons] = useState<IPersonaProps[] | undefined>([]);
  const [selectedGroups, setSelectedGroups] = useState<IPersonaProps[] | undefined>([]);
  const [errorMgs, setErrorMsg] = useState<{
    [key: string]: JSX.Element;
  }>({});

  const [commentValue, setCommentValue] = useState<string | undefined>(undefined);

  const [deadline, setDeadline] = useState<Date | undefined | null>();

  const columnsView = useMemo(
    () => getColumnsAcknowledgement(changedItems, ownerProcessId),
    [changedItems, ownerProcessId]
  );

  const collectPersons = (value: IPersonaProps[] | undefined) => {
    setSelectedPersons(value);
  };

  const collectGroups = (value: IPersonaProps[] | undefined) => {
    setSelectedGroups(value);
  };

  const onFormSave = async (formData: BatchAcknowledgementSaveDto) => {
    setIsSaving(true);
    const result = await postAcknowledgementForm(formData);
    if (!result.apiCode) {
      setChangedItems([]);
      setErrorMsg({});
      setCommentValue(undefined);
      setDeadline(undefined);
      setSelectedPersons(undefined);
      setSelectedGroups(undefined);
    }
    setIsSaving(false);
  };

  const errorMessageBar = useCallback((message: string) => {
    return <MessageBar messageBarType={MessageBarType.severeWarning}>{message}</MessageBar>;
  }, []);

  const validateParts = (validateElement: boolean, errorMsg: string, errorStr: string) => {
    if (validateElement) {
      const errorText = errorMessageBar(errorStr);
      setErrorMsg(prev => ({
        ...prev,
        [errorMsg]: errorText
      }));
    }
  };

  const sendDocumentsHandler = () => {
    setErrorMsg({});
    const documentsToSend = changedItems
      .filter(item => item.checkedAcknowledgementItem === true)
      .map(item => item.id);
    const validatePeopleAndGroup = !selectedPersons?.length && !selectedGroups?.length;
    const validateDocs = !documentsToSend.length;
    const validateDate = !deadline;
    const deadlineValue = deadline && new Date(deadline).valueOf();
    const isDeadlineThePast = !!(deadlineValue && today.valueOf() > deadlineValue);
    const validateComment = !commentValue || !commentValue.length;

    const errorDocMsg = 'No documents selected. Could not send the form';
    validateParts(validateDocs, 'errorDocMsg', errorDocMsg);
    const errorPeopleMsg = 'Neither people nor group selected. Could not send the form';
    validateParts(validatePeopleAndGroup, 'errorPeopleMsg', errorPeopleMsg);
    const errorDateMsg = 'Deadline is required. Could not send the form';
    validateParts(validateDate, 'errorDateMsg', errorDateMsg);
    const errorDatePastMsg = 'Deadline is in the past. Could not send the form';
    validateParts(isDeadlineThePast, 'errorDatePastMsg', errorDatePastMsg);
    const errorCommentMsg = 'Comment is required. Could not send the form';
    validateParts(validateComment, 'errorCommentMsg', errorCommentMsg);

    if (
      validateDocs ||
      validatePeopleAndGroup ||
      validateDate ||
      isDeadlineThePast ||
      validateComment
    ) {
      return;
    }

    const groups = selectedGroups?.map(item => ({
      azureId: (item.key || undefined) as string,
      userPrincipalName: item.secondaryText || null
    }));

    const users = selectedPersons?.map(item => ({
      azureId: (item.key || undefined) as string,
      userPrincipalName: item.secondaryText || null
    }));

    const formData = {
      documentIds: documentsToSend,
      groups: groups as unknown as GroupDto[],
      users: users as unknown as UserDto[],
      deadline: new Date(deadline.setHours(23, 59, 59, 999)).toISOString(),
      comment: commentValue,
      processId: processId
    };
    onFormSave(formData);
  };

  const cancel = () => {
    setChangedItems([]);
    setErrorMsg({});
    navigate(`/unit/${unitId}/process/${processId}`);
  };

  const onChangeComment = useCallback((newValue?: string) => {
    const div = document.createElement('div');
    div.innerHTML = newValue || '';
    if (!div.innerText && newValue?.indexOf('<img') === -1) {
      setCommentValue('');
    } else {
      setCommentValue(newValue);
    }
  }, []);

  if (isSaving) {
    return (
      <div className={styles.acknowledgement}>
        <h5>Acknowledgement required from:</h5>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.acknowledgement}>
      <h5>Acknowledgement required from:</h5>
      {errorMgs.errorPeopleMsg}
      <div className={styles.acknowledgementPicker}>
        <Label htmlFor={'peoplePicker'}>Choose Recipients - Individual Email Address</Label>
        <PeoplePicker
          key={'peoplePicker'}
          onSelectPerson={value => collectPersons(value)}
          itemLimit={50}
        />

        <Label htmlFor={'groupPicker'}>Choose Recipients - Group Email Addresses</Label>
        <GroupsPicker
          key={'groupPicker'}
          onSelectGroup={value => collectGroups(value)}
          itemLimit={50}
        />
        {errorMgs.errorDateMsg}
        {errorMgs.errorDatePastMsg}
        <DatePicker
          label="Choose deadline"
          firstDayOfWeek={DayOfWeek.Monday}
          placeholder="Select a date..."
          ariaLabel="Select a date"
          minDate={today}
          onSelectDate={date => {
            setDeadline(date);
          }}
        />
        {errorMgs.errorCommentMsg}
        <div className={styles.comment}>
          <label>Background / Reason (it will be displayed on the Notification)</label>
          <Richtext onChange={content => onChangeComment(content)} />
        </div>
      </div>
      {errorMgs.errorDocMsg}
      <EnhancedDetailsList<DocumentSystem, DocumentSystem, Document>
        columns={columnsView}
        items={data || []}
        changedItems={changedItems}
        mapChangedItemToItem={mapChangedDocumentToDocument}
        ownerProcessId={ownerProcessId}
      />
      <footer className={styles.acknowledgementBtn}>
        <TheButton onClick={cancel}>Cancel</TheButton>
        <TheButton primary onClick={sendDocumentsHandler}>
          Send documents to acknowledgement
        </TheButton>
      </footer>
    </div>
  );
};
