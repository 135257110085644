import {Links, BusinessLineDto} from '../services/generated';

export class UnitBusinessLine implements BusinessLineDto {
  public id: number;
  public link: Links[] | null;
  public value: string | null;
  public snowflakeId: string | null;
  // public title: string | null;
  // public unitTypeId: number;

  // public get value() {
  //   return this.title;
  // }

  // public get snowflakeId() {
  //   return '';
  // }

  constructor(businessLine: BusinessLineDto) {
    this.id = businessLine.id;
    this.link = businessLine.link;
    this.value = businessLine.value;
    this.snowflakeId = businessLine.snowflakeId;
    // this.title = businessLine.title;
    // this.unitTypeId = businessLine.unitTypeId;
  }
}
