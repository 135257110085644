import {
  BaseItem,
  ColumnKey,
  IEnhancedColumn,
  IEnhancedColumnComplete,
  IEnhancedDetailsListProps,
  SpecialColumnKey
} from './IEnhancedDetailsList';
import {documentSort} from '../../../helpers/arrayMehods';
import {EnhancedAddButton} from './EnhancedAddButton';
import {EnhancedColumn} from './ColumnTypes/EnhancedColumn';
import {IColumn, IComboBoxOption} from '@fluentui/react';
import {OtherColumn} from './ColumnTypes/OtherColumn';
import {StringColumn} from './ColumnTypes';
import {useCallback, useMemo, useState} from 'react';
import {XLSXValue, exportToExcel} from '../../../helpers/excel';
import {InfoTooltip} from '../InfoTooltip';

export const useEnhandedDetailsList = <Item, ChangedItem, ItemToAdd>({
  allowAdd,
  columns,
  columnsForAdd,
  isAddModal,
  items,
  changedItems,
  mapChangedItemToItem,
  mapNewItemToChangedItem,
  ownerProcessId,
  pageSize = 999999,
  excelFileName
}: IEnhancedDetailsListProps<Item & BaseItem, ChangedItem & BaseItem, ItemToAdd & BaseItem>) => {
  const [newItemsIds, setNewItemsIds] = useState<number[]>(
    changedItems
      ?.filter(
        changedItem =>
          !items.some(
            item => changedItem.id === item.id && changedItem.ownerProcessId === item.ownerProcessId
          )
      )
      .map(item => item.id) || []
  );
  const [search, setSearch] = useState<string | undefined>();
  const defaultFilters: {[key: string | number]: string | string[] | undefined} = {};
  for (const column of columns) {
    if (column.defaultFilterValue) {
      defaultFilters[column.key] = column.defaultFilterValue;
    }
  }
  const [filter, setFilter] = useState<{[key: string]: string | string[] | undefined}>(
    defaultFilters
  );
  const [currentPage, setCurrentPage] = useState<'All' | number>(1);
  const [sortedColumnFieldName, setSortedColumnFieldName] = useState<string>('');
  const [sortedColumnIsDescending, setSortedColumnIsDescending] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onColumnClickEvent = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    setSortedColumnFieldName(column.fieldName!);
    const isSortedDescending =
      column.fieldName === sortedColumnFieldName && !sortedColumnIsDescending;
    setSortedColumnIsDescending(isSortedDescending);
  };
  const onRenderColumnByType = useCallback(
    (item?: Item, index?: number | undefined, column?: IColumn | undefined) => {
      if (!column) return <></>;
      const value = (item as {[key: string]: string})[column.key];
      return ((column.data as typeof EnhancedColumn) || EnhancedColumn).renderer(
        value,
        column,
        item
      );
    },
    []
  );
  const columnsList = columns.map<
    IEnhancedColumnComplete<Item & BaseItem> & {data: EnhancedColumn}
  >(column => ({
    key: column.key,
    fieldName: column.key,
    name: column.name,
    minWidth: column.minWidth ?? (column.data ?? StringColumn).minWidth,
    maxWidth: column.maxWidth ?? (column.data ?? StringColumn).maxWidth,
    // flexGrow: column.flexGrow, // TODO not working - NaN
    isResizable: column.isResizable ?? (column.data ?? StringColumn).isResizable,
    isCollapsible: column.isCollapsible ?? false,
    data: column.data ?? StringColumn,
    onRender: column.onRender || onRenderColumnByType,
    onColumnClick: (column.data ?? StringColumn).canSort
      ? column.onColumnClick ?? onColumnClickEvent
      : undefined,
    onRenderHeader:
      column.onRenderHeader ||
      (column.tooltip
        ? (props, defaultRender) => (
            <>
              {defaultRender ? defaultRender(props) : null}
              <InfoTooltip content={column.tooltip as string} />
            </>
          )
        : undefined),
    isSortedDescending: sortedColumnIsDescending,
    isSorted: sortedColumnFieldName === column.key,
    tooltip: column.tooltip
  }));
  const addedIds = useMemo(
    () => [
      ...(items
        ?.filter(item => (ownerProcessId ? ownerProcessId === item.ownerProcessId : true))
        .map(item => item.id) || []),
      ...newItemsIds
    ],
    [items, newItemsIds, ownerProcessId]
  );

  const getKey = useCallback(
    (item: Item & BaseItem) => `${item.id + (item.ownerProcessId || 0) * 1000000000}`,
    []
  );
  const onSearchHandler = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>, searchValue?: string) => {
      setSearch(searchValue);
    },
    []
  );
  const onFilterChange = useCallback(
    (key: ColumnKey, value?: string | string[], options?: IComboBoxOption[]) => {
      const newFilter = {...filter, [key]: value};
      const currentColumn = columns.find(column => column.key === key);
      if (currentColumn?.onFilterChange) {
        currentColumn.onFilterChange(value || '');
      }
      setFilter(newFilter);
    },
    [filter, setFilter, columns]
  );

  const joinItems = useCallback(
    (
      items: (Item & BaseItem)[] | null,
      changedItems?: (ChangedItem & BaseItem)[]
    ): (Item & BaseItem)[] => {
      if (!items) return [];
      if (!changedItems || !changedItems.length || !mapChangedItemToItem) return items;
      const mappedItems = isAddModal
        ? items
        : items.map(item => {
            const changedDocument = changedItems.filter(
              changedDocument =>
                changedDocument.id === item.id &&
                item.ownerProcessId === changedDocument.ownerProcessId
            )[0];
            if (!changedDocument) return item;
            return mapChangedItemToItem(changedDocument);
          });
      const itemsForThisOwnerProcess = items.filter(item => item.ownerProcessId === ownerProcessId);
      const newItemsFiltered = newItemsIds.filter(
        newItemId =>
          itemsForThisOwnerProcess.every(item => item.id !== newItemId) &&
          !mappedItems.some(
            mappedItem =>
              mappedItem.id === newItemId && mappedItem.ownerProcessId === ownerProcessId
          )
      );
      const newItems = newItemsFiltered.map(id =>
        mapChangedItemToItem(
          changedItems.find(
            item => item.id === id && (!ownerProcessId || item.ownerProcessId === ownerProcessId)
          ) as ChangedItem & BaseItem
        )
      );
      return [...mappedItems, ...newItems];
    },
    [mapChangedItemToItem, newItemsIds, ownerProcessId, isAddModal]
  );
  const getSearchItems = useCallback(
    (items: (Item & BaseItem)[], search: string | undefined) => {
      if (!search) {
        return items;
      }
      const lowerCaseSearch = search.toLowerCase().trim();
      return items.filter(item =>
        columnsList.some(column => column.data.searchFunction(item[column.key], lowerCaseSearch))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getFilterItems = useCallback(
    (items: (Item & BaseItem)[], filter: {[key: string]: string | string[] | undefined}) => {
      let filterItems = [...items];
      for (const key in filter) {
        const filterValue: string | string[] | undefined = filter[key];
        const column = columnsList.filter(item => item.key === key)[0];
        if (!column || !filterValue || filterValue.length === 0) continue;
        filterItems = filterItems.filter((item: Item & BaseItem) =>
          column.data.filterFunction((item as {[key: string]: string})[key], filterValue)
        );
      }
      return filterItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getColumnValueForSort = useCallback(
    (
      item: Item & BaseItem,
      column: IEnhancedColumn<Item & BaseItem> & {data: EnhancedColumn}
    ): string => {
      const value = item[column.key];
      return column.data.getColumnValueForSort(value);
    },
    []
  );
  const getSortedItems = useCallback(
    (items: (Item & BaseItem)[], columnKey: string, isSortedDescending?: boolean) => {
      if (!columnKey) return items.sort(documentSort);
      const key = columnKey;
      const column = columnsList.find(col => col.key === key);
      if (!column) return items.sort(documentSort);
      return items.sort((a, b) =>
        (
          isSortedDescending
            ? getColumnValueForSort(a, column) < getColumnValueForSort(b, column)
            : getColumnValueForSort(a, column) > getColumnValueForSort(b, column)
        )
          ? 1
          : -1
      );
    },
    [columnsList, getColumnValueForSort]
  );

  const currentItems = useMemo(() => {
    const allItems = joinItems(items, changedItems);
    const searchedItems = getSearchItems(allItems, search);
    const filterItems = getFilterItems(searchedItems, filter);
    if (currentPage !== 'All' && filterItems.length / pageSize + 1 < currentPage) {
      setCurrentPage(1);
    }
    const sortedItems = getSortedItems(
      filterItems,
      sortedColumnFieldName,
      sortedColumnIsDescending
    );

    return sortedItems;
  }, [
    items,
    changedItems,
    joinItems,
    filter,
    search,
    sortedColumnFieldName,
    sortedColumnIsDescending,
    getFilterItems,
    getSearchItems,
    getSortedItems,
    pageSize,
    currentPage
  ]);

  const currentTableData = useMemo(() => {
    if (currentPage === 'All') return currentItems;
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return currentItems.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, currentItems]);

  const columnsForAddList: IEnhancedColumn<ItemToAdd & BaseItem>[] = useMemo(() => {
    return [
      {
        key: SpecialColumnKey.Add,
        name: '',
        isResizable: false,
        minWidth: 90,
        maxWidth: 90,
        onRender: (item: ItemToAdd & BaseItem) => (
          <EnhancedAddButton
            item={item}
            addedIds={addedIds}
            addDocument={newItem => {
              const mappedItem = mapNewItemToChangedItem
                ? mapNewItemToChangedItem({...newItem, ownerProcessId})
                : {...(newItem as any), ownerProcessId};
              mappedItem.active = true;
              changedItems?.push(mappedItem);
              if (allowAdd) {
                setNewItemsIds([...newItemsIds, newItem.id]);
              }
            }}
          />
        ),
        data: OtherColumn
      },
      ...(columnsForAdd || [])
    ];
  }, [
    allowAdd,
    columnsForAdd,
    addedIds,
    changedItems,
    newItemsIds,
    ownerProcessId,
    mapNewItemToChangedItem,
    setNewItemsIds
  ]);

  const onExportExcel = useCallback(
    (useFiltered?: boolean) => {
      const columnsToExport = columns
        .filter(col => col.exportToExcel)
        .map(col => ({
          title: col.name,
          property: col.key,
          getColumnValueForExcel:
            col.data?.getColumnValueForExcel || EnhancedColumn.getColumnValueForExcel,
          column: col
        }));
      const itemsToExport = (useFiltered === true ? currentItems : items).map(item => {
        const exportItem: {[key: string | number | symbol]: XLSXValue} = {};
        for (const column of columnsToExport) {
          exportItem[column.property] = column.column.onExportToExcel
            ? column.column.onExportToExcel(item, column.column)
            : column.getColumnValueForExcel(item[column.property]);
        }
        return exportItem;
      });
      exportToExcel(itemsToExport, columnsToExport, excelFileName);
    },
    [items, currentItems, columns, excelFileName]
  );


  const exportMenuProps = useMemo(
    () => ({
      items: [
        {
          key: 'filtered',
          text: 'Export filtered',
          iconProps: {
            iconName: 'MultiSelectMirrored'
          },
          onClick: () => {
            onExportExcel(true);
          }
        }
      ]
    }),
    [onExportExcel]
  );

  return useMemo(
    () => ({
      columnsForAddList,
      columnsList,
      currentItemsLength: currentItems.length,
      currentPage,
      currentTableData,
      filter,
      getKey,
      isModalVisible,
      onFilterChange,
      onSearchHandler,
      newItemsIds,
      setCurrentPage,
      setIsModalVisible,
      onExportExcel,
      exportMenuProps
    }),
    [
      columnsForAddList,
      columnsList,
      currentItems,
      currentPage,
      currentTableData,
      filter,
      getKey,
      isModalVisible,
      onFilterChange,
      onSearchHandler,
      newItemsIds,
      setCurrentPage,
      setIsModalVisible,
      onExportExcel,
      exportMenuProps
    ]
  );
};
