import {FC} from 'react';
import {ExternalLink} from '../../../../models';
import {Accordion} from '../../../common/Accordion';
import {LinksList} from '../../../common/ProcessSections/LinksList';
import styles from '../Process.module.scss';

interface IProcessExternalLinksProps {
  externalLinks: ExternalLink[] | null;
}

export const ProcessExternalLinks: FC<IProcessExternalLinksProps> = ({externalLinks}) => {
  return (
    <div className={styles.processLinksWrapper}>
      <Accordion isOpen={!!externalLinks?.length} header={'Links'}>
        {externalLinks ? <LinksList data={externalLinks} /> : undefined}
      </Accordion>
    </div>
  );
};
