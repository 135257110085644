import {IProcessRowValue} from './ITypes';
import {ProcessFlatStructure, ProcessSlim} from '../../../../models';
import {ProcessType} from '../../../../enums';

export class TreeProcessModel {
  public isVisible: boolean;

  public get id() {
    return this.processToManage.masterProcess.id;
  }
  public get parentId() {
    return this.processToManage.masterProcess.parentId;
  }

  public isEdit = false;

  private initialIsChecked: boolean;
  public isChecked: boolean;

  private initialParentUnitId: number | undefined;
  public parentUnitId: number | undefined;

  private initialParentUnitProcessId: number | undefined;
  public parentUnitProcessId: number | undefined;

  private initialType: ProcessType;
  public type: ProcessType;

  private initialProcessOwnerAzureId: string | undefined;
  public processOwnerAzureId: string | undefined;

  public parentUnitTitle = this.processPublished?.parentUnitTitle;
  public isMirrorCopy = this.processPublished?.isMirrorCopy || false;

  public get title() {
    return this.processPublished?.title || this.processToManage.title || '';
  }

  public get rowValue(): IProcessRowValue {
    return {
      deleted: !this.isChecked,
      processOwnerAzureId: this.processOwnerAzureId || null,
      type: this.type,
      parentUnitId: this.parentUnitId || null,
      parentUnitProcessId: this.parentUnitProcessId,
      id: this.processPublished?.id || 0,
      link: null,
      parentProcessId: this.parentUnitProcessId || this.processToManage.id,
      masterProcessId: this.processToManage.masterProcess.id
    };
  }

  public get isModified() {
    return (
      this.initialIsChecked !== this.isChecked ||
      (this.isChecked &&
        (this.initialType !== this.type ||
          this.initialProcessOwnerAzureId !== this.processOwnerAzureId ||
          this.initialParentUnitId !== this.parentUnitId ||
          this.initialParentUnitProcessId !== this.parentUnitProcessId))
    );
  }

  public get isValid() {
    return !this.isChecked || (typeof this.type === 'number' && this.processOwnerAzureId);
  }

  public get orderPrefix() {
    return this.processPublished?.orderPrefix;
  }

  constructor(public processToManage: ProcessSlim, public processPublished?: ProcessFlatStructure) {
    this.isVisible =
      !processToManage.deleted || (processPublished && !processPublished.deleted) || false;

    const isChecked = (processPublished && !processPublished.deleted) || false;
    this.isChecked = isChecked;
    this.initialIsChecked = isChecked;

    const parentUnitId = processPublished?.parentUnitId;
    this.parentUnitId = parentUnitId || undefined;
    this.initialParentUnitId = parentUnitId || undefined;

    const parentUnitProcessId = processPublished?.parentUnitId
      ? processPublished.parentProcessId
      : undefined;
    this.parentUnitProcessId = parentUnitProcessId;
    this.initialParentUnitProcessId = parentUnitProcessId;

    const type =
      typeof processPublished?.processType === 'undefined'
        ? ProcessType.Process
        : processPublished.processType;
    this.type = type;
    this.initialType = type;

    const processOwnerAzureId = processPublished?.processOwner?.azureId;
    this.processOwnerAzureId = processOwnerAzureId;
    this.initialProcessOwnerAzureId = processOwnerAzureId;
  }
}
