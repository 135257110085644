import {createContext} from 'react';

export interface IPreviousUnitPageContext {
  unitId: number | null;
  setUnitId: (unitId: number) => void;
}

export const PreviousUnitPageContext = createContext<IPreviousUnitPageContext>({
  unitId: null,
  setUnitId: (unitId: number) => {}
});
